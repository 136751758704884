import React, { useEffect, useState } from 'react'
import {
  HeaderContainer, 
  ButtonsContainer, 
  TextInput, 
  Container, 
  TableContainer 
} from "./route-details-style";
import CategoryButton from '../../components/buttons/category-button/CategoryButton';
import { TruckCalendar } from '../../components/truck-calendar/TruckCalendar'
import { useNavigate, useParams } from 'react-router-dom';
import { getRouteByIdAction } from './route-actions';
import { ASIGN_ROUTES, TASK_DETAILS_ROUTE } from '../../routes/routes';
import { getTasksAction } from '../task-details/tasks-actions';
import { COLORS_TRUCK } from '../../utils/colors';
import { TruckMap } from '../../components/buttons/truck-map/TruckMap';

export const RouteDetailsScreen = () => {
  const { routeId } = useParams()
  const navigation = useNavigate()
  const [route, setRoute] = useState([])
  const [tasks, setTasks] = useState([])
  
  const getRoute = async () => {
    try {
      const { data } = await getRouteByIdAction(routeId)
      setRoute([data.findRoute])
    } catch (error) {
      console.log('error', error)
    }
  }

  const getTaskByRoute = async () => {
    try {
      const filter = {
        route: routeId
      }

      const { data } = await getTasksAction(filter)

      assignTask(data.myTasks)
    } catch (error) {
      
    }
  }

  const assignTask = (data) => {
    let taskArray = []
    for(let index in data) {
        let taskObj = { backgroundColor: COLORS_TRUCK.ORANGE_TRUCK, borderColor: COLORS_TRUCK.ORANGE_TRUCK }

        taskObj.title = data[index].name
        taskObj.start = data[index].start_date
        taskObj.end = data[index].end_date
        taskObj.id = data[index]._id
     
        taskArray.push(taskObj)
    }
    
    setTasks(taskArray)
  }

  useEffect(() => {
    getRoute()
    getTaskByRoute()
  }, [])
  
  return (
    <Container>
       <div style={{justifyContent: 'center'}}>
        <HeaderContainer style={{width: '100%'}}>
              <TextInput style={{paddingTop: 15}}>
                  { route[0]?.name }
              </TextInput>
              <ButtonsContainer>
                  <CategoryButton onClick={() => navigation(`${TASK_DETAILS_ROUTE}/route` )}  title="Añadir Tareas" />
              </ButtonsContainer>
          </HeaderContainer>
       </div>
        <TruckMap dailyPlanning={true} routes={route} />
        <TableContainer>
          <TruckCalendar style={{width: '93%'}} task={ tasks } setTask={ setTasks } />
        </TableContainer>
    </Container>
  )
}
