import { gql } from '@apollo/client';

export const CREATE_TASK_MUTATION = gql`
mutation createTaskByAdmind( $company_or_client_id_input: ObjectId! $input: CreateTaskInput!) {
  createTask( input: $input,  company_or_client_id_input: $company_or_client_id_input ){
      _id
      start_date
      end_date 
      status
      estimated_km
      client{
        _id
        firts_name
        last_name
      }
      incidents
     load{
       _id
       comments
       coords
       date
       start_hour
       end_hour
       unload_id
     }
     unload{
       _id
       comments
       coords
       date
       start_hour
       end_hour
       load_id
     }
    }
}
`;

export const CREATE_TASK_LOAD_MUTATION = gql`
mutation addTaskLoad($input: [TaskLoadWithUnloadsIdsInput!]!, $task_id: ObjectId!) {
  addTaskLoad(input: $input, task_id: $task_id){
   _id
  }
}
`;

export const CREATE_TASK_DOWNLOAD_MUTATION = gql`
mutation addTaskUnLoad($input: [TaskUnLoadWithUnloadsIdsInput!]!, $task_id: ObjectId!) {
  addTaskUnLoad(input: $input, task_id: $task_id){
   _id
  }
}
`;

export const REMOVE_TASK_MUTATION = gql`
mutation deleteTask($task_id: ObjectId!) {
  deleteTask(task_id: $task_id){
        _id
  }
}
`;

export const REMOVE_TASK_LOAD_MUTATION = gql`
mutation deleteTaskLoad($input: UpdateObjectInput!) {
  deleteTaskLoad(input: $input){
        _id
  }
}
`;

export const REMOVE_TASK_DOWNLOAD_MUTATION = gql`
mutation deleteTaskUnLoad($input: UpdateObjectInput!) {
  deleteTaskUnLoad(input: $input){
        _id
  }
}
`;

export const REMOVE_TASK_UNLOAD_MUTATION = gql`
mutation deleteTaskUnload($input: UpdateObjectInput!) {
  deleteTaskUnload(input: $input){
        _id
  }
}
`;

export const UPDATE_TASK_MUTATION = gql`
mutation updateTask($input: UpdateTaskInput!, $task_id: ObjectId!) {
  updateTask(input: $input, task_id: $task_id){
   _id
   start_date
  }
}
`

export const UPDATE_TASK_LOAD_MUTATION = gql`
mutation updateTaskLoad($task_id: ObjectId!,$input: UpdateTaskLoadInput!) {
  updateTaskLoad(task_id: $task_id,input: $input){
   _id
  }
}
`

export const UPDATE_TASK_DOWNLOAD_MUTATION = gql`
mutation updateTaskUnLoad($task_id: ObjectId!, $input: UpdateTaskUnloadInput!) {
  updateTaskUnLoad(task_id: $task_id, input: $input){
   _id
  }
}
`

export const GET_TASKS_MUTATION = gql`
query myTasks($input: TaskFiltersInput!) {
  myTasks(input: $input){
    _id
   coords {
     country
     x
     y
   }
   end_date
   name
   estimated_km
   start_date
   status
   route {
    _id
    name
    color
   }
   load {
    coords
    address {
      direction
      place
      ref
    }
    start_hour
    end_hour
    date
   }
   unload {
    address {
      direction
      place
      ref
    }
    coords
    start_hour
    end_hour
    date
    recipient_dni
    recipient_name
    recipient_place
    delivery_object
    task_duration
    recipient_delivered
    order
   }
   client {
    _id
    firts_name
    last_name
   }
   incidents
  }
}
`;

export const GET_TASKS_CLIENT_MUTATION = gql`
query findTasksByClient($input: TaskFiltersInput!) {
  findTasksByClient(input: $input){
    _id
   coords {
     country
     x
     y
   }
   end_date
   name
   estimated_km
   start_date
   status
   route {
    _id
    name
    color
   }
   load {
    coords
    address {
      direction 
      place
    }
    start_hour
    end_hour
    date
   }
   unload {
    coords
    address {
      direction
      place
      ref
    }
    start_hour
    end_hour
    date
    delivery_object
   }
   incidents
  }
}
`;

export const GET_TASK_BY_ID_MUTATION = gql`
query findTaskById($task_id: ObjectId!) {
  findTaskById(task_id: $task_id) {
    _id
    start_date
    end_date 
    status
    estimated_km
    name
    route {
      _id
      name
    }
    client{
      _id
      firts_name
      last_name
    }
    load{
      _id
      address {
        direction
        place
      }
      comments
      coords
      date
    }
    unload{
      _id
      address {
        direction
        place
        ref
        phone
      }
      recipient_name
      recipient_dni
      comments
      coords
      date
      start_hour
      end_hour
      task_duration
      delivery_object
    }
    notified
    images {
      _id
      image
      type
    }
    
  }
}
`

export const GET_TASKS_STATUSES_MUTATION = gql`
query taskStatuses($name: String) {
  taskStatuses(name: $name){
    _id
    name
    translate {
      langs {
       de
       es
       fr
       pt
       en
       it
      }
    }
  }
}
`;

export const GET_TASK_IMAGES_MUTATION = gql`
query showTaskImages($input: ShowImagesInput!) {
  showTaskImages(input: $input){
    _id
    fullpath
    path
  }
}
`;

export const EXPORT_TASKS_CSV_MUTATION = gql`
query exportMyTasks($input: TaskFiltersInput) {
  exportMyTasks(input: $input){
        fullpath
        path
        status
  }
}
`;

export const EXPORT_TASKS_EXCEL_MUTATION = gql`
query exportTasksXLSX($input: TaskFiltersInput) {
  exportTasksXLSX(input: $input){
      fullpath
      path
      status
  }
}
`;

export const IMPORT_TASKS_MUTATION = gql`
mutation processTasksFromExcel($file: Upload!) {
  processTasksFromExcel(file: $file) 
}
`;

export const IMPORT_TASK_EXCEL_MUTATION = gql`
mutation importTasksFromExcel($file: Upload!) {
  importTasksFromExcel(file: $file)
}
`

export const ADD_IMAGES_TASK = gql`
mutation addTaskImages($task_id: ObjectId!, $images: [AddTaskImages!]!) {
  addTaskImages(task_id: $task_id, images: $images) {
    images {
      _id
    }
  }
}
`

export const CHANGE_TASK_STATUS = gql`
mutation changeTaskStatus($status: TaskStatus!, $task_id: ObjectId!) {
  changeTaskStatus(status: $status, task_id: $task_id) {
    _id
  }
}
`

export const DELETE_TASK_IMAGE = gql`
mutation deleteTaskImage($input: UpdateObjectInput!) {
  deleteTaskImage(input: $input) {
    images {
      _id
    }
  }
}
`

export const UPDATE_TASK_COORDS_MUTATION = gql`
mutation updateCoords($input: CoordsInput!, $task_id: ObjectId! ) {
  updateCoords(input: $input, task_id: $task_id) {
    city
  }
}
`