import React, { useEffect, useState, useRef } from "react";
import {
  TableTitle,
  TableInfoContainer,
  ChatFilterButtonsContainer,
  ProfileFormSeparator,
  SendIcon,
  MessageInputContainer,
  MessageText,
  Message,
  MessaeTimeText,
  ChatContainer,
  ChatFirstChild,
  ChatSecondChild,
  SendLoader
} from "./chats-styles";
import i18n from '../../i18n/i18n';
import searchIcon from "../../assets/profile/search-icon.svg";
import sendIcon from "../../assets/chats/right-arrow-white.svg";
import FormTextInput from "../../components/inputs/forms/form-text-input/FormTextInput";
import { COLORS_TRUCK } from "../../utils/colors";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { useSelector } from "react-redux";
import { getDrivers } from "../driver-details/driver-details-actions";
import { useSubscription } from "@apollo/client";
import { GET_MESSAGES_SUBSCRIPTION } from "./chats-queries";
import { getChat, sendMessageAction, readMessagesAction, getChatClient, readMessagesClientAction, sendMessageClientAction } from "./chats-actions";
import moment from "moment";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ScrollableDivNested from "../../components/scrollable-div/scrollableDivNested";
import ChatsScreen from "./ChatsScreen";
import { CHAT_ROUTE } from "../../routes/routes";
import { FormTextInputMainContainer } from "../../components/inputs/forms/form-text-input/form-text-input-styles";
import { TextInputContainer } from "../../components/inputs/forms/form-select/form-select-styles";
import { getClientByIdAction } from "../super-admin/clients/clients-actions";
import Loader from "../../components/loader/Loader.jsx";

const ChatScreen = ({rol}) => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([])
  const [searchMessages, setSearchMessages] = useState("")
  const [message, setMessage] = useState("")
  const dispatch = useAppDispatch()
  const userLogged = useSelector((state) => state).userSlice
  const [loading, setLoading] = useState(false)
  const [limit, setlimit] = useState(10)
  const [messagesConfig, setMessagesConfig] = useState({ limit: limit, offset: 0 })
  const [keepIncreasingMessage, setKeepIncreasingMessage] = useState(true)
  const [driver, setDriver] = useState([])
  const isMounted = React.useRef(true)
  const { driverId } = useParams()
  const { search } = useLocation()
  const messageInputRef = useRef(null);
  const [boxHeight, setBoxHeight] = useState(56)
  const inputRef = useRef(null)

  useEffect(() => {
    localStorage.removeItem("numberOfUnreadMessages")
    if (userLogged?.plan_details?.status === "CANCELED") {
      dispatch(openAlert({
        alertType: "plan-canceled",
        isAlertOpen: true
      }))
    }
    if (userLogged?.plan_details?.status === "WAITING") {
      dispatch(openAlert({
        alertType: "plan-waiting",
        isAlertOpen: true
      }))
    }

    (async () => {
      search.includes('driver') ? await getDriverFunc() : await getClientFunc()
    })()

    if (driverId || userLogged.rol == 'client') getChatMessages()


    const observer = new ResizeObserver((entries) => {
      const boxElem = entries[0];
      setBoxHeight(boxElem.contentRect.height)
    });
    observer.observe(messageInputRef.current);

    return () => {
      isMounted.current = false;
    };
  }, [messagesConfig, searchMessages, keepIncreasingMessage])

  useEffect(() => {
    localStorage.removeItem("numberOfUnreadMessages")
    if (userLogged?.plan_details?.status === "CANCELED") {
      dispatch(openAlert({
        alertType: "plan-canceled",
        isAlertOpen: true
      }))
    }
    if (userLogged?.plan_details?.status === "WAITING") {
      dispatch(openAlert({
        alertType: "plan-waiting",
        isAlertOpen: true
      }))
    }

    (async () => {
      search.includes('driver') ? await getDriverFunc() : await getClientFunc()
    })()

    if (driverId) {
      setMessages([])
      setMessagesConfig({ limit: limit, offset: 0 })
    }


    const observer = new ResizeObserver((entries) => {
      const boxElem = entries[0];
      setBoxHeight(boxElem.contentRect.height)
    });
    observer.observe(messageInputRef.current);

    return () => {
      isMounted.current = false;
    };
  }, [driverId])

  useSubscription(GET_MESSAGES_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {


      if (driverId &&
        data?.newMessage &&
        data?.newMessage?.from_user_id === driverId) {
        setMessagesConfig({ limit: limit, offset: 0 })
      }
    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  const getDriverFunc = async () => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: -1,
          offset: 0
        },
        driver_id: driverId || null,
        email: null
      }

      const res = await getDrivers(data)

      const driver = res?.data?.myDrivers[0]

      if (!driverId) {
        navigate(`${CHAT_ROUTE}/${driver._id}`)
      }

      setDriver(driver)
      setLoading(false)
      return driver

    } catch (e) {
      setLoading(false)
    }
  }

  const getClientFunc = async () => {
    try {
      if(driverId) {
        let res = await getClientByIdAction(driverId)
        setDriver(res.data.findMyClientById)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }


  const getChatMessages = async (reset = false, newMessage = null) => {
    if (driverId  || userLogged.rol == 'client') {
      try {
        if (reset) {
          setSearchMessages("")
        }

        const data = {
          message: reset ? null : searchMessages,
          pagination: {
            limit: messagesConfig.limit,
            offset: messagesConfig.offset
          },
          sort: {
            field: "createdAt",
            type: "DESC"
          }
        }

        const res = search.includes('driver') ? await getChat(driverId, data) : search.length <= 0 ? await getChatClient(userLogged?.companies_ids[0], data) : await getChatClient(driverId, data)
        let user =  userLogged._id
        const chats = res?.data?.getChat || res?.data?.getChatClientAdmin
        if(chats.find(item => !item.read )?.to_user_id == user) readMessagesFunc( driverId || userLogged?.companies_ids[0] )
        if (chats.length <= 0) setKeepIncreasingMessage(false) 
        else setKeepIncreasingMessage(true)

        if (chats.length > 0) {
          if (!searchMessages) {
            let messagesArray = reset ? chats : [...messages].concat(chats)

            if (newMessage) {
              messagesArray = messagesArray.concat(newMessage)
            }

            const finalMessages = messagesArray
              .filter((item, index, self) => index === self.findIndex((t) => t._id === item._id))
              .sort((a, b) => moment(b.datetime) - moment(a.datetime))

            setMessages(finalMessages)
          } else {
            let messagesArray = [...chats ]

            if (newMessage) {
              messagesArray = messagesArray.concat(newMessage)
            }

            const finalMessages = messagesArray
              .sort((a, b) => moment(b.datetime) - moment(a.datetime))

            setMessages(finalMessages)
          }
        }


      } catch (e) {
        dispatch(openAlert({
          alertType: "error",
          isAlertOpen: true
        }))
      }
    }
  }

  const readMessagesFunc = async (driverId) => {
    try {
      console.log('driverId', driverId)
      if(driverId) search.includes('driver') ? await readMessagesAction(driverId) : await readMessagesClientAction(driverId)
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const sendMessage = async () => {
    try {
      setLoading(true)
      const data = {
        to_user_id: userLogged.rol == 'client' ? userLogged?.companies_ids[0] : driverId,
        message: message
      }

      search.includes('driver') ? await sendMessageAction(data) : await sendMessageClientAction(data)
      setMessage("")
      setLoading(false)
      setTimeout(() => {
        inputRef.current.focus()
        setMessagesConfig({ limit: limit, offset: 0 })
      }, 1000)
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && driverId && message) {
      event.preventDefault();
      sendMessage()
    }
  };

  const handleKeyPressMessageFilter = (event) => {
    if (event.key === 'Enter' && driverId) {
      event.preventDefault();
      getChatMessages()
    }
  };

  const onEndReachMessages = () => {
    if (keepIncreasingMessage) {
      setMessagesConfig((prevState) => {
        return {
          limit: prevState.limit + limit,
          offset: prevState.offset + limit
        }
      })
    }

  }


  return (
    <div style={{width: '100%', display: "flex", justifyContent: "center", paddingTop: 60}}>
      <div style={{width: '90%'}}>
        <ChatFilterButtonsContainer>
          
          <TableInfoContainer>
            <TableTitle>
              {`${i18n.t('CHATS.title')} ${driver?.name || driver?.firts_name || (userLogged.rol == 'client' && 'Admin') || '...' }`}
            </TableTitle>
          </TableInfoContainer>
          <FormTextInput
            placeholder={i18n.t('CHATS.searchInputPlaceholder')}
            type={"text"}
            onKeyPress={handleKeyPressMessageFilter}
            value={searchMessages}
            onChange={({ target: { value } }) => {
              setMessagesConfig({ limit: limit, offset: 0 })
              setSearchMessages(value)
            }}
            required
            icon={searchIcon}
          />
        </ChatFilterButtonsContainer>
        <ProfileFormSeparator />
        <ScrollableDivNested
          reversed
          style={{
            height: `calc(83vh - ${100 + boxHeight}px)`,
            position: 'relative',
          }}
          onEndReach={() => onEndReachMessages()}
        >
          {messages.map((message, index) => {
            const isOwnMessage = message.from_user_id === userLogged._id
            return (
              <Message
                style={{
                  border: isOwnMessage ? `1px solid ${COLORS_TRUCK.ORANGE_TRUCK}` : `1px solid rgb(48, 37, 33, 0.1)`,
                  alignSelf: isOwnMessage ? "flex-end" : "flex-start"
                }}
                key={index}
              >
                <MessageText>{message.message}</MessageText>
                <MessaeTimeText>{moment(message.datetime).startOf('minute').fromNow()}</MessaeTimeText>
              </Message>
            )
          })}
        </ScrollableDivNested>
        <div style={{ width: '100%', boxSizing: 'border-box', padding: '0 10px', paddingTop: '10px', position: 'relative' }}>
          <MessageInputContainer ref={messageInputRef}>
            <FormTextInputMainContainer style={{ width: "80%" }}>
              <TextInputContainer style={Object.assign({ border: ` 1px solid rgba(0, 0, 0, 0.23)` }, { maxWidth: '100%', width: "100%" })}>
                <input
                  ref={inputRef}
                  placeholder={i18n.t('CHATS.chatInputPlaceholder')}
                  type={"text"}
                  value={message}
                  onKeyPress={handleKeyPress}
                  // disabled={loading}
                  required
                  onChange={({ target: { value } }) => {
                    setMessage(value)
                  }}
                  autocomplete="false"
                  autoComplete="off"
                  onFocus={(event) => {
                    event.target.setAttribute('autocomplete', 'off');
                  }}
                  style={{
                    backgroundColor: "transparent",
                    width: "90%",
                    height: "90%",
                    border: "none",
                    fontSize: 15,
                    color: COLORS_TRUCK.BLACK_TRUCK,
                    margin: 0,
                    padding: 0,
                    paddingLeft: 15
                  }}
                />
              </TextInputContainer>
            </FormTextInputMainContainer>
            <div>
                {
                  !loading ?  
                    <SendIcon
                      onClick={() => (driverId || userLogged?.rol == 'client') && message && !loading ? sendMessage() : null}
                      src={sendIcon}
                    />
                  :
                    <SendLoader>
                      <Loader />
                    </SendLoader>   
                }
            </div>
          </MessageInputContainer>
        </div>
      </div>
     { 
      userLogged.rol !== 'client' && 
      <ChatSecondChild style={{width: '27%'}}>
        <ChatsScreen
          chatDriverId={driverId}
          rol={ rol }
        />
      </ChatSecondChild>
     }
    </div>
  )
}

export default ChatScreen