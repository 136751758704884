import React, { useEffect, useState } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileBillingContainer,
  ProfileTableContainer,
} from "./chats-styles";
import i18n from '../../i18n/i18n';
import searchIcon from "../../assets/profile/search-icon.svg";
import TruckTable from "../../components/truck-table/TruckTable";
import FormTextInput from "../../components/inputs/forms/form-text-input/FormTextInput";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import avatarDefault from "../../assets/drivers/user.png";
import { useSelector } from "react-redux";
import { getDrivers } from "../driver-details/driver-details-actions";
import { getChatClient, getChats, getChatsClient, getChatsClients } from "./chats-actions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CHAT_ROUTE } from "../../routes/routes";
import { getUnreadMessagesAction } from "../user-home/user-home-actions";
import { useSubscription } from "@apollo/client";
import { GET_MESSAGES_SUBSCRIPTION } from "./chats-queries";
import { TableLoaderContainer, UnreadMessages, UserPicture } from "../../components/truck-table/truck-rable-styles";
import Loader from "../../components/loader/Loader";
import { COLORS_TRUCK } from "../../utils/colors";
import { getClientsAction } from "../super-admin/clients/clients-actions";

const ChatsScreen = ({ chatDriverId, rol }) => {
  const navigate = useNavigate();
  const [searchUsers, setSearchUsers] = useState("")
  const dispatch = useAppDispatch()
  const userLogged = useSelector((state) => state).userSlice
  const [loading, setLoading] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(10)
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [drivers, setDrivers] = useState([])
  const [users, setUsers] = useState([])
  const [chatsUnread, setChatsUnread] = useState(0)
  const isMounted = React.useRef(true)

  useEffect(() => {
    localStorage.removeItem("numberOfUnreadMessages")
    if (userLogged?.plan_details?.status === "CANCELED") {
      // dispatch(openAlert({
      //   alertType: "plan-canceled",
      //   isAlertOpen: true
      // }))
    }
    if (userLogged?.plan_details?.status === "WAITING") {
      // dispatch(openAlert({
      //   alertType: "plan-waiting",
      //   isAlertOpen: true
      // }))
    }

    (async () => {
      await getDriversFunc()
      await getClientFunc()
    })()

    return () => {
      isMounted.current = false;
    };
  }, [])

  useSubscription(GET_MESSAGES_SUBSCRIPTION, { 
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      getDriversFunc()
    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  const mixChat = (drivers, chats) => drivers.map(driver => {
    const driverName = ((driver.name && driver.surnames) || (driver.firts_name && driver.last_name) ? `${driver?.name || driver.firts_name} ${driver.last_name}` : driver.name || driver.firts_name)

    if (chats.filter(chat => chat.from_user_name === driverName).length > 0) {
      return {
        ...driver,
        chat: chats.filter(chat => chat.from_user_name === driverName)[0]
      }
    } else {
      return driver
    }
  })

  const mixChatClient = (clients, chats) => clients.map(user => {
    const userName = ((user.name && user.surnames) || (user.firts_name && user.last_name) ? `${user?.name || user.firts_name} ${user.last_name}` : user.name || user.firts_name)
    
    if (chats.filter(chat => chat.from_user_name === userName).length > 0) {
      return {
        ...user,
        chat: chats.filter(chat => chat.from_user_name === userName)[0]
      }
    }
    
    return user
  })

  const getClientFunc = async (reset) => {
    try {
      setLoadingUser(true)
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        }
      }
            
      if(searchUsers && !reset) return setUsers(users.filter(item => item.email.includes(searchUsers)))

      const res = await getClientsAction(data)
      const chats = await getChatsFunc(false)
      console.log('chats', chats)
      if (res?.data?.findMyClients.length <= 0) setKeepIncreasing(false)
      else setKeepIncreasing(true)

      let usersArray = users.concat(res?.data?.findMyClients)
      if (!searchUsers) {
        const data = mixChatClient(usersArray.filter((item, index, self) => index === self.findIndex((t) => t.email === item.email)), chats)
        setUsers(data.sort((a, b) => moment(a?.chat?.datetime) - moment(b?.chat?.datetime)))
      } else {
        const data = mixChatClient(usersArray, chats)
        setUsers(data.sort((a, b) => moment(a?.chat?.datetime) - moment(b?.chat?.datetime)))
      }

      setLoadingUser(false)
    } catch (e) {
      setLoadingUser(false)
    }
  }

  const getDriversFunc = async (reset) => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        email: reset ? null : searchUsers || null,
        driver_id: null
      }
      const res = await getDrivers(data)

      const unreadMessages = await getUnreadMessagesAction()
      let total = unreadMessages?.data?.unreadMessages?.total
      if (total) {
        setChatsUnread(total)
        localStorage.setItem("numberOfUnreadMessages", String(total))
      }

      const chats = await getChatsFunc(true)

      if (res?.data?.myDrivers.length <= 0) {
        setKeepIncreasing(false)
      } else {
        setKeepIncreasing(true)
      }

      let driversArray = drivers.concat(res?.data?.myDrivers)
      if (!searchUsers) {
        const data = mixChat(driversArray.filter((item, index, self) => index === self.findIndex((t) => t.email === item.email)), chats)
        setDrivers(data.sort((a, b) => moment(a?.chat?.datetime) - moment(b?.chat?.datetime)))
      } else {
        const data = mixChat(res?.data?.myDrivers, chats)
        setDrivers(data.sort((a, b) => moment(a?.chat?.datetime) - moment(b?.chat?.datetime)))
      }

      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const goDriverChat = (item) => {
    navigate(`${CHAT_ROUTE}/${item._id}?rol=${item.rol || 'driver'}`, { replace: true })
    getDriversFunc()
  }

  const getChatsFunc = async (driver) => {
    try {

      const data = {
        message: searchUsers || null,
        pagination: {
          limit: -1,
          offset: 0
        },
        sort: {
          field: "createdAt",
          type: "DESC"
        }
      }

      const res = driver ? await getChats( null, data) : await getChatsClients( null, data)
      const chats = driver ? res?.data?.myMessages : res.data.findMessagesOfMyClients

      return chats

    } catch (e) {
      // dispatch(openAlert({
      //   alertType: "error",
      //   isAlertOpen: true
      // }))
    }
  }

  const handleKeyPressUsers = (event) => {
    if (event.key === 'Enter' && searchUsers) {
      event.preventDefault();
      getDriversFunc()
    }
  };

  const header = <ProfileBillingContainer>
    <FormTextInput
      placeholder={i18n.t('CHATS.usersListSearchInputPlaceholder')}
      type={"text"}
      value={searchUsers}
      containerStyle={{ maxWidth: 250 }}
      onChange={({ target: { value } }) => {
        if (value === "") {
          getDriversFunc(true)
          getClientFunc(true)
        } else {
          getDriversFunc()
          getClientFunc()

        }

        setSearchUsers(value)
      }}
      onKeyPress={handleKeyPressUsers}
      required
      icon={searchIcon}
    />
  </ProfileBillingContainer>

  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileTableContainer
          style={{
            maxHeight: 500,
            maxWidth: 300,
            overflow: "auto",
          }}
          className="chat-users-table-desktop"
        >
          <div>
            {header}
            {
            (rol == 'admin') && (
              <div>
                <div style={{ paddingBottom: 10 }}>
                  <div style={{ fontWeight: 'bold', paddingLeft: 10, paddingTop: 10 }}>No leidos</div>
                  {
                    users.map((item, i) => (
                      item?.chat?.unread > 0 && (
                        <div style={{ 
                          cursor: 'pointer', 
                          display: 'flex', 
                          alignItems: 'center', 
                          paddingBottom: 20, 
                          paddingTop: 20, 
                          paddingLeft: 20,
                          marginBottom: 5,
                          borderBottom: !item?.chat?.unread > 0 && '1px solid #9f9f9f', 
                          background: item?.chat?.unread > 0 && COLORS_TRUCK.ORANGE_TRUCK,
                          borderRadius: item?.chat?.unread > 0 && '2.5%',
                          color: item?.chat?.unread > 0 && COLORS_TRUCK.WHITE_TRUCK
                        }} onClick={() => goDriverChat(item)} key={i}>
                        <UserPicture src={item?.avatar?.fullpath || avatarDefault} alt="" />
                        <div style={{ paddingInline: 20, fontWeight: chatDriverId === item._id ? "bold" : "" }}>{`${item.firts_name} ${item.last_name}`}</div>
                        <div>
                          {
                            item?.chat?.unread > 0
                            ? <UnreadMessages>{item?.chat?.unread}</UnreadMessages>
                            : null
                          }
                        </div>
                      </div>
                      )
                    ))
                  }
                </div>
                {
                  loadingUser ?
                    <TableLoaderContainer>
                      <Loader color={COLORS_TRUCK.ORANGE_TRUCK} width={25} height={25} />
                    </TableLoaderContainer>
                    : !loadingUser && users.length <= 0 ? <p style={{ textAlign: 'center', margin: 30 }}>{i18n.t("GENERAL.noResults")}</p> : null
                }
              </div>
            )
          }
            <div style={{ fontWeight: 'bold', paddingLeft: 10 }}>{i18n.t('SIDE_MENU.buttons.drivers')}</div>
            {
              drivers.map((item, i) => (
                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', paddingBottom: 20, paddingTop: 20, paddingLeft: 20, borderBottom: '1px solid #9f9f9f' }} onClick={() => goDriverChat(item)} key={i}>
                  <UserPicture src={item?.avatar?.fullpath} alt="" />
                  <div style={{ paddingInline: 20, fontWeight: chatDriverId === item._id ? "bold" : "" }}>{`${item.name} ${item.surnames}`}</div>
                  <div>
                    {
                      item?.chat?.unread > 0
                      ? <UnreadMessages>{item?.chat?.unread}</UnreadMessages>
                      : null
                    }
                  </div>
                </div>
              ))
            }
            {
              loading ?
                <TableLoaderContainer>
                  <Loader color={COLORS_TRUCK.ORANGE_TRUCK} width={25} height={25} />
                </TableLoaderContainer>
                : !loading && drivers.length <= 0 ? <p style={{ textAlign: 'center', margin: 30 }}>{i18n.t("GENERAL.noResults")}</p> : null
            }
          </div>
          {
            rol == 'admin' && (
              <div>
                <div style={{ fontWeight: 'bold', paddingLeft: 10, paddingTop: 10 }}>{i18n.t('SIDE_MENU.buttons.clients')}</div>
                {
                  users.map((item, i) => (
                      <div style={{ 
                        cursor: 'pointer', 
                        display: 'flex', 
                        alignItems: 'center', 
                        paddingBottom: 20, 
                        paddingTop: 20, 
                        paddingLeft: 20,
                        borderBottom: '1px solid #9f9f9f'
                      }} onClick={() => goDriverChat(item)} key={i}>
                        <UserPicture src={item?.avatar?.fullpath || avatarDefault} alt="" />
                        <div style={{ paddingInline: 20, fontWeight: chatDriverId === item._id ? "bold" : "" }}>{`${item.firts_name} ${item.last_name}`}</div>
                      </div>
                    
                  )) 
                }
                {
                  loadingUser ?
                    <TableLoaderContainer>
                      <Loader color={COLORS_TRUCK.ORANGE_TRUCK} width={25} height={25} />
                    </TableLoaderContainer>
                    : !loadingUser && users.length <= 0 ? <p style={{ textAlign: 'center', margin: 30 }}>{i18n.t("GENERAL.noResults")}</p> : null
                }
              </div>
            )
          }
        </ProfileTableContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default ChatsScreen