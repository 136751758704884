import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';

export const ProfileContainer = styled.div`
height: 80vh;
`;

export const ProfileContentContainer = styled.div`
flex-direction: row;
justify-content: center;
display: flex;
align-items: baseline;
flex-wrap: wrap;
justify-content: flex-start;
@media (max-width: 900px) {
  align-items: baseline;
}
`;

export const Container = styled.div`
display: flex; 
justify-content: center; 
align-items: center; 
min-width: 1000px;
@media (max-width: 800px) {
  min-width: 800px;
}

@media (max-width: 670px) {
  min-width: 650px;
}

@media (max-width: 590px) {
  min-width: 0px;
}
`

export const ChatContainer = styled.div`
margin-top: 50px;
display: flex;
flex-direction: row;
justify-items: center;
@media (max-width: 800px) {
  flex-direction: column !important;
}

@media (max-width: 590px) {
  margin-top: 100px;
}
`;
export const ChatFirstChild = styled.div`
width: 100%;
`;

export const ChatSecondChild = styled.div`
width: 40%;

@media (max-width: 920px) {
  width: 100% !important;
}
`;

export const ProfileButtonsContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const ProfileFormContainer = styled.div`
width: 100%;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: column;

@media (max-width: 900px) {
  align-items: center;
justify-content: center;
}

`;

export const ProfileFormsContainer = styled.div`
width: 100%;
display: flex;
align-items: baseline;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: row;

@media (max-width: 1200px) {
  align-items: center;
justify-content: center;
}
`;

export const ProfileTableContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
border-left: 1px solid rgb(48, 37, 33, 0.1);
@media (max-width: 900px) {
border: none;
}
`;

export const ProfileFormInputsContainer = styled.div`
width: 450px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const ProfileSmallButtonsGroup = styled.div`
// width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const ProfileLastButtonsContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;

@media (min-width: 1200px) {
  justify-content: flex-end;
  padding-right: 16%;
}
@media (max-width: 1200px) {
align-items: center;
justify-content: center;
}
`;

export const ProfileSmallButtonGroup = styled.div`
// width: 45%;
display: flex;
flex-direction: row;
align-items: center;
`;

export const FixedAmountText = styled.p`
padding: 0;
padding-right: 10px;
`;

export const TableTh = styled.th`
text-align: center;
`;

export const TableTd = styled.td`
padding-top: 5px;
padding-bottom: 5px;
text-align: center;
`;

export const ProfileTable = styled.table`
box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.08);
border-radius: 5px;
padding: 10px;
`;

export const ProfileFormSeparator = styled.div`
width: 100%;
background: #302521;
opacity: 0.1;
height: 2px;
margin-top: 5px;
margin-bottom: 5px;
`;

export const ProfileBillingContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: center;
// padding: 20px;
@media (max-width: 400px) {
  padding: 0px;
}
`;

export const ChatFilterButtonsContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: center;
`;

export const MessageInputContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
// position: fixed;
// bottom: 0;
// flex-wrap: wrap;
justify-content: center;
// padding: 20px;
// @media (max-width: 400px) {
  //   padding: 0px;
  // }
//   @media (max-width: 400px) {
//   width: 90%;
// }
`;

export const Message = styled.div`
// width: 100%;
display: flex;
align-items: center;
padding: 15px;
border-radius: 5px;
margin: 10px;
position: relative;
min-width: 100px;
margin-left: 10%;
margin-right: 10%;
`;

export const MessaeTimeText = styled.p`
color: ${COLORS_TRUCK.BLACK_TRUCK};
font-size: 10px;
position: absolute;
right: 15px;
bottom: 0px;
`;

export const TableInfoContainer = styled.p`
margin-right: 100px;
display: flex;
flex-direction: column;
@media (max-width: 400px) {
  margin-right: 20px;
}
`;
export const TableTitle = styled.p`
font-weight: bold;
margin: 10px;
// margin-top: 30px;
@media (max-width: 400px) {
  margin: 0;
}
`;

export const TableInfo = styled.p`
margin: 10px;
color: ${COLORS_TRUCK.ORANGE_TRUCK};
margin-right: 20px;
margin: 0;
`;

export const SendIcon = styled.img`
width: 30px;
height: 30px;
padding: 15px;
background: ${COLORS_TRUCK.ORANGE_TRUCK};
border-radius: 5px;
margin-left: 10px;
`;

export const SendLoader = styled.div`
width: 30px;
height: 30px;
padding: 15px;
background: ${COLORS_TRUCK.ORANGE_TRUCK};
border-radius: 5px;
margin-left: 10px;
`;

export const MessagesContainer = styled.div`
width: 100%;
// height: 400px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
// overflow-y: scroll;
// padding-top: 100px;
`;
export const MessagesContainer2 = styled.div`
width: 100%;
height: calc(100vh - 156px)
// display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
overflow: auto;
// padding-top: 100px;
`;

export const MessageText = styled.p`
font-size: 15px;
color: ${COLORS_TRUCK.BLACK_TRUCK};
`;