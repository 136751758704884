import styled from 'styled-components';
import { COLORS_TRUCK } from '../../../utils/colors';

export const ModalContainer = styled.div`
width: 100%;
position: absolute;
display: flex;
align-items: center;
justify-content: center;
top: 180px;
z-index: 4;
left: 50px;
@media (max-width: 1000px) {
    left: 30px;
}

@media (max-width: 860px) {
    left: 10px;
}

`

export const ModalSubContainer = styled.div`
background: ${COLORS_TRUCK.WHITE_TRUCK};
border: 1px solid ${COLORS_TRUCK.ORANGE_TRUCK};
border-radius: 5px;
`

export const ModalHeader = styled.div`
display: flex;
justify-content: space-between;
background: ${COLORS_TRUCK.ORANGE_TRUCK};
color: ${COLORS_TRUCK.WHITE_TRUCK};
font-weight: 600;
font-size: 18px;
padding: 5px 15px;

`

export const TextInput = styled.p`
width: 290px;
font-size: 15px;
padding: 10px 20px 0 20px;
`

export const InputContainer = styled.div`
padding: 0 20px;
`

export const SendData = styled.button`
border-radius: 5px;
width: 86%;
margin: 10px 20px;
color: ${COLORS_TRUCK.WHITE_TRUCK};
font-size: 18px;
padding: 8px 0;
`

export const TimesButton = styled.img`
width: 20px;
height: 20px;
cursor: pointer;
`