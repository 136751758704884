import styled from 'styled-components';

export const ProfileContainer = styled.div`
width: 100%;
height: 100%;
`;

export const ProfileContentContainer = styled.div`
width: 100%;
height: 100%;
flex-direction: column;
margin: auto;
padding-top: 30px;
`;

export const ProfileFormContainer = styled.div`
width: 100%;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: column;

@media (max-width: 900px) {
  align-items: center;
justify-content: center;
}

`;

export const ProfileTableContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

export const ProfileBillingContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: center;
padding-top: 20px;
padding-bottom: 20px;
@media (max-width: 400px) {
  padding: 0px;
}
`;

export const TableInfoContainer = styled.p`
margin-right: 20px;
display: flex;
flex-direction: column;
`;
export const TableTitle = styled.p`
font-weight: bold;
margin: 10px;
margin-top: 30px;
`;