import React, { useEffect, useState } from "react";
import { ProfileContainer, ProfileContentContainer, ProfileButtonsContainer, ProfileFormContainer, ProfileFormInputsContainer, ProfileFormsContainer, ProfileLastButtonsContainer, FixedAmountText, ProfileSmallButtonGroup, ProfileSmallButtonsGroup, ProfileFormSeparator, ProfileBillingContainer, ProfileTableContainer, TableTitle, TableInfoContainer, ClientInfoContainer, GeneralErrorContainer, GeneralErrorText, TableContainer } from "./clients-details-styles";
import { hoursForOption } from './hoursOption'
import i18n from '../../../i18n/i18n';
import CategoryButton from "../../../components/buttons/category-button/CategoryButton";
import UserCard from "../../../components/user-card/UserCard";
import FormTextInput from "../../../components/inputs/forms/form-text-input/FormTextInput";
import FormSelect from "../../../components/inputs/forms/form-select/FormSelect";
import passwordIcon from "../../../assets/inputs/lock.svg";
import offEye from "../../../assets/inputs/eye-off.svg";
import redEye from "../../../assets/general/eye-icon-red.svg";
import filterByIcon from "../../../assets/profile/filter-by-icon.svg";
import searchIcon from "../../../assets/profile/search-icon.svg";
import TruckTable from "../../../components/truck-table/TruckTable";
import { COLORS_TRUCK } from "../../../utils/colors"; 
import { useNavigate, useParams } from "react-router-dom";
import { getUsersAction, getUserDriversAction, updateUserAction, removeUserAction, createUserAction, uploadUserPicAction, updateUserStatusAction, createClientAction, getClientByIdAction, updateClientAction } from "../clients/clients-actions";
import { openAlert } from "../../../redux/reducers/app.ts";
import { useAppDispatch } from "../../../redux/store.ts";
import { getCitiesAction, getCountriesAction, getStatesAction } from "../../signup/signup-actions";
import avatarDefault from "../../../assets/drivers/user.png";
import { emailsRegex } from "../../../utils/regex";
import FormNumberInput from "../../../components/inputs/forms/form-number-input/FormNumberInput";
import TextInput from "../../../components/inputs/text-input/TextInput";
import { CLIENTS_ROUTE } from "../../../routes/routes";
import { updateDriverAction, updateDriverStatusAction } from "../../driver-details/driver-details-actions";
import { driverData } from "./driverData";
import { actionsSuperAdminAccess, actionsSuperAdminCompany, companyValidate, isEmail, isNumber, validateErrors, validateForm } from "./validate";
import { useSelector } from "react-redux";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

const ClientDetailsScreen = ({rol}) => {
  const isMenuOpen = useSelector((state) => state).appSlice.isMenuOpen
  const { clientEmail, clientId } = useParams()
  const [billingFilterBy, setBillingFilterBy] = useState("1")
  const [user, setUser] = useState("")
  const [currentForm, setCurrentForm] = useState("company")
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch() 
  const [users, setUsers] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [showInputsErrors, setShowInputsErrors] = useState(false)
  const [countryIso2, setCountryIso2] = useState(false)
  const [userDrivers, setUserDrivers] = useState([])
  const [showInputError, setShowInputError] = useState({})
  const [avatar, setAvatar] = useState("")
  const [generalError, setGeneralError] = useState("")
  const [userId, setUserId] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [contactEmailErrorMessage, setContactEmailErrorMessage] = useState("")
  const [emailError, setEmailError] = useState("")
  const navigate = useNavigate();
  //USER DRIVERS
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(10)
  const [search, setSearch] = useState("")
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [type, setType] = useState("")
  const [paymentType, setPaymentType] = useState("")
  const [status, setStatus] = useState("")
  //company
  const [socialReason, setSocialReason] = useState("")
  const [nif, setNif] = useState("")
  const [type2, setType2] = useState("")
  const [phone, setPhone] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [clientState, setClientState] = useState('INACTIVE')
  const [country, setCountry] = useState("")
  const [province, setProvince] = useState("")
  const [city, setCity] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [address, setAddress] = useState("")
  //access
  const [representativeName, setRepresentativeName] = useState("")
  const [representativeLastName, setRepresentativeLastName] = useState("")
  const [dni, setDni] = useState("")
  const [position, setPosition] = useState("")
  const [email, setEmail] = useState("")
  const [confirmEmail, setConfirmEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordShow, setPasswordShow] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [secondPasswordShow, setSecondPasswordShow] = useState("")
  //variables
  const [fixedRemuneration, setFixedRemuneration] = useState("")
  const [nationalComplete, setNationalComplete] = useState("")
  const [internationalComplete, setInternationalComplete] = useState("")
  const [payPerKm, setPayPerKm] = useState("")
  const [lunch, setLunch] = useState("")
  const [lunch2, setLunch2] = useState("")
  const [dinner, setDinner] = useState("")
  const [dinner2, setDinner2] = useState("")
  const [nocturnal, setNocturnal] = useState("")
  const [nocturnal2, setNocturnal2] = useState("")
  const [lunchNational, setLunchNational] = useState("")
  const [lunchInternational, setLunchInternational] = useState("")
  const [dinnerNational, setDinnerNational] = useState("")
  const [dinnerInternational, setDinnerInternational] = useState("")
  const [nocturnalNational, setNocturnalNational] = useState("")
  const [nocturnalInternational, setNocturnalInternational] = useState("")
  const [sunday, setSunday] = useState("")
  //map
  const [addressGet1, setAddressGet1] = useState("")
  const [addressGet2, setAddressGet2] = useState("")  
  const [addressGet3, setAddressGet3] = useState("")
  const [coordsGet1, setCoordsGet1] = useState("")
  const [coordsGet2, setCoordsGet2] = useState("")
  const [coordsGet3, setCoordsGet3] = useState("")
  const [phoneGet1, setPhoneGet1] = useState("")
  const [phoneGet2, setPhoneGet2] = useState("")
  const [phoneGet3, setPhoneGet3] = useState("")
  const [companiesIds, setCompaniesIds] = useState([])
  const hoursForSelect = hoursForOption
  const [countries, setCountries] = useState([])

  useEffect(async () => {
    const countries = await getCountries()
    const iso2 = countries.filter(item => item.value === user?.country)[0]?.iso2
    await getStates(iso2)
    await getCities(iso2, user?.state)
    if (clientEmail || clientId) await getUser(countries)
  }, [user, status, type, paymentType])

  const getCountries = async () => {
    try {
      const response = await getCountriesAction()
      if (response && response.data) {
        const countriesArray = response.data.countries.map(country => { return { label: country.translate[0].langs[localStorage.getItem("language") || "es"], value: country._id, iso2: country.iso2 } })
        setCountries(countriesArray)
        return countriesArray
      }

    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const getUserDrivers = async (id = false, reset = false) => {
    try {
      if (reset) setUserDrivers([])

      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        email: search || null,
        status: status || null,
        type: type || null,
        payment_type: paymentType || null
      }

      const uId = id || userId
      const response = await getUserDriversAction(uId, data)
      response?.data?.drivers?.length <= 0 ? setKeepIncreasing(false) : setKeepIncreasing(true)

      let driversArray = userDrivers.concat(response?.data?.drivers)
      !search && !status && !type && !paymentType ? setUserDrivers(driversArray?.filter((item, index, self) => index === self.findIndex((t) => t.email === item.email))) : setUserDrivers(response?.data?.drivers)

    } catch (e) {
      dispatch(openAlert({ isAlertOpen: true}))
    }
  }

  const getCities = async (country, province) => {
    if (country && province) {
      try {
        const response = await getCitiesAction(country, province)
        const cities = response?.data?.cities?.map(city => { return { label: city.name, value: city._id } })
        setCities(cities)
      } catch (e) {
        dispatch(openAlert({ isAlertOpen: true }))
      }
    }
  }

  const getStates = async (country) => {
    if (country) {
      try {
        const response = await getStatesAction(country)
        setStates(response?.data?.state.map(state => { return { label: state.translate[0].langs[localStorage.getItem("language") || "es"], value: state._id } }))
      } catch (e) {
        dispatch(openAlert({ isAlertOpen: true }))
      }
    }
  }

  const assignClient = async (user, countriesOption) => {
    const fields = [ 
      { set: (item) => setUserId(item), name: '_id' },
      { set: (item) => setDni(item), name: 'dni' },
      { set: (item) => setRepresentativeName(item), name: 'firts_name' },
      { set: (item) => setRepresentativeLastName(item), name: 'last_name' },
      { set: (item) => setEmail(item), name: 'email' },
      { set: (item) => setPosition(item), name: 'position' },
      { set: (item) => setSocialReason(item), name: 'business_name' },
      { set: (item) => setNif(item), name: 'nif' },
      { set: (item) => setAddress(item), name: 'address_tax' },
      { set: (item) => setPhone(item), name: 'phone' },
      { set: (item) => setCountry(item), name: 'country' },
      { set: (item) => setCity(item), name: 'city' },
      { set: (item) => setProvince(item), name: 'state' },
      { set: (item) => setClientState(item), name: 'status' },
      { set: (item) => setCompaniesIds(item), name: 'companies_ids' },
      { set: (item) => setPostalCode(item), name: 'zip' },
      { set: (item) => setAddressGet1(item), name: 'addressGet1', field: 'address' },
      { set: (item) => setAddressGet2(item), name: 'addressGet2', field: 'address' },
      { set: (item) => setAddressGet3(item), name: 'addressGet3', field: 'address' },
      { set: (item) => setCoordsGet1(item), name: 'coordsGet1', field: 'street' },
      { set: (item) => setCoordsGet2(item), name: 'coordsGet2', field: 'street' },
      { set: (item) => setCoordsGet3(item), name: 'coordsGet3', field: 'street' },
      { set: (item) => setPhoneGet1(item), name: 'phoneGet1', field: 'phone' },
      { set: (item) => setPhoneGet2(item), name: 'phoneGet2', field: 'phone' },
      { set: (item) => setPhoneGet3(item), name: 'phoneGet3', field: 'phone' },
    ]
    
    for(let index in fields) {
      if(fields[index].name == 'business_name' || fields[index].name == 'nif' || fields[index].name == 'address_tax' || fields[index].name == 'phone' || fields[index].name == 'country' || fields[index].name == 'city' ||  fields[index].name == 'state' || fields[index].name == 'zip') {
          if(user?.business_client) fields[index].set(user.business_client[fields[index].name])

          continue
      }

      if(fields[index].name == 'addressGet1' || fields[index].name == 'phoneGet1' || fields[index].name == 'coordsGet1') {
        if(user?.address_client.length > 0) {
          let address = user.address_client[0][fields[index].field] 
          fields[index].set(address)
        }

        continue
      }

      if(fields[index].name == 'addressGet2' || fields[index].name == 'phoneGet2' || fields[index].name == 'coordsGet2') {
       if(user?.address_client.length > 0) {
          let address = user.address_client[1][fields[index].field] 
          fields[index].set(address)
        }

        continue
      }

      if(fields[index].name == 'addressGet3' || fields[index].name == 'phoneGet3' || fields[index].name == 'coordsGet3') {
        if(user?.address_client.length > 0) {
          let address = user.address_client[2][fields[index].field] 
          fields[index].set(address)
        }

        continue
      }
      
      if(fields[index].name == 'status') {
        let status = user.status.toUpperCase()
        fields[index].set(status)

        continue
      }

      fields[index].set(user[fields[index].name])
    }

    let getCountry = user?.business_client.country 
    let getState = user?.business_client.state
    const iso2 = countriesOption.filter(item => item.value === getCountry)[0]?.iso2
    await getStates(iso2)
    await getCities(iso2, getState)
  }

  const getUser = async (countriesOption) => {
    try {
      setLoading(true)
      const data = !clientId ? { email: clientEmail || null } :  clientId || null 
      const res = !clientId ? await getUsersAction(data) : await getClientByIdAction(data)
      if(res?.data?.findMyClientById) {
        assignClient(res?.data?.findMyClientById, countriesOption)
        return setLoading(false)
      }

      if (res?.data?.users.length > 0) {
        const user = res?.data?.users[0]
        setUser(user)
        setUserId(user._id)
        setSocialReason(user.company)
        setNif(user.nif)
        setType2(String(user.company_type).toUpperCase())
        setCountry(user.business_client.country)
        setProvince(user.state)
        setClientState(user.status)
        setCity(user.city)
        setPostalCode(user.zip)
        setAddress(user.address)
        setPhone(user.phone)
        setContactEmail(user.email_contact)
        setRepresentativeName(user.name)
        setRepresentativeLastName(user.surnames)
        setDni(user.dni)
        setPosition(user.position)
        setEmail(user.email)
        setConfirmEmail(user.email)
        setAvatar(user?.avatar?.fullpath)
        setPassword("")
        setPasswordShow("")
        setConfirmPassword("")
        setSecondPasswordShow("")
        setFixedRemuneration(user.payment_variables.fixed_remuneration === 0 ? "" : user.payment_variables.fixed_remuneration)
        setLunchNational(user.payment_variables?.diets?.lunch === 0 ? "" : user.payment_variables?.diets?.lunch)
        setLunchInternational(user.payment_variables?.diets?.lunch_international === 0 ? "" : user.payment_variables?.diets?.lunch_international)
        setDinnerNational(user.payment_variables?.diets?.dinner === 0 ? "" : user.payment_variables?.diets?.dinner)
        setDinnerInternational(user.payment_variables?.diets?.dinner_international === 0 ? "" : user.payment_variables?.diets?.dinner_international)
        setNocturnalNational(user.payment_variables?.diets?.nocturnal === 0 ? "" : user.payment_variables?.diets?.nocturnal)
        setNocturnalInternational(user.payment_variables?.diets?.nocturnal_international === 0 ? "" : user.payment_variables?.diets?.nocturnal_international)
        setNationalComplete(user.payment_variables.complete_national === 0 ? "" : user.payment_variables.complete_national)
        setInternationalComplete(user.payment_variables.complete_international === 0 ? "" : user.payment_variables.complete_international)
        setPayPerKm(user.payment_variables.payment_per_km === 0 ? "" : user.payment_variables.payment_per_km)

        setLunch(user.payment_variables?.diets?.lunch_timetable?.split(":")[0])
        setLunch2(user.payment_variables?.diets?.lunch_timetable?.split(":")[1])
        setDinner(user.payment_variables?.diets?.dinner_timetable?.split(":")[0])
        setDinner2(user.payment_variables?.diets?.dinner_timetable?.split(":")[1])
        setNocturnal(user.payment_variables?.diets?.nocturnal_timetable?.split(":")[0])
        setNocturnal2(user.payment_variables?.diets?.nocturnal_timetable?.split(":")[1])
        let getCountry = user.country 
        let getState =  user?.state
        const iso2 = countriesOption.filter(item => item.value === getCountry)[0]?.iso2
        await getStates(iso2)
        await getCities(iso2, getState)
        await getUserDrivers(user?._id)
      }
      return setLoading(false)
     
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({ alertType: "error", isAlertOpen: true }))
    }
  }

  const saveProfile = async () => {
    const passwordValidation = password && password.length < 8
    const passwordMatchValidation = password && password !== confirmPassword
    const contactEmailValidation = contactEmail && !contactEmail.match(emailsRegex)

    if (!socialReason || !nif || !country) {
      setCurrentForm("company")
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      return
    }

    if (!representativeName || !representativeLastName || !dni || !email) {
      setCurrentForm("access")
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      return
    }

    if (passwordValidation) {
      setShowInputsErrors(true)
      setCurrentForm("access")
      setPasswordError(i18n.t("GENERAL.passwordLength"))
      return
    }

    if (passwordMatchValidation && rol !== 'admin') {
      setShowInputsErrors(true)
      setCurrentForm("access")
      setPasswordError(i18n.t("GENERAL.passwordsNotMatch"))
      return
    }

    if (contactEmailValidation) {
      setShowInputsErrors(true)
      setCurrentForm("company")
      setContactEmailErrorMessage(i18n.t("PROFILE.emailValidation"))
      return
    }

    if (!passwordValidation && !passwordMatchValidation && !contactEmailValidation) { 
      setShowInputsErrors(false)
      setContactEmailErrorMessage("")
      setPasswordError("")
    }

    if (socialReason && nif && country && representativeName && representativeLastName && dni && email) {
      setGeneralError("")
      setShowInputsErrors(false)
    }

    try {
      setLoading(true)

      const fields = [
        {name: 'address', value: address || null},
        {name: 'city', value: city || null},
        {name: 'company', value: socialReason || null},
        {name: 'company_type', value: type2?.toUpperCase() || null},
        {name: 'country', value: country || null},
        {name: 'dni', value: Number(dni) || null, type: 3},
        {name: 'email_contact', value: contactEmail || null},
        {name: 'email', value: email || null, type: 3},
        {name: 'language', value: String(localStorage.getItem("language") || "es").toUpperCase()},
        {name: 'name', value: representativeName || null},
        {name: 'nif', value: nif || null},
        {name: 'payment_variables', value: {
            complete_national: nationalComplete ? Number(nationalComplete) : 0,
            complete_international: internationalComplete ? Number(internationalComplete) : 0,
            diets: {
              dinner: dinnerNational ? Number(dinnerNational) : 0,
              dinner_international: dinnerInternational ? Number(dinnerInternational) : 0,
              dinner_timetable: dinner && dinner2 ? `${dinner}:${dinner2}` : null,
              lunch: lunchNational ? Number(lunchNational) : 0,
              lunch_international: lunchInternational ? Number(lunchInternational) : 0,
              lunch_timetable: lunch && lunch2 ? `${lunch}:${lunch2}` : null,
              nocturnal: nocturnalNational ? Number(nocturnalNational) : 0,
              nocturnal_international: nocturnalInternational ? Number(nocturnalInternational) : 0,
              nocturnal_timetable: nocturnal2 && nocturnal ? `${nocturnal}:${nocturnal2}` : null,
              sunday: sunday ? Number(sunday) : 0
            },
            fixed_remuneration: fixedRemuneration ? Number(fixedRemuneration) : 0,
            payment_per_km: payPerKm && payPerKm >= 0 ? Number(payPerKm) : 0
          }
        },
        {name: 'phone', value: phone ?  phone : null},
        {name: 'position', value: position || null, type: 3},
        {name: 'state', value: province || null},
        {name: 'status', value: clientState || null},
        {name: 'surnames', value: representativeLastName || null},
        {name: 'firts_name', type: 4, value: representativeName || null},
        {name: 'last_name', type: 4, value: representativeLastName || null},
        {name: 'business_client', type: 4, value: {
          business_name: socialReason || null,
          nif: nif || null,
          address_tax: address,
          phone: phone,
          country: country,
          city: city || null,
          state: province || null,
          zip: postalCode
        }},
        {name: 'address_client', type: 4, value: [
          {address: addressGet1, phone: phoneGet1, street: coordsGet1},
          {address: addressGet2, phone: phoneGet2, street: coordsGet2},
          {address: addressGet3, phone: phoneGet3, street: coordsGet3},
        ]},
        
      ]
      let data = {}

      for(let index in fields) {
        if(rol === 'admin' && (fields[index].type === 4 || fields[index].type === 3)) {
          if(clientId && fields[index].name == 'dni') continue
          data[fields[index].name] = fields[index].value
          continue
        }
        if(clientId && (fields[index].type === 4 || fields[index].type === 3)) {
          data[fields[index].name] = fields[index].value
          continue
        }

        if(rol == 'superadmin' && (!fields[index].type || fields[index].type === 3)) data[fields[index].name] = fields[index].value
      }

      if (password && password === confirmPassword) data.password = password

      if (clientEmail || clientId) {
        !clientId ? await updateUserAction(userId, data) : await updateClientAction(userId, data)
        await getUser(countries)
        return navigate(CLIENTS_ROUTE)
      }


      (rol !== 'admin') ? await createUserAction(data) : await createClientAction(data)
      navigate(CLIENTS_ROUTE)

      return setLoading(false)

    } catch (e) {
      setLoading(false)
      dispatch(openAlert({ alertType: "error", isAlertOpen: true }))
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getUserDrivers()
    }
  };
  
  const tableHeader = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        Usuarios
      </TableTitle>
    </TableInfoContainer>
    <FormSelect
      value={billingFilterBy}
      fatherContainer={{ zIndex: 99999 }}
      onChange={({ target: { value } }) => {
        setBillingFilterBy(value)
        if (value === "ACTIVE" || value === "INACTIVE" || value === "INTERNAL" || value === "EXTERNAL" || value === "DIETS" || value === "MILEAGE") {
          setUserDrivers([])
          setStatus(value)
          setType("")
          setPaymentType("")
          setLimit(30)
          setOffset(0)
        }
        if (value === "" || value === "1") {
          setUserDrivers([])
          setStatus("")
          setType("")
          setPaymentType("")
          setLimit(30)
          setOffset(0)
        }
      }}

      defaultValue="1"
      containerStyle={{ width: 220 }}
      icon={filterByIcon}
      items={[
        { value: '1', label: i18n.t('DRIVERS.forms.drivers.buttons.filterBy'), icon: filterByIcon },
        { value: 'ACTIVE', label: i18n.t('DRIVERS.forms.drivers.buttons.active'), icon: filterByIcon },
        { value: 'INACTIVE', label: i18n.t('DRIVERS.forms.drivers.buttons.inactive'), icon: filterByIcon },
        { value: 'INTERNAL', label: i18n.t('DRIVERS.forms.drivers.buttons.internal'), icon: filterByIcon },
        { value: 'EXTERNAL', label: i18n.t('DRIVERS.forms.drivers.buttons.external'), icon: filterByIcon },
        { value: 'DIETS', label: i18n.t('DRIVERS.forms.drivers.buttons.diets'), icon: filterByIcon },
        { value: 'MILEAGE', label: i18n.t('DRIVERS.forms.drivers.buttons.mileage'), icon: filterByIcon },
      ]}
      required
    />
    <TextInput
      icon={searchIcon}
      value={search}
      type="text"
      placeholder={i18n.t('DRIVERS.forms.drivers.searchPlaceHolder')}
      containerStyle={{ width: 250 }}
      onChange={(e) => {
        setSearch(e.target.value)
      }}
      onKeyPress={handleKeyPress}
    />
    <CategoryButton
      active={false}
      icon=""
      title={i18n.t('DRIVERS.forms.drivers.buttons.export')}
    />
  </ProfileBillingContainer>

  const removeUser = async () => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: i18n.t("CLIENT_DETAILS.areYouSureToRemove"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("GENERAL.remove"),
      alertOnYes: async () => {
        try {
          setLoading(true)
          await removeUserAction(userId)
          dispatch(openAlert({ alertType: "success", alertMessage: i18n.t("CLIENT_DETAILS.removedSuccessMessage"), isAlertOpen: true }))
          setLoading(false)
          navigate(CLIENTS_ROUTE)
        } catch (e) {
          setLoading(false)
          dispatch(openAlert({ alertType: "error", isAlertOpen: true }))
        }
      }
    }))
  }

  const onEditPicture = async (img) => {
    try {
      clientId ? setAvatar(img) : await uploadUserPicAction(img, userId)
      clientId ?? getUser()
    } catch (e) {
      dispatch(openAlert({ alertType: "error", isAlertOpen: true }))
    }
  }

  const changeUserStatus = async (value) => {
    try {
      await updateUserStatusAction(userId, value)
      getUser()
    } catch (e) {
      dispatch(openAlert({ alertType: "error", isAlertOpen: true }))
    }
  }

  const changeDriverStatus = async (driverId, value) => {
    try {
      await updateDriverStatusAction(driverId, value)
      setUserDrivers(userDrivers.map(item => item._id === driverId ? { ...item, status: value } : item))
    } catch (e) {
      dispatch(openAlert({ alertType: "error", isAlertOpen: true }))
    }
  }

  const updateDriver = async (driverId, value, name) => {
    try {
      let data = driverData
      if (name === "type") data.type = value
      if (name === "payment_type") data.payment_variables.payment_type = value
      if (name === "type") setUserDrivers(userDrivers.map(item => item._id === driverId ? { ...item, type: value } : item))
      if (name === "payment_type") setUserDrivers(userDrivers.map(item => item._id === driverId ? { ...item, payment_variables: { ...item.payment_variables, payment_type: value } } : item))
      
      await updateDriverAction(driverId, data, null)
    } catch (e) {
      dispatch(openAlert({ alertType: "error", isAlertOpen: true }))
    }
  }

  return (
    <ProfileContainer style={{ position: isMenuOpen && 'relative', flexDirection: 'column' }}>
      <div style={{marginTop: 40}}>
        <ProfileButtonsContainer>
          <CategoryButton
            onClick={() => { setCurrentForm('company') }}
            active={currentForm === "company"}
            icon="company"
            title={i18n.t('CLIENT_DETAILS.buttons.companyButton')}
          />
          <CategoryButton
            onClick={() => setCurrentForm("access")}
            active={currentForm === "access"}
            icon="access"
            title={i18n.t('CLIENT_DETAILS.buttons.accessButton')}
          />
          {
            rol !== 'admin' && rol !== 'client' ? 
              <CategoryButton
                onClick={() => setCurrentForm("variables")}
                active={currentForm === "variables"}
                icon="variables"
                title={i18n.t('CLIENT_DETAILS.buttons.variablesButton')}
              />
              :
              <CategoryButton
                onClick={() => setCurrentForm("map")}
                active={currentForm === "map"}
                icon="map"
                title={i18n.t('CLIENT_DETAILS.buttons.address')}
              />
          }
          {(clientEmail || clientId) && <CategoryButton
            onClick={() => setCurrentForm(!clientId ? "users" : "history")}
            active={currentForm === "users" || currentForm === 'history'}
            icon={ !clientId ? "people" : "history"}
            title={ !clientId ? i18n.t('CLIENT_DETAILS.buttons.users') : i18n.t('CLIENT_DETAILS.buttons.history') }
          />}
        </ProfileButtonsContainer>
        <ProfileFormSeparator />
        {generalError && <GeneralErrorContainer>
          <GeneralErrorText>{generalError}</GeneralErrorText>
        </GeneralErrorContainer>}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ProfileFormContainer>
            {currentForm === "company"
              ? <>
                {(clientEmail || clientId) && <ClientInfoContainer>
                  <UserCard
                    user={{
                      name: `${representativeName} ${representativeLastName}`,
                      position: socialReason,
                      picture: avatar || avatarDefault
                    }}
                    onEditPicture={(img) => onEditPicture(img)}
                  />
                  {
                    rol !== 'client' && <FormSelect
                    value={clientState}
                    containerStyle={{ width: 150, margin: 'auto', paddingRight: 20 }}
                    defaultValue={''}
                    onChange={({ target: { value } }) => {
                      setClientState(value)
                      !clientId && changeUserStatus(value)
                    }}
                    disabled={loading}
                    showError={!clientState && showInputsErrors}
                    items={[
                      {
                        value: "ACTIVE",
                        label: <p
                          style={{
                            color: COLORS_TRUCK.GREEN_TRUCK,
                            margin: 0
                          }}
                        >
                          {i18n.t("GENERAL.active")}
                        </p>,
                      },
                      {
                        value: "INACTIVE",
                        label: <p
                          style={{
                            color: COLORS_TRUCK.ORANGE_TRUCK,
                            margin: 0
                          }}
                        >
                          {i18n.t("GENERAL.inactive")}
                        </p>,
                      },
                    ]}
                    required
                  />
                  }
                </ClientInfoContainer>}
                <ProfileFormsContainer>
                  <ProfileFormInputsContainer>
                    <FormTextInput
                      title={i18n.t('CLIENT_DETAILS.forms.company.socialReason')}
                      type={"text"}
                      value={socialReason}
                      onChange={({ target: { value } }) => {
                        setSocialReason(value)
                        showInputError.socialReason = false
                        setShowInputError(showInputError)
                      }}
                      showError={!socialReason && showInputsErrors || showInputError.socialReason}
                      required
                      disabled={loading}
                    />
                    <FormTextInput
                      title={i18n.t('CLIENT_DETAILS.forms.company.nif')}
                      type={"text"}
                      value={nif}
                      onChange={({ target: { value } }) => {
                        setNif(value)
                        showInputError.nif = false
                        setShowInputError(showInputError)
                      }}
                      showError={!nif && showInputsErrors || showInputError.nif}
                      required
                      disabled={loading}
                    />
                    {
                      rol !== 'admin' ? 
                      <>
                        <FormSelect
                          title={i18n.t('PROFILE.forms.company.type')}
                          value={type2}
                          onChange={({ target: { value } }) => setType2(value)}
                          items={[
                            { value: '', label: "", },
                            { value: 'COMPANY', label: i18n.t('SIGNUP.companyInfoTable.companyType1') },
                            { value: 'AGENCY', label: i18n.t('SIGNUP.companyInfoTable.companyType2') },
                          ]}
                          disabled={loading}
                          required
                          showError={!type2 && showInputsErrors}
                        />
                        <FormTextInput
                          title={i18n.t('CLIENT_DETAILS.forms.company.contactEmail')}
                          type={"email"}
                          value={contactEmail}
                          onChange={({ target: { value } }) => setContactEmail(value)}
                          disabled={loading}
                          errorMessage={contactEmailErrorMessage}
                        />
                      </>
                      :
                      <>
                        <FormTextInput
                          title={i18n.t('CLIENT_DETAILS.forms.company.postalCode')}
                          type={"text"}
                          value={postalCode}
                          onChange={({ target: { value } }) => {
                            setPostalCode(value)
                            showInputError.postalCode = false
                            setShowInputError(showInputError)
                          }}
                          disabled={loading}
                          required
                          showError={!postalCode && showInputsErrors || showInputError.postalCode }
                        />
                        <FormTextInput
                          title={i18n.t('CLIENT_DETAILS.forms.company.address')}
                          type={"text"}
                          value={address}
                          onChange={({ target: { value } }) => {
                            setAddress(value)
                            showInputError.address = false
                            setShowInputError(showInputError)
                          }}
                          disabled={loading}
                          required
                          showError={!address && showInputsErrors || showInputError.address}
                        />
                      </>
                    }
                  </ProfileFormInputsContainer>
                  <ProfileFormInputsContainer>   
                    <FormTextInput
                      title={i18n.t('CLIENT_DETAILS.forms.company.phone')}
                      type={"text"}
                      value={phone}
                      onChange={({ target: { value } }) => {
                        setPhone(value)
                        showInputError.phone = isNumber(value)
                        setShowInputError(showInputError)
                      }}
                      showError={!province && showInputsErrors || showInputError.phone }
                      disabled={loading}
                    />
                    <FormSelect
                      disabled={loading}
                      title={i18n.t('CLIENT_DETAILS.forms.company.country')}
                      value={country}
                      onChange={({ target: { value } }) => {
                        showInputError.country = false
                        setShowInputError(showInputError)
                        const iso2 = countries.filter(item => item.value === value)[0]?.iso2
                        setCountry(value)
                        setCountryIso2(iso2)
                        getStates(iso2)
                      }}
                      items={countries}
                      required
                      showError={!country && showInputsErrors || showInputError.country }
                    />
                    {(country && states) && <FormSelect
                      disabled={loading}
                      title={i18n.t('CLIENT_DETAILS.forms.company.province')}
                      value={province}
                      onChange={({ target: { value } }) => {
                        showInputError.province = false
                        setShowInputError(showInputError)
                        setProvince(value)
                        getCities(countryIso2, value)
                      }}
                      items={states}
                      required
                      showError={!province && showInputsErrors || showInputError.province }
                    />}
                    {(country && province && cities) && <FormSelect
                      disabled={loading}
                      title={i18n.t('CLIENT_DETAILS.forms.company.city')}
                      value={city}
                      onChange={({ target: { value } }) => setCity(value)}
                      items={cities}

                    />}
                    {
                      rol !== 'admin' ?
                        <>
                          <FormTextInput
                            title={i18n.t('CLIENT_DETAILS.forms.company.postalCode')}
                            type={"text"}
                            value={postalCode}
                            onChange={({ target: { value } }) => setPostalCode(value)}
                            disabled={loading}
                            required
                            showError={!postalCode && showInputsErrors}
                          />
                          <FormTextInput
                            title={i18n.t('CLIENT_DETAILS.forms.company.address')}
                            type={"text"}
                            value={address}
                            onChange={({ target: { value } }) => setAddress(value)}
                            disabled={loading}
                            required
                            showError={!address && showInputsErrors}
                          />
                        </>
                        :
                        <></>
                    }
                  </ProfileFormInputsContainer>
                  <ProfileFormSeparator
                  />
                  <ProfileLastButtonsContainer>
                    {clientEmail && <CategoryButton
                      onClick={() => removeUser()}
                      active={false}
                      icon=""
                      title={i18n.t('CLIENT_DETAILS.buttons.remove')}
                      loading={loading}
                      disabled={loading}
                    />}
                    <CategoryButton
                      onClick={() => {
                        if (clientEmail || clientId) return saveProfile()

                        rol == 'admin' ? validateForm( currentForm, setShowInputError, setCurrentForm, { address, province, country, socialReason, nif, postalCode, phone }, showInputError)
                          :
                        actionsSuperAdminCompany({ address, province, country, socialReason, nif, postalCode, type2 }, rol, setCurrentForm, setGeneralError, setShowInputsErrors, setContactEmailErrorMessage)
                      }}
                      active
                      icon=""
                      title={i18n.t('CLIENT_DETAILS.buttons.save')}
                      loading={loading}
                      disabled={loading}
                    />
                  </ProfileLastButtonsContainer>
                </ProfileFormsContainer>
              </> : null}
            {currentForm === "access"
              ? <>
                <ProfileFormsContainer>
                  <ProfileFormInputsContainer>
                    <FormTextInput
                      title={i18n.t('CLIENT_DETAILS.forms.access.representativeName')}
                      type={"text"}
                      value={representativeName}
                      onChange={({ target: { value } }) => {
                        setRepresentativeName(value)
                        showInputError.representativeName = false
                        setShowInputError(showInputError)
                      }}
                      allowJustLetters
                      required
                      showError={!representativeName && showInputsErrors || showInputError.representativeName}
                      disabled={loading}
                    />
                    <FormTextInput
                      title={i18n.t('CLIENT_DETAILS.forms.access.representativeLastName')}
                      type={"text"}
                      value={representativeLastName}
                      onChange={({ target: { value } }) => {
                        setRepresentativeLastName(value)
                        showInputError.representativeLastName = false
                        setShowInputError(showInputError)
                      }}
                      allowJustLetters
                      required
                      showError={!representativeLastName && showInputsErrors || showInputError.representativeLastName}
                      disabled={loading}
                    />
                    <FormTextInput
                      title={i18n.t('CLIENT_DETAILS.forms.access.dni')}
                      type={"text"}
                      value={dni}
                      onChange={({ target: { value } }) => {
                        setDni(value)
                        showInputError.dni = false
                        setShowInputError(showInputError)
                      }}
                      required
                      showError={!dni && showInputsErrors || showInputError.dni}
                      disabled={loading}
                    />
                    <FormTextInput
                      title={i18n.t('CLIENT_DETAILS.forms.access.position')}
                      type={"text"}
                      value={position}
                      onChange={({ target: { value } }) => {
                        setPosition(value)
                        showInputError.position = false
                        setShowInputError(showInputError)
                      }}
                      disabled={loading}
                      required
                      showError={!position && showInputsErrors || showInputError.position}
                    />
                  </ProfileFormInputsContainer>
                  <ProfileFormInputsContainer>
                    <FormTextInput
                      title={i18n.t('CLIENT_DETAILS.forms.access.email')}
                      type={"email"}
                      value={email}
                      onChange={({ target: { value } }) => {
                        setEmail(value)
                        isEmail(value) ? setEmailError('El texto ingresado no es un email') : setEmailError('')
                        showInputError.email = false
                        setShowInputError(showInputError)
                      }}
                      disabled={loading}
                      showError={!email && showInputsErrors || showInputError.email}
                      required
                      errorMessage={emailError}
                    />
                    <FormTextInput
                      title={i18n.t('CLIENT_DETAILS.forms.access.confirmEmail')}
                      type={"email"}
                      value={confirmEmail}
                      onChange={({ target: { value } }) => {
                        setConfirmEmail(value)
                        value !== email ? setEmailError('Los email no coinciden') : setEmailError('')
                        showInputError.confirmEmail = false
                        setShowInputError(showInputError)
                      }}
                      disabled={loading}
                      showError={!confirmEmail && showInputsErrors || showInputError.confirmEmail}
                      required
                      errorMessage={emailError}
                    />
                    <FormTextInput
                      title={i18n.t('CLIENT_DETAILS.forms.access.password')}
                      iconRightClick={() => setPasswordShow(!passwordShow)}
                      rightIcon={passwordShow ? offEye : redEye}
                      icon={passwordIcon}
                      type={passwordShow ? "text" : "password"}
                      value={password}
                      placeholder={ (clientId || clientEmail) && '**********' }
                      errorMessage={passwordError}
                      onChange={({ target: { value } }) => {
                        setPassword(value)
                        showInputError.password = false
                        setShowInputError(showInputError)
                        value.length < 8 ? setPasswordError(i18n.t("GENERAL.passwordLength")) : setPasswordError('')
                      }}
                      disabled={loading}
                    />
                    <FormTextInput
                      title={i18n.t('CLIENT_DETAILS.forms.access.confirmPassword')}
                      iconRightClick={() => setSecondPasswordShow(!secondPasswordShow)}
                      rightIcon={secondPasswordShow ? offEye : redEye}
                      icon={passwordIcon}
                      type={secondPasswordShow ? "text" : "password"}
                      value={confirmPassword}
                      placeholder={ (clientId || clientEmail) && '**********' }
                      errorMessage={passwordError}
                      onChange={({ target: { value } }) => {
                        setConfirmPassword(value)
                        value !== password ? setPasswordError(i18n.t("GENERAL.passwordsNotMatch")): setPasswordError('')
                        showInputError.confirmPassword = false
                        setShowInputError(showInputError)
                      }}
                      disabled={loading}
                    />
                  </ProfileFormInputsContainer>
                  <ProfileFormSeparator
                  />
                  <ProfileLastButtonsContainer>
                    <CategoryButton
                      onClick={() => removeUser()}
                      active={false}
                      icon=""
                      title={i18n.t('CLIENT_DETAILS.buttons.remove')}
                      loading={loading}
                    />
                    <CategoryButton
                      onClick={() => {
                        if (clientEmail || clientId) return saveProfile()

                        rol == 'admin' ? validateForm(currentForm, setShowInputError, setCurrentForm, { representativeName, representativeLastName, dni, position, email, confirmEmail, password, confirmPassword }, showInputError, setPasswordError, setEmailError )
                          :
                        actionsSuperAdminAccess({ representativeName, representativeLastName, dni, email, password, confirmPassword, clientId, confirmEmail, position }, rol, setShowInputsErrors, setCurrentForm, setPasswordError, setGeneralError, setEmailError)
                      }}
                      active
                      icon=""
                      title={i18n.t('CLIENT_DETAILS.buttons.save')}
                      loading={loading}
                    />
                  </ProfileLastButtonsContainer>
                </ProfileFormsContainer>
              </> : null}
            {currentForm === "variables"
              ? <>
                <ProfileFormsContainer>
                  <ProfileFormInputsContainer style={{ width: 400 }}>
                    <FormNumberInput
                      title={i18n.t('CLIENT_DETAILS.forms.variables.fixedRemuneration')}
                      type={"number"}
                      value={fixedRemuneration}
                      onValueChange={(value) => setFixedRemuneration(value)}
                      rightComponent={<FixedAmountText>{"€"}</FixedAmountText>}
                      placeholder={"0.00"}
                      allowDecimals
                      disabled={loading}
                    />
                    <FormNumberInput
                      title={i18n.t('CLIENT_DETAILS.forms.variables.nationalComplete')}
                      type={"text"}
                      value={nationalComplete}
                      onValueChange={(value) => setNationalComplete(value)}
                      rightComponent={<FixedAmountText>{"€"}</FixedAmountText>}
                      placeholder={"0.00"}
                      allowDecimals
                      disabled={loading}
                    />
                    <FormNumberInput
                      title={i18n.t('CLIENT_DETAILS.forms.variables.internationalComplete')}
                      type={"text"}
                      value={internationalComplete}
                      onValueChange={(value) => setInternationalComplete(value)}
                      rightComponent={<FixedAmountText>{"€"}</FixedAmountText>}
                      placeholder={"0.00"}
                      allowDecimals
                      disabled={loading}
                    />
                    <FormNumberInput
                      title={i18n.t('CLIENT_DETAILS.forms.variables.payPerKm')}
                      type={"text"}
                      value={payPerKm}
                      onValueChange={(value) => setPayPerKm(value)}
                      rightComponent={<FixedAmountText>{"€"}</FixedAmountText>}
                      placeholder={"0.00"}
                      allowDecimals
                      disabled={loading}
                    />
                  </ProfileFormInputsContainer>
                  <ProfileFormInputsContainer style={{ width: 600 }}>
                    <ProfileSmallButtonsGroup>
                      <ProfileSmallButtonGroup>
                        <FormSelect
                          title={i18n.t('PROFILE.forms.variables.lunch')}
                          value={lunch}
                          containerStyle={{ width: 150 }}
                          onChange={({ target: { value } }) => setLunch(value)}
                          items={hoursForSelect}
                          placeholder={"00:00"}
                          disabled={loading}
                        />
                        <FormSelect
                          value={lunch2}
                          containerStyle={{ width: 150, marginTop: 40 }}
                          onChange={({ target: { value } }) => setLunch2(value)}
                          items={lunch ? hoursForSelect.filter(item => lunch && Number(item.value) > Number(lunch)) : hoursForSelect}
                          placeholder={"00:00"}
                          disabled={loading}
                        />
                      </ProfileSmallButtonGroup>
                      <ProfileSmallButtonGroup>
                        <FormSelect
                          title={i18n.t('PROFILE.forms.variables.dinner')}
                          value={dinner}
                          containerStyle={{ width: 150 }}
                          onChange={({ target: { value } }) => setDinner(value)}
                          items={lunch2 ? hoursForSelect.filter(item => Number(item.value) >= Number(lunch2)) : hoursForSelect}
                          placeholder={"00:00"}
                          disabled={loading}
                        />
                        <FormSelect
                          value={dinner2}
                          containerStyle={{ width: 150, marginTop: 40 }}
                          onChange={({ target: { value } }) => setDinner2(value)}
                          items={dinner ? hoursForSelect.filter(item => Number(item.value) > Number(dinner)) : hoursForSelect}
                          placeholder={"00:00"}
                          disabled={loading}
                        />
                      </ProfileSmallButtonGroup>
                      <ProfileSmallButtonGroup>
                        <FormSelect
                          title={i18n.t('PROFILE.forms.variables.nocturnal')}
                          value={nocturnal}
                          containerStyle={{ width: 150 }}
                          onChange={({ target: { value } }) => setNocturnal(value)}
                          items={hoursForSelect}
                          placeholder={"00:00"}
                          disabled={loading}
                        />
                        <FormSelect
                          value={nocturnal2}
                          containerStyle={{ width: 150, marginTop: 40 }}
                          onChange={({ target: { value } }) => setNocturnal2(value)}
                          items={hoursForSelect}
                          placeholder={"00:00"}
                          disabled={loading}
                        />
                      </ProfileSmallButtonGroup>
                    </ProfileSmallButtonsGroup>
                    <ProfileSmallButtonsGroup>
                      <ProfileSmallButtonGroup>
                        <FormNumberInput
                          title={i18n.t('CLIENT_DETAILS.forms.variables.national')}
                          type={"text"}
                          containerStyle={{ width: 100 }}
                          value={lunchNational}
                          onValueChange={(value) => setLunchNational(value)}
                          placeholder={"0.00€"}
                          disabled={loading}
                          allowDecimals
                        />
                        <FormNumberInput
                          title={i18n.t('CLIENT_DETAILS.forms.variables.international')}
                          type={"text"}
                          containerStyle={{ width: 100 }}
                          value={lunchInternational}
                          onValueChange={(value) => setLunchInternational(value)}
                          placeholder={"0.00€"}
                          disabled={loading}
                          allowDecimals
                        />
                      </ProfileSmallButtonGroup>
                      <ProfileSmallButtonGroup>
                        <FormNumberInput
                          title={i18n.t('CLIENT_DETAILS.forms.variables.national')}
                          type={"text"}
                          containerStyle={{ width: 100 }}
                          value={dinnerNational}
                          onValueChange={(value) => setDinnerNational(value)}
                          placeholder={"0.00€"}
                          disabled={loading}
                          allowDecimals
                        />
                        <FormNumberInput
                          title={i18n.t('CLIENT_DETAILS.forms.variables.international')}
                          type={"text"}
                          containerStyle={{ width: 100 }}
                          value={dinnerInternational}
                          onValueChange={(value) => setDinnerInternational(value)}
                          placeholder={"0.00€"}
                          disabled={loading}
                          allowDecimals
                        />
                      </ProfileSmallButtonGroup>
                      <ProfileSmallButtonGroup>
                        <FormNumberInput
                          title={i18n.t('CLIENT_DETAILS.forms.variables.national')}
                          type={"text"}
                          containerStyle={{ width: 100 }}
                          value={nocturnalNational}
                          onValueChange={(value) => setNocturnalNational(value)}
                          placeholder={"0.00€"}
                          disabled={loading}
                          allowDecimals
                        />
                        <FormNumberInput
                          title={i18n.t('CLIENT_DETAILS.forms.variables.international')}
                          type={"text"}
                          containerStyle={{ width: 100 }}
                          value={nocturnalInternational}
                          onValueChange={(value) => setNocturnalInternational(value)}
                          placeholder={"0.00€"}
                          disabled={loading}
                          allowDecimals
                        />
                      </ProfileSmallButtonGroup>
                    </ProfileSmallButtonsGroup>
                    <ProfileSmallButtonsGroup style={{ width: "90%", justifyContent: "flex-start", alignItems: "flex-start" }}>
                      <FormNumberInput
                        title={i18n.t('PROFILE.forms.variables.sunday')}
                        type={"text"}
                        value={sunday}
                        placeholder={"0.00"}
                        onValueChange={(value) => setSunday(value)}
                        rightComponent={<FixedAmountText>{"€"}</FixedAmountText>}
                        allowDecimals
                        disabled={loading}
                      />
                    </ProfileSmallButtonsGroup>
                  </ProfileFormInputsContainer>
                  <ProfileFormSeparator
                  />
                  <ProfileLastButtonsContainer>
                    {clientEmail && <CategoryButton
                      onClick={() => removeUser()}
                      active={false}
                      icon=""
                      title={i18n.t('CLIENT_DETAILS.buttons.remove')}
                      loading={loading}
                    />}
                    <CategoryButton
                      onClick={() => {
                        saveProfile()
                        setShowInputsErrors(false)
                      }}
                      active
                      icon=""
                      title={i18n.t('CLIENT_DETAILS.buttons.save')}
                      loading={loading}
                    />
                  </ProfileLastButtonsContainer>
                </ProfileFormsContainer>
              </> : null}
            {currentForm === "users" && clientEmail
              ? <>
                <ProfileTableContainer>
                  <TruckTable
                    type="client-users"
                    header={tableHeader}
                    titles={[
                      { label: i18n.t('CLIENT_DETAILS.forms.users.picture') },
                      { label: i18n.t('CLIENT_DETAILS.forms.users.name') },
                      { label: i18n.t('CLIENT_DETAILS.forms.users.lastname') },
                      { label: i18n.t('CLIENT_DETAILS.forms.users.email') },
                      { label: i18n.t('CLIENT_DETAILS.forms.users.type') },
                      { label: i18n.t('CLIENT_DETAILS.forms.users.payments') },
                      { label: i18n.t('CLIENT_DETAILS.forms.users.state') }
                    ]}
                    onEndReach={() => {
                      if (keepIncreasing) {
                        setOffset(offset + increaseLimit)
                        setLimit(limit + increaseLimit)
                      }

                      getUserDrivers()
                    }}
                    changeDriverStatusSuperAdmin={changeDriverStatus}
                    updateDriver={updateDriver}
                    updateTable={(updatedDriver) => {
                      setUserDrivers([])
                      setOffset(0)
                      setLimit(30)

                      if (updatedDriver) setUserDrivers(userDrivers?.map(driver => driver._id === updatedDriver._id ? updatedDriver : driver))
                    }}
                    style={{ maxHeight: 700, overflow: "auto" }}
                    data={userDrivers}
                  />
                </ProfileTableContainer>
              </> : null}
              {currentForm === "map"
              ? <>
                <ProfileFormsContainer>
                  <ProfileFormInputsContainer>
                    <FormTextInput
                      disabled={loading}
                      title={`${i18n.t('CLIENT_DETAILS.forms.address.pickUpAddress')} 1`}
                      type={"text"}
                      value={addressGet1}
                      onChange={({ target: { value } }) => {
                        setAddressGet1(value)
                        geocodeByAddress(value)
                          .then(results => {
                            setAddressGet1(results[0].formatted_address)

                            return getLatLng(results[0])
                          })
                          .then(({ lat, lng }) => {
                            setCoordsGet1( `${lat}, ${lng}` )
                          })
                      }}
                      isAddressAutocomplete 
                    />
                    <FormTextInput
                      disabled={loading}
                      title={`${i18n.t('CLIENT_DETAILS.forms.address.pickUpAddress')} 2`}
                      type={"text"}
                      value={addressGet2}
                      onChange={({ target: { value } }) => {
                        setAddressGet2(value)
                        geocodeByAddress(value)
                        .then(results => {
                          setAddressGet2(results[0].formatted_address)

                          return getLatLng(results[0])
                        })
                        .then(({ lat, lng }) => {
                          setCoordsGet2( `${lat}, ${lng}` )
                        })
                      }}
                      isAddressAutocomplete
                    />
                     <FormTextInput
                      disabled={loading}
                      title={`${i18n.t('CLIENT_DETAILS.forms.address.pickUpAddress')} 3`}
                      type={"text"}
                      value={addressGet3}
                      onChange={({ target: { value } }) => {
                        setAddressGet3(value)
                        geocodeByAddress(value)
                          .then(results => {
                            setAddressGet3(results[0].formatted_address)

                            return getLatLng(results[0])
                          })
                          .then(({ lat, lng }) => {
                            setCoordsGet3( `${lat}, ${lng}` )
                          })
                      }}
                      isAddressAutocomplete
                    />
                  </ProfileFormInputsContainer>
                  <ProfileFormInputsContainer>
                    <FormTextInput
                      title={`${i18n.t('CLIENT_DETAILS.forms.address.phoneAddress')} 1`}
                      type={"text"}
                      value={phoneGet1}
                      onChange={({ target: { value } }) => setPhoneGet1(value)}
                      disabled={loading}
                    />
                    <FormTextInput
                      title={`${i18n.t('CLIENT_DETAILS.forms.address.phoneAddress')} 2`}
                      type={"text"}
                      value={phoneGet2}
                      onChange={({ target: { value } }) => setPhoneGet2(value)}
                      disabled={loading}
                    />
                    <FormTextInput
                      title={`${i18n.t('CLIENT_DETAILS.forms.address.phoneAddress')} 3`}
                      type={"text"}
                      value={phoneGet3}
                      onChange={({ target: { value } }) => setPhoneGet3(value)}
                      disabled={loading}
                    />
                  </ProfileFormInputsContainer>
                  <ProfileFormSeparator
                  />
                  <ProfileLastButtonsContainer>
                    {clientEmail && <CategoryButton
                      onClick={() => removeUser()}
                      active={false}
                      icon=""
                      title={i18n.t('CLIENT_DETAILS.buttons.remove')}
                      loading={loading}
                      disabled={loading}
                    />}
                    <CategoryButton
                      onClick={() => {
                        saveProfile()
                        setShowInputsErrors(false)
                      }}
                      active
                      icon=""
                      title={i18n.t('CLIENT_DETAILS.buttons.save')}
                      loading={loading}
                    />
                  </ProfileLastButtonsContainer>
                </ProfileFormsContainer>
              </>  : null}
          </ProfileFormContainer>
        </div>
      </div>
      {currentForm === "history" && clientId
            ? <TableContainer>
                <TruckTable
                  type='clients'
                  header={tableHeader}
                  titles= {
                    [
                      { label: i18n.t('CLIENTS.forms.clients.picture') },
                      { label: i18n.t('CLIENTS.forms.clients.company') },
                      { label: i18n.t('CLIENTS.forms.clients.name') },
                      { label: i18n.t('CLIENTS.forms.clients.lastname') },
                      { label: i18n.t('CLIENTS.forms.clients.email') },
                      { label: i18n.t('CLIENTS.forms.clients.plan') },
                      { label: i18n.t('CLIENTS.forms.clients.state') },
                      { label: i18n.t('CLIENTS.forms.clients.actions') }
                    ]
                  }
                  data={users}
                  style={{
                    maxHeight: 700,
                    overflow: "auto"
                  }}
                  onEndReach={() => {
                    if (keepIncreasing) {
                      setOffset(offset + increaseLimit)
                      setLimit(limit + increaseLimit)
                    }

                    // getUsersFunc()
                  }}
                />
                <ProfileLastButtonsContainer>
                  {clientEmail && <CategoryButton
                    onClick={() => removeUser()}
                    active={false}
                    icon=""
                    title={i18n.t('CLIENT_DETAILS.buttons.remove')}
                    loading={loading}
                    disabled={loading}
                  />}
                  <CategoryButton
                    onClick={() => {
                      saveProfile()
                      setShowInputsErrors(false)
                    }}
                    active
                    icon=""
                    title={i18n.t('CLIENT_DETAILS.buttons.save')}
                    loading={loading}
                  />
                </ProfileLastButtonsContainer>
            </TableContainer>  : null}
    </ProfileContainer>
   
  )
}

export default ClientDetailsScreen