import client from '../../utils/api';
import { CREATE_ROUTE, GET_MY_ROUTES, GET_ROUTES_ID, REMOVE_ROUTE, UPDATE_ROUTE } from './routes-query';


export const getMyRoutesAction = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await client.query({
          query: GET_MY_ROUTES,
          variables: {},
          fetchPolicy: 'network-only',
        })

        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
}

export const createRouteAction = (formData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await client.mutate({
                mutation: CREATE_ROUTE,
                variables: {
                    createRouteInput: formData
                }
            })

            resolve(res)
        } catch (error) {
            reject(error)
        }
    })
}

export const getRouteIdAction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await client.query({
        query: GET_ROUTES_ID,
        variables: {
          routeId: id
        }
      })

      resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}

export const updateRoute = (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await client.mutate({
        mutation: UPDATE_ROUTE,
        variables: {
          routeId: id,
          updateRouteInput: data
        }
      })

      resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}

export const removeRouteAction = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await client.mutate({
        mutation: REMOVE_ROUTE,
        variables: {
          routeId: id
        }
      })

      resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}