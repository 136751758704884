import React, { useState } from 'react'
import { COLORS_TRUCK } from '../../../utils/colors'
import FormSelect from '../../inputs/forms/form-select/FormSelect'
import CategoryButton from '../../buttons/category-button/CategoryButton'
import { TASK_DETAILS_ROUTE } from '../../../routes/routes'
import { useNavigate } from 'react-router-dom'
import { updateTaskAction, updateTaskLoadAction, updateTaskdownloadAction } from '../../../screens/task-details/tasks-actions'
import { useAppDispatch } from "../../../redux/store.ts";
import { openAlert } from '../../../redux/reducers/app.ts'
import i18n from '../../../i18n/i18n.js'
import { ButtonImg } from '../../buttons/truck-map/truck-map-styles.jsx'
import Times from '../../../assets/general/times.svg'
import { hoursOption } from '../../../screens/task-details/hoursOption.js'
import FormDateInput from '../../inputs/forms/form-date-input/FormDateInput.jsx'
import { DatesTaskInfoContainer } from '../../../screens/task-details/tasks-details-styles.jsx'
import FormTextInput from '../../inputs/forms/form-text-input/FormTextInput.jsx'
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import { useEffect } from 'react'
import Loader from '../../loader/Loader.jsx'
import { FormCheckboxInput } from '../../inputs/forms/form-checkbox-input/FormCheckboxInput.jsx'

export const FormCalendar = ({routes, task, setShowCalendar, reloadList, loadingTask, setMap}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [form, setForm] = useState({ name: '', start_date: '', end_date: '', route: ''})
    const [formUnload, setFormUnload] = useState({ address: '', coords: '', recipient_phone: '', recipient_name: '', recipient_delivered: false })
    const [showInputsErrors, setShowInputsErrors] = useState(false)
    const [loading, setLoading] = useState(false)
    const [coords, setCoords] = useState({})

    const assignTask = () => {
        const fields = ['name', 'start_date', 'end_date', 'route', 'unload']
        let data = { name: '', start_date: '', route: ''}
        let dataUnload = {}

        for(let index in fields) {
            if(fields[index] === 'unload') {
                dataUnload.direction = task[fields[index]]?.address.direction
                dataUnload.recipient_phone = task[fields[index]]?.address.phone
                dataUnload.recipient_name = task[fields[index]]?.recipient_name
                dataUnload.delivery_object = task[fields[index]]?.delivery_object
                dataUnload.recipient_delivered = task[fields[index]]?.recipient_delivered
                dataUnload.coords = task[fields[index]]?.coords
                continue
            } 

            if(fields[index] === 'route') {
                data[fields[index]] = task[fields[index]]?._id
                continue
            }

            data[fields[index]] = task[fields[index]]
        }

        setForm(data)
        setFormUnload(dataUnload)
    }


    const validateForm = () => {
        let fields = []
        for(let index in form) {
            if(form[index] !== 'route' && !form[index]) fields.push(index)
        }

        return fields
    }

    const validateFormUnload = () => {
        let fields = []
        for(let index in formUnload) {
            if(!formUnload[index] && index !== 'recipient_delivered') fields.push(index)
        }

        return fields
    }

    const updateTaskFunc = () => {  
        if(validateForm().length > 0 || validateFormUnload().length > 0) return setShowInputsErrors(true)
        
        setLoading(true)

        try {
            setShowInputsErrors(false)
            let data = {
                name: form.name,
                start_date: form.start_date,
                end_date: form.end_date,
                route: form.route
            }

            let dataUnload = {
                address: {
                    direction: formUnload.direction,
                    phone: formUnload.recipient_phone,
                },
                coords: `${coords.lat}, ${coords.long}`,
                recipient_name: formUnload.recipient_name,
                delivery_object: formUnload.delivery_object,
                recipient_delivered: formUnload.recipient_delivered
            }

            updateTaskdownloadAction(task._id, dataUnload)
            updateTaskAction(task._id, data)

            setShowCalendar(false)
            reloadList()
            dispatch(openAlert({
                alertType: "success",
                alertMessage: i18n.t("TASKS.updatedSuccessMessage"),
                isAlertOpen: true
            }))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            dispatch(openAlert({
                alertType: "error",
                isAlertOpen: true
            }))
        }
    } 

    const handleChange = (value, type) => {
        if(type == 'direction' || type == 'coords' || type == 'recipient_name' || type == 'recipient_phone' || type == 'delivery_object') return setFormUnload({...formUnload, [type]: value})

        setForm({...form, [type]: value})
    }

    const handleChangeCoord = (value) => {
        setCoords(value)
    }

    const closeModal = () => {
        setShowCalendar(false)
        if(setMap) setMap(true)
    }

    useEffect(() => {
      assignTask()
    }, [task])
    

    return (
        <div style={{position: 'fixed', zIndex: 9999, top: 60, left: 0, display: 'flex', justifyContent: 'center', height: 500, width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: 420, alignItems: 'center'}}>
                { loadingTask ? 
                    <div style={{background: COLORS_TRUCK.WHITE_TRUCK, border: `1px solid ${COLORS_TRUCK.ORANGE_TRUCK}`, width: 400, height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Loader color={COLORS_TRUCK.ORANGE_TRUCK} width={200} />
                    </div> 
                : <>
                    <div style={{ width: '104%', background: COLORS_TRUCK.ORANGE_TRUCK, color: COLORS_TRUCK.WHITE_TRUCK, display: 'flex', justifyContent: 'space-between', paddingRight: 15}}>
                        <div style={{ fontWeight: 'bold', padding: '10px 20px', borderTopRightRadius: 5, borderTopLeftRadius: 5 }}>
                            Tarea - { task.name }
                        </div>
                        <ButtonImg
                            src={Times} 
                            style={{width: 25, cursor: "pointer"}} 
                            onClick={() => closeModal()} 
                        />
                    </div>
                    <div style={{ overflow: 'auto', position: 'relative', background: COLORS_TRUCK.WHITE_TRUCK, border: `1px solid ${COLORS_TRUCK.ORANGE_TRUCK}`, borderEndEndRadius: 5, borderBottomLeftRadius: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: 450, height: 400, paddingTop: 160 }}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <FormCheckboxInput 
                                    checked={formUnload.recipient_delivered} 
                                    returnInfo={({check}) => handleChange(check, 'recipient_delivered') } 
                                    value={formUnload.recipient_delivered} 
                                />
                                <div style={{ color: !formUnload.recipient_delivered? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.GREEN_TRUCK }}>
                                    Tarea Entregada
                                </div>
                            </div>
                            <FormTextInput
                                title={i18n.t('TASKS.forms.tasks.name')}
                                type={"text"}
                                containerStyle={{width: 390}}
                                value={form.name}
                                onChange={({ target: { value } }) => handleChange(value, 'name')}
                                showError={!form.name && showInputsErrors}
                                required
                                disabled={loading}
                            /> 
                            <DatesTaskInfoContainer>
                                <FormDateInput
                                    disabled={loading}
                                    containerStyle={{ width: 190 }}
                                    title={i18n.t('TASKS.forms.info.startDate')}
                                    value={form.start_date}
                                    minDate={new Date()}
                                    onChange={(newValue) => {
                                        setForm({...form, 'start_date': new Date(newValue).setHours(0,0,0,0), 'end_date': null})
                                    }}
                                    required
                                    showError={!form.start_date && showInputsErrors}
                                />
                                <FormDateInput
                                    disabled={loading}
                                    containerStyle={{ width: 190 }}
                                    title={i18n.t('TASKS.forms.info.endDate')}
                                    value={form.end_date}
                                    minDate={new Date()}
                                    onChange={(newValue) => handleChange(new Date(newValue).setHours(0,0,0,0), 'end_date')}
                                    required
                                    showError={!form.end_date && showInputsErrors}
                                />
                            </DatesTaskInfoContainer>
                            <DatesTaskInfoContainer>
                                <FormTextInput
                                    title={i18n.t('TASKS.forms.addressee')}
                                    type={"text"}
                                    containerStyle={{width: 190}}
                                    value={formUnload.recipient_name}
                                    onChange={({ target: { value } }) => handleChange(value, 'recipient_name')}
                                    showError={!formUnload.recipient_name && showInputsErrors}
                                    required
                                    disabled={loading}
                                /> 
                                <FormTextInput
                                    title={i18n.t('TASKS.forms.load.phone')}
                                    type={"text"}
                                    containerStyle={{width: 190}}
                                    value={formUnload.recipient_phone}
                                    onChange={({ target: { value } }) => handleChange(value, 'recipient_phone')}
                                    showError={!formUnload.recipient_phone && showInputsErrors}
                                    required
                                    disabled={loading}
                                /> 
                            </DatesTaskInfoContainer>
                            <FormTextInput
                                disabled={loading}
                                title={i18n.t('TASKS.forms.load.address')}
                                type={"text"}
                                containerStyle={{width: 390}}
                                width={387}
                                value={formUnload.direction}
                                onChange={({ target: { value } }) => {
                                    handleChange(value, 'direction')
                                    geocodeByAddress(value)
                                    .then(results => {
                                        handleChange(results[0].formatted_address, 'direction')
                                        return getLatLng(results[0])
                                    })
                                    .then(({ lat, lng }) => handleChangeCoord({ lat, long: lng }))
                                    .catch(e => {})
                                }}
                                required
                                showError={!formUnload.direction && showInputsErrors}
                                isAddressAutocomplete
                            />
                            <div style={{display: 'flex'}}>
                                <div style={{paddingTop: 10}}>
                                    <FormSelect 
                                        title={i18n.t('TASKS.forms.load.route')}
                                        items={routes}
                                        onChange={({ target: { value } }) => handleChange(value, 'route')}
                                        value={form.route}
                                        width={ 340 }
                                        containerStyle={{width: 190}}
                                    />
                                </div>
                                <FormTextInput
                                    title={i18n.t('TASKS.forms.tasks.downloadObject')}
                                    type={"text"}
                                    containerStyle={{width: 190}}
                                    value={formUnload.delivery_object}
                                    onChange={({ target: { value } }) => handleChange(value, 'delivery_object')}
                                    showError={!formUnload.delivery_object && showInputsErrors}
                                    required
                                    disabled={loading}
                                /> 
                            </div>
                            <div style={{ display: 'flex' }}>
                                <CategoryButton
                                    onClick={() => navigate(`${TASK_DETAILS_ROUTE}/${task._id}/route`)}
                                    active={false}
                                    icon="" 
                                    style={{width: 150}}
                                    width="200"
                                    title={'Ver'}
                                />
                                <CategoryButton
                                    onClick={() => updateTaskFunc()}
                                    icon="" 
                                    active={true}
                                    style={{width: 150}}
                                    width="200"
                                    loading={loading}
                                    title={i18n.t('TASKS.forms.info.buttons.see')}
                                />
                            </div>
                        </div>
                    </div>
                </>}
                
            </div>
        </div>
    )
}
