import React, { useEffect, useState } from "react";
import { ProfileContainer, ProfileFormContainer, ProfileFormsContainer, ProfileLastButtonsContainer, ProfileFormSeparator, TableTitle, TableInfoContainer } from "./tasks-details-styles";
import i18n from '../../i18n/i18n';
import { useNavigate, useParams } from "react-router-dom";
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import FormTextInput from "../../components/inputs/forms/form-text-input/FormTextInput";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import FormDateInput from "../../components/inputs/forms/form-date-input/FormDateInput";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { createTaskAction, updateTaskAction, getTaskByIdAction, updateTaskLoadAction, updateTaskdownloadAction } from "./tasks-actions";
import { getClientsAction } from '../super-admin/clients/clients-actions'
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete"
import { getMyRoutesAction } from "../routes/routes-actions";
import { isDownloadsFormCompleted, isInfoFormCompleted, isLoadsFormCompleted } from "./validateForm";
import { useSelector } from "react-redux";
import { FormCheckboxInput } from "../../components/inputs/forms/form-checkbox-input/FormCheckboxInput.jsx";
import { COLORS_TRUCK } from "../../utils/colors.jsx";
import redPinIcon from "../../assets/home/red-pin.svg";
import { TASKS_ROUTE } from "../../routes/routes.jsx";

const TasksDetailsScreen = () => {
  const isMenuOpen = useSelector((state) => state).appSlice.isMenuOpen
  const user = useSelector((state) => state).userSlice
  const [showInputsErrors, setShowInputsErrors] = useState(false)
  const [showInputUnload, setShowInputUnload] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [showInputDirection, setShowInputDirection] = useState(false)
  const navigate = useNavigate();
  const [taskDurationUser, setTaskDurationUser] = useState(false)
  const dispatch = useAppDispatch()
  const { taskId } = useParams()
  const [loading, setLoading] = useState(false)
  const [clients, setClients] = useState([])
  const [routes, setRoutes] = useState([])
  const [addressClient, setAddressClient] = useState([])
  const [mapCoords, setMapCoords] = useState([
    { name: 'address_load', position: {lat: null, lng: null} },
    { name: 'other_address_load', position: {lat: null, lng: null} },
    { name: 'address_unload', position: {lat: null, lng: null} },
  ])
  const [form, setForm] = useState({name: '', start_date: '', end_date: '', route: '', client: ''})
  const [formLoad, setFormLoad] = useState({ direction: '', delivered: false, other_direction: '', place: '', coords: ''})
  const [formUnload, setFormUnload] = useState({recipient_name: '', dni: '', recipient_phone: '', delivery_object: '', direction: '', place: '', coords: '', object: '', observations: '', ref: ''})
  const redIcon = divIcon({ html: renderToStaticMarkup(<img alt="" src={redPinIcon} />) })

  useEffect(() => {
    if(user.rol !== 'client') {
      (async () => { 
        await getClients()
        await getRoutes()
      })()
    }

    if (taskId) {
      getTaskInfo()
    } else {
      setIsCreate(true)
    }
  }, [])

  const getTaskInfo = async () => {
    try {
      setLoading(true)

      const { data } = await getTaskByIdAction(taskId)
      assignTaskEdit(data.findTaskById)

      setLoading(false)
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const assignTaskEdit = (data) => {
    let fields = ['name', 'recipient_name', 'recipient_phone', 'delivery_object', 'address', 'coords', 'task_duration', 'start_date', 'comments', 'route', 'start_date', 'end_date', 'client' ]
    let dataForm = {}
    let dataFormLoad = {}
    let dataFormUnload = {}

    for(let index in fields) {
      if(fields[index] == 'name') {
        dataForm[fields[index]] = data[fields[index]]
        continue
      }

      if(fields[index] == 'comments' || fields[index] == 'task_duration' || fields[index] == 'recipient_name' || fields[index] == 'delivery_object') {
        dataFormUnload[fields[index]] = data.unload[fields[index]]
        continue
      }

      if(fields[index] === 'address') {
        dataFormLoad.direction = data.load[fields[index]].direction
        dataFormLoad.place = data.load[fields[index]].place
        dataFormUnload.place = data.unload[fields[index]].place
        dataFormUnload.direction = data.unload[fields[index]].direction
        dataFormUnload.ref = data.unload[fields[index]].ref
        dataFormUnload.recipient_phone = data.unload[fields[index]].phone
        continue
      }

      if(fields[index] === 'coords') {
        let map = mapCoords
        for(let index in map) {
          let coordLoad = data.load.coords.split(',')
          let coordUnload = data.unload.coords.split(',')
          if(map[index].name === 'address_load') {
            map[index].position = { lat: Number(coordLoad[0]), lng: Number(coordLoad[1]) }
          }
          if(map[index].name === 'address_unload') map[index].position = { lat: coordUnload[0], lng: coordUnload[1] }
        }

        setMapCoords(map)
      }

      if(fields[index] === 'route' || fields[index] === 'client' || fields[index] === 'start_date') dataForm[fields[index]] = (fields[index] == 'route' || fields[index] == 'client')  ? data[fields[index]]._id : data[fields[index]]
    }

    setForm(dataForm)
    setFormLoad(dataFormLoad)
    setFormUnload(dataFormUnload)
  }

  const assignForms = () => {
    let data = assignForm()

    data.load = assignFormLoad()
    data.unload = assignFormUnload()

    return data
  }

  const assignFormUnload = () => {
    let fields = ['recipient_name', 'address', 'coords', 'start_date', 'comments', 'delivery_object', 'task_duration']
    let data = {
      address: {
        direction: ''
      }
    }
    for(let index in fields) {
      if(fields[index] == 'task_duration') {
        data[fields[index]] = Number(formUnload[fields[index]])
        continue
      }

      if(fields[index] === 'address') {
        data.address.direction = formUnload.direction
        data.address.place = formUnload.place
        data.address.ref = formUnload.ref
        data.address.phone = formUnload.recipient_phone
        continue
      }

      if(fields[index] === 'start_date') {
        data.date = form[fields[index]]
        continue
      }

      if(fields[index] === 'coords') {
        for(let i in mapCoords) {
          if(mapCoords[i].name === 'address_unload') data.coords = `${mapCoords[i].position.lat}, ${mapCoords[i].position.lng}`
        }
        continue
      }

      data[fields[index]] = formUnload[fields[index]]
    }

    return data
  }

  const assignFormLoad = () => {
    let fields = ['address', 'coords', 'start_date', 'delivered' ]
    let data = {
      address: {
        direction: '',
        neighbourhood: '',
        street: '',
        phone: ''
      }
    }
    for(let index in fields) {
      if(fields[index] === 'address') {
        data.address.place = formLoad.place
        data.address.direction = formLoad.direction
        continue
      }

      if(fields[index] === 'start_date') {
        data.date = form[fields[index]]
        continue
      }

      if(fields[index] === 'coords') {
        for(let i in mapCoords) {
          if(mapCoords[i].name === 'address_load') data.coords = `${mapCoords[i].position.lat}, ${mapCoords[i].position.lng}`
        }
        continue
      }

      data[fields[index]] = formLoad[fields[index]]
    }
    return data
  }

  const assignForm = () => {
    let fields = ['name', 'route', 'start_date' ]
    let data = {}
    for(let index in fields) {
      if(fields[index] == 'start_date') data.end_date = form[fields[index]]
      if(fields[index] == 'route' && !form[fields[index]]) continue
      data[fields[index]] = form[fields[index]]
    }

    return data
  }

  const createTask = async () => {
    try {
      await createTaskAction(user.rol !== 'client' ? form.client : user.companies_ids[0], assignForms())
      navigate(`${TASKS_ROUTE}`)
      dispatch(openAlert({ alertType: "success", alertMessage: i18n.t("TASKS.successMessage"), isAlertOpen: true }))
    } catch (error) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const updateTask = async () => {
    try {
      await updateTaskAction(taskId, assignForm())
      await updateTaskLoadAction(taskId, assignFormLoad())
      await updateTaskdownloadAction(taskId, assignFormUnload())
      navigate(-1)
      dispatch(openAlert({ alertType: "success", alertMessage: i18n.t("TASKS.updatedSuccessMessage"), isAlertOpen: true }))
    } catch (error) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const validateForm = async () => {
    setLoading(true)

    if(isDownloadsFormCompleted(formUnload, setShowInputsErrors, user.rol) && isInfoFormCompleted(form, setShowInputsErrors) && isLoadsFormCompleted(formLoad, setShowInputsErrors)) !taskId  ? await createTask() : await updateTask()

    setLoading(false)
  }

  const getClients = async () => {
    try {
      setLoading(true)
      const clientData = {
        pagination: {
          limit: 30,
          offset: 0
        },
        status: 'active'
      }

      const { data } = await getClientsAction(clientData)

      setClients(data?.findMyClients)
      setLoading(false)

    } catch (error) {
      setLoading(false)
    }
  }

  const handleChangeForm = (value, type) => {
    if(type === 'client') {
      return setForm({...form, 'client': value.value, 'name': value.label})
    }

    setForm({...form, [type]: value})
  }
  const handleChangeFormLoad = (value, type) => setFormLoad({...formLoad, [type]: value})
  const handleChangeFormUnload = (value, type) => setFormUnload({...formUnload, [type]: value})

  const handleChangeCoords = (coords, name) => {
    let coord = mapCoords.filter((item) => item.name === name)
    let oldCoord = mapCoords.filter((item) => item.name !== name)
    coord[0].position = coords

    setMapCoords(oldCoord.concat(coord))
  }

  const getRoutes = async () => {
    try {
      setLoading(true)
      const { data } = await getMyRoutesAction()
      let res = data?.findMyRoutes
      let nullRoute =  [{ label: i18n.t('TASKS.table.unassigned'), value: null}]
      let newArray = nullRoute.concat(res)
      setRoutes(newArray.map((route) => ({ label: route?.name || route.label, value: route?._id || route.value })))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const assignDirectionClient = (client) => {
    let address = []
    let { address_client } = clients.find(item => item._id === client)
    address.push({address: 0, name: i18n.t('TASKS.forms.tasks.selectAnother')})

    for(let index in address_client) {
      if(address_client[index].address) address.push(address_client[index])
    }

    setAddressClient(address)
    setShowInputDirection(address.length > 1 ? false : true)
  }

  const getTaskDuration = (check) => {
    let data = user?.payment_variables?.task_duration || 0
    handleChangeFormUnload(check ? data : 0, 'task_duration')
    setTaskDurationUser(check)
  }
  
  return (
    <ProfileContainer style={{ position: isMenuOpen && 'relative', flexDirection: 'column' }}>
      <div style={{marginTop: 40}}>
        <div style={{ display: "flex", justifyContent: "center"}}>
          <ProfileFormContainer>
            <>
              <TableInfoContainer style={{ display: 'flex', width: 1000, justifyContent: 'space-between', alignItems: 'center' }}>
                <TableTitle>
                  {`${taskId ? `${form.name}` : i18n.t('TASKS.forms.info.title')}`}
                </TableTitle>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',  gap: 5}}>
                    <div style={{ color: !form.delivered ? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.GREEN_TRUCK }}>{ i18n.t('TASKS.forms.tasks.taskReady') }</div>
                    <FormCheckboxInput 
                      checked={form.delivered} 
                      returnInfo={({check}) => handleChangeForm(check, 'delivered') } 
                      value={form.delivered} 
                    />
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',  gap: 5}}>
                    <div style={{ color: !form.delivered ? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.GREEN_TRUCK }}>{ i18n.t('TASKS.forms.tasks.timeDefaultEstimated') }</div>
                    <FormCheckboxInput 
                      checked={taskDurationUser} 
                      returnInfo={({check}) => getTaskDuration(check) } 
                      value={taskDurationUser} 
                    />
                  </div>
                </div>
              </TableInfoContainer>
              <ProfileFormsContainer>
                {/* Info task */}
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
                  {
                      user.rol !== 'client' &&
                      <FormSelect
                        disabled={loading}
                        title={i18n.t('BILLING.forms.billing.client')}
                        value={form.client}
                        onChange={({ target: { value, label } }) => {
                          assignDirectionClient(value)
                          handleChangeForm({ value, label }, 'client')
                        }}
                        items={clients.map((client) => ({ label:  client.business_client.business_name, value: client._id }))}
                        showError={ !form.client && showInputsErrors }
                        required
                      />
                    }
                    {
                      user.rol !== 'client' &&
                      <FormSelect
                        disabled={true}
                        title={i18n.t('TASKS.representative')}
                        value={form.client}
                        items={clients.map((client) => ({ label:  client.firts_name, value: client._id }))}
                        showError={ !form.client && showInputsErrors }
                      />
                    }
                    {
                      (addressClient.length > 1 && !showInputDirection) && 
                      <FormSelect
                        disabled={loading || !form.client}
                        title={i18n.t('CLIENT_DETAILS.buttons.address')}
                        value={formLoad.direction}
                        onChange={({ target: { value } }) => {
                          let coords = addressClient.find(item => item.address === value).street?.split(',')
                          setShowInputDirection(value == 0 ? true : false)
                          handleChangeFormLoad(value, 'direction')
                          handleChangeCoords({ lat: coords[0], lng: coords[1] }, 'address_load')
                        }}
                        items={addressClient.map((client) => ({ label: client.address === 0 ? client.name : client.address, value: client.address }))}
                        showError={ !form.client && showInputsErrors }
                        required
                      />
                    }
                    
                    {
                      (addressClient.length < 1 || showInputDirection )  && 
                      <FormTextInput
                        disabled={loading}
                        title={i18n.t('TASKS.forms.tasks.addressLoading')}
                        type={"text"}
                        value={formLoad.direction}
                        onChange={({ target: { value } }) => {
                          handleChangeFormLoad(value, 'direction')
                          geocodeByAddress(value)
                            .then(results => {
                              handleChangeFormLoad(results[0].formatted_address, 'direction')

                              return getLatLng(results[0])
                            })
                            .then(({ lat, lng }) => {
                              handleChangeCoords({ lat, lng }, 'address_load')
                            })
                        }}
                        required
                        showError={!formLoad.direction && showInputsErrors}
                        isAddressAutocomplete
                      />
                    }
                    <FormDateInput
                      disabled={loading}
                      containerStyle={{ width: 300 }}
                      title={i18n.t('TASKS.forms.load.date')}
                      value={form.start_date}
                      minDate={new Date()}
                      onChange={(newValue) => handleChangeForm(new Date(newValue).setHours(0,0,0,0), 'start_date')}
                      required
                      showError={!form.start_date && showInputsErrors}
                    />
                    <FormTextInput
                      mainContainerStyle={{marginTop: 10}}
                      disabled={loading}
                      title={i18n.t('TASKS.forms.tasks.timeEstimated')}
                      type={"number"}
                      value={formUnload.task_duration}
                      onChange={({ target: { value } }) => handleChangeFormUnload(value, 'task_duration')}
                    />
                    
                    {
                      user.rol !== 'client' && 
                        <>
                          <div style={{paddingTop: 8}}>
                            <FormSelect
                              disabled={loading}
                              title={i18n.t('TASKS.forms.tasks.route')}
                              value={form.route}
                              onChange={({ target: { value } }) => handleChangeForm(value, 'route')}
                              items={routes}
                            />
                          </div>
                        </>
                    }
                    <div>
                      {mapCoords?.map((item, i) =>
                        (
                            (item.position.lat && item.name !== 'address_unload') && 
                            <MapContainer
                                style={{ width: 250, height: 200, marginLeft: 40, marginBottom: 20, borderRadius: 15 }}
                                center={item.position}
                                zoom={5}
                                scrollWheelZoom={true}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                />
                                <Marker key={item.name} icon={redIcon} color="red" position={item.position}>
                                  
                                </Marker>
                            </MapContainer>
                        )
                      )}
                    </div>
                    {
                      mapCoords.map(item => (
                       (item.position.lat && item.name !== 'address_unload' ) && 
                       <FormTextInput
                          disabled={true}
                          title={i18n.t('TASKS.forms.load.coordinates')}
                          type={"text"}
                          value={`${item.position.lat}, ${item.position.lng}`}
                          onChange={({ target: { value } }) => handleChangeFormLoad(value, 'place')}
                          required
                          showError={!formLoad.coords && showInputsErrors}
                        />
                      ))
                    }
                </div>
                <ProfileFormSeparator />
                {/* Addressee info */}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TableInfoContainer>
                      <TableTitle>
                          { i18n.t('TASKS.forms.tasks.addressee') }
                      </TableTitle>
                    </TableInfoContainer>
                    <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                      <FormTextInput
                        title={i18n.t('TASKS.forms.tasks.name')}
                        type={"text"}
                        value={formUnload.recipient_name}
                        onChange={({ target: { value } }) =>handleChangeFormUnload(value, 'recipient_name') }
                        showError={!formUnload.recipient_name && showInputsErrors}
                        required
                        disabled={loading}
                      />
                      <FormTextInput
                        title={i18n.t('TASKS.forms.load.phone')}
                        type={"text"}
                        value={formUnload.recipient_phone}
                        onChange={({ target: { value } }) => handleChangeFormUnload(value, 'recipient_phone')}
                        showError={!formUnload.recipient_phone && showInputsErrors}
                        required
                        disabled={loading}
                      />
                      {
                        (addressClient.length > 1 && !showInputUnload) && 
                        <FormSelect
                          disabled={loading || !form.client}
                          title={i18n.t('CLIENT_DETAILS.buttons.address')}
                          value={formUnload.direction}
                          onChange={({ target: { value } }) => {
                            let coords = addressClient.find(item => item.address === value).street?.split(',')
                            setShowInputUnload(value == 0 ? true : false)
                            handleChangeFormUnload(value, 'direction')
                            handleChangeCoords({ lat: coords[0], lng: coords[1] }, 'address_unload')
                          }}
                          items={addressClient.map((client) => ({ label: client.address === 0 ? client.name : client.address, value: client.address }))}
                          showError={ !form.client && showInputsErrors }
                          required
                        />
                      }
                      
                      {
                        (addressClient.length < 1 || showInputUnload )  && 
                        <FormTextInput
                          disabled={loading}
                          title={i18n.t('TASKS.forms.tasks.addressDownload')}
                          type={"text"}
                          value={formUnload.direction}
                          onChange={({ target: { value } }) => {
                            handleChangeFormUnload(value, 'direction')
                            geocodeByAddress(value)
                              .then(results => {
                                handleChangeFormUnload(results[0].formatted_address, 'direction')

                                return getLatLng(results[0])
                              })
                              .then(({ lat, lng }) => {
                                handleChangeCoords({ lat, lng }, 'address_unload')
                              })
                          }}
                          required
                          showError={!formUnload.direction && showInputsErrors}
                          isAddressAutocomplete
                        />
                      }
                      <FormTextInput
                        mainContainerStyle={{marginTop: 10}}
                        title={i18n.t('TASKS.forms.tasks.observations')}
                        type={"text"}
                        value={formUnload.comments}
                        onChange={({ target: { value } }) => handleChangeForm(value, 'comments')}
                        disabled={loading}
                      />
                      <FormTextInput
                        title={i18n.t('TASKS.forms.tasks.downloadObject')}
                        type={"text"}
                        value={formUnload.delivery_object}
                        onChange={({ target: { value } }) => handleChangeFormUnload(value, 'delivery_object')}
                        showError={!formUnload.delivery_object && showInputsErrors}
                        required
                        disabled={loading}
                      />
                      <div style={{paddingTop: 8}}>
                        <FormTextInput
                          title={i18n.t('TASKS.forms.load.reference')}
                          type={"text"}
                          value={formUnload.ref}
                          onChange={({ target: { value } }) => handleChangeFormUnload(value, 'ref')}
                          disabled={loading}
                        />
                      </div>
                        {mapCoords?.map((item, i) =>
                          (
                              ( item.position.lat && item.name === 'address_unload') && 
                              <div>
                                <MapContainer
                                    style={{ width: 250, height: 200, marginLeft: 40, marginBottom: 20, borderRadius: 15 }}
                                    center={ item.position }
                                    zoom={5}
                                    scrollWheelZoom={false}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                    />
                                        {mapCoords?.map((item, i) =>
                                          (
                                              ( item.name === 'address_unload') && <Marker icon={redIcon} color="red" position={ item.position }>
                                              </Marker>
                                          )
                                        )}
                                </MapContainer> 
                              </div>
                          )
                        )}
                        {
                          mapCoords.map(item => (
                          (item.position.lat && item.name == 'address_unload' ) && 
                            <FormTextInput
                              disabled={true}
                              title={'Coordenadas'}
                              type={"text"}
                              value={`${item.position.lat}, ${item.position.lng}`}
                              required
                              showError={!formUnload.coords && showInputsErrors}
                            />
                          ))
                        }
                    </div>
                  </div>        
                  <ProfileLastButtonsContainer>              
                    <CategoryButton
                      onClick={() => validateForm()}
                      active
                      icon=""
                      title={taskId && !isCreate ? i18n.t('TASKS.forms.info.buttons.update') : i18n.t('TASKS.forms.info.buttons.save')}
                      loading={loading}
                      disabled={loading}
                    />
                  </ProfileLastButtonsContainer>
                </div>
              </ProfileFormsContainer>
            </>
          </ProfileFormContainer>
        </div>
      </div>
    </ProfileContainer>
  )
}

export default TasksDetailsScreen