import styled from "styled-components";
import { COLORS_TRUCK } from "../../../utils/colors";

export const ButtonContainer = styled.div`
background: ${COLORS_TRUCK.WHITE_TRUCK};
border-radius: 5px;
display: flex;
justify-content: center;
cursor: pointer;
border: 1px solid ${COLORS_TRUCK.ORANGE_TRUCK}
`

export const ButtonImg = styled.img`
width: 40px;
`
export const TableLoaderContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;

export const PlansRoutesContainer = styled.div`
position: absolute;
width: 1100px;
height: 400px;
top: 50px;
z-index: 50;
right: 100px;
@media (max-width: 1000px) {
    max-width: 800px;
    min-width: 800px;
    right: 100px;
}

@media (max-width: 860px) {
    max-width: 600px;
    min-width: 600px;
    right: 100px;
}

@media (max-width: 600px) {
    max-width: 400px;
    min-width: 400px;
    right: 40px;
}
`



export const PlansRoutesHeader = styled.div`
background: ${COLORS_TRUCK.ORANGE_TRUCK};
padding: 0 10px;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
display: flex;
justify-content: space-between;
align-items: center;
`

export const TextHeader = styled.p`
color: white;
font-size: 18px;
font-weight: 600;
`

export const ContentContainer = styled.div`
background: white;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
border: 1px solid ${COLORS_TRUCK.ORANGE_TRUCK};
`

export const TaskDetailsContainer = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding: 5px;
 min-width: 200px;
`;

export const TaskDetailsItemsContainer = styled.div`
 display: flex;
 flex-direction: column;
`;

export const TaskDetailsTitle = styled.p`
font-wight: bold;
margin: 0 !important;
`;

export const TaskDetailsContentLink = styled.p`
 color: blue;
 border-bottom: 1px solid blue;
 margin: 0 !important;
 margin-left: 10px !important;
 cursor: pointer;
`;

export const TaskDetailsItemContainer = styled.div`
 display: flex;
 margin: 5px;
`;

export const TaskDetailsContent = styled.p`
//  color: blue;
//  border-bottom: 1px solid blue;
margin: 0 !important;
 margin-left: 10px !important;
`;

export const DateContainer = styled.div`
display: flex;
align-items: center;
width: 50%;
justify-content: end;
color: ${COLORS_TRUCK.ORANGE_TRUCK};
`

export const DownloadItenary = styled.div`
border: 1px solid ${COLORS_TRUCK.ORANGE_TRUCK};
background: ${COLORS_TRUCK.WHITE_TRUCK};
color: ${COLORS_TRUCK.ORANGE_TRUCK};
margin: 10px;
display: flex;
font-size: 18px;
padding: 2px 5px;
align-items: center;
justify-content: center;
border-radius: 4px;
font-weight: 600;
cursor: pointer;
`

export const ArrowLeft = styled.div`
border-top: 2px solid ${COLORS_TRUCK.ORANGE_TRUCK};
border-left: 2px solid ${COLORS_TRUCK.ORANGE_TRUCK};
width: 13px;
height: 13px;
margin: 10px;
transform: rotate(-45deg)
`

export const ArrowRight = styled.div`
border-bottom: 2px solid ${COLORS_TRUCK.ORANGE_TRUCK};
border-right: 2px solid ${COLORS_TRUCK.ORANGE_TRUCK};
width: 13px;
height: 13px;
margin: 10px;
transform: rotate(-45deg)
`

export const OptionContainer = styled.div`
display: flex;
width: 65%;
justify-content: space-between;

@media(max-width: 860px) {
    width: 100%;
}
`

export const InfoItemsContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
width: 35%;

@media(max-width: 860px) {
    width: 100%;
}
`;

export const OptionsContainer = styled.div`
display:flex;
flex-wrap: wrap;

@media(max-width: 550px) {
    display: block;
}
`

export const InfoContainer = styled.div`
 display: flex;
 align-items: center;
 margin: 0 5px;
`;

export const ColorSquare = styled.div`
 width: 15px;
 height: 15px;
 border-radius: 5px;
`;

export const InfoContentParagraph = styled.p`
margin-left: 5px;
font-size: 13px;
font-weight: 600;
`;

export const InfoRoutesContainer = styled.div`
// width: 33%;
margin: 0 0 0 25px;
// overflow: auto;
// height: 500px;
`

export const InfoTitleRoutes = styled.p`
font-weight: 600;
`

export const InfoDriverContainer = styled.div`
display: flex;
justify-content: space-around;
align-items: center;
margin: 0 5px;
border-radius: 5px;
`

export const SortableContainer = styled.div`
display: flex;
flex-direction: column;
overflow: auto;
width: 400px;
padding-left: 10px;
`

export const MainContainer = styled.div`
display: flex;
align-items: start;
flex-wrap: wrap;

@media (max-width: 1000px) {
   align-items: center;
   justify-content: center;
}

@media (max-width: 860px) {
    align-items: center;
    justify-content: center;
}

@media (max-width: 600px) {
    align-items: center;
    justify-content: center;
}
`

export const TextInfo = styled.p`
color: white;
width: 70%;
font-size: 13px;
text-align: center;
`