import client from '../../utils/api';
import { GET_ROUTE_BY_ID, GET_TASK_WORK_BY_ROUTE } from './route-query';

export const getRouteByIdAction = (routeId) => {
  return new Promise(async (resolve, reject) => {
      try {
        const response = await client.mutate({
          mutation: GET_ROUTE_BY_ID,
          variables: {
              routeId
          },
        })
  
        resolve(response)
      } catch (error) {
        reject(error)
      }
  })
}

export const getMyTaskWorkAction = (route_id, input) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: GET_TASK_WORK_BY_ROUTE,
        variables: {
          route_id,
          input
        },
      })

      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
}