import { gql } from '@apollo/client';

export const GET_MESSAGES_SUBSCRIPTION = gql`
subscription newMessage {
  newMessage {
        _id
        datetime
        from_user_id
        message
        read
        to_user_id
  }
}
`;

export const GET_CHAT_MUTATION = gql`
query getChat($dest_id: ObjectId!, $input: MessageFiltersInput) {
  getChat(dest_id: $dest_id, input: $input){
    _id
    datetime
    from_user_id
    message
    read
    to_user_id
  }
}
`;


export const GET_CHAT_CLIENT_MUTATION = gql`
query getChatClientAdmin($dest_id: ObjectId!, $input: MessageFiltersInput) {
  getChatClientAdmin(dest_id: $dest_id, input: $input){
    _id
    datetime
    from_user_id
    message
    read
    to_user_id
  }
}
`;


export const GET_CHATS_MUTATION = gql`
query myMessages($name: String, $input: MessageFiltersInput) {
  myMessages(name: $name, input: $input){
      _id
      datetime
      from_user_name
      message
      read
      unread
  }
}
`;

export const GET_CHATS_CLIENTS_MUTATION = gql`
query findMessagesOfMyClients($name: String, $input: MessageFiltersInput) {
  findMessagesOfMyClients(name: $name, input: $input){
      _id
      datetime
      from_user_name
      message
      read
      unread
  }
}
`;

export const SEND_MESSAGE_MUTATION = gql`
mutation sendMessage($input: CreateMessageInput!) {
  sendMessage(input: $input){
        _id
        datetime
        from_user_id
        message
        read
        to_user_id
  }
}
`;

export const SEND_MESSAGE_CLIENT_MUTATION = gql`
mutation sendMessageClientAdmin($input: CreateMessageInput!) {
  sendMessageClientAdmin(input: $input){
        _id
        datetime
        from_user_id
        message
        read
        to_user_id
  }
}
`;

export const READ_MESSAGES_MUTATION = gql`
mutation readMessages($dest_id: ObjectId) {
  readMessages(dest_id: $dest_id){
       status
  }
}
`;

export const READ_MESSAGES_CLIENT_MUTATION = gql`
mutation readMessagesClientAdmin($dest_id: ObjectId) {
  readMessagesClientAdmin(dest_id: $dest_id){
       status
  }
}
`;