import { gql } from '@apollo/client';

export const GET_ROUTE_BY_ID = gql`
query findRoute($routeId: ObjectId!) {
    findRoute(routeId: $routeId) {
        _id
        name
        color
        driver {
            _id
            name
        }
        vehicle {
            _id
            plate
        }
        trailer {
            _id
            plate
        }
    }
}
`

export const GET_TASK_WORK_BY_ROUTE = gql`
query myTasksWork($route_id: ObjectId!, $input: TaskFiltersInput) {
    myTasksWork(route_id: $route_id, input: $input) {
        _id
        name
        status
        start_date
        end_date
    }
}
`