import React from "react";
import { CategoryButtonContainer, CategoryButtonIcon, CategoryButtonContent } from "./category-button-styles";

import plansIconRed from "../../../assets/category-button/data-icon-red.svg";
import plansIconWhite from "../../../assets/category-button/data-icon-white.svg";

import accessIconRed from "../../../assets/category-button/mention-icon-red.svg";
import accessIconWhite from "../../../assets/category-button/mention-icon-white.svg";

import companyIconWhite from "../../../assets/category-button/buildings-icon-white.svg";
import companyIconRed from "../../../assets/category-button/buildings-icon-red.svg";

import variablesIconRed from "../../../assets/category-button/data-trending-icon-red.svg";
import variablesIconWhite from "../../../assets/category-button/data-trending-icon-white.svg";

import billingIconRed from "../../../assets/category-button/wallet-icon-red.svg";
import billingIconWhite from "../../../assets/category-button/wallet-icon-white.svg";

import infoIconRed from "../../../assets/driver-details/info-icon-red.svg";
import infoIconWhite from "../../../assets/driver-details/info-icon-white.svg";

import docIconRed from "../../../assets/driver-details/contact-icon-red.svg";
import docIconWhite from "../../../assets/driver-details/contact-icon-white.svg";

import issuesIconWhite from "../../../assets/tasks/warning-icon-white.svg";
import issuesIconRed from "../../../assets/tasks/warning-icon-red.svg";

import loadIconWhite from "../../../assets/tasks/load-icon-white.svg";
import loadIconRed from "../../../assets/tasks/load-icon-red.svg";

import downloadIconWhite from "../../../assets/tasks/download-icon-white.svg";
import downloadIconRed from "../../../assets/tasks/download-icon-red.svg";

import peopleIconWhite from "../../../assets/general/people-icon-white.svg";
import peopleIconRed from "../../../assets/general/people-icon-red.svg";

import addCircleRed from "../../../assets/general/add-circle-red.svg";

import mapIconRed from '../../../assets/inputs/map-red.svg'
import mapIconWhite from '../../../assets/inputs/map-white.svg'

import reportIconRed from '../../../assets/inputs/report-red.svg'
import reportIconWhite from '../../../assets/inputs/report-white.svg'

import { COLORS_TRUCK } from "../../../utils/colors";

import Loader from "../../loader/Loader";
const CategoryButton = ({ icon, active, title, onClick, width, loading, style, iconStyles, disabled, titleStyle }) => {
  const plansIcon = active ? plansIconWhite : plansIconRed
  const accessIcon = active ? accessIconWhite : accessIconRed
  const companyIcon = active ? companyIconWhite : companyIconRed
  const variablesIcon = active ? variablesIconWhite : variablesIconRed
  const billingIcon = active ? billingIconWhite : billingIconRed
  const infoIcon = active ? infoIconWhite : infoIconRed
  const docIcon = active ? docIconWhite : docIconRed
  const issuesIcon = active ? issuesIconWhite : issuesIconRed
  const loadIcon = active ? loadIconWhite : loadIconRed
  const downloadIcon = active ? downloadIconWhite : downloadIconRed
  const peopleIcon = active ? peopleIconWhite : peopleIconRed
  const mapIcon = active ? mapIconWhite : mapIconRed
  const reportIcon = active ? reportIconWhite : reportIconRed
  const img = icon === "plans" ? plansIcon
    : icon === "access"
      ? accessIcon
      : icon === "company"
        ? companyIcon
        : icon === "variables"
          ? variablesIcon
          : icon === "info"
            ? infoIcon
            : icon === "doc"
              ? docIcon
              : icon === "billing"
                ? billingIcon
                : icon === "issues"
                  ? issuesIcon
                  : icon === "load"
                    ? loadIcon
                    : icon === "download"
                      ? downloadIcon
                      : icon === "people"
                        ? peopleIcon
                      : icon === "add-circle"
                        ? addCircleRed
                        : icon === "map" 
                          ? mapIcon
                          : icon === "history" 
                            ? reportIcon
                              : ""

  return (
    <CategoryButtonContainer
      onClick={!loading && !disabled ? onClick : null}
      style={Object.assign(
        loading ? { cursor: "default" } : {},
        active ? { color: COLORS_TRUCK.WHITE_TRUCK, background: COLORS_TRUCK.ORANGE_TRUCK, width: width || 150 } : { color: COLORS_TRUCK.ORANGE_TRUCK, width: width || 150 },
        style
      )}
    >
      {loading
        ? <>
          <Loader color={active ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.ORANGE_TRUCK } />
        </>
        : <>
          {img && <CategoryButtonIcon style={Object.assign({ marginRight: title ? 5 : 0 }, iconStyles)} src={img} alt="" />}
          <CategoryButtonContent style={Object.assign(active ? { color: COLORS_TRUCK.WHITE_TRUCK } : { color: COLORS_TRUCK.ORANGE_TRUCK }, titleStyle)}>
            {title}
          </CategoryButtonContent>
        </>}
    </CategoryButtonContainer>
  )
}

export default CategoryButton