import React, { useState } from 'react'
import {
    TextInputContainer,
    InputIcon,
    InputTitle,
    FormTextInputMainContainer,
  } from "./form-checkbox-input";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import { COLORS_TRUCK } from "../../../../utils/colors";

export const FormCheckboxInput = ({value, inactive, returnInfo, checked}) => {
    const handleClick = (e) => {
        returnInfo({value: e.target.value, check: e.target.checked})
    }
    
  return (
    <FormTextInputMainContainer>
        <FormControlLabel
            value={value}
            control={
                <Checkbox
                    checked={checked}
                    sx={{
                        color: inactive ? COLORS_TRUCK.BLACK_TRUCK : COLORS_TRUCK.ORANGE_TRUCK,
                        '&.Mui-checked': {
                            color: inactive ? COLORS_TRUCK.BLACK_TRUCK : COLORS_TRUCK.ORANGE_TRUCK,
                        },
                        ":hover": {
                            color: inactive ? COLORS_TRUCK.BLACK_TRUCK : COLORS_TRUCK.ORANGE_TRUCK
                        }
                    }}
                    onChange={handleClick}
                />
            }
        />
    </FormTextInputMainContainer>
  )
}
