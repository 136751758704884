import { gql } from '@apollo/client';
 
export const GET_MY_ROUTES = gql`
query findMyRoutes {
    findMyRoutes {
        _id
        name
        color
        vehicle {
            _id
            plate
        }
        driver {
            _id
            name
        }
        company {
            _id
            name
        }
        trailer {
            _id
            plate
        }
    }
}
`

export const CREATE_ROUTE = gql`
mutation createRoute($createRouteInput: CreateRouteInput!) {
    createRoute(createRouteInput: $createRouteInput) {
        _id
        color
    }
}
`

export const GET_ROUTES_ID = gql`
query findRoute($routeId: ObjectId!) {
    findRoute(routeId: $routeId) {
        _id
    }
}
`

export const UPDATE_ROUTE = gql`
mutation updateRoute($routeId: ObjectId!, $updateRouteInput: UpdateRouteInput!) {
    updateRoute(routeId: $routeId, updateRouteInput: $updateRouteInput) {
        _id
    }
}
`

export const REMOVE_ROUTE = gql`
mutation deleteRoute($routeId: ObjectId!) {
    deleteRoute(routeId: $routeId) {
        _id
    }
}
`