import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';

export const RoutesContainer = styled.div`
width: 100%;
height: 100%;
`;

export const RoutesContentContainer = styled.div`
width: 100%;
height: 100%;
flex-direction: column;
margin: auto;
padding-top: 30px;
`;

export const TableContainer = styled.div`
display: flex;
justify-content: center;
align-content: center;
flex-direction: column;
width: 100%;
`

export const RoutesFormContainer = styled.div`
width: 100%;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: column;

@media (max-width: 900px) {
    align-items: center;
    justify-content: center;
}

`;

export const RoutesBillingContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: center;
padding-top: 20px;
padding-bottom: 20px;

@media (max-width: 400px) {
  padding: 0px;
}
`;

export const TableInfoContainer = styled.p`
margin-right: 20px;
display: flex;
flex-direction: column;
`;
export const TableTitle = styled.p`
font-weight: bold;
margin: 10px;
margin-top: 30px;
`;

export const TableInfo = styled.p`
margin: 10px;
color: ${COLORS_TRUCK.ORANGE_TRUCK};
margin-right: 20px;
margin: 0;
`;

export const TableContainerButton = styled.div`
display: flex;
justify-content: end;
align-items: center;
`

export const DividerSections = styled.div`
width: 99%;
border-top: 2px solid #c4c3c3;
margin: 10px 0px;
`