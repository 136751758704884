import React, { useState, useEffect } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  // ProfileButtonsContainer,
  ProfileFormContainer,
  // ProfileFormInputsContainer,
  // ProfileFormsContainer,
  // ProfileLastButtonsContainer,
  // FixedAmountText,
  // ProfileSmallButtonGroup,
  // ProfileSmallButtonsGroup,
  // TableTh,
  // TableTd,
  // ProfileTable,
  // ProfileFormSeparator,
  ProfileBillingContainer,
  TableTitle,
  // TableInfo,
  TableInfoContainer
} from "./issues-styles";
import i18n from '../../i18n/i18n';
import { useNavigate } from "react-router-dom";
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import filterByIcon from "../../assets/profile/filter-by-icon.svg";
import searchIcon from "../../assets/profile/search-icon.svg";
import TruckTable from "../../components/truck-table/TruckTable";
import { ISSUE_DETAILS } from "../../routes/routes";
import { exportIssues, getIssuesAction } from "../issue-details/issues-actions";
import { useSelector } from "react-redux";
import TextInput from "../../components/inputs/text-input/TextInput";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";

const IssuesScreen = ({ taskId }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [issues, setIssues] = useState([])
  const [loading, setLoading] = useState(false)
  const [issueStatus, setIssueState] = useState("")
  const [search, setSearch] = useState("")
  const user = useSelector((state) => state).userSlice
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(10)
  const [keepIncreasing, setKeepIncreasing] = useState(true)

  useEffect(() => {
    getIssuesFunc()
    if(user?.plan_details?.status === "CANCELED"){
      dispatch(openAlert({
        alertType: "plan-canceled",
        isAlertOpen: true
      }))
    }
    if (user?.plan_details?.status === "WAITING") {
      dispatch(openAlert({
        alertType: "plan-waiting",
        isAlertOpen: true
      }))
    }
    
  }, [issueStatus, search])

  const getIssuesFunc = async (reset) => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        task_id: taskId || null,
        creation_date: null,
        driver: null,
        sort: null,
        status: issueStatus || null,
        type: null,
        vehicle: !reset ? search : null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      const res = await getIssuesAction(data)
      if (res?.data?.myIncidents.length <= 0) {
        setKeepIncreasing(false)
      } else {
        setKeepIncreasing(true)
      }

      let issuesArray = issues.concat(res?.data?.myIncidents)
      if ((!search && !issueStatus && !reset) || limit > 30) {
        setIssues(issuesArray.filter((item, index, self) => index === self.findIndex((t) => t._id === item._id)))
      } else {
        setIssues(res?.data?.myIncidents)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      const errorCode = localStorage.getItem("error-code")
      if (errorCode === "998") {
        setIssues([])
      }
      // if (!search && !type) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setIssues([])
      getIssuesFunc()
    }
  };

  const exportIssuesFunc = async () => {
    try {
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        creation_date: null,
        driver: null,
        sort: null,
        status: issueStatus || null,
        type: null,
        vehicle: null,
      }
      const res = await exportIssues(data)
      var win = window.open(res.data.exportMyIncidents.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const header = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('ISSUES.forms.issues.title')}
      </TableTitle>
    </TableInfoContainer>
    <FormSelect
      value={issueStatus}
      containerStyle={{ width: 220 }}
      fatherContainer={{ zIndex: 99999 }}
      onChange={({ target: { value } }) => {
        setIssueState(value)
        if (value === "RESOLVED" || value === "UNRESOLVED") {
          setIssues([])
          setIssueState(value)
          // getVehiclesFunc()
          setSearch("")
        }
        if (value === "" || value === "1") {
          setIssues([])
          setIssueState("")
          setSearch("")
          // getVehiclesFunc()
        }
      }}
      placeholder={i18n.t('VEHICLES.forms.vehicles.buttons.filterBy')}
      icon={filterByIcon}
      items={[
        { value: '', label: i18n.t('ISSUES.forms.details.all') },
        { value: 'RESOLVED', label: i18n.t('ISSUES.forms.details.status1') },
        { value: 'UNRESOLVED', label: i18n.t('ISSUES.forms.details.status2') },
      ]}
      required
    />
    <TextInput
      icon={searchIcon}
      value={search}
      containerStyle={{ width: 250 }}
      type="text"
      placeholder={i18n.t('ISSUES.forms.issues.buttons.search')}
      onChange={async (e) => {
        setSearch(e.target.value)
        if (e.target.value === "") {
          getIssuesFunc(true)
        }


      }}
      onKeyPress={handleKeyPress}
      // disabled={loading}
    />
    <CategoryButton
      onClick={() => exportIssuesFunc()}
      active={false}
      icon=""
      title={i18n.t('ISSUES.forms.issues.buttons.export')}
    />
    <CategoryButton
      onClick={() => navigate(ISSUE_DETAILS)}
      active
      icon=""
      title={i18n.t('ISSUES.forms.issues.buttons.add')}
    />
  </ProfileBillingContainer>
  return (
    <ProfileContainer>
      <ProfileContentContainer style={{ paddingTop: !taskId && 30 }}>
        <ProfileFormContainer>
          <TruckTable
            type="issues"
            header={header}
            onEndReach={() => {
              if (keepIncreasing) {
                setOffset(offset + increaseLimit)
                setLimit(limit + increaseLimit)
              }

              getIssuesFunc()
            }}
            updateTable={(reset) => getIssuesFunc(reset)}
            titles={[
              { label: i18n.t('ISSUES.forms.issues.id') },
              { label: i18n.t('ISSUES.forms.issues.type') },
              { label: i18n.t('ISSUES.forms.issues.driver') },
              { label: i18n.t('ISSUES.forms.issues.vehicle') },
              { label: i18n.t('ISSUES.forms.issues.issueDate') },
              { label: i18n.t('ISSUES.forms.issues.state') },
              { label: i18n.t('ISSUES.forms.issues.actions') },
            ]}
            data={issues}
            style={{
              maxHeight: 700,
              overflow: "auto"
            }}
          />
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default IssuesScreen