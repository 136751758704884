export const driverData = {
    address: null,
    birthday: null,
    city: null,
    company_phone: null,
    country: null,
    dni: null,
    documentation: {
      CAP: {
        card: null,
        date: null
      },
      driving_cards: {
        AM: null,
        A1: null,
        A2: null,
        A: null,
        B: null,
        C1: null,
        C: null,
        D1: null,
        D: null,
        BE: null,
        C1E: null,
        CE: null,
        D1E: null,
        DE: null
      },
      sanitary_card: { card: null, date: null },
      sanitary_card_EU: { card: null, date: null },
      tachograph_card: { card: null, date: null }
    },
    email: null,
    name: null,
    password: null,
    payment_variables: {
      complete_international: null,
      complete_national: null,
      diets: {
        dinner: null,
        dinner_international: null,
        lunch: null,
        lunch_international: null,
        nocturnal: null,
        nocturnal_international: null,
        sunday: null
      },
      fixed_remuneration: null,
      payment_per_km: null,
      payment_type: null,
      values: null
    },
    personal_email: null,
    phone: null,
    state: null,
    surnames: null,
    type: null,
    zip: null
}