import { emailsRegex } from "../../../utils/regex";
import i18n from '../../../i18n/i18n';

const companyValidate = (fields) => {
    let errors = {}
    for(let index in fields) {
        if(!fields[index]) errors[index] = true
    }

    return errors
}

const accessValidate = (fields) => {
    let errors = {}
    for(let index in fields) {
        if(!fields[index]) errors[index] = true
        if(index === 'password' && fields[index].length < 8) errors[index] = true
        if(index === 'password' && fields[index] !== fields.confirmPassword) errors[index] = true
        if(index === 'email' && fields[index] !== fields.confirmEmail) errors[index] = true
    }

    return errors
}

export const validateForm = (currentForm, setShowInputError, setCurrentForm, fields, showInputError) => {
    switch (currentForm) {
        case 'company':
            validateErrors(companyValidate(fields), setShowInputError, setCurrentForm, 'access', showInputError)
            break
        case 'access': 
            validateErrors(accessValidate(fields), setShowInputError, setCurrentForm, 'map', showInputError)
            break
        default:
            break;
    }
}

export const validateErrors = (errors, setShowInputError, setCurrentForm, currentNext, showInputError) => {
    let errorArray = [] 
    for(let index in errors) {
        errorArray.push(errors[index])
    }
    
    errorArray.some(item => !item) || errorArray.length === 0 ? setCurrentForm(currentNext) : setShowInputError( errors )
}

export const isNumber = (value) => {
    return isNaN(value)
}

export const isEmail = (value) => {
    if(emailsRegex.test(value)) return false

    return true
}

export const actionsSuperAdminCompany = (currentNext, rol, setCurrentForm, setGeneralError, setShowInputsErrors, setContactEmailErrorMessage) => {
    let { postalCode, socialReason, country, nif, address, province, type2, contactEmail } = currentNext
    if (socialReason && country && nif && address && province && postalCode) {
        if(type2 && rol !== 'admin') {
            setCurrentForm("access")
            return setShowInputsErrors(false)
        }
        
        setCurrentForm("access")
        setShowInputsErrors(false)
    } else {
        setGeneralError(i18n.t("SIGNUP.completeAllFields"))
        setShowInputsErrors(true)
    }

    const contactEmailValidation = contactEmail && !contactEmail.match(emailsRegex)
    if (contactEmailValidation) {
        setShowInputsErrors(true)
        setCurrentForm("company")
        setContactEmailErrorMessage(i18n.t("PROFILE.emailValidation"))
    }
}

export const actionsSuperAdminAccess = (currentNext, rol, setShowInputsErrors, setCurrentForm, setPasswordError, setGeneralError, setEmailError) => {
    let { representativeName, representativeLastName, dni, email, password, confirmPassword, clientId, confirmEmail, position } = currentNext
    if (representativeName && representativeLastName && dni && email && (password && confirmPassword || clientId) && confirmEmail && position) {
        const passwordValidation = password && password.length < 8
        const passwordMatchValidation = (confirmPassword && password) && password !== confirmPassword
        const emailsMatchValidation = (email && confirmEmail) && email !== confirmEmail
        if (passwordValidation) {
            setShowInputsErrors(true)
            setCurrentForm("access")
            setPasswordError(i18n.t("GENERAL.passwordLength"))
            setGeneralError(i18n.t("SIGNUP.completeAllFields"))
            return
        } else {
            setPasswordError("")
        }

        if (passwordMatchValidation) {
            setShowInputsErrors(true)
            setCurrentForm("access")
            setPasswordError(i18n.t("GENERAL.passwordsNotMatch"))
            setGeneralError(i18n.t("SIGNUP.completeAllFields"))
        return
        } else {
            setPasswordError("")
        }

        if (emailsMatchValidation) {
            setShowInputsErrors(true)
            setCurrentForm("access")
            setEmailError(i18n.t("GENERAL.emailsNotMatch"))
            setGeneralError(i18n.t("SIGNUP.completeAllFields"))

            return
        } else {
            setEmailError("")
        }
        rol !== 'admin' ? setCurrentForm("variables") : setCurrentForm("map")
    
        setShowInputsErrors(false)
    } else {
        setGeneralError(i18n.t("SIGNUP.completeAllFields"))
        setShowInputsErrors(true)
    }
}