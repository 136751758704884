import styled from 'styled-components';

export const UserHomeContainer = styled.div`
height: 100%;
width: 100%;
height: 100%;
display: flex;
position: fixed;
overflow: auto;
margin-top: 20px; 

@media (max-width: 600px) {
  margin-top: 70px;
}
`;

export const UserHomeContentContainer = styled.div`
width: 100%;
height: 100%;
max-width: 900px;
margin: auto;
padding-top: 30px;
`;

export const HomeStatsContainerGeneral = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
`;

export const HomeStatContainer = styled.div`
width: 150px;
max-height: 100px;
min-height: 50px;
box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.08);
border-radius: 10px;
margin: 10px;
padding: 10px;
cursor: pointer;
`;

export const HomeStatContentContainer = styled.div`
width: 90%;
margin: auto;
display: flex;
align-items: center;
justify-content: space-between;
`;

export const HomeStatIcon = styled.img`
width: 30px;
height: 30px;
`;

export const HomeStatNumber = styled.h1`
font-wight: bold;
margin: 0;
`;

export const TaskDetailsTitle = styled.p`
font-wight: bold;
margin: 0 !important;
`;

export const HomeStatTitle = styled.p`

`;

export const TaskDetailsContent = styled.p`
//  color: blue;
//  border-bottom: 1px solid blue;
margin: 0 !important;
 margin-left: 10px !important;
`;

export const TaskDetailsContentLink = styled.p`
 color: blue;
 border-bottom: 1px solid blue;
 margin: 0 !important;
 margin-left: 10px !important;
 cursor: pointer;
`;

export const TaskDetailsContainer = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding: 5px;
 min-width: 200px;
`;

export const TaskDetailsItemContainer = styled.div`
 display: flex;
 margin: 5px;
`;

export const TaskDetailsItemsContainer = styled.div`
 display: flex;
 flex-direction: column;
`;

export const InfoContainer = styled.div`
 display: flex;
 align-items: center;
 margin: 15px;
`;

export const ColorSquare = styled.div`
 width: 20px;
 height: 20px;
 border-radius: 5px;
`;

export const InfoContentParagraph = styled.p`
margin-left: 10px;
`;

export const InfoItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
