const config = {
    SECRET_KEY_STORAGE: process.env.REACT_APP_SECRET_KEY_STORAGE,
    API_URL_APOLLO_CLIENT: process.env.REACT_APP_API_URL_APOLLO_CLIENT,
    API_URL_APOLLO_SUBSCRIPTION: process.env.REACT_APP_API_URL_APOLLO_SUBSCRIPTION,
    STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    GOOGLE_PLACES_API: process.env.REACT_APP_GOOGLE_PLACES_API,
    BRONZE_PLAN_ID: process.env.REACT_APP_BRONZE_PLAN_ID,
    SILVER_PLAN_ID: process.env.REACT_APP_SILVER_PLAN_ID,
    GOLD_PLAN_ID: process.env.REACT_APP_GOLD_PLAN_ID,
    TIME_ZONE: process.env.REACT_APP_TIME_ZONE,
    TEMPLATE_XLSX: process.env.REACT_APP_TEMPLATE_XLSX || 'https://testing-cdn.apptruckdriver.no/resources/templates/tasks.xlsx',
    INSTRUCTIONS_XLSX: process.env.REACT_APP_INSTRUCTIONS_XLSX || 'https://testing-cdn.apptruckdriver.no/resources/templates/instructions.xlsx',
    URL_BASE_FILE: process.env.REACT_APP_URL_BASE_FILE || 'https://testing-cdn-secondary.apptruckdriver.no'
}

export default config