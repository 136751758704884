import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';

export const HeaderContainer = styled.div`
display: flex;
justify-content: space-between;
width: 900px;
padding-top: 40px;
flex-wrap: wrap;
z-index: 0;
padding-left: 40px;

@media(max-width: 750px) {
align-items: center;
justify-content: center;
width: 600px;
}

@media(max-width: 550px) {
align-items: center;
justify-content: center;
width: 400px;
}
`

export const TextInput = styled.p`
font-size: 18px;
font-weight: 600;
`

export const ButtonsContainer = styled.div`
display: flex;
margin-top: 10px;
margin-right: 40px;
`

export const ButtonOption = styled.button`
font-weight: 700;
font-size: 18px;
padding: 5px 10px;
height: 40px;
border: 1px solid ${COLORS_TRUCK.ORANGE_TRUCK};
border-radius: 5px;
`

export const Container = styled.div`
display: flex;
width: 100%;
flex-direction: column;
`

export const TableContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
margin-left: 40px;

@media (max-width: 1250px) {
  margin-left: 20px;
}
`