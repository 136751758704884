import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSubscription } from "@apollo/client";
import './app-styles.jsx';
// import HomeScreen from "./screens/home/HomeScreen";
import LoginScreen from "./screens/login/LoginScreen";
import Header from "./screens/header/Header";
import { MainContainer, RoutesContainer, RouteContainer, RouteContainerAuth } from './app-styles.jsx';
import {
  LOGIN_ROUTE,
  ROOT_ROUTE,
  RESTORE_PASSWORD_ROUTE,
  ENTER_CODE_ROUTE,
  ENTER_NEW_PASSWORD_ROUTE,
  PLANS_ROUTE,
  PROFILE_ROUTE,
  DRIVERS_ROUTE,
  VEHICLES_ROUTE,
  VEHICLE_DETAILS_ROUTE,
  DRIVER_DETAILS_ROUTE,
  TASKS_ROUTE,
  TASK_DETAILS_ROUTE,
  ISSUES_ROUTE,
  ISSUE_DETAILS,
  CHECKLIST_DETAILS,
  ACCOUTING_ROUTE,
  PAYMENT_CONCEPT,
  CHAT_ROUTE,
  ADMIN_DETAILS_ROUTE,
  SIGNUP_ROUTE,
  BILLING_ROUTE,
  CLIENTS_ROUTE,
  CLIENT_DETAILS_ROUTE,
  JOURNEYS_ROUTE,
  CHECKLIST_JOURNEY_ROUTE,
  ROUTES_CLIENT_ROUTE,
  ASIGN_ROUTES,
  ROUTES_DETAILS_CLIENT
} from './routes/routes.jsx';
import RestorePasswordScreen from './screens/restore-password/RestorePasswordScreen.jsx';
import EnterCodeScreen from './screens/enter-code/EnterCodeScreen.jsx';
import EnterNewPasswordScreen from './screens/enter-new-password/EnterNewPasswordScreen.jsx';
import PlansScreen from './screens/plans/PlansScreen.jsx';
import UserHomeScreen from './screens/user-home/UserHomeScreen.jsx';
import ProfileScreen from './screens/profile/ProfileScreen.jsx';
import SideMenu from "./screens/side-menu/SideMenu.jsx";
import { useAppDispatch } from "../src/redux/store.ts";
import { openMenu, closeMenu, openAlert } from "../src/redux/reducers/app.ts";
import DriversScreen from './screens/drivers/DriversScreen.jsx';
import VehiclesScreen from './screens/vehicles/VehiclesScreen.jsx';
import VehicleDetailsScreen from './screens/vehicle-details/VehicleDetailsScreen.jsx';
import DriverDetailsScreen from './screens/driver-details/DriverDetailsScreen.jsx';
import TasksScreen from './screens/tasks/TasksScreen.jsx';
import TasksDetailsScreen from './screens/task-details/TasksDetailsScreen.jsx';
import IssuesScreen from './screens/issues/IssuesScreen.jsx';
import IssueDetailsScreen from './screens/issue-details/IssueDetailsScreen.jsx';
import ChecklistDetailsScreen from './screens/checklist-details/ChecklistDetailsScreen.jsx';
import PaymentConceptsList from './screens/payment-concept-list/PaymentConceptsList.jsx';
import PaymentConceptScreen from './screens/payment-concept/PaymentConceptScreen.jsx';
// import ChatsScreen from './screens/chats/ChatsScreen.jsx';
import ChatScreen from './screens/chats/ChatScreen';
import SuperAdminHomeScreen from './screens/super-admin/super-admin-home/SuperAdminHomeScreen.jsx';
import AdminDetailsScreen from './screens/super-admin/admin-details/AdminDetailsScreen.jsx';
import SignupScreen from './screens/signup/SignupScreen.jsx';
import BillingScreen from './screens/super-admin/billing/BillingScreen.jsx';
import Alert from './components/alert/Alert.js';
import ClientsScreen from './screens/super-admin/clients/ClientsScreen.jsx';
import ClientDetailsScreen from './screens/super-admin/client-details/ClientDetailsScreen.jsx';
import { NEW_NOTIFICATION_SUBSCRIPTION } from "./screens/user-home/user-home-queries.jsx";
import JourneysScreen from "./screens/journeys/JourneysScreen.jsx";
import ChecklistJourneyScreen from "./screens/checklist-details/ChecklistJourneyScreen.jsx";
import { RoutesScreen } from "./screens/routes/RoutesScreen.jsx";
// import { AsignRoutesScreen } from "./screens/asign-routes/AsignRoutesScreen.jsx"
import { AsignRoutesScreen } from "./screens/asign-routes/AsignRoutesScreen.jsx";
import { RouteDetailsScreen } from "./screens/route-details/RouteDetailsScreen.jsx"

function App() {
  const user = useSelector((state) => state).userSlice
  const isMenuOpen = useSelector((state) => state).appSlice.isMenuOpen
  const RoutesContainerComponent = user.email ? RouteContainer : RouteContainerAuth
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (window.matchMedia("(min-width: 1200px)").matches) {
      dispatch(openMenu())
    } else {
      dispatch(closeMenu())
    }
  }, [dispatch])

  useSubscription(NEW_NOTIFICATION_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      if (data.newNotification) {
        dispatch(openAlert({
          alertType: "error",
          isAlertOpen: true,
          alertMessage: data.newNotification.message
        }))
      }

    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  return (
    <BrowserRouter>
      <MainContainer>
        <Header />
        <RoutesContainer>
          {(user._id && isMenuOpen) && <SideMenu />}
          <RoutesContainerComponent>
            {user && user._id && user.rol !== "superadmin"
              ? 
                user.rol !== "client" ? 
                <Routes>
                  <Route path={ROOT_ROUTE} element={<UserHomeScreen />}  />
                  <Route path={PROFILE_ROUTE} element={<ProfileScreen />} />
                  <Route path={DRIVERS_ROUTE} element={<DriversScreen />} />
                  <Route path={VEHICLES_ROUTE} element={<VehiclesScreen />} />
                  <Route path={`${VEHICLE_DETAILS_ROUTE}/:vehiclePlate`} element={<VehicleDetailsScreen />} />
                  <Route path={VEHICLE_DETAILS_ROUTE} element={<VehicleDetailsScreen />} />
                  <Route path={`${DRIVER_DETAILS_ROUTE}/:driverEmail`} element={<DriverDetailsScreen />} />
                  <Route path={DRIVER_DETAILS_ROUTE} element={<DriverDetailsScreen />} />
                  <Route path={TASKS_ROUTE} element={<TasksScreen />} />
                  <Route path={ROUTES_CLIENT_ROUTE} element={<RoutesScreen />} />
                  <Route path={`${ROUTES_DETAILS_CLIENT}/:routeId`} element={<RouteDetailsScreen/>} />
                  <Route path={ASIGN_ROUTES} element={<AsignRoutesScreen/>} />
                  <Route path={`${ASIGN_ROUTES}/:routeId`} element={<AsignRoutesScreen/>} />
                  <Route path={CLIENTS_ROUTE} element={<ClientsScreen rol={user.rol} />} />
                  <Route path={`${CLIENT_DETAILS_ROUTE}/:clientId`} element={<ClientDetailsScreen rol={user.rol} />} />
                  <Route path={CLIENT_DETAILS_ROUTE} element={<ClientDetailsScreen rol={user.rol} />} />
                  <Route path={TASK_DETAILS_ROUTE} element={<TasksDetailsScreen />} />
                  <Route path={`${TASK_DETAILS_ROUTE}/:taskId`} element={<TasksDetailsScreen />} />
                  <Route path={`${TASK_DETAILS_ROUTE}/:taskId/route`} element={<TasksDetailsScreen />} />
                  <Route path={ISSUES_ROUTE} element={<IssuesScreen />} />
                  <Route path={`${ISSUE_DETAILS}/:issueId`} element={<IssueDetailsScreen />} />
                  <Route path={ISSUE_DETAILS} element={<IssueDetailsScreen />} />
                  <Route path={`${CHECKLIST_DETAILS}/:issueId`} element={<ChecklistDetailsScreen />} />
                  <Route path={`${CHECKLIST_JOURNEY_ROUTE}/:journeyId`} element={<ChecklistJourneyScreen />} />
                  <Route path={ACCOUTING_ROUTE} element={<PaymentConceptsList />} />
                  <Route path={PAYMENT_CONCEPT} element={<PaymentConceptScreen />} />
                  <Route path={`${PAYMENT_CONCEPT}/:paymentConceptId`} element={<PaymentConceptScreen />} />
                  <Route path={`${TASK_DETAILS_ROUTE}/route`} element={<TasksDetailsScreen />} />x
                  <Route path={CHAT_ROUTE} element={<ChatScreen rol={ user.rol } />} />
                  <Route path={JOURNEYS_ROUTE} element={<JourneysScreen />} />
                  <Route path={`${CHAT_ROUTE}/:driverId`} element={<ChatScreen rol={ user.rol } />} />
                  <Route path="*" element={<UserHomeScreen />} />
                </Routes>
                :
                <Routes>
                  <Route path={ROOT_ROUTE} element={<UserHomeScreen />} />
                  <Route path={PROFILE_ROUTE} element={<ProfileScreen />} />
                  <Route path={TASKS_ROUTE} element={<TasksScreen />} />
                  <Route path={TASK_DETAILS_ROUTE} element={<TasksDetailsScreen />} />
                  <Route path={`${TASK_DETAILS_ROUTE}/:taskId/route`} element={<TasksDetailsScreen />} />
                  <Route path={`${TASK_DETAILS_ROUTE}/:taskId`} element={<TasksDetailsScreen />} />
                  <Route path={CHAT_ROUTE} element={<ChatScreen  />} />
                  <Route path={`${CHAT_ROUTE}/:driverId`} element={<ChatScreen />} />
                  <Route path="*" element={<UserHomeScreen />} />
                </Routes>
              : user.rol === "superadmin"
                ? <Routes>
                    <Route path={ROOT_ROUTE} element={<SuperAdminHomeScreen />} />
                    <Route path={ADMIN_DETAILS_ROUTE} element={<AdminDetailsScreen />} />
                    <Route path={BILLING_ROUTE} element={<BillingScreen />} />
                    <Route path={CLIENTS_ROUTE} element={<ClientsScreen />}  />
                    <Route path={CLIENT_DETAILS_ROUTE} element={<ClientDetailsScreen rol={ user.rol } />} />
                    <Route path={`${CLIENT_DETAILS_ROUTE}/:clientEmail`} element={<ClientDetailsScreen rol={ user.rol } />} />
                  <Route path="*" element={<SuperAdminHomeScreen />} />
                </Routes>
                : <Routes>
                  <Route path={ROOT_ROUTE} element={<LoginScreen />} />
                  <Route path={LOGIN_ROUTE} element={<LoginScreen />} />
                  <Route path={SIGNUP_ROUTE} element={<SignupScreen />} />
                  <Route path={RESTORE_PASSWORD_ROUTE} element={<RestorePasswordScreen />} />
                  <Route path={ENTER_CODE_ROUTE} element={<EnterCodeScreen />} />
                  <Route path={ENTER_NEW_PASSWORD_ROUTE} element={<EnterNewPasswordScreen />} />
                  <Route path={PLANS_ROUTE} element={<PlansScreen />} />
                  <Route path="*" element={<LoginScreen />} />
                </Routes>}
          </RoutesContainerComponent>
        </RoutesContainer>
        <Alert />
      </MainContainer>
    </BrowserRouter>
  );
}

export default App;
