import { removeRouteAction } from "../../screens/routes/routes-actions"
import i18n from "../../i18n/i18n";

export const removeRoutes = async (id, updateTable, dispatch, openAlert) => {
    dispatch(openAlert({
        alertType: "question",
        alertMessage: 'Estas seguro de que quieres eliminar esta ruta',
        isAlertOpen: true,
        alertOnYesButtonTitle: i18n.t("GENERAL.remove"),
        alertOnYes: async () => {
          try {
            await removeRouteAction(id)
            updateTable(true)
            dispatch(openAlert({
              alertType: "success",
              alertMessage: i18n.t("ROUTES.removedSuccessMessage"),
              isAlertOpen: true
            })) 
          } catch (e) {
            dispatch(openAlert({
              alertType: "error",
              isAlertOpen: true
            }))
          }
        }
      }))
}