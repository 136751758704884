import React, { useState, useEffect } from 'react'
import TruckTable from "../../components/truck-table/TruckTable";
import searchIcon from "../../assets/profile/search-icon.svg";
import { 
    RoutesContainer,
    RoutesContentContainer,
    RoutesFormContainer,
    RoutesBillingContainer,
    TableInfoContainer,
    TableTitle,
    TableContainerButton,
    DividerSections,
    TableContainer
} from "./routes-styles";
import i18n from '../../i18n/i18n';
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import TextInput from "../../components/inputs/text-input/TextInput";
import { TruckMap } from '../../components/buttons/truck-map/TruckMap';
import { TruckCalendar } from '../../components/truck-calendar/TruckCalendar';
import { ASIGN_ROUTES } from '../../routes/routes';
import { useNavigate } from 'react-router-dom';
import { FormModal } from "../../components/modal/form-modal/FormModal";
import { createRouteAction, getMyRoutesAction, updateRoute } from './routes-actions';
import { getTasksAction } from '../task-details/tasks-actions';
import { getDrivers } from "../driver-details/driver-details-actions";
import { getVehiclesAction } from "../vehicles/vehicles-actions";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from '../../redux/store.ts';
import { COLORS_TRUCK } from '../../utils/colors';
import moment from 'moment';

export const RoutesScreen = () => {
    const dispatch = useAppDispatch()
    const [fields, setFields] = useState([
        {id: 1, title: i18n.t('ROUTES.forms.routes.name') , name: "name", value: "", type: "text", required: true},
        {id: 2, title: i18n.t('ROUTES.forms.routes.color'), name: "color", value: "", type: "color", required: true},
        {id: 3, title: i18n.t('ROUTES.forms.routes.driver'),name: "driver", value: "", type: "select", option: [], required: true},
        {id: 4, title: i18n.t('ROUTES.forms.routes.vehicles'),name: "vehicle", value: "", type: "select", option: [], required: true},
        {id: 5, title: i18n.t('ROUTES.forms.routes.trailer'),name: "trailer", value: "", type: "select", option: [], required: false},
    ])
    const navigate = useNavigate()
    const [keepIncreasing, setKeepIncreasing] = useState(true)
    const [modalInfo, setModalInfo] = useState(<></>)
    const [limit, setLimit] = useState(30)
    const [idRoute, setIdRoute] = useState('')
    const [increaseLimit, setIncreaseLimit] = useState(30)
    const [offset, setOffset] = useState(0)
    const [addRoutes, setAddRoutes] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingForm, setLoadingForm] = useState(false)
    const [search, setSearch] = useState('')
    const [routes, setRoutes] = useState([]) 
    const [task, setTask] = useState([])

    useEffect(() => {
        getRoutesFunc()
        getDriverFunc()
        getVehiclesFunc()
        getTaskFunc()
    }, [])
    
    const getTaskFunc = async () => {
        try {
            const dataFilter = {
                status: 'PENDING',
                // route: null
            }
            const { data } = await getTasksAction(dataFilter) 
            
            assignTask(data.myTasks)
        } catch (error) {
            
        }
    }

    const assignTask = (data) => {
        let taskArray = []
        for(let index in data) {
            let taskObj = {
                backgroundColor:  data[index]?.route ? data[index].route?.color?.includes('#') ? data[index].route.color : `#${data[index].route.color}` : '#4a4a4a', 
                borderColor: data[index]?.route ? data[index].route?.color?.includes('#') ? data[index].route.color : `#${data[index].route.color}` : '#4a4a4a' 
            }

            let date = new Date(data[index].start_date)

            taskObj.title = `${data[index].unload?.delivery_object || 'Sin objeto' } - ${data[index].unload?.address?.direction}`
            taskObj.id = data[index]._id
            taskObj.start = moment(new Date(date)).format('YYYY-MM-DD')
            taskObj.task = data[index]
            taskObj.order = data[index].unload?.order
         
            taskArray.push(taskObj)
        }

        setTask(taskArray)
    }

    const getData = (input, id) => {
        let data = {}

        for(let index in input) {
            data[input[index].name] = input[index].value.length > 0 ? input[index].value : null
        }

        if(!data.color || data.color == '') data.color = '#F84812'
        
        !id ? createRoute(data) : editRoute(data, id)
    }

    const editRoute = async (data, id) => {
        setLoadingForm(true)
        try {
            await updateRoute(id, data)
            getRoutesFunc(true)
            setLoadingForm(false)
            return closeModal()
        } catch (error) {
            setLoadingForm(false)
            dispatch(openAlert({
                alertType: "error",
                isAlertOpen: true
            }))
        }
    }

    const createRoute = async (data) => {
        setLoadingForm(true)
        try {
            await createRouteAction(data)
            getRoutesFunc(true)
            setLoadingForm(false)
            return closeModal()
        } catch (error) {
            setLoadingForm(false)
            dispatch(openAlert({
                alertType: "error",
                isAlertOpen: true
            }))
        }
    }
    
    const getVehiclesFunc = async () => {
        try {
            const vehiclesData = {
                pagination: {
                  limit: 30,
                  offset: 0
                },
                plate: null,
                vehicle_type: null
            }
            const { data } = await getVehiclesAction(vehiclesData)

            for(let index in fields) {
                if(fields[index].id === 4) {
                    fields[index].option = data.myVehicles.filter(item => item.vehicle_type.toUpperCase() !== "TRAILER").map((vehicle) => ({ label: vehicle.plate, value: vehicle._id }))
                    fields[index].option.unshift({ label: 'Seleccionar', value: 0 })
                }
                if(fields[index].id === 5) {
                    fields[index].option = data.myVehicles.filter(item => item.vehicle_type.toUpperCase() === "TRAILER").map((vehicle) => ({ label: vehicle.plate, value: vehicle._id }))
                    fields[index].option.unshift({ label: 'Seleccionar', value: 0 })
                }
            }
        
            setFields(fields)
        } catch (error) {
            for(let index in fields) {
                if(fields[index].id === 4) fields[index].option = []
                if(fields[index].id === 5) fields[index].option = []
            }

            setFields(fields)
        }
    }   

    const getDriverFunc = async () => {
        try {
            const driversData = {
                pagination: {
                limit: 30,
                offset: 0
                },
                email: null,
                status: null,
                type: null,
                payment_type: null,
                driver_id: null
            }
            const { data } = await getDrivers(driversData)
            for(let index in fields) {
                if(fields[index].id === 3) {
                    fields[index].option = data.myDrivers.map((driver) => ({ label: driver.name, value: driver._id }))
                    fields[index].option.unshift({ label: 'Seleccionar', value: null })
                }
            }

            setFields(fields)
        } catch (error) {
            for(let index in fields) {
                if(fields[index].id === 3) fields[index].option = []
            }

            setFields(fields)
        }
    }

    const getRoutesFunc = async (reset = false) => {
        try {
            setLoading(true)
            const { data } = await getMyRoutesAction()
            
            if (data?.findMyRoutes.length <= 0)  setKeepIncreasing(false)
            else setKeepIncreasing(true)
    
            let routesArray = [...routes].concat(data?.findMyRoutes)

            if (!search && !reset  || limit > 30) {
                setRoutes(routesArray.filter((item, index, self) => index === self.findIndex((t) => t._id === item._id)))
            } else {
                setRoutes(data?.findMyRoutes)
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorCode = localStorage.getItem("error-code")
            if (errorCode === "998") setRoutes([])
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            getRoutesFunc()
        }
    }
    
    const asignDataEdit = (data) => {
        for(let index in data) {
            for(let i in fields) {
                if(index == fields[i].name) {
                    if(fields[i].name === 'vehicle' || fields[i].name === 'driver' || fields[i].name === 'trailer' ) fields[i].value = data[index]._id
                    else fields[i].value = data[index]

                    setFields(fields)
                }
            }
        }
    }

    const cleanDataEdit = () => {
        for(let i in fields) {
            fields[i].value = ""
            setFields(fields)
        }
    }

    const getDataEdit = async (id) => {
        for(let index in routes) {
            if(routes[index]._id == id) asignDataEdit(routes[index])
        }

        setIdRoute(id)
    }

    const showModal = (edit) => {
        let data = []
        if(edit == 'Create') {
            for(let index in fields) {
                if(fields[index].name == 'color') {
                    data.push({ ...fields[index], 'value': '#F84812' })
                    continue
                }

                data.push(fields[index])
            }
        }

        setModalInfo(edit !== "Create" ? 
            <FormModal 
                title={ i18n.t('ROUTES.forms.routes.title') } 
                info={ i18n.t('ROUTES.forms.routes.subtitle') } 
                fields={fields}
                returnInfo={getData}
                titleButton={ i18n.t('SUPER_ADMIN_ADMINISTRATION.save') }
                closeModal={closeModal}
                edit={edit}
                loading={loadingForm}
            />
            :
            <FormModal 
                title={ i18n.t('ROUTES.forms.routes.createRouteTitle') }
                info={ i18n.t('ROUTES.forms.routes.createRouteSubtitle') } 
                fields={data}
                returnInfo={getData}
                titleButton={i18n.t('ROUTES.forms.buttons.add')}
                closeModal={closeModal}
                loading={loadingForm}
            />
        ) 

        setAddRoutes(!addRoutes)
        if(edit !== "Create") return getDataEdit(edit)

        return cleanDataEdit()
    }

    const closeModal = () => {
        setIdRoute('')
        setModalInfo(<></>)
    }

    const tableHeader = <RoutesBillingContainer>
        <TableInfoContainer>
            <TableTitle>
                {i18n.t('ROUTES.title')}
            </TableTitle>
        </TableInfoContainer>
        <TextInput
            icon={searchIcon}
            value={search}
            containerStyle={{ width: 250 }}
            type="text"
            placeholder={i18n.t('ROUTES.forms.routes.name')}
            onChange={async (e) => {
                if (e.target.value === "") getRoutesFunc(true)
                setSearch(e.target.value)
            }}
            onKeyPress={handleKeyPress}
        />
        <TableContainerButton>
            <TruckMap 
                style={{width: 80}}
                routes={routes}
            />
            <CategoryButton
                onClick={() => navigate(ASIGN_ROUTES)}
                active={false}
                icon="" 
                style={{width: 150}}
                width="200"
                title={i18n.t('ROUTES.forms.buttons.assign')}
            />
            <CategoryButton
                onClick={ () => showModal("Create") }
                active
                icon="" 
                style={{width: 150}}
                width="200"
                title={i18n.t('ROUTES.forms.buttons.add')}
            />
        </TableContainerButton>
    </RoutesBillingContainer>

  return (
    <>
        <RoutesContainer>
            <RoutesContentContainer>
                <RoutesFormContainer>
                    <TruckTable
                        loading={loading}
                        type="routes"
                        header={tableHeader}
                        showEdit={ showModal }
                        onEndReach={() => {
                        if (keepIncreasing) {
                            setOffset(() => {
                                return offset + increaseLimit
                            })
                            setLimit(() => {
                                return limit + increaseLimit
                            })
                        }

                        setTimeout(() => getRoutesFunc(), 500)
                        }}
                        updateTable={(reset) => getRoutesFunc(reset)}
                        titles={[
                            { label: i18n.t('ROUTES.forms.routes.color') },
                            { label: i18n.t('ROUTES.forms.routes.name') },
                            { label: i18n.t('ROUTES.forms.routes.driver') },
                            { label: i18n.t('ROUTES.forms.routes.vehicles') },
                            { label: i18n.t('ROUTES.forms.routes.trailer') },
                            { label: i18n.t('ROUTES.forms.routes.actions') },
                        ]}
                        data={routes}
                        style={{
                            maxHeight: 300,
                            overflow: "auto"
                        }}
                    />
                    <DividerSections />
                    <TableContainer>
                        <TableTitle>
                            {i18n.t('TASKS.forms.tasks.title')}
                        </TableTitle>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <TruckCalendar 
                                routes={routes} 
                                style={{width: '98%'}} 
                                task={ task } 
                                setTask={ setTask }
                                reloadList={getTaskFunc}
                             />
                        </div>
                    </TableContainer>
                </RoutesFormContainer>
            </RoutesContentContainer>
        </RoutesContainer>
        {modalInfo}
    </>
  )
}