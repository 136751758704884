import styled from 'styled-components';
import { COLORS_TRUCK } from '../../../../utils/colors';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

export const FormTextInputMainContainer = styled.div`
position: relative;
`;

export const TextInputContainer = styled.div`
width: 300px;
// max-width: 300px;
height: 60px;
box-sizing: border-box;
border-radius: 5px;
display: flex;
margin: 10px;
margin-left: 0px;
justify-content: flex-start;
align-items: center;

@media (max-width: 320px) {
width: 250px;
  }
`;

export const InputIcon = styled.img`
width: 20px;
height: 20px;
margin: 20px;
`;

export const CopyIcon = styled.img`
width: 25px;
height: 25px;
position: absolute;
right: 20px;
cursor: pointer;
`;

export const CustomTextInputMaps = styled(GooglePlacesAutocomplete)`
width: 300px !important;
`;

export const CustomTextInput = styled.input`
background-color: transparent;
width: 90%;
height: 90%;
border: none;
font-size: 15px;
color: ${COLORS_TRUCK.BLACK_TRUCK};
margin: 0;
padding: 0;
padding-left: 15px;
`;

export const InputTitle = styled.p`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
font-size: 13px;
`;

export const ErrorMessage = styled.p`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
font-size: 13px;
`;