import React, { useEffect, useState } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileButtonsContainer,
  ProfileFormContainer,
  ProfileFormInputsContainer,
  ProfileFormsContainer,
  ProfileLastButtonsContainer,
  FixedAmountText,
  ProfileSmallButtonGroup,
  ProfileSmallButtonsGroup,
  TableTh,
  TableTd,
  ProfileTable,
  ProfileFormSeparator,
  ProfileBillingContainer,
  // ProfileTableContainer,
  TableTitle,
  TableInfoContainer,
  PlanTitle,
  NewPlanButtonsContainer,
  ChangePlanWarning,
  ArrowIcon,
  SubTitle,
  GeneralErrorContainer,
  GeneralErrorText
} from "./profile-styles";
import { COLORS_TRUCK } from '../../utils/colors';
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import i18n from '../../i18n/i18n';
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import UserCard from "../../components/user-card/UserCard";
import FormTextInput from "../../components/inputs/forms/form-text-input/FormTextInput";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import FormRadioButtonsInput from "../../components/inputs/forms/form-radio-buttons-input/FormRadioButtonsInput";
import passwordIcon from "../../assets/inputs/lock.svg";
import offEye from "../../assets/inputs/eye-off.svg";
import redEye from "../../assets/general/eye-icon-red.svg";
import filterByIcon from "../../assets/profile/filter-by-icon.svg";
import searchIcon from "../../assets/profile/search-icon.svg";
import redArrow from "../../assets/profile/change-plan-red-arrow.svg";
import TruckTable from "../../components/truck-table/TruckTable";
import FormNumberInput from "../../components/inputs/forms/form-number-input/FormNumberInput";
import avatarDefault from "../../assets/drivers/user.png";
import { useSelector } from "react-redux";
import { confirmPaymentIntentAction, createPaymentIntentAction, getCitiesAction, getCountriesAction, getInvoices, getPlans, getStatesAction } from "../signup/signup-actions";
import { useAppDispatch } from "../../redux/store.ts";
import { openAlert } from "../../redux/reducers/app.ts";
import { getUserProfile, getCoinAction, updateUserPicAction, updateProfileAction, updateUserPlanAction, cancelSubscriptionAction, exportInvoices, updateUserPaymentMethodAction, downloadInvoice, getClientProfile, updateClientPicAction, getDriverProfile, updateDriverAction } from "./profile-actions";
import { setUser } from "../../redux/reducers/user.ts";
import BackButton from "../../components/back-button/BackButton"; 
import DriversScreen from "../drivers/DriversScreen";
import { emailsRegex } from "../../utils/regex";
import config from "../../config";
import { hoursArrayDinner, hoursArrayLunch, hoursArrayNocturnal } from "./hoursOptions";
import { updateClientAction } from "../super-admin/clients/clients-actions.jsx";

const ProfileScreen = () => {
  const dispatch = useAppDispatch()
  const user = useSelector((state) => state).userSlice
  const [currentForm, setCurrentForm] = useState("company")
  const [userId, setUserId] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [countries, setCountries] = useState([])
  const [coinOption, setCoinOption] = useState({})
  const [coins, setCoins] = useState([])
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const [countryIso2, setCountryIso2] = useState("")
  const [generalError, setGeneralError] = useState("")
  const [plans, setPlans] = useState([])
  const [invoices, setInvoices] = useState([])
  const [passwordError, setPasswordError] = useState("")
  const [usersNumberError, setUsersNumberError] = useState("")
  const [showInputsErrors, setShowInputsErrors] = useState(false)
  const [confirmPlanScreen, setConfirmPlanScreen] = useState(false)
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(10)
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState("")
  const [paymentTypeFilter, setPaymentTypeFilter] = useState("")
  const [contactEmailErrorMessage, setContactEmailErrorMessage] = useState("")
  const [isUpdatePaymentMethodOpen, setIsUpdatePaymentMethodOpen] = useState(false)
  const [creditCardError, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [avatar, setAvatar] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  //company
  const [socialReason, setSocialReason] = useState("")
  const [country, setCountry] = useState("")
  const [coin, setCoin] = useState("")
  const [province, setProvince] = useState("")
  const [city, setCity] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [nif, setCompanyNif] = useState("")
  const [address, setAddress] = useState("")
  const [companyType, setCompanyType] = useState("")
  const [phone, setPhone] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  //access
  const [representativeName, setRepresentativeName] = useState("")
  const [representativeLastName, setRepresentativeLastName] = useState("")
  const [dni, setDni] = useState("")
  const [position, setPosition] = useState("")
  const [email, setEmail] = useState("")
  const [confirmEmail, setConfirmEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordShow, setPasswordShow] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [secondPasswordShow, setSecondPasswordShow] = useState("")
  //variables
  const [fixedRemuneration, setFixedRemuneration] = useState("")
  const [nationalComplete, setNationalComplete] = useState("")
  const [internationalComplete, setInternationalComplete] = useState("")
  const [payPerKm, setPayPerKm] = useState("")
  const [lunch, setLunch] = useState("")
  const [lunch2, setLunch2] = useState("")
  const [dinner, setDinner] = useState("")
  const [dinner2, setDinner2] = useState("")
  const [nocturnal, setNocturnal] = useState("")
  const [nocturnal2, setNocturnal2] = useState("")
  const [lunchNational, setLunchNational] = useState("")
  const [lunchInternational, setLunchInternational] = useState("")
  const [dinnerNational, setDinnerNational] = useState("")
  const [dinnerInternational, setDinnerInternational] = useState("")
  const [nocturnalNational, setNocturnalNational] = useState("")
  const [nocturnalInternational, setNocturnalInternational] = useState("")
  const [sunday, setSunday] = useState("")
  const [taskDuration, setTaskDuration] = useState("")
  //plans
  const [plan, setPlan] = useState("")
  const [paymentType, setPaymentType] = useState("")
  const [userNumber, setUserNumber] = useState("")
  //billing
  const [billingFilterBy, setBillingFilterBy] = useState("1")
  const [addressGet1, setAddressGet1] = useState("")
  const [addressGet2, setAddressGet2] = useState("")  
  const [addressGet3, setAddressGet3] = useState("")
  const [phoneGet1, setPhoneGet1] = useState("")
  const [phoneGet2, setPhoneGet2] = useState("")
  const [phoneGet3, setPhoneGet3] = useState("")
  const [companiesIds, setCompaniesIds] = useState([])

  useEffect(async () => {
    setUserFunc()
    const countries = await getCountries()
    const iso2 = countries.filter(item => item.value === user?.country)[0]?.iso2
    await getStates(iso2)
    await getCities(iso2, user?.state)
    if(user.rol !== 'client' && user.rol !== 'driver') {
      getPlansFunc()
      getInvoicesFunc(true)
      getCoins()
    }

    const params = new URLSearchParams(window.location.search) // id=123
    let section = params.get('section') // 123 
    if (section && section === "plans") {
      setCurrentForm("plans")
    }

  }, [user, status, paymentTypeFilter, search])

  const assignClient = (res) => {
    const fields = [ 
      { set: (item) => setUserId(item), name: '_id' },
      { set: (item) => setDni(item), name: 'dni' },
      { set: (item) => setRepresentativeName(item), name: 'firts_name' },
      { set: (item) => setRepresentativeLastName(item), name: 'last_name' },
      { set: (item) => setEmail(item), name: 'email' },
      { set: (item) => setPosition(item), name: 'position' },
      { set: (item) => setSocialReason(item), name: 'business_name' },
      { set: (item) => setCompanyNif(item), name: 'nif' },
      { set: (item) => setAvatar(item), name: 'avatar' },
      { set: (item) => setAddress(item), name: 'address_tax' },
      { set: (item) => setPhone(item), name: 'phone' },
      { set: (item) => setCountry(item), name: 'country' },
      { set: (item) => setCity(item), name: 'city' },
      { set: (item) => setProvince(item), name: 'state' },
      { set: (item) => setCompaniesIds(item), name: 'companies_ids' },
      { set: (item) => setPostalCode(item), name: 'zip' },
      { set: (item) => setAddressGet1(item), name: 'addressGet1', field: 'address' },
      { set: (item) => setAddressGet2(item), name: 'addressGet2', field: 'address' },
      { set: (item) => setAddressGet3(item), name: 'addressGet3', field: 'address' },
      { set: (item) => setPhoneGet1(item), name: 'phoneGet1', field: 'phone' },
      { set: (item) => setPhoneGet2(item), name: 'phoneGet2', field: 'phone' },
      { set: (item) => setPhoneGet3(item), name: 'phoneGet3', field: 'phone' },
    ]
    
    for(let index in fields) {
      if(fields[index].name == 'business_name' || fields[index].name == 'nif' || fields[index].name == 'address_tax' || fields[index].name == 'phone' || fields[index].name == 'country' || fields[index].name == 'city' ||  fields[index].name == 'state' || fields[index].name == 'zip') {
          fields[index].set(res.business_client[fields[index].name])

          continue
      }

      if(fields[index].name == 'addressGet1' || fields[index].name == 'phoneGet1') {
        let address = res.address_client[0][fields[index].field] 
        fields[index].set(address)

        continue
      }

      if(fields[index].name == 'addressGet2' || fields[index].name == 'phoneGet2') {
        let address = res.address_client[1][fields[index].field] 
        fields[index].set(address)

        continue
      }

      if(fields[index].name == 'addressGet3' || fields[index].name == 'phoneGet3') {
        let address = res.address_client[2][fields[index].field] 
        fields[index].set(address)

        continue
      }

      fields[index].set(res[fields[index].name])
    }

  }

  const assignDriver = (res) => {
    const fields = [ 
      { set: (item) => setUserId(item), name: '_id' },
      { set: (item) => setDni(item), name: 'dni' },
      { set: (item) => setRepresentativeName(item), name: 'name' },
      { set: (item) => setRepresentativeLastName(item), name: 'surnames' },
      { set: (item) => setEmail(item), name: 'email' },
      { set: (item) => setPosition(item), name: 'position' },
      { set: (item) => setSocialReason(item), name: 'company' },
      { set: (item) => setAvatar(item), name: 'avatar' },
      { set: (item) => setAddress(item), name: 'address' },
      { set: (item) => setPhone(item), name: 'phone' },
      { set: (item) => setCountry(item), name: 'country' },
      { set: (item) => setCity(item), name: 'city' },
      { set: (item) => setProvince(item), name: 'state' },
      { set: (item) => setPostalCode(item), name: 'zip' },
      { set: (item) => setContactEmail(item), name: 'personal_email' },
      { set: (item) => {
        setFixedRemuneration(item.fixed_remuneration === 0 ? "" : item.fixed_remuneration)
        setLunchNational(item.diets?.lunch === 0 ? "" : item.diets?.lunch)
        setLunchInternational(item.diets?.lunch_international === 0 ? "" : item.diets?.lunch_international)
        setDinnerNational(item.diets?.dinner === 0 ? "" : item.diets?.dinner)
        setDinnerInternational(item.diets?.dinner_international === 0 ? "" : item.diets?.dinner_international)
        setSunday(item.diets?.sunday === 0 ? "" : item.diets?.sunday)
        setTaskDuration(item.task_duration === 0 ? "" : item.task_duration)
        setNocturnalNational(item.diets?.nocturnal === 0 ? "" : item.diets?.nocturnal)
        setNocturnalInternational(item.diets?.nocturnal_international === 0 ? "" : item.diets?.nocturnal_international)
        setNationalComplete(item.complete_national === 0 ? "" : item.complete_national)
        setInternationalComplete(item.complete_international === 0 ? "" : item.complete_international)
        setPayPerKm(item.payment_per_km === 0 ? "" : item.payment_per_km)
 
        setLunch(item.diets?.lunch_timetable?.split(":")[0])
        setLunch2(item.diets?.lunch_timetable?.split(":")[1])
        setDinner(item.diets?.dinner_timetable?.split(":")[0])
        setDinner2(item.diets?.dinner_timetable?.split(":")[1])
        setNocturnal(item.diets?.nocturnal_timetable?.split(":")[0])
        setNocturnal2(item.diets?.nocturnal_timetable?.split(":")[1])
      }, name: 'payment_variables_id'}
    ]

    
    for(let index in fields) {
      fields[index].set(res[fields[index].name])
    }

  }

  const setUserFunc = async () => {
    if(user.rol == 'client') {
      const res = await getClientProfile() 

      return assignClient(res.data.getMyProfile)
    }

    if(user.rol == 'driver') {
      const res = await getDriverProfile()

      return assignDriver(res.data.driverProfile)
    }

    await getUserData()
    setLoading(true)
    setSocialReason(user.company)
    setCountry(user.country)
    setCoin(user.coin)
    setProvince(user.state)
    setCity(user.city)
    setPostalCode(user.zip)
    setCompanyNif(user.nif)
    setAddress(user.address)
    setCompanyType(user?.company_type)
    setPhone(user.phone)
    setContactEmail(user.email_contact)
    setRepresentativeName(user.name)
    setRepresentativeLastName(user.surnames)
    setDni(user.dni)
    setPosition(user.position)
    setEmail(user.email)
    setConfirmEmail(user.email)
    setPassword("")
    setPasswordShow("")
    setConfirmPassword("")
    setSecondPasswordShow("")

    setFixedRemuneration(user.payment_variables.fixed_remuneration === 0 ? "" : user.payment_variables.fixed_remuneration)
    setLunchNational(user.payment_variables?.diets?.lunch === 0 ? "" : user.payment_variables?.diets?.lunch)
    setLunchInternational(user.payment_variables?.diets?.lunch_international === 0 ? "" : user.payment_variables?.diets?.lunch_international)
    setDinnerNational(user.payment_variables?.diets?.dinner === 0 ? "" : user.payment_variables?.diets?.dinner)
    setDinnerInternational(user.payment_variables?.diets?.dinner_international === 0 ? "" : user.payment_variables?.diets?.dinner_international)
    setSunday(user.payment_variables?.diets?.sunday === 0 ? "" : user.payment_variables?.diets?.sunday)
    setTaskDuration(user.payment_variables.task_duration === 0 ? "" : user.payment_variables.task_duration)
    setNocturnalNational(user.payment_variables?.diets?.nocturnal === 0 ? "" : user.payment_variables?.diets?.nocturnal)
    setNocturnalInternational(user.payment_variables?.diets?.nocturnal_international === 0 ? "" : user.payment_variables?.diets?.nocturnal_international)
    setNationalComplete(user.payment_variables.complete_national === 0 ? "" : user.payment_variables.complete_national)
    setInternationalComplete(user.payment_variables.complete_international === 0 ? "" : user.payment_variables.complete_international)
    setPayPerKm(user.payment_variables.payment_per_km === 0 ? "" : user.payment_variables.payment_per_km)

    setLunch(user.payment_variables?.diets?.lunch_timetable?.split(":")[0])
    setLunch2(user.payment_variables?.diets?.lunch_timetable?.split(":")[1])
    setDinner(user.payment_variables?.diets?.dinner_timetable?.split(":")[0])
    setDinner2(user.payment_variables?.diets?.dinner_timetable?.split(":")[1])
    setNocturnal(user.payment_variables?.diets?.nocturnal_timetable?.split(":")[0])
    setNocturnal2(user.payment_variables?.diets?.nocturnal_timetable?.split(":")[1])
    setPlan(user?.plan_details?.plan_id)
    setPaymentType(user?.plan_details?.payment_type.toUpperCase())
    setUserNumber(user?.plan_details?.max_users)

    setLoading(false)
  }

  const onEditPicture = async (img) => {
    try {
      user.rol !== 'client' ? await updateUserPicAction(img) : await updateClientPicAction(img)
      setUserFunc()
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getUserData = async () => {
    try {
      const res = await getUserProfile()
      const user = res.data.profile
      dispatch(setUser({
        ...user
      }))
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getCoins = async () => {
    try {
      const response = await getCoinAction()
      const cointArray = response.data.findAllCoins.map( coin => { return { label: `${coin.name} ${coin.symbol}`, value: coin._id } })
      const cointSelected = response.data.findAllCoins.filter( coin => coin._id == user.coin )
      if(cointSelected.length > 0) setCoinOption(cointSelected[0])
      setCoins(cointArray)
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const getCountries = async () => {
    try {
      const response = await getCountriesAction()
      if (response && response.data) {
        const countriesArray = response.data.countries.map(country => { return { label: country.translate[0].langs[localStorage.getItem("language") || "es"], value: country._id, iso2: country.iso2 } })
        setCountries(countriesArray)
        return countriesArray
      }
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const exportInvoicesFunc = async () => {
    try {
      const data = {
        company: null,
        pagination: {
          limit: limit,
          offset: offset
        },
        final_amount: search ? Number(Number(search).toFixed(2)) : null,
        payment_type: paymentTypeFilter || null,
        status: status || null,
      }
      const res = await exportInvoices(data)
      var win = window.open(res.data.exportMyInvoices.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const downloadInvoiceFunc = async (invoice_id) => {
    try {
      setLoadingDownload(true)
      const res = await downloadInvoice(invoice_id)
      var win = window.open(res.data.downloadInvoice.fullpath, '_blank');
      win.focus();
      setLoadingDownload(false)
    } catch (e) {
      setLoadingDownload(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getInvoicesFunc = async (reset) => {
    try {
      if (reset) setInvoices([])
      const data = {
        company: null,
        pagination: {
          limit: limit,
          offset: offset
        },
        final_amount: search ? Number(Number(search).toFixed(2)) : null,
        payment_type: paymentTypeFilter || null,
        status: status || null,
      }
      const response = await getInvoices(data)
      if (response?.data?.myInvoices.length <= 0) setKeepIncreasing(false) 
      else setKeepIncreasing(true)

      let invoicesArray = invoices.concat(response?.data?.myInvoices)
      if (!search && !status && !paymentTypeFilter) setInvoices(invoicesArray.filter((item, index, self) => index === self.findIndex((t) => t._id === item._id)))
      else setInvoices(response?.data?.myInvoices)
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const saveProfile = async () => {
    const passwordValidation = password && password.length < 8
    const passwordMatchValidation = password && password !== confirmPassword
    const contactEmailValidation = contactEmail && !contactEmail.match(emailsRegex)

    if (!socialReason || (!nif && user.rol !== 'driver') || (!companyType && user.rol == 'superadmin') || !country || (!coin && user.rol == 'superadmin')) {
      console.log('user.rol', !nif && user.rol !== 'driver')
      console.log('(user.rol !== client || user.rol !== driver)', (user.rol !== 'client' || user.rol !== 'driver'))
      setCurrentForm("company")
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      return
    }

    if (!representativeName || !representativeLastName || !dni || !email) {
      setCurrentForm("access")
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      return
    }

    if (passwordValidation) {
      setShowInputsErrors(true)
      setCurrentForm("access")
      setPasswordError(i18n.t("GENERAL.passwordLength"))
      return
    }

    if (passwordMatchValidation) {
      setShowInputsErrors(true)
      setCurrentForm("access")
      setPasswordError(i18n.t("GENERAL.passwordsNotMatch"))
      return
    }

    if (contactEmailValidation && user.rol !== 'client') {
      setShowInputsErrors(true)
      setCurrentForm("company")
      setContactEmailErrorMessage(i18n.t("PROFILE.emailValidation"))
      return
    }

    if (!passwordValidation && !passwordMatchValidation && (!contactEmailValidation && user.rol !== 'client')) {
      setShowInputsErrors(false)
      setContactEmailErrorMessage("")
      setPasswordError("")
    }

    if (socialReason && nif && companyType && country && representativeName && representativeLastName && dni && email) {
      setGeneralError("")
      setShowInputsErrors(false)
    }

    try {
      setLoading(true)
      const fields = [
        {name: 'address', value: address || null},
        {name: 'city', value: city || null},
        {name: 'company', value: socialReason || null},
        {name: 'company_type', value: companyType?.toUpperCase() || null},
        {name: 'country', value: country || null},
        {name: 'email_contact', value: contactEmail || null},
        {name: 'personal_email', value: contactEmail || null},
        {name: 'email', value: email || null, type: 3},
        {name: 'language', value: String(localStorage.getItem("language") || "es").toUpperCase()},
        {name: 'name', value: representativeName || null},
        {name: 'nif', value: nif || null},
        {name: 'coin', value: coin || null},
        {name: 'zip', value: postalCode || null},
        {name: 'payment_variables', value: {
            complete_national: nationalComplete ? Number(nationalComplete) : 0,
            complete_international: internationalComplete ? Number(internationalComplete) : 0,
            diets: {
              dinner: dinnerNational ? Number(dinnerNational) : 0,
              dinner_international: dinnerInternational ? Number(dinnerInternational) : 0,
              dinner_timetable: dinner && dinner2 ? `${dinner}:${dinner2}` : null,
              lunch: lunchNational ? Number(lunchNational) : 0,
              lunch_international: lunchInternational ? Number(lunchInternational) : 0,
              lunch_timetable: lunch && lunch2 ? `${lunch}:${lunch2}` : null,
              nocturnal: nocturnalNational ? Number(nocturnalNational) : 0,
              nocturnal_international: nocturnalInternational ? Number(nocturnalInternational) : 0,
              nocturnal_timetable: nocturnal2 && nocturnal ? `${nocturnal}:${nocturnal2}` : null,
              sunday: sunday ? Number(sunday) : 0,
            },
            fixed_remuneration: fixedRemuneration ? Number(fixedRemuneration) : 0,
            payment_per_km: payPerKm && payPerKm >= 0 ? Number(payPerKm) : 0,
            task_duration: taskDuration ? Number(taskDuration) : 0
          }
        },
        {name: 'payment_variables', type: 5, value: {
            complete_national: nationalComplete ? Number(nationalComplete) : 0,
            complete_international: internationalComplete ? Number(internationalComplete) : 0,
            diets: {
              dinner: dinnerNational ? Number(dinnerNational) : 0,
              dinner_international: dinnerInternational ? Number(dinnerInternational) : 0,
              lunch: lunchNational ? Number(lunchNational) : 0,
              lunch_international: lunchInternational ? Number(lunchInternational) : 0,
              nocturnal: nocturnalNational ? Number(nocturnalNational) : 0,
              nocturnal_international: nocturnalInternational ? Number(nocturnalInternational) : 0,
              sunday: sunday ? Number(sunday) : 0
            },
            fixed_remuneration: fixedRemuneration ? Number(fixedRemuneration) : 0,
            payment_per_km: payPerKm && payPerKm >= 0 ? Number(payPerKm) : 0
          }
        },
        {name: 'phone', value: phone ?  phone : null},
        {name: 'position', value: position || null, type: 3},
        {name: 'state', value: province || null},
        {name: 'surnames', value: representativeLastName || null},
        {name: 'firts_name', type: 4, value: representativeName || null},
        {name: 'last_name', type: 4, value: representativeLastName || null},
        {name: 'business_client', type: 4, value: {
          business_name: socialReason || null,
          nif: nif || null,
          address_tax: address,
          phone: phone,
          country: country,
          city: city || null,
          state: province || null,
          zip: postalCode
        }},
        {name: 'address_client', type: 4, value: [
          {address: addressGet1, phone: phoneGet1},
          {address: addressGet2, phone: phoneGet2},
          {address: addressGet3, phone: phoneGet3},
        ]},
        
      ]
      let data = {}

      for(let index in fields) {
        if(user.rol === 'admin' && (fields[index].type === 3 || !fields[index].type)) {
          if(fields[index].name == 'dni') continue
          if(fields[index].name == 'zip') continue
          if(fields[index].name == 'personal_email') continue

          data[fields[index].name] = fields[index].value
          continue
        }
        if(user.rol == 'client' && (fields[index].type === 4 || fields[index].type === 3)) {
          data[fields[index].name] = fields[index].value
          continue
        }

        if(user.rol == 'driver' && (fields[index].type === 3 || !fields[index].type)) {
          if(fields[index].name == 'nif') continue
          if(fields[index].name == 'coin') continue
          if(fields[index].name == 'company_type') continue
          if(fields[index].name == 'position') continue
          if(fields[index].name == 'language') continue
          if(fields[index].name == 'email_contact') continue
          if(fields[index].name == 'company') continue
          if(fields[index].name == 'payment_variables' && !fields[index].type) continue

          data[fields[index].name] = fields[index].value
          continue
        }
        
        if(user.rol == 'superadmin' && (!fields[index].type || fields[index].type === 3)) data[fields[index].name] = fields[index].value
      }

      if (password && password === confirmPassword) data.password = password

      user.rol !== 'client' ? 
        (user.rol !== 'driver') ? 
          await updateProfileAction(data) : 
          await updateDriverAction(data) 
        : await updateClientAction( companiesIds[0] ,data)
      user.rol == 'superadmin' ?  getUserData() : setUserFunc()
      setLoading(false)

      dispatch(openAlert({
        alertType: "success",
        alertMessage: i18n.t("PROFILE.updatedSuccessMessage"),
        isAlertOpen: true
      }))
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getCities = async (country, province) => {
    try {
      const response = await getCitiesAction(country, province)
      const cities = response?.data?.cities?.map(city => { return { label: city.name, value: city._id } })
      setCities(cities)
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  } 

  const getStates = async (country) => {
    try {
      const response = await getStatesAction(country)
      setStates(response?.data?.state.map(state => { return { label: state.translate[0].langs[localStorage.getItem("language") || "es"], value: state._id } }))
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const getPlansFunc = async () => {
    try {
      const response = await getPlans()
      if (response && response.data && response.data.plans) setPlans(response.data.plans)
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const requestPlanChange = async () => {
    try {
      setLoading(true)
      const data = {
        plan_id: newPlan?._id,
        users: Number(userNumber),
        payment_type: paymentType.toUpperCase()
      }
      const response = await updateUserPlanAction(data)

      if (response?.data?.updateSubscription?.payment_intent && response?.data?.updateSubscription?.message === "WAITING") {
        const confirmRes = await confirmPaymentIntentAction({
          payment_intent: response?.data?.updateSubscription?.payment_intent,
        })
        if (confirmRes.data.confirmPaymentIntent.status === "requires_action") {
          const redirectUrl = confirmRes?.data?.confirmPaymentIntent?.data?.next_action?.redirect_to_url?.url
          if (redirectUrl) window.location.replace(redirectUrl)
        }
      }

      await getUserData()
      setConfirmPlanScreen(false)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }

  }

  const cancelSubscription = async () => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: i18n.t("PROFILE.areYouSureToRemoveSubscription"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("GENERAL.remove"),
      alertOnYes: async () => {
        try {
          setLoading(true)
          await cancelSubscriptionAction()
          await getUserData()
          dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("PROFILE.cancelSubscriptionSuccessMessage"),
            isAlertOpen: true
          }))
          setLoading(false)
        } catch (e) {
          setLoading(false)
          dispatch(openAlert({
            isAlertOpen: true
          }))
        }
      }
    }))
  }
  const cancelWaitingSubscription = async () => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: i18n.t("PROFILE.areYouSureToRemoveWaitingPayment"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("GENERAL.accept"),
      alertOnYes: async () => {
        try {
          setLoading(true)
          await cancelSubscriptionAction()
          await getUserData()
          dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("PROFILE.cancelWaitingPaymentSuccessMessage"),
            isAlertOpen: true
          }))
          setLoading(false)
        } catch (e) {
          setLoading(false)
          dispatch(openAlert({
            isAlertOpen: true
          }))
        }
      }
    }))
  }

  const requestChange = () => {
    const userNumberMaxValidation = userNumber > newPlan?.max_users
    const userNumberMinValidation = userNumber < newPlan?.min_users
    if (userNumberMaxValidation) {
      setShowInputsErrors(true)
      setUsersNumberError(i18n.t("PROFILE.maxUsersError"))
      return
    }
    if (userNumberMinValidation) {
      setShowInputsErrors(true)
      setUsersNumberError(i18n.t("PROFILE.minUsersError"))
      return
    }

    if (!userNumberMaxValidation && !userNumberMinValidation) {
      setShowInputsErrors(false)
      setUsersNumberError("")
    }

    setConfirmPlanScreen(true)
  }

  const cancelPlanChange = () => {
    setShowInputsErrors(false)
    setUsersNumberError("")
    setPlan(user?.plan_details?.plan_id)
    setPaymentType(user?.plan_details?.payment_type.toUpperCase())
    setUserNumber(user?.plan_details?.max_users)
  }
  
  const hoursArrayForSelectNocturnal = hoursArrayNocturnal
  const hoursArrayForSelectDinner = hoursArrayDinner
  const hoursArrayForSelectLunch = hoursArrayLunch

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getInvoicesFunc()
    }
  };

  const renovateplan = async () => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: `${i18n.t("PROFILE.areYouSureToRenovatePlan")} ${user.stripe_details.card.last_digit}`,
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("ALERT.renovate"),
      alertOnYes: async () => {
        try {
          setLoading(true)
          //CREATE PAYMENT METHOD
          const payment_method_id = user.stripe_details.payment_method
          let data = {
            payment_method: payment_method_id,
            payment_type: paymentType.toUpperCase(),
            plan_id: plan,
            users: Number(userNumber),
          }
          //CREATE PAYMENT INTENT
          const res = await createPaymentIntentAction(data)
          const paymentIntentStatus = res.data.createPaymentIntent.status
          if (paymentIntentStatus !== "free_trial") {
            const paymentIntentId = res.data.createPaymentIntent.payment_intent

            //CONFIRM PAYMENT INTENT
            const confirmRes = await confirmPaymentIntentAction({
              payment_intent: paymentIntentId,
            })

            if (confirmRes.data.confirmPaymentIntent.status === "requires_action") {
              const redirectUrl = confirmRes?.data?.confirmPaymentIntent?.data?.next_action?.redirect_to_url?.url
              if (redirectUrl) window.location.replace(redirectUrl)

              return
            }
          }

          await getUserData()
          dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("PROFILE.renovatePlanSuccess"),
            isAlertOpen: true
          }))

        } catch (e) {
          setLoading(false)
          dispatch(openAlert({
            isAlertOpen: true
          }))
        }
      }
    }))
  }
  const confirmWaitingPayment = async () => {
    try {

      setLoading(true)
      const paymentIntent = user.stripe_details.payment_intent
      //CONFIRM PAYMENT INTENT
      const confirmRes = await confirmPaymentIntentAction({
        payment_intent: paymentIntent,
      })

      await getUserData()
      if (confirmRes.data.confirmPaymentIntent.status === "requires_action") {
        const redirectUrl = confirmRes?.data?.confirmPaymentIntent?.data?.next_action?.redirect_to_url?.url
        if (redirectUrl) window.location.replace(redirectUrl)
      } else {
        //SUCCESS
        dispatch(openAlert({ alertType: "success", alertMessage: i18n.t("PROFILE.renovatePlanSuccess"), isAlertOpen: true }))
      }
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const createPaymentMethod = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            // Include any additional collected billing details.
            // name: representativeName,
          },
        })

        if (!result.error) {
          // Otherwise send paymentMethod.id to your server (see Step 4)
          const payment_method_id = result.paymentMethod.id
          resolve(payment_method_id)
        }

        if (result.error) {
          reject(result.error)
        }
      } catch (e) {
        reject(e)
      }
    })
  };

  const saveNewPaymentMethod = async () => {
    setLoading(true)
    try {
      const paymentMethodId = await createPaymentMethod()
      await updateUserPaymentMethodAction({ payment_method: paymentMethodId })
      await getUserData()
      dispatch(openAlert({
        alertType: "success",
        alertMessage: i18n.t("PROFILE.paymentMethodSuccess"),
        isAlertOpen: true
      }))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        isAlertOpen: true
      }))

    }
  }

  const tableHeader = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('PROFILE.forms.billing.title')}
      </TableTitle>
    </TableInfoContainer>
    <FormSelect
      value={billingFilterBy}
      fatherContainer={{ zIndex: 99999 }}
      onChange={({ target: { value } }) => {
        setBillingFilterBy(value)
        if (value === "PAID" || value === "UNPAID") {
          setInvoices([])
          setStatus(value)
          setPaymentTypeFilter("")
        }
        if (value === "ANNUALLY" || value === "MONTHLY") {
          setInvoices([])
          setStatus("")
          setPaymentTypeFilter(value)
        }
        if (value === "" || value === "1") {
          setInvoices([])
          setStatus("")
          setPaymentTypeFilter("")
        }
      }}

      defaultValue="1"
      containerStyle={{ width: 220 }}
      icon={filterByIcon}
      items={[
        { value: '1', label: i18n.t('PROFILE.forms.billing.buttons.filterBy'), icon: filterByIcon },
        { value: 'PAID', label: i18n.t('PROFILE.paid'), icon: filterByIcon },
        { value: 'UNPAID', label: i18n.t('PROFILE.unpaid'), icon: filterByIcon },
        { value: 'ANNUALLY', label: i18n.t('PROFILE.annual'), icon: filterByIcon },
        { value: 'MONTHLY', label: i18n.t('PROFILE.monthly'), icon: filterByIcon },
      ]}
      required
    />
    <FormNumberInput
      icon={searchIcon}
      value={search}
      type="text"
      placeholder={i18n.t('PROFILE.searchPlaceHolder')}
      containerStyle={{ width: 250 }}
      onValueChange={(value) => {
        if (value === "") getInvoicesFunc(true)

        setSearch(value)
      }}
      onKeyPress={handleKeyPress}
      disabled={loading}
    />
    <CategoryButton
      onClick={() => exportInvoicesFunc()}
      active={false}
      icon=""
      title={i18n.t('PROFILE.forms.billing.buttons.export')}
    />
  </ProfileBillingContainer>

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const isPlanDiferent = user?.plan_details?.plan_id !== plan || user?.plan_details?.max_users !== userNumber || user?.plan_details?.payment_type.toUpperCase() !== paymentType.toUpperCase()
  const newPlan = plans?.length > 0 && plan ? plans.filter(item => item._id === plan)[0] : null
  const isPlanCanceled = user?.plan_details?.status === "CANCELED"
  const isPlanWaiting = user?.plan_details?.status === "WAITING"

  const cardLastDigits = user?.stripe_details?.card?.last_digit
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      },
    },
    hidePostalCode: true
  };

  const newPlanIsHigher = user?.plan_details?.plan_id === config.BRONZE_PLAN_ID && newPlan?._id === config.SILVER_PLAN_ID || user?.plan_details?.plan_id === config.SILVER_PLAN_ID && newPlan?._id === config.GOLD_PLAN_ID
  return (
    <ProfileContainer>
      <ProfileContentContainer>
        {!confirmPlanScreen && <ProfileButtonsContainer>
          <CategoryButton
            onClick={() => setCurrentForm("company")}
            active={currentForm === "company"}
            icon="company"
            title={i18n.t('PROFILE.buttons.companyButton')}
            disabled={loading}
          />
          <CategoryButton
            onClick={() => setCurrentForm("access")}
            active={currentForm === "access"}
            icon="access"
            title={i18n.t('PROFILE.buttons.accessButton')}
            disabled={loading}
          />
          {
            user.rol !== 'client' ?
              <>
                <CategoryButton
                  onClick={() => setCurrentForm("variables")}
                  active={currentForm === "variables"}
                  icon="variables"
                  title={i18n.t('PROFILE.buttons.variablesButton')}
                  disabled={loading}
                />
                {
                  user.rol !== 'driver' && <>
                    <CategoryButton
                      onClick={() => setCurrentForm("plans")}
                      active={currentForm === "plans"}
                      icon="plans"
                      title={i18n.t('PROFILE.buttons.plansButton')}
                      disabled={loading}
                    />
                    <CategoryButton
                      onClick={() => setCurrentForm("billing")}
                      active={currentForm === "billing"}
                      icon="billing"
                      title={i18n.t('PROFILE.buttons.billingButton')}
                      disabled={loading}
                    />
                  </>
                }
              </>
              :
              <>
                <CategoryButton
                  onClick={() => setCurrentForm("map")}
                  active={currentForm === "map"}
                  icon="map"
                  title={i18n.t('CLIENT_DETAILS.buttons.address')}
                />
              </>
            
          }
        </ProfileButtonsContainer>}
        <ProfileFormSeparator />
        {generalError && <GeneralErrorContainer>
          <GeneralErrorText>{generalError}</GeneralErrorText>
        </GeneralErrorContainer>}
        <ProfileFormContainer>
          {currentForm === "company"
            ? <>
              <UserCard
                user={{
                  name: user?.name || representativeName,
                  position: user.position,
                  picture: user?.avatar?.fullpath || user.rol !== 'client' ? avatarDefault : `${config.URL_BASE_FILE}${avatar}`
                }}
                onEditPicture={onEditPicture}
              />
              <ProfileFormsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    title={i18n.t('PROFILE.forms.company.socialReason')}
                    type={"text"}
                    value={socialReason}
                    onChange={({ target: { value } }) => setSocialReason(value)}
                    required
                    showError={!socialReason && showInputsErrors}
                    disabled={loading}
                  />
                  {
                    user.rol !== 'driver' && (
                      <FormTextInput
                        title={i18n.t('PROFILE.forms.company.nif')}
                        type={"text"}
                        value={nif}
                        onChange={({ target: { value } }) => setCompanyNif(value)}
                        required
                        showError={!nif && showInputsErrors}
                        disabled={loading}
                      />
                    )
                  }
                  {
                    (user.rol == 'superadmin') && (
                      <FormRadioButtonsInput
                        title={i18n.t('PROFILE.forms.company.companyType')}
                        value={companyType?.toUpperCase()}
                        // defaultValue="1"
                        onChange={({ target: { value } }) => setCompanyType(value)}
                        items={[
                          { value: 'COMPANY', label: i18n.t('PROFILE.forms.company.companyType1company') },
                          { value: 'AGENCY', label: i18n.t('PROFILE.forms.company.companyType2agency') },
                        ]}
                        required
                        showError={!companyType && showInputsErrors}
                        disabled={loading}
                      />
                    )
                  }
                  <FormTextInput
                    title={i18n.t('PROFILE.forms.company.phone')}
                    value={phone}
                    onChange={({ target: { value } }) => setPhone(value)}
                    disabled={loading}
                  />
                  {
                    user.rol !== 'client' && <>
                      <FormTextInput
                        title={i18n.t('PROFILE.forms.company.contactEmail')}
                        type={"email"}
                        value={contactEmail}
                        onChange={({ target: { value } }) => setContactEmail(value)}
                        disabled={loading}
                        errorMessage={contactEmailErrorMessage}
                      />
                      {
                        user.rol !== 'driver' && <>
                          <FormSelect
                            disabled={loading}
                            title={i18n.t('PROFILE.forms.company.coin')}
                            value={coin}
                            onChange={({ target: { value } }) => setCoin(value)}
                            items={coins}
                            required
                            showError={!coin && showInputsErrors}
                          />
                        </>
                      }
                    </>
                  }
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer>
                  <FormSelect
                    disabled={loading}
                    title={i18n.t('PROFILE.forms.company.country')}
                    value={country}
                    onChange={({ target: { value } }) => {
                      const iso2 = countries.filter(item => item.value === value)[0]?.iso2
                      setCountry(value)
                      setProvince("")
                      setCity("")
                      setCountryIso2(iso2)
                      getStates(iso2)
                    }}
                    items={countries}
                    required
                    showError={!country && showInputsErrors}
                  />
                  {(country && states) && <FormSelect
                    disabled={loading}
                    title={i18n.t('PROFILE.forms.company.province')}
                    value={province}
                    onChange={({ target: { value } }) => {
                      setProvince(value)
                      getCities(countryIso2, value)
                    }}
                    items={states}
                  />}
                  {(country && province && cities) && <FormSelect
                    disabled={loading}
                    title={i18n.t('PROFILE.forms.company.city')}
                    value={city}
                    onChange={({ target: { value } }) => setCity(value)}
                    items={cities}
                  />}
                  <FormTextInput
                    title={i18n.t('PROFILE.forms.company.postalCode')}
                    type={"text"}
                    value={postalCode}
                    onChange={({ target: { value } }) => setPostalCode(value)}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={i18n.t('PROFILE.forms.company.address')}
                    type={"text"}
                    value={address}
                    onChange={({ target: { value } }) => setAddress(value)}
                    disabled={loading}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormSeparator />
                <ProfileLastButtonsContainer>
                  <CategoryButton
                    onClick={() => saveProfile()}
                    active
                    icon=""
                    title={i18n.t('PROFILE.buttons.save')}
                    loading={loading}
                  />
                </ProfileLastButtonsContainer>
              </ProfileFormsContainer>
            </> : null}
          {currentForm === "access"
            ? <>
              <ProfileFormsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    title={i18n.t('PROFILE.forms.access.representativeName')}
                    type={"text"}
                    value={representativeName}
                    onChange={({ target: { value } }) => setRepresentativeName(value)}
                    required
                    showError={!representativeName && showInputsErrors}
                    disabled={loading}
                    allowJustLetters
                  />
                  <FormTextInput
                    title={i18n.t('PROFILE.forms.access.representativeLastName')}
                    type={"text"}
                    value={representativeLastName}
                    onChange={({ target: { value } }) => setRepresentativeLastName(value)}
                    required
                    showError={!representativeLastName && showInputsErrors}
                    disabled={loading}
                    allowJustLetters
                  />
                  <FormTextInput
                    title={i18n.t('PROFILE.forms.access.dni')}
                    type={"text"}
                    value={dni}
                    onChange={({ target: { value } }) => setDni(value)}
                    required
                    showError={!dni && showInputsErrors}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={i18n.t('PROFILE.forms.access.position')}
                    type={"text"}
                    value={position}
                    onChange={({ target: { value } }) => setPosition(value)}
                    disabled={loading}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    title={i18n.t('PROFILE.forms.access.email')}
                    type={"email"}
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                    disabled={true}
                    required
                    showError={!email && showInputsErrors}
                  />
                  <FormTextInput
                    title={i18n.t('PROFILE.forms.access.password')}
                    iconRightClick={() => setPasswordShow(!passwordShow)}
                    rightIcon={passwordShow ? offEye : redEye}
                    icon={passwordIcon}
                    type={passwordShow ? "text" : "password"}
                    errorMessage={passwordError}
                    value={password}
                    onChange={({ target: { value } }) => setPassword(value)}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={i18n.t('PROFILE.forms.access.confirmPassword')}
                    iconRightClick={() => setSecondPasswordShow(!secondPasswordShow)}
                    rightIcon={secondPasswordShow ? offEye : redEye}
                    icon={passwordIcon}
                    type={secondPasswordShow ? "text" : "password"}
                    errorMessage={passwordError}
                    value={confirmPassword}
                    onChange={({ target: { value } }) => setConfirmPassword(value)}
                    disabled={loading}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormSeparator />
                <ProfileLastButtonsContainer>
                  <CategoryButton
                    onClick={() => saveProfile()}
                    active
                    icon=""
                    title={i18n.t('PROFILE.buttons.save')}
                    loading={loading}
                  />
                </ProfileLastButtonsContainer>
              </ProfileFormsContainer>
            </> : null}
          {currentForm === "variables"
            ? <>
              <ProfileFormsContainer>
                <ProfileFormInputsContainer style={{ width: 400 }}>
                  <FormNumberInput
                    title={i18n.t('PROFILE.forms.variables.fixedRemuneration')}
                    type={"number"}
                    value={fixedRemuneration}
                    onValueChange={(value) => setFixedRemuneration(value)}
                    rightComponent={<FixedAmountText>{coinOption.symbol}</FixedAmountText>}
                    // required
                    // showError={!fixedRemuneration && showInputsErrors}
                    allowDecimals
                    disabled={loading}
                    placeholder={"0.00"}
                  />
                  <FormNumberInput
                    title={i18n.t('PROFILE.forms.variables.nationalComplete')}
                    type={"text"}
                    value={nationalComplete}
                    onValueChange={(value) => setNationalComplete(value)}
                    rightComponent={<FixedAmountText>{coinOption.symbol}</FixedAmountText>}
                    allowDecimals
                    disabled={loading}
                    placeholder={"0.00"}
                  />
                  <FormNumberInput
                    title={i18n.t('PROFILE.forms.variables.internationalComplete')}
                    type={"text"}
                    value={internationalComplete}
                    onValueChange={(value) => setInternationalComplete(value)}
                    rightComponent={<FixedAmountText>{coinOption.symbol}</FixedAmountText>}
                    allowDecimals
                    disabled={loading}
                    placeholder={"0.00"}
                  />
                  <FormNumberInput
                    title={i18n.t('PROFILE.forms.variables.payPerKm')}
                    type={"text"}
                    value={payPerKm}
                    onValueChange={(value) => setPayPerKm(value)}
                    rightComponent={<FixedAmountText>{coinOption.symbol}</FixedAmountText>}
                    // required
                    // showError={!payPerKm && showInputsErrors}
                    allowDecimals
                    disabled={loading}
                    placeholder={"0.00"}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer style={{ width: 600 }}>
                  <ProfileSmallButtonsGroup>
                    <ProfileSmallButtonGroup>
                      <FormSelect
                        title={i18n.t('PROFILE.forms.variables.lunch')}
                        value={lunch}
                        containerStyle={{ width: 150 }}
                        onChange={({ target: { value } }) => setLunch(value)}
                        items={hoursArrayForSelectLunch}
                        placeholder={"00:00"}
                        disabled={loading}
                      />
                      <FormSelect
                        value={lunch2}
                        containerStyle={{ width: 150, marginTop: 40 }}
                        onChange={({ target: { value } }) => setLunch2(value)}
                        items={hoursArrayForSelectLunch}
                        placeholder={"00:00"}
                        disabled={loading}
                      />
                    </ProfileSmallButtonGroup>
                    <ProfileSmallButtonGroup>
                      <FormSelect
                        title={i18n.t('PROFILE.forms.variables.dinner')}
                        value={dinner}
                        containerStyle={{ width: 150 }}
                        onChange={({ target: { value } }) => setDinner(value)}
                        items={hoursArrayForSelectDinner}
                        placeholder={"00:00"}
                        disabled={loading}
                      />
                      <FormSelect
                        value={dinner2}
                        containerStyle={{ width: 150, marginTop: 40 }}
                        onChange={({ target: { value } }) => setDinner2(value)}
                        items={hoursArrayForSelectDinner}
                        placeholder={"00:00"}
                        disabled={loading}
                      />
                    </ProfileSmallButtonGroup>
                    <ProfileSmallButtonGroup>
                      <FormSelect
                        title={i18n.t('PROFILE.forms.variables.nocturnal')}
                        value={nocturnal}
                        containerStyle={{ width: 150 }}
                        onChange={({ target: { value } }) => setNocturnal(value)}
                        items={hoursArrayForSelectNocturnal}
                        placeholder={"00:00"}
                        disabled={loading}
                      />
                      <FormSelect
                        value={nocturnal2}
                        containerStyle={{ width: 150, marginTop: 40 }}
                        onChange={({ target: { value } }) => setNocturnal2(value)}
                        items={hoursArrayForSelectNocturnal}
                        placeholder={"00:00"}
                        disabled={loading}
                      />
                    </ProfileSmallButtonGroup>
                  </ProfileSmallButtonsGroup>

                  <ProfileSmallButtonsGroup>
                    <ProfileSmallButtonGroup>
                      <FormNumberInput
                        title={i18n.t('PROFILE.forms.variables.national')}
                        containerStyle={{ width: 100 }}
                        value={lunchNational}
                        onValueChange={(value) => setLunchNational(value)}
                        placeholder={"0.00"}
                        rightComponent={<FixedAmountText>{coinOption.symbol}</FixedAmountText>}
                        disabled={loading}
                        allowDecimals
                      />
                      <FormNumberInput
                        title={i18n.t('PROFILE.forms.variables.international')}
                        containerStyle={{ width: 100 }}
                        value={lunchInternational}
                        onValueChange={(value) => setLunchInternational(value)}
                        placeholder={"0.00"}
                        rightComponent={<FixedAmountText>{coinOption.symbol}</FixedAmountText>}
                        disabled={loading}
                        allowDecimals
                      />
                    </ProfileSmallButtonGroup>
                    <ProfileSmallButtonGroup>
                      <FormNumberInput
                        title={i18n.t('PROFILE.forms.variables.national')}
                        containerStyle={{ width: 100 }}
                        value={dinnerNational}
                        onValueChange={(value) => setDinnerNational(value)}
                        placeholder={"0.00"}
                        rightComponent={<FixedAmountText>{coinOption.symbol}</FixedAmountText>}
                        disabled={loading}
                        allowDecimals
                      />
                      <FormNumberInput
                        title={i18n.t('PROFILE.forms.variables.international')}
                        containerStyle={{ width: 100 }}
                        value={dinnerInternational}
                        onValueChange={(value) => setDinnerInternational(value)}
                        placeholder={"0.00"}
                        rightComponent={<FixedAmountText>{coinOption.symbol}</FixedAmountText>}
                        disabled={loading}
                        allowDecimals
                      />
                    </ProfileSmallButtonGroup>
                    <ProfileSmallButtonGroup>
                      <FormNumberInput
                        title={i18n.t('PROFILE.forms.variables.national')}
                        containerStyle={{ width: 100 }}
                        value={nocturnalNational}
                        onValueChange={(value) => setNocturnalNational(value)}
                        placeholder={"0.00"}
                        rightComponent={<FixedAmountText>{coinOption.symbol}</FixedAmountText>}
                        disabled={loading}
                        allowDecimals
                      />
                      <FormNumberInput
                        title={i18n.t('PROFILE.forms.variables.international')}
                        containerStyle={{ width: 100 }}
                        value={nocturnalInternational}
                        onValueChange={(value) => setNocturnalInternational(value)}
                        placeholder={"0.00"}
                        rightComponent={<FixedAmountText>{coinOption.symbol}</FixedAmountText>}
                        disabled={loading}
                        allowDecimals
                      />
                    </ProfileSmallButtonGroup>
                  </ProfileSmallButtonsGroup>
                  <ProfileSmallButtonsGroup style={{
                    width: "90%",
                    display: 'flex',
                    flexDirection: "row",
                  }}> 
                    <FormNumberInput
                      containerStyle={{ width: 260 }}
                      title={i18n.t('PROFILE.forms.variables.sunday')}
                      type={"text"}
                      value={sunday}  
                      placeholder={"0.00"}
                      onValueChange={(value) => setSunday(value)}
                      rightComponent={<FixedAmountText>{coinOption.symbol}</FixedAmountText>}
                      allowDecimals
                      disabled={loading}
                    />
                    <FormNumberInput
                      containerStyle={{ width: 260 }}
                      title={'Duracion de la Tarea'}
                      type={"text"}
                      value={taskDuration}
                      placeholder={"0.00"}
                      onValueChange={(value) => setTaskDuration(value)}
                      allowDecimals
                      disabled={loading}
                    />
                  </ProfileSmallButtonsGroup>
                  
                </ProfileFormInputsContainer>
                <ProfileFormSeparator />
                <ProfileLastButtonsContainer>
                  <CategoryButton
                    onClick={() => saveProfile()}
                    active
                    icon=""
                    title={i18n.t('PROFILE.buttons.save')}
                    loading={loading}
                  />
                </ProfileLastButtonsContainer>
              </ProfileFormsContainer>
            </> : null}
          {currentForm === "map"
            ? <>
              <ProfileFormsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    title={`${i18n.t('CLIENT_DETAILS.forms.address.pickUpAddress')} 1`}
                    type={"text"}
                    value={addressGet1}
                    onChange={({ target: { value } }) => setAddressGet1(value)}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={`${i18n.t('CLIENT_DETAILS.forms.address.pickUpAddress')} 2`}
                    type={"text"}
                    value={addressGet2}
                    onChange={({ target: { value } }) => setAddressGet2(value)}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={`${i18n.t('CLIENT_DETAILS.forms.address.pickUpAddress')} 3`}
                    type={"text"}
                    value={addressGet3}
                    onChange={({ target: { value } }) => setAddressGet3(value)}
                    disabled={loading}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    title={`${i18n.t('CLIENT_DETAILS.forms.address.phoneAddress')} 1`}
                    type={"text"}
                    value={phoneGet1}
                    onChange={({ target: { value } }) => setPhoneGet1(value)}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={`${i18n.t('CLIENT_DETAILS.forms.address.phoneAddress')} 2`}
                    type={"text"}
                    value={phoneGet2}
                    onChange={({ target: { value } }) => setPhoneGet2(value)}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={`${i18n.t('CLIENT_DETAILS.forms.address.phoneAddress')} 3`}
                    type={"text"}
                    value={phoneGet3}
                    onChange={({ target: { value } }) => setPhoneGet3(value)}
                    disabled={loading}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormSeparator
                />
                <ProfileLastButtonsContainer>
                  <CategoryButton
                    onClick={() => {
                      saveProfile()
                      // setShowInputsErrors(false)
                    }}
                    active
                    icon=""
                    title={i18n.t('CLIENT_DETAILS.buttons.save')}
                    loading={loading}
                  />
                </ProfileLastButtonsContainer>
              </ProfileFormsContainer>
            </> : null
          }
          {currentForm === "plans"
            ? isUpdatePaymentMethodOpen
              ? <>
                <ProfileFormInputsContainer style={{ margin: "auto", minHeight: 200 }}>
                  <TableInfoContainer>
                    <TableTitle>
                      {i18n.t('PROFILE.paymentDataTitle')}
                    </TableTitle>
                  </TableInfoContainer>
                  <form className="form-payment" id="payment-form" onSubmit={createPaymentMethod}>
                    {cardLastDigits && <SubTitle style={{ color: COLORS_TRUCK.BLACK_TRUCK, marginTop: 10, fontSize: 14 }}>
                      {`${i18n.t('PROFILE.currentCard')}: **** **** **** ${cardLastDigits}`}
                    </SubTitle>}
                    <CardElement
                      id="card-element"
                      options={cardStyle}
                      onChange={handleChange}
                    />
                    {creditCardError && <SubTitle style={{ color: COLORS_TRUCK.ORANGE_TRUCK, marginTop: 10, fontSize: 14 }}>
                      {creditCardError}
                    </SubTitle>}
                  </form>
                  <ProfileLastButtonsContainer style={{ marginTop: 20 }}>
                    <CategoryButton
                      onClick={() => setIsUpdatePaymentMethodOpen(false)}
                      active
                      icon=""
                      title={i18n.t('PROFILE.forms.plans.buttons.back')}
                      loading={loading}
                    />
                    <CategoryButton
                      onClick={() => saveNewPaymentMethod()}
                      active={false}
                      disabled={loading || disabled}
                      width={120}
                      icon=""
                      title={i18n.t('PROFILE.forms.plans.buttons.save')}
                      loading={loading}
                    />

                  </ProfileLastButtonsContainer>
                </ProfileFormInputsContainer>
              </>
              : <>
                {confirmPlanScreen && <BackButton onClick={() => setConfirmPlanScreen(false)} route="" />}
                <ProfileFormsContainer>
                  <ProfileFormInputsContainer style={{ width: confirmPlanScreen ? 900 : isPlanDiferent && !isPlanCanceled ? 700 : 500 }}>
                    {!isPlanCanceled && <div>
                      <PlanTitle>
                        {`${user?.plan_details?.name[localStorage.getItem("language") || "es"]} ${i18n.t('PROFILE.forms.plans.oldPlanTitle')}`}
                      </PlanTitle>
                      <ProfileTable style={{ width: 300 }}>
                        <thead>
                          <tr>
                            <TableTh>{i18n.t('PROFILE.forms.plans.description')}</TableTh>
                            <TableTh>{i18n.t('PROFILE.forms.plans.unity')}</TableTh>
                            <TableTh>{i18n.t('PROFILE.forms.plans.amount')}</TableTh>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <TableTd>{`${user?.plan_details?.max_users} ${i18n.t('PLANS.users')}`}</TableTd>
                            <TableTd style={{ opacity: 0.4 }}>{plans.filter(item => item._id === user?.plan_details?.plan_id)[0]?.prices[user?.plan_details?.payment_type.toLowerCase() === "annually" ? "user_annual" : "user_monthly"]}  €</TableTd>
                            <TableTd style={{ opacity: 0.4 }}>{user?.plan_details?.final_amount.toFixed(2)}  €</TableTd>
                          </tr>
                          <tr>
                            <TableTd></TableTd>
                            <TableTd style={{ fontWeight: 'bold' }}>{i18n.t('PROFILE.forms.plans.total')}</TableTd>
                            <TableTd style={{ fontWeight: 'bold' }}>{user?.plan_details?.final_amount.toFixed(2)}  €</TableTd>
                          </tr>
                        </tbody>
                      </ProfileTable>
                    </div>}
                    {(confirmPlanScreen && !isPlanCanceled) && <div>
                      <ArrowIcon src={redArrow} />
                    </div>}
                    {((isPlanDiferent && newPlan && !isPlanCanceled) || isPlanCanceled) && <div>
                      <PlanTitle style={{ marginLeft: 20 }}>
                        {`${newPlan?.translate[0]?.langs[localStorage.getItem("language") || "es"]} ${i18n.t('PROFILE.forms.plans.newPlanTitle')}`}
                      </PlanTitle>
                      <ProfileTable style={{ width: 300, marginLeft: 20 }}>
                        <thead>
                          <tr>
                            <TableTh>{i18n.t('PROFILE.forms.plans.description')}</TableTh>
                            <TableTh>{i18n.t('PROFILE.forms.plans.unity')}</TableTh>
                            <TableTh>{i18n.t('PROFILE.forms.plans.amount')}</TableTh>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <TableTd>{`${userNumber} ${i18n.t('PLANS.users')}`}</TableTd>
                            <TableTd style={{ opacity: 0.4 }}>{newPlan?.prices[paymentType === "ANNUALLY" ? "user_annual" : "user_monthly"]}  {coinOption.symbol}</TableTd>
                            <TableTd style={{ opacity: 0.4 }}>{(userNumber * newPlan?.prices[paymentType === "ANNUALLY" ? "user_annual" : "user_monthly"]).toFixed(2)}   {coinOption.symbol}</TableTd>
                          </tr>
                          <tr>
                            <TableTd></TableTd>
                            <TableTd style={{ fontWeight: 'bold' }}>{i18n.t('PROFILE.forms.plans.total')}</TableTd>
                            <TableTd style={{ fontWeight: 'bold' }}>{(userNumber * newPlan?.prices[paymentType === "ANNUALLY" ? "user_annual" : "user_monthly"]).toFixed(2)}   {coinOption.symbol}</TableTd>
                          </tr>
                        </tbody>
                      </ProfileTable>
                    </div>
                    }
                    {(confirmPlanScreen && !isPlanCanceled) && <div>
                      <CategoryButton
                        onClick={() => requestPlanChange()}
                        active
                        width={120}
                        icon=""
                        loading={loading}
                        title={i18n.t('PROFILE.forms.plans.buttons.confirm')}
                      />
                    </div>}
                  </ProfileFormInputsContainer>
                  {(confirmPlanScreen && !isPlanCanceled && !newPlanIsHigher) && <ProfileFormSeparator />}
                  {(confirmPlanScreen && !isPlanCanceled && !newPlanIsHigher) && <ChangePlanWarning>
                    {i18n.t('PROFILE.changePlanWarning')}
                  </ChangePlanWarning>}
                  {!confirmPlanScreen && <ProfileFormInputsContainer style={{ width: isPlanDiferent && !isPlanCanceled ? 620 : 500 }}>
                    <div style={{ width: 300 }}>
                      <FormSelect
                        title={i18n.t('PROFILE.forms.plans.plan')}
                        value={plan}
                        onChange={({ target: { value } }) => !loading ? setPlan(value) : null}
                        items={plans.map((item) => ({ value: item._id, label: item.translate[0].langs[localStorage.getItem("language") || "es"] }))}
                        disabled={loading}
                        required
                      />
                      <FormSelect
                        title={i18n.t('PROFILE.forms.plans.paymentType')}
                        value={paymentType}
                        onChange={({ target: { value } }) => !loading ? setPaymentType(value) : null}
                        items={[
                          { value: '', label: "", },
                          { value: 'ANNUALLY', label: i18n.t('PROFILE.forms.plans.annual'), },
                          { value: 'MONTHLY', label: i18n.t('PROFILE.forms.plans.monthly') }
                        ]}
                        required
                      />
                      <FormNumberInput
                        title={i18n.t('PROFILE.forms.plans.userNumber')}
                        value={userNumber}
                        onValueChange={(value) => setUserNumber(value)}
                        required
                        max={newPlan?.max_users || user?.plan_details?.max_users}
                        min={newPlan?.min_users || user?.plan_details?.min_users}
                        errorMessage={usersNumberError}
                        showError={!userNumber && showInputsErrors ? true : false}
                        disabled={loading}
                      />
                    </div>
                    {(isPlanDiferent && !isPlanCanceled) && <NewPlanButtonsContainer style={{ width: 300 }}>
                      <CategoryButton
                        onClick={() => requestChange()}
                        active
                        width={250}
                        icon=""
                        loading={loading}
                        title={i18n.t('PROFILE.forms.plans.buttons.requestChange')}
                      />
                      <CategoryButton
                        onClick={() => cancelPlanChange()}
                        active={false}
                        width={250}
                        loading={loading}
                        icon=""
                        title={i18n.t('PROFILE.forms.plans.buttons.cancelChange')}
                      />
                    </NewPlanButtonsContainer>}
                  </ProfileFormInputsContainer>}
                  <ProfileFormSeparator />
                  {(confirmPlanScreen && !isPlanCanceled) && <DriversScreen />}
                  {!confirmPlanScreen && <ProfileLastButtonsContainer>
                    {(!isPlanCanceled && !isPlanWaiting) && <CategoryButton
                      onClick={() => cancelSubscription()}
                      active={false}
                      width={300}
                      icon=""
                      loading={loading}
                      title={i18n.t('PROFILE.forms.plans.buttons.cancelSubcription')}
                    />}
                    <CategoryButton
                      onClick={() => setIsUpdatePaymentMethodOpen(true)}
                      active
                      icon=""
                      title={i18n.t('PROFILE.forms.plans.buttons.paymentData')}
                      loading={loading}
                    />
                    {isPlanCanceled && <CategoryButton
                      onClick={() => renovateplan()}
                      active={false}
                      width={250}
                      icon=""
                      title={i18n.t('PROFILE.forms.plans.buttons.renovateSubcription')}
                      loading={loading}
                    />}
                    {isPlanWaiting && <CategoryButton
                      onClick={() => cancelWaitingSubscription()}
                      active={false}
                      width={250}
                      icon=""
                      title={i18n.t('PROFILE.forms.plans.buttons.cancelWaitingPayment')}
                      loading={loading}
                    />}
                    {isPlanWaiting && <CategoryButton
                      onClick={() => confirmWaitingPayment()}
                      active={false}
                      width={250}
                      icon=""
                      title={i18n.t('PROFILE.forms.plans.buttons.confirmPayment')}
                      loading={loading}
                    />}

                  </ProfileLastButtonsContainer>}
                </ProfileFormsContainer>
              </>
            : null}
          {currentForm === "billing"
            ? <>
              <TruckTable
                header={tableHeader}
                titles={[
                  { label: i18n.t('PROFILE.forms.billing.id') },
                  { label: i18n.t('PROFILE.forms.billing.concept') },
                  { label: i18n.t('PROFILE.forms.billing.period') },
                  { label: i18n.t('PROFILE.forms.billing.date') },
                  { label: i18n.t('PROFILE.forms.billing.state') },
                  { label: i18n.t('PROFILE.forms.billing.amount') },
                  { label: i18n.t('PROFILE.forms.billing.discount') },
                  { label: i18n.t('PROFILE.forms.billing.finalAmount') },
                  { label: i18n.t('PROFILE.forms.billing.actions') },
                ]}
                loadingDownload={loadingDownload}
                onEndReach={() => {
                  if (keepIncreasing) {
                    setOffset(offset + increaseLimit)
                    setLimit(limit + increaseLimit)
                  } 

                  getInvoicesFunc()
                }}
                downloadInvoice={(invoice_id) => downloadInvoiceFunc(invoice_id)}
                data={invoices}
                type="invoices"
                style={{
                  maxHeight: 700, 
                  overflow: "auto"
                }}
              />
            </> : null}
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default ProfileScreen