// import * as navigation from '../../routes/navigation';
import client from '../../utils/api';
import {
  GET_CITIES_QUERY,
  GET_COUNTRIES_QUERY,
  GET_COUNTRY_STATES,
  GET_PLANS,
  REGISTER_MUTATION,
  LOGIN_MUTATION,
  CONFIRM_PAYMENT_INTENT_MUTATION,
  CREATE_PAYMENT_INTENT_MUTATION,
  GET_DISCOUNTS,
  GET_PRE_INVOICE_MUTATION,
  UPDATE_USER_MUTATION,
  SEND_CONFIRM_USER_CODE,
  CONFIRM_USER_CODE,
  CHECK_EMAIL,
  GET_INVOICES,
  LOGOUT_MUTATION
} from './signup-queries';
// import {handleAlert} from '../../general/functions';

/**
 * registerAction - action for signup
 *
 * @param {object} formData - registerAction data
 * @returns {any}
 */
export const registerAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: REGISTER_MUTATION,
        variables: {
          signUpUserInput: {
            ...formData
          },
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getPreInvoiceAction - action for for pre invoice
 *
 * @param {object} formData - getPreInvoiceAction data
 * @returns {any}
 */
export const getPreInvoiceAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: GET_PRE_INVOICE_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateUserAction - action for update user
 *
 * @param {object} formData - updateUserAction data
 * @returns {any}
 */
export const updateUserAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: UPDATE_USER_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * loginAction - action for login
 *
 * @param {object} formData - loginAction data
 * @returns {any}
 */
export const loginAction = (
  formData
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          loginUserInput: { ...formData },
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * createPaymentIntentAction - action for create payment intent
 *
 * @param {object} formData - createPaymentIntentAction data
 * @returns {any}
 */
export const createPaymentIntentAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("createPaymentIntentAction / formData: ", formData)
      const response = await client.mutate({
        mutation: CREATE_PAYMENT_INTENT_MUTATION,
        variables: {
          createSubscriptionInput: { ...formData },
        },
      });
      // console.log("createPaymentIntentAction / response: ", response)
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      // console.log("createPaymentIntentAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * confirmPaymentIntentAction - action for confirm payment intent
 *
 * @param {object} formData - confirmPaymentIntentAction data
 * @returns {any}
 */
export const confirmPaymentIntentAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("confirmPaymentIntentAction / formData: ", formData)
      const response = await client.mutate({
        mutation: CONFIRM_PAYMENT_INTENT_MUTATION,
        variables: {
          confirmPaymentIntentInput: { ...formData },
        },
      });
      // console.log("confirmPaymentIntentAction / response: ", response)
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      // console.log("confirmPaymentIntentAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getCitiesAction - action for get data the list of a country cities
 *
 * @param {string} countryCode - country code
 * @returns {any}
 */
export const getCitiesAction = async (countryCode, state_id) => {
  try {
    const response = await client.query({
      query: GET_CITIES_QUERY,
      variables: {
        country_code: countryCode,
        state_id: state_id,
      },
      fetchPolicy: 'network-only',
    });
    if (response) {
      return response;
    }
    // throw Object.assign(
    //   new Error("Ha ocurrido un error"),
    //   { code: 500 }
    // );
  } catch (err) {
    return err;
  }
};

/**
 * getInvoices - action for get data the list of a country cities
 *
 * @param {object} formData - formData
 * @returns {any}
 */
export const getInvoices = async (formData) => {
  try {
    const response = await client.query({
      query: GET_INVOICES,
      variables: {
        input: { ...formData },
      },
      fetchPolicy: 'network-only',
    });
    if (response) {
      return response;
    }
    // throw Object.assign(
    //   new Error("Ha ocurrido un error"),
    //   { code: 500 }
    // );
  } catch (err) {
    return err;
  }
};

/**
 * sendConfirmUserCode - action for get code from an email to confirm user
 *
 * @param {string} email - registered user email
 * @returns {any}
 */
export const sendConfirmUserCode = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: SEND_CONFIRM_USER_CODE,
        variables: {
          email,
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // if (response) {
      //   return response;
      // }
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (err) {
      reject(err);
    }
  })
};

/**
 * confirmUserCode - action for confirming user code
 * @param {string} code - confirm email code
 * @returns {any}
 */
export const confirmUserCode = (code) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: CONFIRM_USER_CODE,
        variables: {
          code: code,
        },
        fetchPolicy: 'network-only',
      });
      resolve(response);
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (err) {
      reject(err);
    }
  })
};

/**
 * checkEmailExist - action for check if an email is registered
 *
 * @returns {any}
 */
export const checkEmailExist = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: CHECK_EMAIL,
        variables: {
          email: email,
        },
        fetchPolicy: 'network-only',
      });
      resolve(response);
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (err) {
      reject(err);
    }
  })
};

/**
 * getDiscounts - action for get discounts
 *
 * @param {string} code - discount code
 * @returns {any}
 */
export const getDiscounts = async (code) => {
  try {
    const response = await client.query({
      query: GET_DISCOUNTS,
      variables: {
        name: code,
      },
      fetchPolicy: 'network-only',
    });
    if (response) {
      return response;
    }
    // throw Object.assign(
    //   new Error("Ha ocurrido un error"),
    //   { code: 500 }
    // );
  } catch (err) {
    return err;
  }
};

/**
 * getStatesAction - action for get data the list of a country states
 *
 * @param {string} countryCode - country code
 * @returns {any}
 */
export const getStatesAction = async (countryCode) => {
  try {
    const response = await client.query({
      query: GET_COUNTRY_STATES,
      variables: {
        country_code: countryCode
      },
      fetchPolicy: 'network-only',
    });
    if (response) {
      return response;
    }
    // throw Object.assign(
    //   new Error("Ha ocurrido un error"),
    //   { code: 500 }
    // );
  } catch (err) {
    return err;
  }
};

/**
 * getCountriesAction - action for get data the list of countries
 *
 * @returns {any}
 */
export const getCountriesAction = async () => {
  try {
    const response = await client.query({
      query: GET_COUNTRIES_QUERY,
      variables: {},
      fetchPolicy: 'network-only',
    });
    if (response) {
      return response;
    }
    // throw Object.assign(
    //   new Error("Ha ocurrido un error"),
    //   { code: 500 }
    // );
  } catch (err) {
    return err;
  }
};

/**
 * getPlans - action for get plans
 *
 * @returns {any}
 */
export const getPlans = async () => {
  try {
    const response = await client.query({
      query: GET_PLANS,
      variables: {},
      fetchPolicy: 'network-only',
    });
    if (response) {
      return response;
    }
    // throw Object.assign(
    //   new Error("Ha ocurrido un error"),
    //   { code: 500 }
    // );
  } catch (err) {
    return err;
  }
};