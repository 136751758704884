import React, { useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ButtonImg, ColorSquare, InfoDriverContainer, InfoTitleRoutes, SortableContainer, TextInfo } from "./truck-map-styles";
import { updateTaskAction, updateTaskdownloadAction } from "../../../screens/task-details/tasks-actions";
import { openAlert } from "../../../redux/reducers/app.ts";
import { useAppDispatch } from "../../../redux/store.ts";
import i18n from "../../../i18n/i18n.js";

const getItemStyle = (isDragging, draggableStyle, color) => ({
  userSelect: "none",
  margin: 8,
  background: !isDragging ? color : "grey",

  ...draggableStyle
});
const getListStyle = () => ({ width: 400 });

const SortableRoutes = ({tasks, MenuIcon, statusOption, date, reload, showTask}) => {
    const dispatch = useAppDispatch()

    const getHours = (tasks) => {
        let hours = 0

        for(let index in tasks) {
            const duration = tasks[index]?.unload?.task_duration 
            hours += duration;
        }
        
        return hours > 0 ? hours : 0
    }

    const onDragEnd = async (result) => {
        const { source, destination, draggableId } = result;

        if (!destination) return
        const sId = source.droppableId
        const sIndx = source.index + 1 
        const dId = destination.droppableId || null
        const dIndx = destination.index + 1

        let taskRouteDestination = tasks?.find(item => item._id === dId)
        let taskRouteSource = tasks?.find(item => item._id === sId)

        if (sId === dId) { 
            if(sIndx !== dIndx) {
                for(let index in taskRouteDestination.tasks) {
                    let task = taskRouteDestination.tasks
                    if(task[index].unload.order >= dIndx && (sIndx > dIndx || sId !== dId) && task[index].unload.order < task.length) await updateTaskdownloadAction(task[index]._id, { order: task[index].unload.order + 1 })
                }
    
                for(let index in taskRouteSource.tasks) {
                    let taskSource = taskRouteSource.tasks
                    if(taskSource[index].unload.order <= dIndx && taskSource[index].unload.order > 0 && (sIndx < dIndx || sId !== dId) && taskSource[index].unload.order - 1 > 0 ) await updateTaskdownloadAction(taskSource[index]._id, { order: taskSource[index].unload.order - 1 })
                }
            }
            await updateTaskdownloadAction(draggableId, { order: dIndx }) 

            reload(date)
            dispatch(openAlert({
                alertType: "success",
                alertMessage: i18n.t("TASKS.updatedSuccessMessage"),
                isAlertOpen: true
            }))
            return
        } 

        let filterTasks = taskRouteSource.tasks.filter(item => item._id !== draggableId)

        for(let index in filterTasks) {
            if(filterTasks[index].unload.order > sIndx && filterTasks[index].unload.order > 0) await updateTaskdownloadAction(filterTasks[index]._id, { order: filterTasks[index].unload.order - 1 })
        }

        await updateTaskAction(draggableId, { route: dId })
        await updateTaskdownloadAction(draggableId, { order: taskRouteDestination.tasks.length + 1 }) 

        reload(date)
        dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("TASKS.updatedSuccessMessage"),
            isAlertOpen: true
        }))
    }

    return (
        <div>
            <SortableContainer>
                <DragDropContext onDragEnd={onDragEnd}>
                {tasks.map((item, i) => (
                    <div key={i}>
                        <div style={{ display: 'flex' , alignItems: "center", justifyContent: 'space-between', paddingRight: 15}}>
                            <div>
                                {item.name !== 'unassigned' ?  
                                <InfoTitleRoutes>{ i18n.t('TASKS.forms.tasks.route') } - {item.name} - {item.driver}</InfoTitleRoutes> : 
                                <InfoTitleRoutes>{ i18n.t('TASKS.table.unassigned') }</InfoTitleRoutes>}
                            </div>
                            <div>
                                <InfoTitleRoutes>
                                    {item.tasks.length > 0 ? `${getHours(item.tasks)}min` : `0min`}
                                </InfoTitleRoutes>
                            </div>
                        </div>
                        <Droppable droppableId={`${item._id}`}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                {item.tasks.map((task, index) => (
                                    <Draggable
                                        key={task._id}
                                        draggableId={`${task._id}`}
                                        index={index}
                                    >
                                    {(provided, snapshot) => (
                                        <InfoDriverContainer 
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                                item?.color ? item?.color?.includes('#') ? item?.color : `#${item?.color}` : '#000000' 
                                            )}
                                            onDoubleClick={() => showTask(task)}
                                        >
                                            <ButtonImg style={{width: 20, marginLeft: 4, marginRight: 4}} src={MenuIcon}/>
                                            <TextInfo> { task.unload.delivery_object} - {task.unload.address.direction} - {task.unload.address.place}</TextInfo>
                                            <ColorSquare style={{ backgroundColor: statusOption[task.status.toUpperCase()], width: 20, height: 20, border: '2px solid white' }} />
                                        </InfoDriverContainer>
                                    )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                {
                                    item.tasks.length == 0 && <div style={{ fontWeight: "bold", textAlign: "center" }}> {i18n.t("GENERAL.noResults")} </div>
                                }
                                </div>
                            )}
                        </Droppable>
                    </div>
                ))}
                </DragDropContext>
            </SortableContainer>
        </div>
    )
}

export default SortableRoutes