import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';
// import { COLORS_TRUCK } from '../../utils/colors';

export const ProfileContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
// position: fixed;
overflow: auto;
margin-top: 25px; 
`;

export const ProfileContentContainer = styled.div`
width: 100%;
height: 100%;
flex-direction: column;
max-width: 1100px;
margin: auto;
padding-top: 30px;
`;

export const ProfileButtonsContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const ProfileFormContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
flex-direction: column;
`;

export const ProfileFormsContainer = styled.div`
width: 100%;
display: flex;
align-items: baseline;
justify-content: center;
flex-wrap: wrap;
flex-direction: row;

@media (max-width: 1200px) {
  align-items: center;
justify-content: center;
}
`;

export const ProfileTableContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

export const ProfileFormInputsContainer = styled.div`
width: 470px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const ProfileSmallButtonsGroup = styled.div`
// width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const ProfileLastButtonsContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;
max-width: 940px;

@media (max-width: 1200px) {
align-items: center;
justify-content: center;
}
`;

export const ProfileSmallButtonGroup = styled.div`
// width: 45%;
display: flex;
flex-direction: row;
align-items: center;
`;

export const FixedAmountText = styled.p`
padding: 0;
padding-right: 10px;
`;

export const TableTh = styled.th`
text-align: center;
`;

export const TableTd = styled.td`
padding-top: 5px;
padding-bottom: 5px;
text-align: center;
`;

export const ProfileTable = styled.table`
box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.08);
border-radius: 5px;
padding: 10px;
`;

export const ProfileFormSeparator = styled.div`
width: 100%;
background: #302521;
opacity: 0.1;
height: 2px;
margin-top: 20px;
margin-bottom: 20px;
`;

export const ProfileBillingContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: center;
padding-top: 20px;
padding-bottom: 20px;
@media (max-width: 400px) {
  padding: 0px;
}
`;

export const TableInfoContainer = styled.p`
margin-right: 20px;
display: flex;
// flex-direction: column;
`;

export const TableTitle = styled.div`
font-weight: bold;
@media (max-width: 800px) {
  margin-left: 20px;
}
`;

export const DriverDetailsVariblesItemContainer = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: center;
`;

export const DatesTaskInfoContainer = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: center;
min-width: 300px;
`;

export const DropdownContainer = styled.div`
border: 1px solid ${COLORS_TRUCK.ORANGE_TRUCK};
width: 100%;
max-width: 940px;
position: relative;
margin-bottom: 10px;
padding-bottom: 10px;
`;
export const DropdownHeader = styled.div`
position: relative;
background: ${COLORS_TRUCK.ORANGE_TRUCK};
display: flex;
flex-wrap: wrap;
flex-direction: row;
justify-content: space-between;
cursor: pointer;
width: 100%;
`;

export const MenuIcon = styled.img`
width: 30px;
height: 30px;
margin: 10px;
cursor: pointer;
`;

export const DownArrowIcon = styled.img`
width: 30px;
height: 30px;
margin: 10px;
cursor: pointer;
`;

export const RemoveIcon = styled.img`
width: 30px;
height: 30px;
margin: 10px;
cursor: pointer;
position: absolute;
right: -65px;
background: ${COLORS_TRUCK.ORANGE_TRUCK};
padding: 5px;
border-radius: 5px;
top: 0;
`;

export const GeneralErrorText = styled.p`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
margin: 5px;
`;

export const GeneralErrorContainer = styled.div`
display: flex;
align-items: center;
width: 100%;
justify-content: center;
`;

export const InputTitle = styled.p`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
font-size: 13px;
`;

export const ContainerImage = styled.div`
display: flex; 
background: ${COLORS_TRUCK.ORANGE_TRUCK};
align-items: center; 
cursor: pointer; 
justify-content: center; 
position: absolute; 
width: 25px; 
height: 25px;
bottom: 5px; 
border-radius: 100%;
`