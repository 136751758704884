import React, { useState, useEffect } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileButtonsContainer,
  ProfileFormContainer,
  ProfileFormInputsContainer,
  ProfileFormsContainer,
  ProfileLastButtonsContainer,
  FixedAmountText,
  ProfileSmallButtonGroup,
  ProfileSmallButtonsGroup,
  ProfileFormSeparator,
  DriverDetailsVariblesItemContainer,
  ClientInfoContainer,
  GeneralErrorContainer,
  GeneralErrorText,
  FileInputsContainer
} from "./driver-details-styles";
import i18n from '../../i18n/i18n';
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import UserCard from "../../components/user-card/UserCard";
import FormTextInput from "../../components/inputs/forms/form-text-input/FormTextInput";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import FormRadioButtonsInput from "../../components/inputs/forms/form-radio-buttons-input/FormRadioButtonsInput";
import passwordIcon from "../../assets/inputs/lock.svg";
import offEye from "../../assets/inputs/eye-off.svg";
import redEye from "../../assets/general/eye-icon-red.svg";
import FormDateInput from "../../components/inputs/forms/form-date-input/FormDateInput";
import { COLORS_TRUCK } from "../../utils/colors";
import { useAppDispatch } from "../../redux/store.ts";
import { openAlert } from "../../redux/reducers/app.ts";
import { emailsRegex } from "../../utils/regex";
import { getCoinAction } from "../profile/profile-actions";
import { createDriverAction, updateDriverAction, updateDriverStatusAction, updateDriverPicAction, removeDriverAction } from "./driver-details-actions";
import { getCitiesAction, getCountriesAction, getStatesAction } from "../signup/signup-actions";
import FormNumberInput from "../../components/inputs/forms/form-number-input/FormNumberInput";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { DRIVERS_ROUTE } from "../../routes/routes";
import { getDrivers } from "../driver-details/driver-details-actions";
import avatarDefault from "../../assets/drivers/user.png";
import { useSelector } from "react-redux";
import FormFileInput from "../../components/inputs/forms/form-file-input/FormTextInput";

const DriverDetailsScreen = () => {
  const isMenuOpen = useSelector((state) => state).appSlice.isMenuOpen
  const user = useSelector((state) => state).userSlice
  const dispatch = useAppDispatch()
  const [currentForm, setCurrentForm] = useState("info")
  const [loading, setLoading] = useState(false)
  const [countries, setCountries] = useState([])
  const [coin, setCoin] = useState({})
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const [countryIso2, setCountryIso2] = useState("")
  const [avatar, setAvatar] = useState(false)
  const [driverStatus, setDriverStatus] = useState("")
  const [driverId, setDriverId] = useState("")
  const [showInputsErrors, setShowInputsErrors] = useState(false)
  const [picture1, setPicture1] = useState(null)
  const [picture2, setPicture2] = useState(null)
  const [picture3, setPicture3] = useState(null)
  const [picture4, setPicture4] = useState(null)
  const [picture5, setPicture5] = useState(null)
  const [picture6, setPicture6] = useState(null)
  // const [isEdit, setIsEdit] = useState(false)
  const isMounted = React.useRef(true)
  const navigate = useNavigate();
  const { driverEmail } = useParams()
  //inputs errors
  const [personalEmailError, setPersonalEmailError] = useState("")
  const [accessEmailError, setAccessEmailError] = useState("")
  const [passwordLengthError, setPasswordLengthError] = useState("")
  const [passwordsError, setPasswordsError] = useState("")
  const [generalError, setGeneralError] = useState("")
  //licenses
  const [AM, setAM] = useState('');
  const [A1, setA1] = useState('');
  const [A2, setA2] = useState('');
  const [A, setA] = useState('');
  const [B, setB] = useState('');
  const [C1, setC1] = useState('');
  const [C, setC] = useState('');
  const [D1, setD1] = useState('');
  const [D, setD] = useState('');
  const [BE, setBE] = useState('');
  const [C1E, setC1E] = useState('');
  const [CE, setCE] = useState('');
  const [DIE, setDIE] = useState('');
  const [DE, setDE] = useState('');

  const [name, setName] = useState("")
  const [lastname, setLastname] = useState("")
  const [dni, setDni] = useState("")
  const [birthDate, setBirthDate] = useState()
  const [employerType, setEmployerType] = useState("")
  const [country, setCountry] = useState("")
  const [province, setProvince] = useState("")
  const [city, setCity] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [address, setAddress] = useState("")
  //access
  const [companyPhone, setCompanyPhone] = useState("")
  const [personalPhone, setPersonalPhone] = useState("")
  const [personalEmail, setPersonalEmail] = useState("")
  const [accessEmail, setAccessEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordShow, setPasswordShow] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [secondPasswordShow, setSecondPasswordShow] = useState("")
  //doc
  const [sanitaryCard, setSanitaryCard] = useState("")
  const [sanitaryCardDate, setSanitaryCardDate] = useState("")
  const [sanitaryCardEuropean, setSanitaryCardEuropean] = useState("")
  const [sanitaryCardEuropeanDate, setSanitaryCardEuropeanDate] = useState("")
  const [cap, setCap] = useState("")
  const [capDate, setCapDate] = useState("")
  const [tachographCard, setTachographCard] = useState("")
  const [tachographCardDate, setTachographCardDate] = useState("")
  //variables
  const [fixedRemuneration, setFixedRemuneration] = useState("")
  const [nationalComplete, setNationalComplete] = useState("")
  const [internationalComplete, setInternationalComplete] = useState("")
  const [payPerKm, setPayPerKm] = useState("")
  const [lunchNational, setLunchNational] = useState("")
  const [lunchInternational, setLunchInternational] = useState("")
  const [dinnerNational, setDinnerNational] = useState("")
  const [dinnerInternational, setDinnerInternational] = useState("")
  const [nocturnalNational, setNocturnalNational] = useState("")
  const [nocturnalInternational, setNocturnalInternational] = useState("")
  const [paymentType, setPaymentType] = useState("")
  const [values, setValues] = useState("")
  const [sunday, setSunday] = useState("")
  const [taskDuration, setTaskDuration] = useState("")

  useEffect(async () => {
    await getCoin()
    const countries = await getCountries()
    const iso2 = countries?.filter(item => item.value === user?.country)[0]?.iso2
    if(iso2){
      await getStates(iso2)
      await getCities(iso2, user?.state)
    }

    if (driverEmail) {
      getDriverData(driverEmail)
    }

    return () => {
      isMounted.current = false;
    };
  }, [country, countryIso2, driverEmail])

  const handleDates = (date) => date ? moment(date, "DD-MM-YYYY") : ""
  const getDriverData = async (driverEmail) => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: 30,
          offset: 0
        },
        email: driverEmail,
        driver_id: null
      }
      const drivers = await getDrivers(data)
      const driver = drivers?.data?.myDrivers[0]
      const iso2 = countries.filter(item => item.value === driver?.country)[0]?.iso2
      await getStates(iso2)

      setAddress(driver?.address)
      setBirthDate(handleDates(driver?.birthday))
      setCap(driver?.documentation.CAP.card)
      setCapDate(handleDates(driver?.documentation.CAP.date))
      setCity(city ? city : driver?.city)
      setProvince(province ? province : driver?.state)
      setCountry(country ? country : driver?.country)
      setCountryIso2(driver?.country)
      setDni(driver?.dni)
      setEmployerType(driver?.type.toUpperCase())
      setLastname(driver?.surnames)
      setName(driver?.name)
      console.log('driver.payment_variables_id', driver.payment_variables_id)
      setDriverStatus(driver?.status)

      setNocturnalInternational(driver?.payment_variables_id.diets.nocturnal_international === 0 ? "" : driver?.payment_variables_id.diets.nocturnal_international)
      setNocturnalNational(driver?.payment_variables_id.diets.nocturnal === 0 ? "" : driver?.payment_variables_id.diets.nocturnal)
      setFixedRemuneration(driver?.payment_variables_id?.fixed_remuneration === 0 ? "" : driver?.payment_variables_id?.fixed_remuneration)
      setNationalComplete(driver?.payment_variables_id?.complete_national === 0 ? "" : driver?.payment_variables_id?.complete_national)
      setInternationalComplete(driver?.payment_variables_id?.complete_international === 0 ? "" : driver?.payment_variables_id?.complete_international)
      setLunchNational(driver?.payment_variables_id.diets.lunch === 0 ? "" : driver?.payment_variables_id.diets.lunch)
      setSunday(driver?.payment_variables_id.diets.sunday === 0 ? "" : driver?.payment_variables_id.diets.sunday)
      setTaskDuration(driver?.payment_variables_id.task_duration === 0 ? "" : driver?.payment_variables_id.task_duration)
      setLunchInternational(driver?.payment_variables_id.diets.lunch_international === 0 ? "" : driver?.payment_variables_id.diets.lunch_international)
      setDinnerNational(driver?.payment_variables_id.diets.dinner === 0 ? "" : driver?.payment_variables_id.diets.dinner)
      setDinnerInternational(driver?.payment_variables_id.diets.dinner_international === 0 ? "" : driver?.payment_variables_id.diets.dinner_international)
      setPayPerKm(driver?.payment_variables_id?.payment_per_km === 0 ? "" : driver?.payment_variables_id?.payment_per_km)

      setPaymentType(driver?.payment_variables_id?.payment_type.toUpperCase())
      setPersonalEmail(driver?.personal_email)
      setPersonalPhone(driver?.phone)
      setPostalCode(driver?.zip)
      setSanitaryCard(driver?.documentation.sanitary_card.card)
      setSanitaryCardDate(handleDates(driver?.documentation.sanitary_card.date))
      setSanitaryCardEuropean(driver?.documentation.sanitary_card_EU.card)
      setSanitaryCardEuropeanDate(handleDates(driver?.documentation.sanitary_card_EU.date))
      setSecondPasswordShow("")
      setPasswordShow("")
      setSecondPasswordShow("")
      setValues(driver?.payment_variables_id.values)
      setAccessEmail(driver?.email)
      setTachographCard(driver?.documentation.tachograph_card.card)
      setTachographCardDate(handleDates(driver?.documentation.tachograph_card.date))
      setAvatar(driver?.avatar?.fullpath)
      setDriverId(driver?.documentation.driver_id)
      setCompanyPhone(driver?.company_phone)
      setAM(handleDates(driver?.documentation.driving_cards.AM))
      setA1(handleDates(driver?.documentation.driving_cards.A1))
      setA2(handleDates(driver?.documentation.driving_cards.A2))
      setA(handleDates(driver?.documentation.driving_cards.A))
      setB(handleDates(driver?.documentation.driving_cards.B))
      setC1(handleDates(driver?.documentation.driving_cards.C1))
      setC(handleDates(driver?.documentation.driving_cards.C))
      setD1(handleDates(driver?.documentation.driving_cards.D1))
      setD(handleDates(driver?.documentation.driving_cards.D))
      setBE(handleDates(driver?.documentation.driving_cards.BE))
      setC1E(handleDates(driver?.documentation.driving_cards.C1E))
      setCE(handleDates(driver?.documentation.driving_cards.CE))
      setDIE(handleDates(driver?.documentation.driving_cards.D1E))
      setDE(handleDates(driver?.documentation.driving_cards.DE))

      if (driver?.documentation.CAP_images.length > 0 &&
        driver?.documentation.CAP_images.filter(item => item.face === "FRONT").length > 0) {
        setPicture1(driver?.documentation.CAP_images.filter(item => item.face === "FRONT")[0].fullpath)
      }
      if (driver?.documentation.CAP_images.length > 0 &&
        driver?.documentation.CAP_images.filter(item => item.face === "BACK").length > 0) {
        setPicture4(driver?.documentation.CAP_images.filter(item => item.face === "BACK")[0].fullpath)
      }

      if (driver?.documentation.driving_cards_images.length > 0 &&
        driver?.documentation.driving_cards_images.filter(item => item.face === "FRONT").length > 0) {
        setPicture2(driver?.documentation.driving_cards_images.filter(item => item.face === "FRONT")[0].fullpath)
      }
      if (driver?.documentation.driving_cards_images.length > 0 &&
        driver?.documentation.driving_cards_images.filter(item => item.face === "BACK").length > 0) {
        setPicture5(driver?.documentation.driving_cards_images.filter(item => item.face === "BACK")[0].fullpath)
      }

      if (driver?.documentation.tachograph_card_images.length > 0 &&
        driver?.documentation.tachograph_card_images.filter(item => item.face === "FRONT").length > 0) {
        setPicture3(driver?.documentation.tachograph_card_images.filter(item => item.face === "FRONT")[0].fullpath)
      }
      if (driver?.documentation.tachograph_card_images.length > 0 &&
        driver?.documentation.tachograph_card_images.filter(item => item.face === "BACK").length > 0) {
        setPicture6(driver?.documentation.tachograph_card_images.filter(item => item.face === "BACK")[0].fullpath)
      }


      await getCities(iso2, driver?.state)

      setLoading(false)
    } catch (e) {
      console.log('e', e)
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
      navigate(DRIVERS_ROUTE)
    }
  }

  const getCities = async (country, province) => {
    try {
      const response = await getCitiesAction(country, province)
      const cities = response?.data?.cities?.map(city => { return { label: city.name, value: city._id } })
      setCities(cities)
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const getStates = async (country) => {
    try {
      if (country) {
        const response = await getStatesAction(country)
        setStates(response?.data?.state.map(state => { return { label: state.translate[0].langs[localStorage.getItem("language") || "es"], value: state._id } }))
      }
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const getCoin = async () => {
    try {
      const response = await getCoinAction()
      const cointSelected = response.data.findAllCoins.filter( coin => coin._id == user.coin)

      if(cointSelected.length > 0) setCoin(cointSelected[0])
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const getCountries = async () => {
    try {
      const response = await getCountriesAction()
      if (response && response.data) {
        setCountries(response.data.countries.map(country => { return { label: country.translate[0].langs[localStorage.getItem("language") || "es"], value: country._id, iso2: country.iso2 } }))
      }
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const changeDriverStatus = async (value) => {
    try {
      await updateDriverStatusAction(driverId, value)
      getDriverData()
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const setDrivingCard = (value, name) => {
    if (name === 'AM') { setAM(value) }
    if (name === 'A1') { setA1(value) }
    if (name === 'A2') { setA2(value) }
    if (name === 'A') { setA(value) }
    if (name === 'B') { setB(value) }
    if (name === 'C1') { setC1(value) }
    if (name === 'C') { setC(value) }
    if (name === 'D1') { setD1(value) }
    if (name === 'D') { setD(value) }
    if (name === 'BE') { setBE(value) }
    if (name === 'C1E') { setC1E(value) }
    if (name === 'CE') { setCE(value) }
    if (name === 'DIE') { setDIE(value) }
    if (name === 'DE') { setDE(value) }
  }

  const isInfoFormCompleted = () => {
    if ( name &&  lastname && employerType && country && province ) {
      setGeneralError("")
      setShowInputsErrors(false)
      return true
    } else {
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      setCurrentForm("info")
      return false
    }
  }

  const isAccessFormCompleted = () => {
    const personalEmailValidation = personalEmail && !personalEmail.match(emailsRegex)
    const accessEmailValidation = accessEmail && !accessEmail.match(emailsRegex)
    const passwordLengthValidation = password && password.length < 8
    const passwordsValidation = password && confirmPassword && confirmPassword !== password
    if (personalEmailValidation) {
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      setPersonalEmailError(i18n.t("DRIVER_DETAILS.errors.contactEmailInvalid"))
      setCurrentForm("access")
      return false;
    } else {
      setPersonalEmailError("")
    }


    if (accessEmailValidation) {
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      setAccessEmailError(i18n.t("DRIVER_DETAILS.errors.accessEmailInvalid"))
      setCurrentForm("access")
      return false;
    } else {
      setAccessEmailError("")
    }


    if (passwordLengthValidation) {
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      setPasswordLengthError(i18n.t("DRIVER_DETAILS.errors.passwordLength"))
      setCurrentForm("access")
      return false;
    } else {
      setPasswordLengthError("")
    }

    if (passwordsValidation) {
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setShowInputsErrors(true)
      setPasswordsError(i18n.t("DRIVER_DETAILS.errors.passwordDontMatch"))
      setCurrentForm("access")
      return false;
    } else {
      setPasswordsError("")
    }

    if ( accessEmail && (driverEmail ? true : password) && (driverEmail ? true : confirmPassword)) {
      return true
    } else {
      setShowInputsErrors(true)
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      return false
    }
  }

  const isVariablesFormCompleted = () => {
    if ( paymentType && (employerType === "INTERNAL" ? values : true)) {
      setShowInputsErrors(false)
      setGeneralError("")
      return true
    } else {
      setShowInputsErrors(true)
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setCurrentForm("variables")
      return false
    }
  }

  const onEditPicture = async (img) => {
    try {
      await updateDriverPicAction(driverId, img)
      getDriverData()
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const createDriver = async () => {
    try {
      setLoading(true)
      let data = {
        address: address || null,
        birthday: birthDate || null,
        city: city || null,
        company_phone: companyPhone ? String(companyPhone) : null,
        country: country || null,
        dni: dni || null,
        documentation: {
          CAP: { card: cap || null, date: capDate ? moment(capDate).format('DD-MM-YYYY') : null },
          driving_cards: {
            AM: AM ? moment(AM).format('DD-MM-YYYY') : null,
            A1: A1 ? moment(A1).format('DD-MM-YYYY') : null,
            A2: A2 ? moment(A2).format('DD-MM-YYYY') : null,
            A: A ? moment(A).format('DD-MM-YYYY') : null,
            B: B ? moment(B).format('DD-MM-YYYY') : null,
            C1: C1 ? moment(C1).format('DD-MM-YYYY') : null,
            C: C ? moment(C).format('DD-MM-YYYY') : null,
            D1: D1 ? moment(D1).format('DD-MM-YYYY') : null,
            D: D ? moment(D).format('DD-MM-YYYY') : null,
            BE: BE ? moment(BE).format('DD-MM-YYYY') : null,
            C1E: C1E ? moment(C1E).format('DD-MM-YYYY') : null,
            CE: CE ? moment(CE).format('DD-MM-YYYY') : null,
            D1E: DIE ? moment(DIE).format('DD-MM-YYYY') : null,
            DE: DE ? moment(DE).format('DD-MM-YYYY') : null,
          },
          sanitary_card: { card: sanitaryCard || null, date: sanitaryCardDate ? moment(sanitaryCardDate).format('DD-MM-YYYY') : null },
          sanitary_card_EU: { card: sanitaryCardEuropean || null, date: sanitaryCardEuropeanDate ? moment(sanitaryCardEuropeanDate).format('DD-MM-YYYY') : null },
          tachograph_card: { card: tachographCard || null, date: tachographCardDate ? moment(tachographCardDate).format('DD-MM-YYYY') : null },
        },
        email: accessEmail || null,
        name: name || null,
        password: password || null,
        payment_variables: {
          complete_international: internationalComplete ? Number(internationalComplete) : 0,
          complete_national: nationalComplete ? Number(nationalComplete) : 0,
          diets: {
            dinner: dinnerNational ? Number(dinnerNational) : 0,
            dinner_international: dinnerInternational ? Number(dinnerInternational) : 0,
            lunch: lunchNational ? Number(lunchNational) : 0,
            lunch_international: lunchInternational ? Number(lunchInternational) : 0,
            nocturnal: nocturnalNational ? Number(nocturnalNational) : 0,
            nocturnal_international: nocturnalInternational ? Number(nocturnalInternational) : 0,
            sunday: sunday ? Number(sunday) : 0
          },
          fixed_remuneration: fixedRemuneration ? Number(fixedRemuneration) : 0,
          payment_per_km: payPerKm ? Number(payPerKm) : 0,
          payment_type: paymentType ? paymentType : null,
          values: values || null,
          task_duration: taskDuration ? Number(taskDuration) : 0
        },
        personal_email: personalEmail || null,
        phone: personalPhone ? String(personalPhone) : null,
        state: province || null,
        surnames: lastname || null,
        type: employerType || null,
        zip: postalCode || null
      }

      let files = [ { }, { }, { } ]

      if (picture1?.name) {
        files[0].card = "CAP"
        files[0].file_front = picture1
      }
      if (picture4?.name) {
        files[0].card = "CAP"
        files[0].file_back = picture4
      }
      if (picture2?.name) {
        files[1].card = "DRIVING_CARD"
        files[1].file_front = picture2
      }
      if (picture5?.name) {
        files[1].card = "DRIVING_CARD"
        files[1].file_back = picture5
      }
      if (picture3?.name) {
        files[2].card = "TACHOGRAPH"
        files[2].file_front = picture3
      }
      if (picture6?.name) {
        files[2].card = "TACHOGRAPH"
        files[2].file_back = picture6
      }

      if (!driverEmail) {
        const driver = await createDriverAction(data, files?.filter(item => item.card))
        if (avatar && driver?.data?.createDriver?._id) await updateDriverPicAction(driver?.data?.createDriver?._id, avatar)
      } else await updateDriverAction(driverId, data, files.filter(item => item.card))

      setLoading(false)
      if (!driverEmail) {
        dispatch(openAlert({
          alertType: "success",
          alertMessage: i18n.t("DRIVER_DETAILS.successMessage"),
          isAlertOpen: true
        }))
        navigate(DRIVERS_ROUTE)
      } else {
        dispatch(openAlert({
          alertType: "success",
          alertMessage: i18n.t("DRIVER_DETAILS.updatedSuccessMessage"),
          isAlertOpen: true
        }))
      }
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const onEditPicture1 = async (img) => {
    setPicture1(img)

  }
  const onEditPicture2 = async (img) => {
    setPicture2(img)

  }
  const onEditPicture3 = async (img) => setPicture3(img)

  const onEditPicture4 = async (img) => setPicture4(img)

  const onEditPicture5 = async (img) => setPicture5(img)

  const onEditPicture6 = async (img) => setPicture6(img)

  const removeDriver = async () => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: i18n.t("DRIVER_DETAILS.areYouSureToRemove"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("GENERAL.remove"),
      alertOnYes: async () => {
        try {
          setLoading(true)
          await removeDriverAction(driverId)
          dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("DRIVER_DETAILS.removedSuccessMessage"),
            isAlertOpen: true
          }))
          setLoading(false)
          navigate(DRIVERS_ROUTE)
        } catch (e) {
          setLoading(false)
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true
          }))
        }
      }
    }))
  }

  return (
    <ProfileContainer style={{ position: isMenuOpen && 'relative' }}>
      <ProfileContentContainer>
        <ProfileButtonsContainer>
          <CategoryButton
            onClick={() => !loading ? setCurrentForm("info") : null}
            active={currentForm === "info"}
            width={200}
            icon="info"
            title={i18n.t('DRIVER_DETAILS.buttons.info')}
          />
          <CategoryButton
            onClick={() => isInfoFormCompleted() && !loading ? setCurrentForm("access") : null}
            active={currentForm === "access"}
            width={200}
            icon="access"
            title={i18n.t('DRIVER_DETAILS.buttons.contact')}
          />
          {employerType !== "EXTERNAL" && <CategoryButton
            onClick={() => isInfoFormCompleted() && isAccessFormCompleted() && !loading ? setCurrentForm("doc") : null}
            active={currentForm === "doc"}
            width={200}
            icon="doc"
            title={i18n.t('DRIVER_DETAILS.buttons.doc')}
          />}
          <CategoryButton
            onClick={() => isInfoFormCompleted() && isAccessFormCompleted() && !loading ? setCurrentForm("variables") : null}
            active={currentForm === "variables"}
            width={200}
            icon="variables"
            title={i18n.t('DRIVER_DETAILS.buttons.paymentVaribles')}
          />
        </ProfileButtonsContainer>
        <ProfileFormSeparator />
        {generalError && <GeneralErrorContainer>
          <GeneralErrorText>{generalError}</GeneralErrorText>
        </GeneralErrorContainer>}
        <ProfileFormContainer>
          {currentForm === "info"
            ? <>
              <ClientInfoContainer>
                <UserCard
                  user={{ name: name, position: driverEmail || accessEmail, picture: avatar || avatarDefault }}
                  onEditPicture={(img) => {
                    if (driverEmail) onEditPicture(img)
                    else  setAvatar(img)
                  }}
                  borders={false}
                />
                {driverEmail && <FormSelect
                  value={driverStatus}
                  containerStyle={{ width: 150, margin: 'auto' }}
                  defaultValue={''}
                  onChange={({ target: { value } }) => {
                    setDriverStatus(value)
                    if (driverEmail) changeDriverStatus(value)
                  }}
                  disabled={loading}
                  showError={!driverStatus && showInputsErrors}
                  items={[
                    {
                      value: "ACTIVE",
                      label: <p style={{ color: COLORS_TRUCK.GREEN_TRUCK, margin: 0 }}> {i18n.t("GENERAL.active")} </p>,
                    },
                    {
                      value: "INACTIVE",
                      label: <p style={{  color: COLORS_TRUCK.ORANGE_TRUCK, margin: 0 }}> {i18n.t("GENERAL.inactive")} </p>,
                    },
                  ]}
                  required
                />}
              </ClientInfoContainer>
              <ProfileFormsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    title={i18n.t('DRIVER_DETAILS.forms.info.name')}
                    type={"text"}
                    value={name}
                    onChange={({ target: { value } }) => setName(value)}
                    disabled={loading}
                    showError={!name && showInputsErrors}
                    allowJustLetters
                    required
                  />
                  <FormTextInput
                    title={i18n.t('DRIVER_DETAILS.forms.info.lastname')}
                    type={"text"}
                    value={lastname}
                    onChange={({ target: { value } }) => setLastname(value)}
                    disabled={loading}
                    showError={!lastname && showInputsErrors}
                    allowJustLetters
                    required
                  />
                  <FormTextInput
                    title={i18n.t('DRIVER_DETAILS.forms.info.dni')}
                    type={"text"}
                    value={dni}
                    onChange={({ target: { value } }) => setDni(value)}
                    disabled={loading}
                  />
                  <FormDateInput
                    title={i18n.t('DRIVER_DETAILS.forms.info.birthDate')}
                    value={birthDate}
                    disabled={loading}
                    onChange={(newValue) => {
                      setBirthDate(newValue);
                    }}
                    maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                  />
                  <FormRadioButtonsInput
                    title={i18n.t('DRIVER_DETAILS.forms.info.employerType')}
                    defaultValue=""
                    value={employerType}
                    onChange={({ target: { value } }) => {
                      if (value === "EXTERNAL") setPaymentType("MILEAGE")

                      setEmployerType(value)
                    }}
                    items={[
                      { value: 'INTERNAL', label: i18n.t('DRIVER_DETAILS.forms.info.employerType1intern') },
                      { value: 'EXTERNAL', label: i18n.t('DRIVER_DETAILS.forms.info.employerType2external') },
                    ]}
                    disabled={loading}
                    showError={!employerType && showInputsErrors}
                    required
                  />
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer>
                  <FormSelect
                    disabled={loading}
                    title={i18n.t('DRIVER_DETAILS.forms.info.country')}
                    value={country}
                    onChange={({ target: { value, iso2 } }) => {
                      setCountry(value)
                      setCountryIso2(iso2)
                      getStates(iso2)
                    }}
                    items={countries}
                    showError={!country && showInputsErrors}
                    required
                  />
                  {(country && states) && <FormSelect
                    disabled={loading}
                    title={i18n.t('DRIVER_DETAILS.forms.info.province')}
                    value={province}
                    onChange={({ target: { value } }) => {
                      setProvince(value)
                      getCities(countryIso2, value)
                    }}
                    items={states}
                    showError={!province && showInputsErrors}
                    required
                  />}
                  {(country && province && cities) && <FormSelect
                    disabled={loading}
                    title={i18n.t('DRIVER_DETAILS.forms.info.city')}
                    value={city}
                    onChange={({ target: { value } }) => setCity(value)}
                    items={cities}
                  />}
                  <FormTextInput
                    title={i18n.t('DRIVER_DETAILS.forms.info.postalCode')}
                    value={postalCode}
                    onChange={({ target: { value } }) => setPostalCode(value)}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={i18n.t('DRIVER_DETAILS.forms.info.address')}
                    value={address}
                    onChange={({ target: { value } }) => setAddress(value)}
                    disabled={loading}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormSeparator />
                <ProfileLastButtonsContainer>
                  {driverEmail && <CategoryButton
                    onClick={() => removeDriver()}
                    active={false}
                    icon=""
                    title={i18n.t('DRIVER_DETAILS.buttons.remove')}
                    disabled={loading}
                  />}
                  <CategoryButton
                    onClick={() => navigate(DRIVERS_ROUTE)}
                    active={false}
                    icon=""
                    title={i18n.t('PROFILE.buttons.cancel')}
                    loading={loading}
                  />
                  <CategoryButton
                    loading={loading}
                    onClick={async () => {
                      if (isInfoFormCompleted()) {
                        if (driverEmail && driverEmail === accessEmail) return await createDriver()
                        return setCurrentForm("access")
                      }
                    }}
                    active
                    icon=""
                    title={i18n.t('PROFILE.buttons.save')}
                  />
                </ProfileLastButtonsContainer>
              </ProfileFormsContainer>
            </> : null}
          {currentForm === "access"
            ? 
              <ProfileFormsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    title={i18n.t('DRIVER_DETAILS.forms.access.companyPhone')}
                    value={companyPhone}
                    onChange={({ target: { value } }) => setCompanyPhone(value)}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={i18n.t('DRIVER_DETAILS.forms.access.personalPhone')}
                    value={personalPhone}
                    onChange={({ target: { value } }) => setPersonalPhone(value)}
                    disabled={loading}
                  />
                  <FormTextInput
                    title={i18n.t('DRIVER_DETAILS.forms.access.personalEmail')}
                    type={"email"}
                    value={personalEmail}
                    errorMessage={personalEmailError}
                    onChange={({ target: { value } }) => setPersonalEmail(value)}
                  />
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer>
                  <FormTextInput
                    title={i18n.t('DRIVER_DETAILS.forms.access.accessEmail')}
                    type={"email"}
                    value={accessEmail}
                    errorMessage={accessEmailError}
                    onChange={({ target: { value } }) => setAccessEmail(value)}
                    showError={!accessEmail && showInputsErrors}
                    required
                  />
                  <FormTextInput
                    title={i18n.t('DRIVER_DETAILS.forms.access.password')}
                    iconRightClick={() => setPasswordShow(!passwordShow)}
                    rightIcon={passwordShow ? offEye : redEye}
                    icon={passwordIcon}
                    type={passwordShow ? "text" : "password"}
                    value={password}
                    errorMessage={passwordLengthError || passwordsError}
                    onChange={({ target: { value } }) => setPassword(value)}
                    showError={!password && showInputsErrors}
                    required
                  />
                  <FormTextInput
                    title={i18n.t('DRIVER_DETAILS.forms.access.confirmPassword')}
                    iconRightClick={() => setSecondPasswordShow(!secondPasswordShow)}
                    rightIcon={secondPasswordShow ? offEye : redEye}
                    icon={passwordIcon}
                    errorMessage={passwordLengthError || passwordsError}
                    type={secondPasswordShow ? "text" : "password"}
                    value={confirmPassword}
                    onChange={({ target: { value } }) => setConfirmPassword(value)}
                    showError={!confirmPassword && showInputsErrors}
                    required
                  />
                </ProfileFormInputsContainer>
                <ProfileFormSeparator />
                <ProfileLastButtonsContainer>
                  <CategoryButton
                    onClick={() => navigate(DRIVERS_ROUTE)}
                    active={false}
                    icon=""
                    title={i18n.t('PROFILE.buttons.cancel')}
                    loading={loading}
                  />
                  <CategoryButton
                    onClick={async () => {
                      if (isInfoFormCompleted() && isAccessFormCompleted()) {
                        if (driverEmail && String(driverEmail) === String(accessEmail)) {
                          await createDriver()
                        } else {
                          if (employerType !== "EXTERNAL") return setCurrentForm("doc")
                          
                          return setCurrentForm("variables")
                        }
                      }
                    }}
                    active
                    icon=""
                    title={i18n.t('PROFILE.buttons.save')}
                    loading={loading}
                  />
                </ProfileLastButtonsContainer>
              </ProfileFormsContainer>
               : null}
          {currentForm === "doc" && employerType !== "EXTERNAL"
            ?
              <ProfileFormsContainer>
                <ProfileFormInputsContainer style={{ marginTop: 10 }}>
                  {[
                    { name: 'AM', value: AM },
                    { name: 'A1', value: A1 },
                    { name: 'A2', value: A2 },
                    { name: 'A', value: A },
                    { name: 'B', value: B },
                    { name: 'C1', value: C1 },
                    { name: 'C', value: C },
                    { name: 'D1', value: D1 },
                    { name: 'D', value: D },
                    { name: 'BE', value: BE },
                    { name: 'C1E', value: C1E },
                    { name: 'CE', value: CE },
                    { name: 'DIE', value: DIE },
                    { name: 'DE', value: DE },
                  ].map((licence, i) =>
                    <FormDateInput
                      key={i}
                      value={licence.value}
                      fatherContainer={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                      containerStyle={{ width: 185, }}
                      disabled={loading}
                      leftComponent={<p style={{ width: 20, margin: 10 }}>{licence.name}</p>}
                      onChange={(value) => { setDrivingCard(value, licence.name); }}
                    />
                  )}
                </ProfileFormInputsContainer>
                <ProfileFormInputsContainer>
                  <DriverDetailsVariblesItemContainer>
                    <FormTextInput
                      title={i18n.t('DRIVER_DETAILS.forms.doc.sanitaryCard')}
                      type={"text"}
                      value={sanitaryCard}
                      onChange={({ target: { value } }) => setSanitaryCard(value)}
                      containerStyle={{ width: 250 }}
                    />
                    <FormDateInput
                      containerStyle={{ width: 150, marginTop: 40 }}
                      value={sanitaryCardDate}
                      onChange={(newValue) => setSanitaryCardDate(newValue)}
                    />
                  </DriverDetailsVariblesItemContainer>
                  <DriverDetailsVariblesItemContainer>
                    <FormTextInput
                      title={i18n.t('DRIVER_DETAILS.forms.doc.sanitaryCardEuropean')}
                      type={"text"}
                      value={sanitaryCardEuropean}
                      onChange={({ target: { value } }) => setSanitaryCardEuropean(value)}
                      containerStyle={{ width: 250 }}
                    />
                    <FormDateInput
                      containerStyle={{ width: 150, marginTop: 40 }}
                      value={sanitaryCardEuropeanDate}
                      onChange={(newValue) => {
                        setSanitaryCardEuropeanDate(newValue);
                      }}
                    />
                  </DriverDetailsVariblesItemContainer>
                  <DriverDetailsVariblesItemContainer>
                    <FormTextInput
                      title={i18n.t('DRIVER_DETAILS.forms.doc.cap')}
                      type={"text"}
                      value={cap}
                      onChange={({ target: { value } }) => setCap(value)}
                      containerStyle={{ width: 250 }}
                    />
                    <FormDateInput
                      containerStyle={{ width: 150, marginTop: 40 }}
                      value={capDate}
                      onChange={(newValue) => setCapDate(newValue)}
                    />
                  </DriverDetailsVariblesItemContainer>
                  <DriverDetailsVariblesItemContainer>
                    <FormTextInput
                      title={i18n.t('DRIVER_DETAILS.forms.doc.tachographCard')}
                      type={"text"}
                      value={tachographCard}
                      onChange={({ target: { value } }) => setTachographCard(value)}
                      containerStyle={{ width: 250 }}
                    />
                    <FormDateInput
                      containerStyle={{ width: 150, marginTop: 40 }}
                      value={tachographCardDate}
                      onChange={(newValue) => setTachographCardDate(newValue)}
                    />
                  </DriverDetailsVariblesItemContainer>
                  <FileInputsContainer style={{ maxWidth: 400 }}>
                    <FormFileInput title={i18n.t('DRIVER_DETAILS.capFront')} picture={picture1} onEditPicture={onEditPicture1} />
                    <FormFileInput title={i18n.t('DRIVER_DETAILS.drivingCardFront')} picture={picture2} onEditPicture={onEditPicture2} />
                    <FormFileInput title={i18n.t('DRIVER_DETAILS.tachographFront')} picture={picture3} onEditPicture={onEditPicture3} />
                    <FormFileInput title={i18n.t('DRIVER_DETAILS.back')} picture={picture4} onEditPicture={onEditPicture4} />
                    <FormFileInput title={i18n.t('DRIVER_DETAILS.back')} picture={picture5} onEditPicture={onEditPicture5} />
                    <FormFileInput title={i18n.t('DRIVER_DETAILS.back')} picture={picture6} onEditPicture={onEditPicture6} />
                  </FileInputsContainer>
                </ProfileFormInputsContainer>
                <ProfileFormSeparator />
                <ProfileLastButtonsContainer>
                  <CategoryButton
                    onClick={() => navigate(DRIVERS_ROUTE)}
                    active={false}
                    icon=""
                    title={i18n.t('PROFILE.buttons.cancel')}
                    loading={loading}
                  />
                  <CategoryButton
                    onClick={() => {
                      if (isInfoFormCompleted() && isAccessFormCompleted()) {
                        if (driverEmail && driverEmail === accessEmail) return createDriver()

                        return setCurrentForm("variables")
                      }
                    }}
                    active
                    icon=""
                    title={i18n.t('PROFILE.buttons.save')}
                    loading={loading}
                  />
                </ProfileLastButtonsContainer>
              </ProfileFormsContainer>
            : null}
          {currentForm === "variables"
            ? 
              <ProfileFormsContainer>
                <ProfileFormInputsContainer>
                  <FormRadioButtonsInput
                    title={i18n.t('DRIVER_DETAILS.forms.variables.paymentType')}
                    value={paymentType}
                    defaultValue=""
                    onChange={({ target: { value } }) => setPaymentType(value)}
                    showError={!paymentType && showInputsErrors}
                    items={employerType === "EXTERNAL" ? [
                      { value: 'MILEAGE', label: i18n.t('DRIVER_DETAILS.forms.variables.paymentType2km') },
                    ] : [
                      { value: 'DIETS', label: i18n.t('DRIVER_DETAILS.forms.variables.paymentType1diets') },
                      { value: 'MILEAGE', label: i18n.t('DRIVER_DETAILS.forms.variables.paymentType2km') },
                      { value: 'BOTH', label: i18n.t('DRIVER_DETAILS.forms.variables.both') },
                    ]}
                    required
                    disabled={loading}
                  />
                </ProfileFormInputsContainer>
                {employerType !== "EXTERNAL" && <ProfileFormInputsContainer>
                  <FormRadioButtonsInput
                    title={i18n.t('DRIVER_DETAILS.forms.variables.values')}
                    value={values}
                    defaultValue=""
                    onChange={({ target: { value } }) => {
                      if (value === "DEFAULT") {
                        setFixedRemuneration(user.payment_variables?.fixed_remuneration)
                        setLunchNational(user.payment_variables?.diets?.lunch)
                        setLunchInternational(user.payment_variables?.diets?.lunch_international)
                        setDinnerNational(user.payment_variables?.diets?.dinner)
                        setDinnerInternational(user.payment_variables?.diets?.dinner_international)
                        setSunday(user.payment_variables?.diets?.sunday)
                        setTaskDuration(user.payment_variables?.task_duration)
                        setNocturnalNational(user.payment_variables?.diets?.nocturnal)
                        setNocturnalInternational(user.payment_variables?.diets?.nocturnal_international)
                        setNationalComplete(user.payment_variables?.complete_national)
                        setInternationalComplete(user.payment_variables?.complete_international)
                        setPayPerKm(user.payment_variables?.payment_per_km)
                      }

                      setValues(value)
                    }}
                    showError={!values && showInputsErrors}
                    items={[
                      { value: 'DEFAULT', label: i18n.t('DRIVER_DETAILS.forms.variables.values1byDefault') },
                      { value: 'PERSONALIZED', label: i18n.t('DRIVER_DETAILS.forms.variables.values2custom') },
                    ]}
                    required
                    disabled={loading}
                  />
                </ProfileFormInputsContainer>}
                <ProfileFormSeparator />
                <ProfileFormInputsContainer>
                  <FormNumberInput
                    title={i18n.t('DRIVER_DETAILS.forms.variables.fixedRemuneration')}
                    value={fixedRemuneration}
                    onValueChange={(value) => {
                      setFixedRemuneration(value)
                      if (values === "DEFAULT" && value !== user.payment_variables?.fixed_remuneration) setValues("PERSONALIZED")
                    }}
                    placeholder={"0.00"}
                    rightComponent={<FixedAmountText>{coin.symbol}</FixedAmountText>}
                    allowDecimals
                  />
                  {employerType === "INTERNAL" && <FormNumberInput
                    title={i18n.t('DRIVER_DETAILS.forms.variables.nationalComplete')}
                    value={nationalComplete}
                    onValueChange={(value) => {
                      setNationalComplete(value)
                      if (values === "DEFAULT" && value !== user.payment_variables?.complete_national) {
                        setValues("PERSONALIZED")
                      }
                    }}
                    placeholder={"0.00"}
                    rightComponent={<FixedAmountText>{coin.symbol}</FixedAmountText>}
                    disabled={loading}
                    allowDecimals
                  />}
                  {employerType === "INTERNAL" && <FormNumberInput
                    title={i18n.t('DRIVER_DETAILS.forms.variables.internationalComplete')}
                    value={internationalComplete}
                    onValueChange={(value) => {
                      setInternationalComplete(value)
                      if (values === "DEFAULT" && value !== user.payment_variables?.complete_international) {
                        setValues("PERSONALIZED")
                      }
                    }}
                    placeholder={"0.00"}
                    rightComponent={<FixedAmountText>{coin.symbol}</FixedAmountText>}
                    disabled={loading}
                    allowDecimals
                  />}
                  <FormNumberInput
                    title={i18n.t('DRIVER_DETAILS.forms.variables.payPerKm')}
                    value={payPerKm}
                    onValueChange={(value) => {
                      setPayPerKm(value)
                      if (values === "DEFAULT" && value !== user.payment_variables?.payment_per_km) {
                        setValues("PERSONALIZED")
                      }
                    }}
                    placeholder={"0.00"}
                    rightComponent={<FixedAmountText>{coin.symbol}</FixedAmountText>}
                    // showError={!payPerKm && showInputsErrors}
                    allowDecimals
                  // required
                  // disabled={loading}
                  />
                </ProfileFormInputsContainer>
                {employerType === "INTERNAL" && <ProfileFormInputsContainer>

                  <ProfileSmallButtonsGroup>
                    <ProfileSmallButtonGroup>
                      <FormNumberInput
                        title={i18n.t('DRIVER_DETAILS.forms.variables.lunch')}
                        containerStyle={{ width: 150 }}
                        value={lunchNational}
                        onValueChange={(value) => {
                          setLunchNational(value)
                          if (values === "DEFAULT" && value !== user.payment_variables?.diets?.lunch) {
                            setValues("PERSONALIZED")
                          }
                        }}
                        placeholder={"0.00"}
                        rightComponent={<FixedAmountText>{coin.symbol}</FixedAmountText>}
                        disabled={loading}
                        allowDecimals
                      />
                      <FormNumberInput
                        title={i18n.t('DRIVER_DETAILS.forms.variables.lunchInternational')}
                        containerStyle={{ width: 150 }}
                        value={lunchInternational}
                        onValueChange={(value) => {
                          setLunchInternational(value)
                          if (values === "DEFAULT" && value !== user.payment_variables?.diets?.lunch_international) {
                            setValues("PERSONALIZED")
                          }
                        }}
                        placeholder={"0.00"}
                        rightComponent={<FixedAmountText>{coin.symbol}</FixedAmountText>}
                        disabled={loading}
                        allowDecimals
                      />
                    </ProfileSmallButtonGroup>
                    <ProfileSmallButtonGroup>
                      <FormNumberInput
                        title={i18n.t('DRIVER_DETAILS.forms.variables.dinner')}
                        containerStyle={{ width: 150 }}
                        value={dinnerNational}
                        onValueChange={(value) => {
                          setDinnerNational(value)
                          if (values === "DEFAULT" && value !== user.payment_variables?.diets?.dinner) {
                            setValues("PERSONALIZED")
                          }
                        }}
                        placeholder={"0.00"}
                        rightComponent={<FixedAmountText>{coin.symbol}</FixedAmountText>}
                        disabled={loading}
                        allowDecimals
                      />
                      <FormNumberInput
                        title={i18n.t('DRIVER_DETAILS.forms.variables.dinnerInternational')}
                        containerStyle={{ width: 150 }}
                        value={dinnerInternational}
                        onValueChange={(value) => {
                          setDinnerInternational(value)
                          if (values === "DEFAULT" && value !== user.payment_variables?.diets?.dinner_international) {
                            setValues("PERSONALIZED")
                          }
                        }}
                        placeholder={"0.00"}
                        rightComponent={<FixedAmountText>{coin.symbol}</FixedAmountText>}
                        disabled={loading}
                        allowDecimals
                      />
                    </ProfileSmallButtonGroup>
                    <ProfileSmallButtonGroup>
                      <FormNumberInput
                        title={i18n.t('DRIVER_DETAILS.forms.variables.nocturnal')}
                        containerStyle={{ width: 150 }}
                        value={nocturnalNational}
                        onValueChange={(value) => {
                          setNocturnalNational(value)
                          if (values === "DEFAULT" && value !== user.payment_variables?.diets?.nocturnal) {
                            setValues("PERSONALIZED")
                          }
                        }}
                        placeholder={"0.00"}
                        rightComponent={<FixedAmountText>{coin.symbol}</FixedAmountText>}
                        disabled={loading}
                        allowDecimals
                      />
                      <FormNumberInput
                        title={i18n.t('DRIVER_DETAILS.forms.variables.nocturnalInternational')}
                        containerStyle={{ width: 150 }}
                        value={nocturnalInternational}
                        onValueChange={(value) => {
                          setNocturnalInternational(value)
                          if (values === "DEFAULT" && value !== user.payment_variables?.diets?.nocturnal_international) {
                            setValues("PERSONALIZED")
                          }
                        }}
                        placeholder={"0.00"}
                        rightComponent={<FixedAmountText>{coin.symbol}</FixedAmountText>}
                        disabled={loading}
                        allowDecimals
                      />
                    </ProfileSmallButtonGroup>
                  </ProfileSmallButtonsGroup>
                  <ProfileSmallButtonsGroup style={{
                    width: "90%",
                    display: 'flex',
                    flexDirection: "row",
                  }}>
                    <FormNumberInput
                      title={i18n.t('PROFILE.forms.variables.sunday')}
                      type={"text"}
                      containerStyle={{ width: 200 }}
                      value={sunday}
                      onValueChange={(value) => {
                        if (values === "DEFAULT" && value !== user.payment_variables?.diets?.sunday) {
                          setValues("PERSONALIZED")
                        }

                        setSunday(value)
                      }}
                      placeholder={"0.00"}
                      rightComponent={<FixedAmountText>{coin.symbol}</FixedAmountText>}
                      allowDecimals
                      disabled={loading}
                    />
                    <FormNumberInput
                      title={'Tiempo estimado'}
                      type={"text"}
                      containerStyle={{ width: 200 }}
                      value={taskDuration}
                      onValueChange={(value) => {
                        if (values === "DEFAULT" && value !== user.payment_variables?.task_duration) setValues("PERSONALIZED")

                        setTaskDuration(value)
                      }}
                      placeholder={"0.00"}
                      rightComponent={<FixedAmountText>{coin.symbol}</FixedAmountText>}
                      allowDecimals
                      disabled={loading}
                    />
                  </ProfileSmallButtonsGroup>
                </ProfileFormInputsContainer>}
                <ProfileFormSeparator />
                <ProfileLastButtonsContainer>
                  <CategoryButton
                    onClick={() => navigate(DRIVERS_ROUTE)}
                    active={false}
                    icon=""
                    title={i18n.t('PROFILE.buttons.cancel')}
                    loading={loading}
                  />
                  <CategoryButton
                    onClick={() => {
                      if (isInfoFormCompleted() && isAccessFormCompleted() && isVariablesFormCompleted()) {
                        createDriver()
                      }
                    }}
                    active
                    icon=""
                    title={i18n.t('PROFILE.buttons.save')}
                    loading={loading}
                  />
                </ProfileLastButtonsContainer>
              </ProfileFormsContainer>
             : null}
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default DriverDetailsScreen