import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import FormDateInput from "../../components/inputs/forms/form-date-input/FormDateInput";
import FormFileInput from "../../components/inputs/forms/form-file-input/FormTextInput";
import FormRadioButtonsInput from "../../components/inputs/forms/form-radio-buttons-input/FormRadioButtonsInput";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import FormTextArea from "../../components/inputs/forms/form-text-area/FormTextArea";
import i18n from "../../i18n/i18n";
import { CHECKLIST_DETAILS, ISSUES_ROUTE } from "../../routes/routes";
import { getTasksAction } from "../task-details/tasks-actions";
import {
  VehicleDetailsContainer,
  VehicleDetailsContentContainer,
  VehicleDetailsFormsContainer,
  VehicleDetailsFormInputsContainer,
  TableInfoContainer,
  TableTitle,
  VehicleDetailsDateInputsContainer,
  ProfileLastButtonsContainer,
  FileInputsContainer,
  GeneralErrorContainer,
  GeneralErrorText
} from "./issue-details-styles";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { getDrivers } from "../driver-details/driver-details-actions";
import { getVehiclesAction } from "../vehicles/vehicles-actions";
import { createIssueAction, getIssuesAction, updateIssueAction, updateIssuePicture } from "./issues-actions";
import moment from "moment";
import { useSelector } from "react-redux";

const IssueDetailsScreen = () => {
  const isMenuOpen = useSelector((state) => state).appSlice.isMenuOpen
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [isChecklistOpen, setIsChecklistOpen] = useState(false)
  const [showInputsErrors, setShowInputsErrors] = useState(false)
  const [generalError, setGeneralError] = useState("")
  const [tasks, setTasks] = useState([])
  const [picture1, setPicture1] = useState(null)
  const [picture2, setPicture2] = useState(null)
  const [picture3, setPicture3] = useState(null)
  const [vehicles, setVehicles] = useState([])
  const [drivers, setDrivers] = useState([])
  const [detailsType, setDetailsType] = useState("")
  const [detailsTask, setDetailsTask] = useState("")
  const [detailsDriver, setDetailsDriver] = useState("")
  const [detailsVehicle, setDetailsVehicle] = useState("")
  const [detailsTrailer, setDetailsTrailer] = useState("")
  const [detailsState, setDetailsState] = useState("")
  const [creationDate, setCreationDate] = useState("")
  const [resolutionDate, setResolutionDate] = useState("")
  const [comment, setComment] = useState("")
  const [issueObj, setIssueObj] = useState(null)
  const { issueId } = useParams()
  //checklist
  const [luminary, setLuminary] = useState("")
  const [windshield, setWindshield] = useState("")
  const [rearViewMirrors, setRearViewMirrors] = useState("")
  const [windshieldWasherFluid, setWindshieldWasherFluid] = useState("")
  const [tirePressure, setTirePressure] = useState("")
  const [batteries, setBatteries] = useState("")
  const [fireExtinguishers, setFireExtinguishers] = useState("")
  const [securityShoes, setSecurityShoes] = useState("")
  const [helmet, setHelmet] = useState("")
  const [gloves, setGloves] = useState("")
  const [protetiveScreen, setProtetiveScreen] = useState("")
  const [masks, setMasks] = useState("")
  const [highVisibilityGarment, setHighVisibilityGarment] = useState("")
  const [others, setOthers] = useState("")

  useEffect(() => {
    getTasksFunc()
    getDriversFunc()
    getVehiclesFunc()

    if (issueId) {
      getIssueData()
    }

  }, [issueId])

  const getIssueData = async () => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: 30,
          offset: 0
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      const issues = await getIssuesAction(data)
      const issuesFiltered = issues?.data?.myIncidents.filter(item => item._id === issueId)
      if (issuesFiltered.length > 0) {
        const issue = issuesFiltered[0]
        setIssueObj(issue)
        setDetailsType(issue.type.toUpperCase())
        setDetailsTask(issue.task_id)
        setDetailsDriver(issue.driver_id)
        setDetailsVehicle(issue.vehicle_id)
        setDetailsTrailer(issue.trailer_id)
        setDetailsState(issue.status)
        setCreationDate(issue.creation_date)
        setResolutionDate(issue.end_date)
        setComment(issue.comment)
        if (issue.images.length > 0 && issue.images[0]) {
          setPicture1(issue.images[0].fullpath)
        }
        if (issue.images.length > 0 && issue.images[1]) {
          setPicture2(issue.images[1].fullpath)
        }
        if (issue.images.length > 0 && issue.images[2]) {
          setPicture3(issue.images[2].fullpath)
        }
      }

      setLoading(false)

      // setDrivers(drivers?.data?.myDrivers)
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
      // navigate(ISSUES_ROUTE)
    }
  }

  const getTasksFunc = async () => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: 30,
          offset: 0
        }
      }
      const res = await getTasksAction(data)

      setTasks(res?.data?.myTasks)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      const errorCode = localStorage.getItem("error-code")
      if (errorCode === "998") setTasks([])
    }
  }

  const getDriversFunc = async () => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: 30,
          offset: 0
        },
        email: null,
        status: null,
        type: null,
        payment_type: null,
        driver_id: null
      }
      const res = await getDrivers(data)

      setDrivers(res?.data?.myDrivers)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const getVehiclesFunc = async () => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: 30,
          offset: 0
        },
        plate: null,
        vehicle_type: null,
        year: null,
        model: null,
        itv_date: null,
        brand: null
      }
      const res = await getVehiclesAction(data)

      setVehicles(res?.data?.myVehicles)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const onEditPicture = async (img) => {
    setPicture1(img)

  }
  const onEditPicture2 = async (img) => {
    setPicture2(img)

  }
  const onEditPicture3 = async (img) => {
    setPicture3(img)

  }


  const createIssue = async () => {

    if (!detailsType ||  !comment ||  !creationDate ) {
      setShowInputsErrors(true)
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      return
    } else {
      setGeneralError("")
      setShowInputsErrors(false)
    }

    if (detailsType === "CHECKLIST" && !issueId &&
      (luminary === "" || windshield === "" || rearViewMirrors === "" || windshieldWasherFluid === "" || tirePressure === "" || batteries === "" || fireExtinguishers === "" || securityShoes === "" || helmet === "" || gloves === "" || protetiveScreen === "" || masks === "" || highVisibilityGarment === "" || others === "")) {
      setShowInputsErrors(true)
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      setIsChecklistOpen(true)
      return
    }

    setLoading(true)

    try {
      let data = {
        creation_date: creationDate ? moment(creationDate).set("hour", 0).set("minute", 0).valueOf() : null,
        end_date: resolutionDate ? moment(resolutionDate).set("hour", 23).set("minute", 59).valueOf() : null,
        comment: comment || null,
        driver_id: detailsDriver || null,
        status: detailsState || null,
        task_id: detailsTask || null,
        trailer_id: detailsTrailer || null,
        type: detailsType || null,
        vehicle_id: detailsVehicle || null,
        checklist: {
          epis: {
            gloves: gloves !== "" ? gloves : null,
            helmet: helmet !== "" ? helmet : null,
            high_visibility_garment: highVisibilityGarment !== "" ? highVisibilityGarment : null,
            masks: masks !== "" ? masks : null,
            others: others !== "" ? others : null,
            protective_screen: protetiveScreen !== "" ? protetiveScreen : null,
            safety_footwear: securityShoes !== "" ? securityShoes : null
          },
          vehicle: {
            batteries: batteries !== "" ? batteries : null,
            fire_extinguishers: fireExtinguishers !== "" ? fireExtinguishers : null,
            luminary: luminary !== "" ? luminary : null,
            mirrors: rearViewMirrors !== "" ? rearViewMirrors : null,
            tire_pressure: tirePressure !== "" ? tirePressure : null,
            windshield: windshield !== "" ? windshield : null,
            windshield_washer: windshieldWasherFluid !== "" ? windshieldWasherFluid : null
          },
        },
      }

      const files = [ picture1, picture2, picture3].filter(item => item && item !== null && item.name)

      if (issueId) {
        await updateIssueAction(issueObj._id, data)

        if (picture1 && picture1.name && issueObj?.images[0]?._id) updateIssuePicture(issueObj?.images[0]?._id, picture1, issueObj._id)
        
        if (picture2 && picture2.name && issueObj?.images[1]?._id) updateIssuePicture(issueObj?.images[1]?._id, picture2, issueObj._id)
        
        if (picture3 && picture3.name && issueObj?.images[2]?._id) updateIssuePicture(issueObj?.images[2]?._id, picture3, issueObj._id)
        
      } else {
        await createIssueAction(files, data)
      }

      setLoading(false)
      dispatch(openAlert({
        alertType: "success",
        alertMessage: issueId ? i18n.t("ISSUES.updateSuccessMessage") : i18n.t("ISSUES.successMessage"),
        isAlertOpen: true
      }))
      navigate(ISSUES_ROUTE)
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const isCreaatedByDriver = issueId && detailsType === "CHECKLIST"
  return (
    <VehicleDetailsContainer style={{ position: isMenuOpen && 'relative' }}>
      <VehicleDetailsContentContainer>
        <TableInfoContainer>
          <TableTitle>
            {i18n.t('ISSUES.forms.details.title')}
          </TableTitle>
        </TableInfoContainer>
        {generalError && <GeneralErrorContainer>
          <GeneralErrorText>{generalError}</GeneralErrorText>
        </GeneralErrorContainer>}
        {isChecklistOpen ?
          <VehicleDetailsFormsContainer>
            <VehicleDetailsFormInputsContainer style={{ flexDirection: "column" }}>
              <TableInfoContainer>
                <TableTitle>
                  {i18n.t('ISSUES.forms.checklistDetails.vehicle')}
                </TableTitle>
              </TableInfoContainer>
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.luminary')}
                defaultValue="1"
                value={luminary}
                onChange={({ target: { value } }) => setLuminary(value === "true" ? true : false)}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!luminary && showInputsErrors}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.windshield')}
                defaultValue="1"
                value={windshield}
                onChange={({ target: { value } }) => setWindshield(value === "true" ? true : false)}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!windshield && showInputsErrors}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.rearViewMirrors')}
                value={rearViewMirrors}
                defaultValue="1"
                onChange={({ target: { value } }) => setRearViewMirrors(value === "true" ? true : false)}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!rearViewMirrors && showInputsErrors}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.windshieldWasherFluid')}
                defaultValue="1"
                onChange={({ target: { value } }) => setWindshieldWasherFluid(value === "true" ? true : false)}
                value={windshieldWasherFluid}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!windshieldWasherFluid && showInputsErrors}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.tirePressure')}
                defaultValue="1"
                onChange={({ target: { value } }) => setTirePressure(value === "true" ? true : false)}
                value={tirePressure}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!tirePressure && showInputsErrors}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.batteries')}
                defaultValue="1"
                value={batteries}
                onChange={({ target: { value } }) => setBatteries(value === "true" ? true : false)}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!batteries && showInputsErrors}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.fireExtinguishers')}
                defaultValue="1"
                value={fireExtinguishers}
                onChange={({ target: { value } }) => setFireExtinguishers(value === "true" ? true : false)}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!fireExtinguishers && showInputsErrors}
              />
            </VehicleDetailsFormInputsContainer>
            <VehicleDetailsFormInputsContainer style={{ flexDirection: "column" }}>
              <TableInfoContainer>
                <TableTitle>
                  {i18n.t('ISSUES.forms.checklistDetails.epis')}
                </TableTitle>
              </TableInfoContainer>
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.securityShoes')}
                defaultValue="1"
                value={securityShoes}
                onChange={({ target: { value } }) => setSecurityShoes(value === "true" ? true : false)}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!securityShoes && showInputsErrors}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.helmet')}
                defaultValue="1"
                value={helmet}
                onChange={({ target: { value } }) => setHelmet(value === "true" ? true : false)}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!helmet && showInputsErrors}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.gloves')}
                defaultValue="1"
                value={gloves}
                onChange={({ target: { value } }) => setGloves(value === "true" ? true : false)}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!gloves && showInputsErrors}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.protetiveScreen')}
                defaultValue="1"
                value={protetiveScreen}
                onChange={({ target: { value } }) => setProtetiveScreen(value === "true" ? true : false)}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!protetiveScreen && showInputsErrors}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.masks')}
                defaultValue="1"
                value={masks}
                onChange={({ target: { value } }) => setMasks(value === "true" ? true : false)}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!masks && showInputsErrors}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.highVisibilityGarment')}
                defaultValue="1"
                value={highVisibilityGarment}
                onChange={({ target: { value } }) => setHighVisibilityGarment(value === "true" ? true : false)}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!highVisibilityGarment && showInputsErrors}
              />
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.checklistDetails.others')}
                defaultValue="1"
                value={others}
                onChange={({ target: { value } }) => setOthers(value === "true" ? true : false)}
                items={[
                  { value: true, label: i18n.t('ISSUES.forms.checklistDetails.option1right') },
                  { value: false, label: i18n.t('ISSUES.forms.checklistDetails.option2wrong') },
                ]}
                disabled={loading}
                showError={!others && showInputsErrors}
              />
              <ProfileLastButtonsContainer>
                <CategoryButton
                  onClick={() => setIsChecklistOpen(false)}
                  active={false}
                  icon=""
                  title={i18n.t('ISSUES.forms.details.buttons.cancel')}
                  width={120}
                />
                <CategoryButton
                  onClick={() => {
                    if (luminary !== "" &&
                      windshield !== "" &&
                      rearViewMirrors !== "" &&
                      windshieldWasherFluid !== "" &&
                      tirePressure !== "" &&
                      batteries !== "" &&
                      fireExtinguishers !== "" &&
                      securityShoes !== "" &&
                      helmet !== "" &&
                      gloves !== "" &&
                      protetiveScreen !== "" &&
                      masks !== "" &&
                      highVisibilityGarment !== "" &&
                      others !== "") {
                      setIsChecklistOpen(false)
                      setShowInputsErrors(false)
                      setGeneralError("")
                    } else {
                      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
                      setShowInputsErrors(true)
                    }
                  }}
                  active
                  icon=""
                  loading={loading}
                  title={i18n.t('ISSUES.forms.details.buttons.save')}
                  width={120}
                />
              </ProfileLastButtonsContainer>
            </VehicleDetailsFormInputsContainer>
          </VehicleDetailsFormsContainer>
          : <VehicleDetailsFormsContainer>
            <VehicleDetailsFormInputsContainer>
              <FormRadioButtonsInput
                title={i18n.t('ISSUES.forms.details.type')}
                value={detailsType}
                onChange={({ target: { value } }) => !loading ? setDetailsType(value) : null}
                items={[
                  { value: 'GENERAL', label: i18n.t('ISSUES.forms.details.type1general') },
                  { value: 'CHECKLIST', label: i18n.t('ISSUES.forms.details.type2checklist') },
                ]}
                showError={!detailsType && showInputsErrors}
                disabled={loading || isCreaatedByDriver || issueId}
                required
              />
              <FormSelect
                title={i18n.t('ISSUES.forms.details.task')}
                value={detailsTask}
                onChange={({ target: { value } }) => {
                  if (!loading) {
                    setDetailsTask(value)
                    const selectedTask = tasks.filter(task => task._id === value)[0]
                    if (selectedTask?.driver_id) {
                      setDetailsDriver(selectedTask.driver_id)
                    }
                    if (selectedTask?.vehicle_id) {
                      setDetailsVehicle(selectedTask.vehicle_id)
                    }
                    if (selectedTask?.trailer_id) {
                      setDetailsTrailer(selectedTask.trailer_id)
                    }
                  }
                }}
                disabled={loading || isCreaatedByDriver}
                items={tasks.map((task) => ({ value: task._id, label: task.name }))}
                // required
                // showError={!detailsTask && showInputsErrors}
              />
              <FormSelect
                title={i18n.t('ISSUES.forms.details.driver')}
                value={detailsDriver}
                onChange={({ target: { value } }) => !loading ? setDetailsDriver(value) : null}
                items={drivers.map(driver => ({ value: driver._id, label: driver.name }))}
                disabled={true}
              />
              <FormSelect
                title={i18n.t('ISSUES.forms.details.vehicle')}
                value={detailsVehicle}
                onChange={({ target: { value } }) => !loading ? setDetailsVehicle(value) : null}
                items={vehicles.filter(item => item.vehicle_type.toUpperCase() !== "TRAILER").map((vehicle) => ({ label: vehicle.plate, value: vehicle._id }))}
                disabled={true}
              />
              <FormSelect
                title={i18n.t('ISSUES.forms.details.trailer')}
                value={detailsTrailer}
                onChange={({ target: { value } }) => !loading ? setDetailsTrailer(value) : null}
                items={vehicles.filter(item => item.vehicle_type.toUpperCase() === "TRAILER").map((vehicle) => ({ label: vehicle.plate, value: vehicle._id }))}
                disabled={true}
              />
              {(isCreaatedByDriver || detailsType === "CHECKLIST") && <CategoryButton
                onClick={() => detailsType === "CHECKLIST" && !issueId ? setIsChecklistOpen(true) : navigate(`${CHECKLIST_DETAILS}/${issueId}`)}
                active
                icon=""
                title={i18n.t('ISSUES.forms.details.buttons.detailsChecklist')}
                width={170}
              />}
            </VehicleDetailsFormInputsContainer>
            <VehicleDetailsFormInputsContainer>
              <FormSelect
                title={i18n.t('ISSUES.forms.details.state')}
                value={detailsState}
                onChange={({ target: { value } }) => !loading ? setDetailsState(value) : null}
                items={[
                  { value: 'RESOLVED', label: i18n.t('ISSUES.forms.details.status1'), },
                  { value: 'UNRESOLVED', label: i18n.t('ISSUES.forms.details.status2'), },
                ]}
                showError={!detailsState && showInputsErrors}
                required
              />
              <VehicleDetailsDateInputsContainer>
                <FormDateInput
                  title={i18n.t('ISSUES.forms.details.creationDate')}
                  value={creationDate}
                  containerStyle={{ width: 150 }}
                  disabled={loading || isCreaatedByDriver}
                  onChange={(newValue) => {
                    setCreationDate(newValue);
                  }}
                  showError={!creationDate && showInputsErrors}
                  required
                />
                <FormDateInput
                  title={i18n.t('ISSUES.forms.details.resolutionDate')}
                  value={resolutionDate}
                  containerStyle={{ width: 150 }}
                  fatherContainer={{ marginTop: 8 }}
                  minDate={creationDate}
                  disabled={loading}
                  onChange={(newValue) => {
                    setResolutionDate(newValue);
                  }}
                />
              </VehicleDetailsDateInputsContainer>
              <FormTextArea
                title={i18n.t('ISSUES.forms.details.comment')}
                type={"text"}
                value={comment}
                disabled={loading}
                onChange={({ target: { value } }) => setComment(value)}
                required
                showError={!comment && showInputsErrors}
              />
              <FileInputsContainer>
                <FormFileInput disabled={loading || isCreaatedByDriver} picture={picture1} onEditPicture={onEditPicture} />
                <FormFileInput disabled={loading || isCreaatedByDriver} picture={picture2} onEditPicture={onEditPicture2} />
                <FormFileInput disabled={loading || isCreaatedByDriver} picture={picture3} onEditPicture={onEditPicture3} />
              </FileInputsContainer>
              <ProfileLastButtonsContainer>
                <CategoryButton
                  onClick={() => navigate(ISSUES_ROUTE)}
                  active={false}
                  icon=""
                  title={i18n.t('ISSUES.forms.details.buttons.cancel')}
                  width={120}
                />
                <CategoryButton
                  onClick={() => createIssue()}
                  active
                  icon=""
                  loading={loading}
                  title={i18n.t('ISSUES.forms.details.buttons.save')}
                  width={120}
                />
              </ProfileLastButtonsContainer>
            </VehicleDetailsFormInputsContainer>
          </VehicleDetailsFormsContainer>}
      </VehicleDetailsContentContainer>
    </VehicleDetailsContainer>
  )
}

export default IssueDetailsScreen