import { gql } from '@apollo/client';

export const GET_USERS_MUTATION = gql`
query users($input: UserFiltersInput) {
  users(input: $input) {
    _id
    address
    avatar {
      fullpath
      path
    }
    city
    company
    company_type
    confirmed
    country
    dni
    email
    email_contact
    language
    name
    nif
    payment_variables {
      complete_national
      complete_international
      diets {
        dinner
        dinner_international
        dinner_timetable
        lunch
        lunch_international
        lunch_timetable
        nocturnal
        nocturnal_international
        nocturnal_timetable
        sunday
      }
      fixed_remuneration
      payment_per_km
      user_id
    }
    phone
    plan_details {
      amount
      discount
      end_date
      final_amount
      max_users
      name {
        es
        pt
        fr
        de
      }
      payment_type
      plan_id
      status
      subscription_id
      tax
      }
      position
      rol
      state
      status
      stripe_details {
        account_connect
        bank_account {
          default
          last_digit
          name
          routing_number
          token
        }
        card {
          brand
          default
          expiry
          last_digit
          token
        }
        customer
        method
        payment_intent
        payment_method
      }
    surnames
    zip
    }
}
`;

export const EXPORT_USERS_MUTATION = gql`
query exportUsers($input: UserFiltersInput) {
  exportUsers(input: $input){
        fullpath
        path
        status
  }
}
`;

export const CLIENT_USERS_MUTATION = gql`
query exportMyClientsXlsx($input: ClientFilterInput) {
  exportMyClientsXlsx(input: $input){
        fullpath
        path
        status
  }
}
`;

export const EXPORT_USER_DRIVERS_MUTATION = gql`
query exportDrivers($company_id: ObjectId!, $input: DriverFiltersInput) {
  exportDrivers(company_id: $company_id, input: $input){
        fullpath
        path
        status
  }
}
`;

export const REMOVE_USER_MUTATION = gql`
mutation deleteUser($user_id: ObjectId!) {
  deleteUser(user_id: $user_id){
        status
  }
}
`;

export const GET_CLIENTS_QUERY = gql`
query findMyClients($clientFilterInput: ClientFilterInput) {
  findMyClients(clientFilterInput: $clientFilterInput) {
    _id
    avatar
    firts_name
    email
    last_name
    address_client {
      address
      phone
      street
    }
    business_client {
      address_tax
      business_name
      city
      country
      nif
      phone
      state
      zip
    }
    companies_ids
    language
    dni
    rol
    status
  }
}
`

export const UPDATE_USER_MUTATION = gql`
mutation updateUser($input: UpdateUserInput!, $user_id: ObjectId!) {
  updateUser(input: $input, user_id: $user_id){
        _id
  }
}
`;

export const UPDATE_CLIENT_MUTATION = gql`
mutation updateClient($company_or_client_id_input: ObjectId! , $updateClientInput: UpdateClientInput!) {
  updateClient(updateClientInput: $updateClientInput, company_or_client_id_input: $company_or_client_id_input) {
    _id
  }
}
` 

export const GET_CLIENT_BY_ID_QUERY = gql`
query findMyClientById($clientId: ObjectId!) {
  findMyClientById(clientId: $clientId) {
    _id
    avatar
    firts_name
    email
    last_name
    position
    address_client {
      address
      phone
      street
    }
    business_client {
      address_tax
      business_name
      city
      country
      nif
      phone
      state
      zip
    }
    companies_ids
    language
    dni
    status
  }
}
`



export const CREATE_USER_MUTATION = gql`
mutation createUser($input: CreateUserInput!) {
  createUser(input: $input){
        _id
  }
}
`;

export const CREATE_CLIENT_MUTATION = gql`
mutation createClient($input: CreateClientInput!) {
  createClient(input: $input) {
      _id
  }
}
`

export const UPDATE_USER_STATUS_MUTATION = gql`
mutation updateUserStatus($confirmed: UserConfirmStatus!, $status: UserStatus!, $user_id: ObjectId!) {
  updateUserStatus(confirmed: $confirmed, status: $status, user_id: $user_id){
        _id
  }
}
`;

export const GET_USER_DRIVERS_MUTATION = gql`
query drivers($company_id: ObjectId!, $input: DriverFiltersInput) {
  drivers(company_id: $company_id, input: $input){
    address
      birthday
      city
      state
      country
      avatar {
        fullpath
        path
      }
      status
      company_phone
      dni
      email
      _id
      name
      personal_email
      phone
      surnames
      type
      zip
      documentation {
        CAP {
          card
          date
        }
        CAP_images {
            _id
            face
            fullpath
            path
          }
        driving_cards_images {
            _id
            face
            fullpath
            path
          }
          tachograph_card_images {
            _id
            face
            fullpath
            path
          }
        driver_id
        driving_cards {
          AM
          A1
          A2
          A
          B
          C1
          C
          D1
          D
          BE
          C1E
          CE
          D1E
          DE
        }
        sanitary_card {
          card
          date
        }
        sanitary_card_EU {
          card
          date
        }
        tachograph_card {
          card
          date
        }
      }
      payment_variables {
        complete_international
        complete_national
        diets {
          dinner
          dinner_international
          lunch
          lunch_international
          nocturnal
          nocturnal_international
          sunday
        }
        fixed_remuneration
        payment_per_km
        payment_type
        user_id
        values
      }
  }
}
`;

export const UPDATE_USER_PIC_MUTATION = gql`
mutation uploadPhotoToUser($file: Upload!, $user_id: ObjectId!) {
  uploadPhotoToUser(file: $file, user_id: $user_id){
        fullpath
        path
        status
  }
}
`;
