import React from "react";
import {
  TextInputContainer,
  InputIcon,
  InputTitle,
  FormTextInputMainContainer,
} from "./form-radio-buttons-input-styles";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { COLORS_TRUCK } from "../../../../utils/colors";

const FormRadioButtonsInput = (props) => {

  return (
    <FormTextInputMainContainer>
      {props.title ? <InputTitle>
        {props.title}{props.required ? <span style={{ fontSize: 20 }}>{` *`}</span> : ""}
      </InputTitle>
        : null}
      <TextInputContainer>
        {props.icon && <InputIcon src={props.icon} />}
        <RadioGroup
          style={{ width: '100%' }}
          defaultValue={props.defaultValue}
          row
          value={props.value}
          onChange={props.onChange}
        >
          {props.items.map((item, i) =>
            <FormControlLabel
              key={i}
              value={item.value}
              control={<Radio sx={{
                color: props.showError && props.value === "" ? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.BLACK_TRUCK,
                fontWeight: "normal",
                // border: props.showError ? `1px solid ${COLORS_TRUCK.ORANGE_TRUCK}` : ``,
                '&.Mui-checked': {
                  color: COLORS_TRUCK.BLACK_TRUCK,
                },
              }} />}
              label={<span style={{ color: props.showError && props.value === "" ? COLORS_TRUCK.ORANGE_TRUCK : COLORS_TRUCK.BLACK_TRUCK, }}>{item.label}</span>}
              disabled={props.disabled}
            />
          )}
        </RadioGroup>
        {props.rightIcon && <InputIcon
          onClick={props.iconRightClick}
          style={{ cursor: 'pointer' }}
          src={props.rightIcon}
        />}
      </TextInputContainer>
    </FormTextInputMainContainer>
  )
}

export default FormRadioButtonsInput