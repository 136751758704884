import React, { useState, useEffect } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileFormContainer,
  ProfileBillingContainer,
  TableTitle,
  TableInfoContainer
} from "./asign-routes-styles";
import i18n from '../../i18n/i18n';
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import filterByIcon from "../../assets/profile/filter-by-icon.svg";
import searchIcon from "../../assets/profile/search-icon.svg";
import TruckTable from "../../components/truck-table/TruckTable";
import { useNavigate } from "react-router-dom";
import { getTasksAction, getTasksStatusesAction, updateTaskAction } from "../task-details/tasks-actions";
import TextInput from "../../components/inputs/text-input/TextInput";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { useSelector } from "react-redux";
import { FormModal } from "../../components/modal/form-modal/FormModal";
import { getMyRoutesAction } from "../routes/routes-actions";
import { Button } from "@mui/material";
import arrowRight from '../../assets/inputs/flecha-izquierda.png'
import { ROUTES_CLIENT_ROUTE } from "../../routes/routes.jsx";

export const AsignRoutesScreen = () => {
  const navigate = useNavigate()
  const [fields, setFields] = useState([ {id: 1, name: "name", value: "", type: "select", option: {}, required: true} ])
  const queryParams = new URLSearchParams(window.location.search);
  const filter = queryParams.get('filter') || null;
  const dispatch = useAppDispatch()
  const user = useSelector((state) => state).userSlice
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(30)
  const [taskStatuses, setTaskStatuses] = useState([])
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [filterBy, setFilterBy] = useState(filter ? filter.toUpperCase() : "1")
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [tasks, setTasks] = useState([])
  const [tasksSelected, setTasksSelected] = useState([])
  const [tasksFilter, setTasksFilter] = useState([])
  const [asignModal, setAsignModal] = useState(false)
  const [taskStatus, setTaskStatus] = useState(filter ? filter.toUpperCase() : "")

  useEffect(() => {
    let status = user?.plan_details?.status
    getTasksFunc()
    getRoutesFunc()
    
    if (status === "CANCELED" || status === "WAITING") {
      dispatch(openAlert({
        alertType: status === "CANCELED" ? "plan-canceled" : "plan-waiting",
        isAlertOpen: true
      }))
    }

    getTaskStatusesFunc()
  }, [taskStatus, search, filterBy, limit, offset])

  const getTaskStatusesFunc = async () => {
    try {
      const res = await getTasksStatusesAction()
      setTaskStatuses(res.data.taskStatuses)
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getRoutesFunc = async ( ) => {
    try {
      const { data } = await getMyRoutesAction()

      for(let index in fields) {
        if(fields[index].id == 1) fields[index].option = data.findMyRoutes.map((item) => ({ label: item.name, value: item._id }))
      }

      setFields(fields)
    } catch (error) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getTasksFunc = async (reset = false) => {
    if(reset) {
      setLoading(true)
      setTasks([])
    }
    try {
      const data = {
        pagination: {
          limit: limit,
          offset: offset
        },
        status: 'PENDING',
        route: null
      }
      const res = await getTasksAction(data)
      setKeepIncreasing(res?.data?.myTasks.length <= 0 ? false : true)

      let tasksArray = [...tasks].concat(res?.data?.myTasks)
      if ((!search && !taskStatus && !reset && (!filterBy || filterBy === "1")) || limit > 30) {
        tasksArray.filter((item, index, self) => index === self.findIndex((t) => t._id === item._id))
        let newObject = createNewObjectTask(tasksArray)
        setTasks(newObject)
        return getTaskAsign(newObject)
      } 

      let myTasks = filterBy === "incidents" ? res?.data?.myTasks.filter(item => item.incidents > 0) : res?.data?.myTasks
      let newObject = createNewObjectTask(myTasks)
      setLoading(false)
      return setTasks(newObject)
    } catch (e) {
      setLoading(false)
      const errorCode = localStorage.getItem("error-code")
      if (errorCode === "998") {
        setTasks([])
      }
    }
  }

  const createNewObjectTask = (tasksArray) => {
    return tasksArray.map(item => {
      let object = {}
      let fields = ['_id', 'name', 'start_date','end_date', 'check', 'estimated_km', 'route']

      for(let index in fields) {
        object[fields[index]] = item[fields[index]] || false
      }

      return object
    })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setTasks([])
      getTasksFunc()
    }
  };

  const getData = async (e) => {
    setLoading(true)
    setTasks([])
    setAsignModal(!asignModal)
    if(tasksSelected.length > 0) {
      const data = {
        route: e[0].value
      }

      for(let index in tasksSelected) {
        await updateTaskAction(tasksSelected[index].value, data)
      }

      setLoading(false)
      getTasksFunc(true)
      return dispatch(openAlert({alertType: "success", alertMessage: i18n.t("TASKS.updatedSuccessMessage"), isAlertOpen: true}))
    } 

    setAsignModal(!asignModal)
    return dispatch(openAlert({ alertType: "error", isAlertOpen: true, alertMessage: 'No has seleccionado una tarea' }))
  }

  const filterTask = (e) => {
    setSearch(e.target.value)

    setTasksFilter(tasks.filter(item => item.name.includes(e.target.value)))
  }

  const getTaskAsign = (data) => {    
    const dataComplete =  []
    
    for(let index in tasks) {
      if(tasks[index]._id == data.value || data.value == "completed") {
        tasks[index].check = (data.value == "completed") ? data.check : !tasks[index].check
        if(data.value == "completed") dataComplete.push({ value: tasks[index]._id }) 
        setTasks(tasks)
      }
    }

    if(data.check && data.value !== 'completed') return setTasksSelected([...tasksSelected, data])
    if(!data.check && data.value !== 'completed') return setTasksSelected(tasksSelected.filter(item => item.value !== data.value ))
    if(!data.check && data.value == 'completed') return setTasksSelected([])
    
    return setTasksSelected(dataComplete)
  }
  
  const tableHeader = <ProfileBillingContainer>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Button onClick={() => navigate(`${ROUTES_CLIENT_ROUTE}`)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, borderRadius: 5, marginTop: 20   }}>
          <img style={{ width: 30 }} src={arrowRight} />
      </Button>
    </div>
    <TableInfoContainer>
      <TableTitle>
        Rutas - Tareas Pendientes
      </TableTitle>
    </TableInfoContainer>
    <div style={{ display: 'flex',justifyContent: 'end', alignItems: 'center' }}>
      <TextInput
        icon={searchIcon}
        value={search}
        containerStyle={{ width: 250 }}
        type="text"
        placeholder={i18n.t('TASKS.searchPlaceHolder')}
        onChange={async (e) => {
          if (e.target.value === "") {
            getTasksFunc(true) 
          }

          filterTask(e)
        }}
        onKeyPress={handleKeyPress}
      />
      <CategoryButton
        onClick={() => setAsignModal(!asignModal)}
        active
        icon="" 
        style={{
          width: 170
        }}
        title="Asignar Ruta"
      />
    </div>
  </ProfileBillingContainer>

  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileFormContainer>
          <TruckTable
            type="asign"
            header={tableHeader}
            selectedTask={getTaskAsign}
            onEndReach={() => {
              if (keepIncreasing) {
                setOffset(() =>{
                  return offset + increaseLimit
                })
                setLimit(() => {
                  return limit + increaseLimit
                })
              }

              setTimeout(() => {
                getTasksFunc()
              }, 500)
            }}
            inactive={ tasksSelected.length === tasks.length }
            updateTable={(reset) => getTasksFunc(reset)}
            titles={[
              { label: '*' },
              { label: "Nombre" },
              { label: "Fecha" },
              { label: "Horario" },
              { label: "Km Estimados" },
              { label: "Ruta" },
              { label: "Acciones" },
            ]}
            data={!search ? tasks : tasksFilter}
            style={{
              maxHeight: 500,
              overflow: "auto"
            }}
            loading={loading}
          />
        </ProfileFormContainer>
      </ProfileContentContainer>
      {
        asignModal ? 
            <FormModal 
              title="Asignar Rutas"
              info="Seleccione la ruta a la que asignar las rutas"
              fields={fields}
              returnInfo={getData}
              titleButton="Asignar"
              closeModal={ () => setAsignModal(!asignModal)}
            />
            : 
          <></>
      }
    </ProfileContainer>
  )
}