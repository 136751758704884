import React, { useEffect, useState } from "react";
import {
  UserHomeContainer,
  UserHomeContentContainer,
  HomeStatContainer,
  HomeStatTitle,
  HomeStatContentContainer,
  HomeStatNumber,
  HomeStatIcon,
  HomeStatsContainerGeneral,
  TaskDetailsContainer,
  TaskDetailsTitle,
  TaskDetailsContent,
  TaskDetailsContentLink,
  TaskDetailsItemContainer,
  TaskDetailsItemsContainer,
  InfoContainer,
  InfoContentParagraph,
  ColorSquare,
  InfoItemsContainer
} from "./user-home-styles";
import i18n from '../../i18n/i18n';
import inProcessIcon from "../../assets/home/in-process-task-icon.svg";
import issuesIcon from "../../assets/home/issues-icon.svg";
import messagesIcon from "../../assets/home/messages-icon.svg";
import pendingTasksIcon from "../../assets/home/pending-tasks-icon.svg";
import redPinIcon from "../../assets/home/red-pin.svg";
import bluePinIcon from "../../assets/home/blue-pin.svg";
import greenPinIcon from "../../assets/home/green-pin.svg";
import {
  MapContainer,
  TileLayer,
  Popup,
  Marker
} from 'react-leaflet';
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { COLORS_TRUCK } from "../../utils/colors";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { useSelector } from "react-redux";
import {
  LISTEN_RUNNING_TASKS_SUBSCRIPTION,
  LISTEN_COORDS_SUBSCRIPTION,
  LISTEN_PENDING_TASKS_SUBSCRIPTION,
  LISTEN_OPEN_INCIDENTS_SUBSCRIPTION,
  LISTEN_UNREAD_MESSAGES_SUBSCRIPTION,
} from "./user-home-queries";
import { useSubscription } from "@apollo/client";
import {
  getRunningTasksAction,
  getPendingTasksAction,
  getOpenIncidentsAction,
  getUnreadMessagesAction,
  getMapCoordsAction
} from "./user-home-actions";
import { CHAT_ROUTE, ISSUES_ROUTE, TASKS_ROUTE, TASK_DETAILS_ROUTE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { getPaymentIntentAction, getUserProfile } from "../profile/profile-actions";
import { setUser } from "../../redux/reducers/user.ts";
import { TruckCalendar } from "../../components/truck-calendar/TruckCalendar";
import { getClientByIdAction } from "../super-admin/clients/clients-actions.jsx";
import { getChatClient } from "../chats/chats-actions.jsx";
import { getTasksClientAction } from "../task-details/tasks-actions.jsx";
import moment from "moment";

const UserHomeScreen = () => {
  const isMenuOpen = useSelector((state) => state).appSlice.isMenuOpen
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const user = useSelector((state) => state).userSlice

  const [tasksInProcess, setTasks] = useState(0)
  const [tasksCalendar, setTasksCalendar] = useState([])
  const [pendingTasks, setPendingTasks] = useState(0)
  const [openIncidents, setOpenIncidents] = useState(0)
  const [unreadMessages, setUnreadMessages] = useState(0)
  const [mapCoords, setMapCoords] = useState([])

  useEffect(async () => {
    if(user.rol !== 'client') await getUserData()
    else {
      await getClientData()
      getTasksFunc()
    }
   

    getStats()
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const payment_intent = params.get('payment_intent');
    if (payment_intent) {
      validatePaymentIntent(payment_intent)
    }
  }, [])

  const assignTask = (data) => {
    let taskArray = []
    for(let index in data) {
      let taskObj = {
        backgroundColor:  data[index]?.route ? data[index].route?.color?.includes('#') ? data[index].route.color : `#${data[index].route.color}` : '#4a4a4a', 
        borderColor: data[index]?.route ? data[index].route?.color?.includes('#') ? data[index].route.color : `#${data[index].route.color}` : '#4a4a4a' 
      }
      let date = new Date(data[index].start_date)

      taskObj.title = `${data[index].unload?.delivery_object || 'Sin objeto' } - ${data[index].unload?.address?.direction}`
      taskObj.id = data[index]._id
      taskObj.start = moment(new Date(date)).format('YYYY-MM-DD')
      taskObj.task = data[index]
      taskObj.order = data[index].unload?.order
   
      taskArray.push(taskObj)
    }
    
    console.log(taskArray)
    setTasksCalendar(taskArray)
  }

  const getTasksFunc = async (status = 'PENDING') => {
    try {
      const dataFilter = {
        status: status,
        route: null
      }
      const { data } = await getTasksClientAction(dataFilter) 
      
      if(status == 'PENDING') assignTask(data.findTasksByClient)

      return data.findTasksByClient
    } catch (error) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }


  }

  const getClientData = async () => {
    try {
      const data = user._id

      const res = await getClientByIdAction(data)
      const user = res.data.findMyClientById
      dispatch(setUser({
        ...user
      }))
    } catch (error) {
      
    }
  }

  const validatePaymentIntent = async (paymentIntent) => {
    try {

      const res = await getPaymentIntentAction(paymentIntent)
      if (res.data.getPaymentIntent.status === "succeeded") {
        dispatch(openAlert({
          alertType: "success",
          alertMessage: i18n.t("PROFILE.planPaymentSuccess"),
          isAlertOpen: true
        }))
      }
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getUserData = async () => {
    try {

      const res = await getUserProfile()
      const user = res.data.profile
      if (user?.plan_details?.status === "CANCELED") {
        dispatch(openAlert({
          alertType: "plan-canceled",
          isAlertOpen: true
        }))
      }
      if (user?.plan_details?.status === "WAITING") {
        dispatch(openAlert({
          alertType: "plan-waiting",
          isAlertOpen: true
        }))
      }
      dispatch(setUser({
        ...user
      }))
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  useSubscription(LISTEN_OPEN_INCIDENTS_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      if (data?.openIncidents?.total) {
        setOpenIncidents(data?.openIncidents?.total)
      }

    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  useSubscription(LISTEN_UNREAD_MESSAGES_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {

      if (data?.unreadMessages?.total) {
        setUnreadMessages(data?.unreadMessages?.total)
      }
    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  useSubscription(LISTEN_RUNNING_TASKS_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      if (data?.runningTasks?.total) {
        setTasks(data?.runningTasks?.total)
      }
    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  useSubscription(LISTEN_PENDING_TASKS_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      if (data?.pendingTasks?.total) {
        setPendingTasks(data?.pendingTasks?.total)
      }

    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  useSubscription(LISTEN_COORDS_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      if (data?.newCoord?.journeys?.length > 0) {
        const items = data?.newCoord?.journeys

        setMapCoords(fixTasks(items))
      } else {
        getStats()
      }
    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  const fixTasks = (tasks) => tasks.map(item => {
    return {
      position: item?.coords[0]?.x && item?.coords[0]?.y ? [item?.coords[0]?.x, item?.coords[0]?.y] : [],
      details: {
        task: item.tasks && item.tasks.name ? item.tasks.name : "",
        driver: item.driver || "N/A",
        vehicle: item.vehicle || "N/A",
        trailer: item.trailer || "N/A"
      },
      icon: divIcon({
        html: renderToStaticMarkup(<img alt="" src={
          item.incidents > 0
            ? redPinIcon
            : item.pending //is free
              ? bluePinIcon :
              !item.pending
                ? greenPinIcon
                  : bluePinIcon
        } />),
      }),
      _id: item.tasks && item.tasks._id ? item.tasks._id : ""
    }
  }).filter(item => item.position.length > 0)

  const getStats = async () => {
    try {
      if(user.rol !== 'client') {
        const runningTasks = await getRunningTasksAction()
        if (runningTasks?.data?.runningTasks?.total) setTasks(runningTasks?.data?.runningTasks?.total)

        const pendingTasks = await getPendingTasksAction()
        if (pendingTasks?.data?.pendingTasks?.total) setPendingTasks(pendingTasks?.data?.pendingTasks?.total)

        const openIncidents = await getOpenIncidentsAction()
        if (openIncidents?.data?.openIncidents?.total) setOpenIncidents(openIncidents?.data?.openIncidents?.total)
        
        const mapCoords = await getMapCoordsAction()

        let items = []
        if (mapCoords?.data?.map) {
          items = mapCoords?.data?.map.journeys
        }

        setMapCoords(fixTasks(items))
      }

      const unreadMessages = await getUnreadMessagesAction()
      if (unreadMessages?.data?.unreadMessages?.total) {
        localStorage.setItem("numberOfUnreadMessages", String(unreadMessages?.data?.unreadMessages?.total))
        setUnreadMessages(unreadMessages?.data?.unreadMessages?.total)
      }

      if(user.rol === 'client') {
        const pendingTasks = await getTasksFunc()
        const runningTasks = await getTasksFunc('PROCESS')

        setTasks(runningTasks.length)
        setPendingTasks(pendingTasks.length)
      }
    } catch (e) {
      console.log('e', e)
      // console.log("getStats / error: ", e)
    }
  }

  const stats = [
    {
      title: i18n.t('HOME.stats.stat1Title'),
      name: 'stat1',
      value: tasksInProcess,
      icon: inProcessIcon,
      onClick: () => navigate(`${TASKS_ROUTE}/?filter=process`)
    },
    {
      title: i18n.t('HOME.stats.stat2Title'),
      name: 'stat2',
      value: pendingTasks,
      icon: pendingTasksIcon,
      onClick: () => navigate(`${TASKS_ROUTE}/?filter=pending`)
    },
    {
      title: i18n.t('HOME.stats.stat3Title'),
      name: 'stat3',
      value: openIncidents,
      icon: issuesIcon,
      onClick: () => navigate(ISSUES_ROUTE)
    },
    {
      title: i18n.t('HOME.stats.stat4Title'),
      name: 'stat4',
      value: unreadMessages,
      icon: messagesIcon,
      onClick: () => navigate(CHAT_ROUTE)
    },
  ]
  const center = [42.25, -2.5]
  return (
    <UserHomeContainer style={{ position: isMenuOpen && 'relative' }}>
      <UserHomeContentContainer>
        <HomeStatsContainerGeneral>
          {stats.map((item, i) =>
            (item.name !== 'stat3' || user.rol !== 'client')  && <>
              <HomeStatContainer onClick={item.onClick} key={i}>
                <HomeStatTitle>
                  {item.title}
                </HomeStatTitle>
                <HomeStatContentContainer>
                  <HomeStatNumber>
                    {item.value}
                  </HomeStatNumber>
                  <HomeStatIcon alt="" src={item.icon} />
                </HomeStatContentContainer>
              </HomeStatContainer>
            </>
          )}
        </HomeStatsContainerGeneral>
        {
          user.rol == 'admin' ? 
            <MapContainer
              style={{ width: '100%', height: '500px', marginTop: 20, borderRadius: 15 }}
              center={center}
              zoom={8}
              scrollWheelZoom={false}
            >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            {mapCoords?.map((item, i) =>
              <Marker key={i} icon={item.icon} color="red" position={item.position}>
                <Popup>
                  <TaskDetailsContainer>
                    <TaskDetailsItemsContainer>
                      {item.details.task && <TaskDetailsItemContainer>
                        <TaskDetailsTitle>
                          {i18n.t('HOME.taskdetails.task')}:
                        </TaskDetailsTitle>
                        <TaskDetailsContentLink onClick={() => navigate(`${TASK_DETAILS_ROUTE}/${item._id}`)}>
                          {item.details.task}
                        </TaskDetailsContentLink>
                      </TaskDetailsItemContainer>}
                      <TaskDetailsItemContainer>
                        <TaskDetailsTitle>
                          {i18n.t('HOME.taskdetails.driver')}:
                        </TaskDetailsTitle>
                        <TaskDetailsContent>
                          {item.details.driver}
                        </TaskDetailsContent>
                      </TaskDetailsItemContainer>
                      <TaskDetailsItemContainer>
                        <TaskDetailsTitle>
                          {i18n.t('HOME.taskdetails.vehicle')}:
                        </TaskDetailsTitle>
                        <TaskDetailsContent>
                          {item.details.vehicle}
                        </TaskDetailsContent>
                      </TaskDetailsItemContainer>
                      <TaskDetailsItemContainer>
                        <TaskDetailsTitle>
                          {i18n.t('HOME.taskdetails.trailer')}:
                        </TaskDetailsTitle>
                        <TaskDetailsContent>
                          {item.details.trailer || "N/A"}
                        </TaskDetailsContent>
                      </TaskDetailsItemContainer>
                    </TaskDetailsItemsContainer>
                  </TaskDetailsContainer>
                </Popup>
              </Marker>
            )}
            </MapContainer>
              :
            <TruckCalendar style={{width: '98%'}} task={ tasksCalendar } setTask={ setTasksCalendar } />
        }
        
        {
          user.rol !== 'client' && 
          <>
            <InfoItemsContainer>
              <InfoContainer>
                <ColorSquare style={{ backgroundColor: COLORS_TRUCK.BLUE_TRUCK }} />
                <InfoContentParagraph>
                  {i18n.t('HOME.freeDriver')}
                </InfoContentParagraph>
              </InfoContainer>
              <InfoContainer>
                <ColorSquare style={{ backgroundColor: COLORS_TRUCK.GREEN_TRUCK }} />
                <InfoContentParagraph>
                  {i18n.t('HOME.process')}
                </InfoContentParagraph>
              </InfoContainer>
              <InfoContainer>
                <ColorSquare style={{ backgroundColor: COLORS_TRUCK.ORANGE_TRUCK }} />
                <InfoContentParagraph>
                  {i18n.t('HOME.map.issue')}
                </InfoContentParagraph>
              </InfoContainer>
            </InfoItemsContainer>
          </>
        }
      </UserHomeContentContainer>
    </UserHomeContainer>
  )
}

export default UserHomeScreen