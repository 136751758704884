import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';

export const SideMenuContainer = styled.div`
max-width: 300px;
min-width: 300px;
width: 20%;
overflow: auto;
height: 100%;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
z-index: 0;
background: ${COLORS_TRUCK.WHITE_TRUCK};
margin-top: 8vh;

@media (max-width: 1000px) {
  position: fixed;
  z-index: 40;
}

@media (max-width: 600px) {
  margin-top: 16vh;
}
`;

export const SideMenuButtonContainer = styled.div`
width: 100%;
height: 70px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`;

export const SideMenuButtonContainerLogout = styled.div`
width: 100%;
height: 70px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
margin-top: 100px;

@media (max-width: 1000px) {
  margin-bottom: 50px;
}
`;

export const SideMenuButtonContentContainer = styled.div`
width: 150px;
height: 100%;
display: flex;
align-items: center;
justify-content: flex-start;
`;

export const SideMenuButtonTitle = styled.p`
// font-weight: bold;
`;

export const SideMenuButtonIcon = styled.img`
width: 20px;
height: 20px;
margin-right: 10px;
`;

export const UnreadMessages = styled.div`
padding: 1px;
    border-radius: 50%;
    font-weight: bold;
    text-align: center;
    min-width: 20px;
    min-height: 20px;
    margin-right: 10px;
`;