import { gql } from '@apollo/client';

export const CREATE_DRIVER_MUTATION = gql`
mutation createDriver($input: CreateDriverInput!, $images: [AddDocumentationImages!]) {
  createDriver(input: $input, images: $images) {
    _id
    address
      birthday
      city
      company_phone
      country
      avatar {
        fullpath
        path
      }
      dni
      email
      name
      personal_email
      phone
      state
      surnames
      type
      zip
      documentation {
        CAP {
          card
          date
        }
        driver_id
        driving_cards {
          AM
          A1
          A2
          A
          B
          C1
          C
          D1
          D
          BE
          C1E
          CE
          D1E
          DE
        }
        sanitary_card {
          card
          date
        }
        sanitary_card_EU {
          card
          date
        }
        tachograph_card {
          card
          date
        }
      }
      payment_variables_id {
        complete_international
        complete_national
        diets {
          dinner
          dinner_international
          lunch
          lunch_international
          nocturnal
          nocturnal_international
        }
        fixed_remuneration
        payment_per_km
        payment_type
        user_id
        values
        task_duration
      }
  }
}
`;

export const UPDATE_DRIVER_PIC_MUTATION = gql`
mutation changePhotoDriver($driver_id: ObjectId!, $file: Upload!) {
  changePhotoDriver(driver_id: $driver_id, file: $file){
        fullpath
        path
        status
  }
}
`;

export const REMOVE_DRIVER_MUTATION = gql`
mutation deleteDriver($driver_id: ObjectId!) {
  deleteDriver(driver_id: $driver_id){
        status
  }
}
`;

export const UPDATE_DRIVER_MUTATION = gql`
mutation updateDriver($driver_id: ObjectId!, $input: UpdateDriverInput!, $images: [AddDocumentationImages!]) {
  updateDriver(driver_id: $driver_id, input: $input, images: $images) {
    address
      birthday
      city
      company_phone
      country
      avatar {
        fullpath
        path
      }
      dni
      email
      name
      personal_email
      phone
      state
      surnames
      type
      zip
      documentation {
        CAP {
          card
          date
        }
        driver_id
        driving_cards {
          AM
          A1
          A2
          A
          B
          C1
          C
          D1
          D
          BE
          C1E
          CE
          D1E
          DE
        }
        sanitary_card {
          card
          date
        }
        sanitary_card_EU {
          card
          date
        }
        tachograph_card {
          card
          date
        }
      }
      payment_variables_id {
        complete_international
        complete_national
        diets {
          dinner
          dinner_international
          lunch
          lunch_international
          nocturnal
          nocturnal_international
        }
        fixed_remuneration
        payment_per_km
        payment_type
        user_id
        values
      }
  }
}
`;

export const UPDATE_DRIVER_STATUS_MUTATION = gql`
mutation updateDriverStatus($driver_id: ObjectId!, $status: DriverStatus!) {
  updateDriverStatus(driver_id: $driver_id, status: $status){
    address
      birthday
      city
      company_phone
      country
      status
      _id
      avatar {
        fullpath
        path
      }
      dni
      email
      name
      personal_email
      phone
      state
      surnames
      type
      zip
      documentation {
        CAP {
          card
          date
        }
        driver_id
        driving_cards {
          AM
          A1
          A2
          A
          B
          C1
          C
          D1
          D
          BE
          C1E
          CE
          D1E
          DE
        }
        sanitary_card {
          card
          date
        }
        sanitary_card_EU {
          card
          date
        }
        tachograph_card {
          card
          date
        }
      }
      payment_variables {
        complete_international
        complete_national
        diets {
          dinner
          dinner_international
          lunch
          lunch_international
          nocturnal
          nocturnal_international
          sunday
        }
        fixed_remuneration
        payment_per_km
        payment_type
        user_id
        values
      }
  }
}
`;

export const GET_DRIVERS_MUTATION = gql`
query myDrivers($input: DriverFiltersInput!) {
  myDrivers(input: $input){
    address
      birthday
      city
      state
      country
      journey
      avatar {
        fullpath
        path
      }
      status
      company_phone
      dni
      email
      _id
      name
      personal_email
      phone
      surnames
      type
      zip
      documentation {
        CAP {
          card
          date
        }
        CAP_images {
            _id
            face
            fullpath
            path
          }
        driving_cards_images {
            _id
            face
            fullpath
            path
          }
          tachograph_card_images {
            _id
            face
            fullpath
            path
          }
        driver_id
        driving_cards {
          AM
          A1
          A2
          A
          B
          C1
          C
          D1
          D
          BE
          C1E
          CE
          D1E
          DE
        }
        sanitary_card {
          card
          date
        }
        sanitary_card_EU {
          card
          date
        }
        tachograph_card {
          card
          date
        }
      }
       payment_variables_id {
          payment_type
          values
          fixed_remuneration
          complete_international
          complete_national
          payment_per_km
          working_day
          task_duration
          diets {
            dinner
            dinner_international
            lunch
            lunch_international
            nocturnal
            nocturnal_international
            sunday
          }
          user_id
        }
  }
}
`;

export const GET_DRIVER_TYPES_MUTATION = gql`
query driverTypes($name: String) {
  driverTypes(name: $name){
   _id
   name
   translate {
    langs {
     de
     es
     fr
     pt
     en
     it
  }
   }
  }
}
`;
export const GET_DRIVER_PAYMENT_TYPES_MUTATION = gql`
query driverPaymentTypes($name: String) {
  driverPaymentTypes(name: $name){
   _id
   name
   translate {
    langs {
     de
     es
     fr
     pt
     en
  }
   }
  }
}
`;

export const EXPORT_DRIVERS_MUTATION = gql`
query exportMyDrivers($input: DriverFiltersInput!) {
  exportMyDrivers(input: $input){
        fullpath
        path
        status
  }
}
`;

export const FINISH_JOURNEY_MUTATION = gql`
  mutation finishJourney($driver_id: ObjectId, $input: FinishJourneyInput!) {
    finishJourney(driver_id: $driver_id, input: $input) {
      status
    }
  }
`;

