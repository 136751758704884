import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';
// import { COLORS_TRUCK } from '../../utils/colors';

export const ProfileContainer = styled.div`
width: 100%;
height: 100%;
`;

export const ProfileContentContainer = styled.div`
width: 100%;
height: 100%;
flex-direction: column;
margin: auto;
`;

export const ProfileButtonsContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const ProfileFormContainer = styled.div`
width: 100%;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: column;

@media (max-width: 900px) {
  align-items: center;
justify-content: center;
}

`;

export const ProfileFormsContainer = styled.div`
width: 100%;
display: flex;
align-items: baseline;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: row;

@media (max-width: 1200px) {
  align-items: center;
justify-content: center;
}
`;

export const ProfileTableContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

export const ProfileFormInputsContainer = styled.div`
width: 450px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const ProfileSmallButtonsGroup = styled.div`
// width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
flex-wrap: wrap;
`;

export const ProfileLastButtonsContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;

@media (min-width: 1200px) {
  justify-content: flex-end;
  padding-right: 16%;
}
@media (max-width: 1200px) {
align-items: center;
justify-content: center;
}
`;

export const ProfileSmallButtonGroup = styled.div`
// width: 45%;
display: flex;
flex-direction: row;
align-items: center;
`;

export const FixedAmountText = styled.p`
padding: 0;
padding-right: 10px;
`;

export const TableTh = styled.th`
text-align: center;
`;

export const TableTd = styled.td`
padding-top: 5px;
padding-bottom: 5px;
text-align: center;
`;

export const ProfileTable = styled.table`
box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.08);
border-radius: 5px;
padding: 10px;
`;

export const ProfileFormSeparator = styled.div`
width: 100%;
background: #302521;
opacity: 0.1;
height: 2px;
margin-top: 20px;
margin-bottom: 20px;
`;

export const ProfileBillingContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;
justify-content: center;
padding-top: 20px;
padding-bottom: 20px;
@media (max-width: 400px) {
  padding: 0px;
}
`;

export const TableInfoContainer = styled.p`
margin-right: 20px;
display: flex;
flex-direction: column;
`;
export const TableTitle = styled.p`
font-weight: bold;
margin: 10px;
margin-top: 30px;
`;

export const TableInfo = styled.p`
margin: 10px;
color: ${COLORS_TRUCK.ORANGE_TRUCK};
margin-right: 20px;
margin: 0;
`;