import React, { useState, useEffect } from "react";
import {
  ProfileContainer,
  ProfileContentContainer,
  ProfileFormContainer,
  ProfileBillingContainer,
  ProfileTableContainer,
  TableTitle,
  TableInfoContainer
} from "./tasks-styles";
import i18n from '../../i18n/i18n';
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import UploaderButton from "../../components/buttons/uploader-button/UploaderButton";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import filterByIcon from "../../assets/profile/filter-by-icon.svg";
import searchIcon from "../../assets/profile/search-icon.svg";
import TruckTable from "../../components/truck-table/TruckTable";
import { TASK_DETAILS_ROUTE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { exportTasks, exportTasksExcel, getTasksAction, getTasksClientAction, getTasksStatusesAction, importTasks } from "../task-details/tasks-actions";
import TextInput from "../../components/inputs/text-input/TextInput";
import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { useSelector } from "react-redux";
import SelectButton from "../../components/buttons/select-button/Select-Button";
import config from "../../config.js";

const TasksScreen = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const filter = queryParams.get('filter') || null;
  const dispatch = useAppDispatch()
  const user = useSelector((state) => state).userSlice
  const [limit, setLimit] = useState(30)
  const [offset, setOffset] = useState(0)
  const [increaseLimit, setIncreaseLimit] = useState(30)
  const [taskStatuses, setTaskStatuses] = useState([])
  const [keepIncreasing, setKeepIncreasing] = useState(true)
  const [filterBy, setFilterBy] = useState(filter ? filter.toUpperCase() : "1")
  const [taskFilter, setTaskFilter] = useState([])
  const [loadingSelect,   setLoadingSelect ] = useState(false)
  const [loading, setLoading] = useState("1")
  const [search, setSearch] = useState("")
  const [tasks, setTasks] = useState([])
  const [taskStatus, setTaskStatus] = useState(filter ? filter.toUpperCase() : "")
  const navigate = useNavigate();

  useEffect(() => {
    getTasksFunc()
    if (user?.plan_details?.status === "CANCELED") {
      dispatch(openAlert({
        alertType: "plan-canceled",
        isAlertOpen: true
      }))
    }
    if (user?.plan_details?.status === "WAITING") {
      dispatch(openAlert({
        alertType: "plan-waiting",
        isAlertOpen: true
      }))
    }

    getTaskStatusesFunc()

  }, [taskStatus, search, filterBy, limit, offset])

  const getTaskStatusesFunc = async () => {
    try {
      const res = await getTasksStatusesAction()
      setTaskStatuses(res.data.taskStatuses)
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getTasksFunc = async (reset = false) => {
    setTasks([])
    try {
      setLoading(true)
      const data = {
        // route:  filterBy,
        status: taskStatus || null,
        pagination: {
          limit: limit,
          offset: offset
        },
      }
      const res = user.rol !== 'client' ? await getTasksAction(data) : await getTasksClientAction(data)
      const myTasks = res?.data?.myTasks || res?.data?.findTasksByClient
      if(myTasks.length <= 0 || myTasks.length <= 0) setKeepIncreasing(false)
      else setKeepIncreasing(true)

      let tasksArray = [...tasks].concat(myTasks)
      if ((!search && !taskStatus && !reset && (!filterBy || filterBy === "1")) || limit > 30) {
        setTasks(tasksArray.filter((item, index, self) => index === self.findIndex((t) => t._id === item._id)))
      } else {
        if(filterBy === "incidents") setTasks(myTasks.filter(item => item.incidents > 0)) 
        else setTasks(myTasks)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      const errorCode = localStorage.getItem("error-code")
      if (errorCode === "998") setTasks([])
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setTasks([])
      getTasksFunc()
    }
  };

  const onEditFile = async (file) => {

    try {
      const res = await importTasks(file)
      dispatch(openAlert({
        alertType: "success",
        alertMessage: i18n.t("TASKS.importSucces"),
        isAlertOpen: true
      }))
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const exportTasksExcelFunc = async () => {
    setLoadingSelect(true)
    try {
      const res = await exportTasksExcel()
      setLoadingSelect(false)
      let win = window.open(`${config.URL_BASE_FILE}${res.data.exportTasksXLSX.path}`, '_blank');
      win.focus();
    } catch (e) {
      setLoadingSelect(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const tasksFilterAction = (value) => {
    setSearch(value)
    
    setTaskFilter(tasks.filter(item => item.name.includes(value)))
  }

  const tableHeader = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('TASKS.forms.tasks.title')}
      </TableTitle>
    </TableInfoContainer>
    <FormSelect
      value={filterBy}
      containerStyle={{ width: 220 }}
      fatherContainer={{ zIndex: 4 }}
      defaultValue="1"
      onChange={({ target: { value } }) => {
        setFilterBy(value)
        if (value === "FINISHED" || value === "PROCESS" || value === "PENDING" || value === "DRAFT") {
          setTasks([])
          setTaskStatus(value)
          setSearch("")
        }

        if(value === 'routes') {
          setTasks([])

          setSearch('')
        }

        if (value === "incidents") {
          setTasks([])

          setSearch("")
        }
        if (value === "" || value === "1") {
          setTasks([])
          setTaskStatus("")
          setSearch("")
        }

      }}
      icon={filterByIcon}
      items={taskStatuses?.length > 0
        ? [
          { value: '1', label: i18n.t('VEHICLES.forms.vehicles.buttons.filterBy'), icon: filterByIcon },
          ...taskStatuses?.map(status => ({ value: status.name.toUpperCase(), label: status.translate[0]?.langs[localStorage.getItem("language") || "es"], icon: filterByIcon })),
          { value: 'routes', label: i18n.t('TASKS.forms.tasks.route'), icon: filterByIcon },
          // { value: 'incidents', label: i18n.t('VEHICLES.forms.vehicles.buttons.incidents'), icon: filterByIcon },
        ]
        : [
          { value: '1', label: i18n.t('VEHICLES.forms.vehicles.buttons.filterBy'), icon: filterByIcon }
        ]}
      required
    />
    <TextInput
      icon={searchIcon}
      value={search}
      containerStyle={{ width: 250 }}
      type="text"
      placeholder={i18n.t('TASKS.searchPlaceHolder')}
      onChange={async (e) => {
        if (e.target.value === "") getTasksFunc(true) 

        tasksFilterAction(e.target.value)
      }}
      onKeyPress={handleKeyPress}
    />
    {
      user.rol !== 'driver' && <>
        {
          user.rol !== 'client' && <UploaderButton 
            width="100"
            title={i18n.t('TASKS.buttons.import')}
            onEditFile={onEditFile}
          />
        }
        <CategoryButton 
          onClick={() => exportTasksExcelFunc()}
          active={false}
          icon="" 
          width="100"
          title={i18n.t('TASKS.forms.tasks.buttons.export')} 
        />
        <CategoryButton
          onClick={() => navigate(TASK_DETAILS_ROUTE)}
          active
          icon="" 
          width="100"
          title={i18n.t('TASKS.forms.tasks.buttons.add')}
        />
      </>
    }
  </ProfileBillingContainer>

  return (
    <ProfileContainer>
      <ProfileContentContainer>
        <ProfileFormContainer>
          <TruckTable
            type="tasks"
            header={tableHeader}
            onEndReach={() => {
              if (keepIncreasing) {
                setOffset(() =>{
                  return offset + increaseLimit
                })
                setLimit(() => {
                  return limit + increaseLimit
                })
              }

              setTimeout(() => {
                getTasksFunc()
              }, 500)
            }}
            updateTable={(reset) => getTasksFunc(reset)}
            titles={[
              { label: i18n.t('TASKS.table.dateDelivery') },
              { label: i18n.t('TASKS.forms.tasks.name') }, 
              { label: i18n.t('TASKS.table.addressee') },
              { label: i18n.t('TASKS.forms.load.address') },
              { label: i18n.t('TASKS.table.objectDelivery') },
              { label: i18n.t('TASKS.forms.tasks.route') }, 
              { label: i18n.t('TASKS.forms.tasks.issue') },
              { label: i18n.t('TASKS.forms.tasks.status') },
              { label: i18n.t('TASKS.forms.tasks.actions') },
            ]}
            data={!search ? tasks : taskFilter}
            style={{
              maxHeight: 700,
              overflow: "auto"
            }}
          />
        </ProfileFormContainer>
      </ProfileContentContainer>
    </ProfileContainer>
  )
}

export default TasksScreen