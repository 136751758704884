import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import i18n from "../../i18n/i18n";
import {
  SideMenuContainer,
  SideMenuButtonContainer,
  SideMenuButtonTitle,
  SideMenuButtonIcon,
  SideMenuButtonContentContainer,
  UnreadMessages,
  SideMenuButtonContainerLogout
} from "./side-menu-styles";
import { useNavigate } from "react-router-dom";
import { COLORS_TRUCK } from "../../utils/colors";
import { useAppDispatch } from "../../redux/store.ts";
import { PROFILE_ROUTE, ROOT_ROUTE, DRIVERS_ROUTE, VEHICLES_ROUTE, TASKS_ROUTE, ISSUES_ROUTE, ACCOUTING_ROUTE, ADMIN_DETAILS_ROUTE, BILLING_ROUTE, CLIENTS_ROUTE, CLIENT_DETAILS_ROUTE, DRIVER_DETAILS_ROUTE, CHAT_ROUTE, JOURNEYS_ROUTE, ROUTES_CLIENT_ROUTE, ROUTES_DETAILS_CLIENT, TASK_DETAILS_ROUTE } from "../../routes/routes";
import journeysIconWhite from "../../assets/side-bar/journeys-icon-white.svg";
import journeysIconBlack from "../../assets/side-bar/journeys-icon-black.svg";
import homeIconWhite from "../../assets/side-bar/house-icon-white.svg";
import homeIconBlack from "../../assets/side-bar/house-icon-black.svg";
import userIconWhite from "../../assets/side-bar/user-icon-white.svg";
import userIconBlack from "../../assets/side-bar/user-icon-black.svg";
import usersIconWhite from "../../assets/side-bar/users-icon-white.svg";
import usersIconBlack from "../../assets/side-bar/users-icon-black.svg";
import truckIconWhite from "../../assets/side-bar/truck-icon-white.svg";
import truckIconBlack from "../../assets/side-bar/truck-icon-black.svg";
import squareIconWhite from "../../assets/side-bar/box-icon-white.svg";
import squareIconBlack from "../../assets/side-bar/box-icon-black.svg";
import barChartIconWhite from "../../assets/side-bar/bar-chart-icon-white.svg";
import barChartIconBlack from "../../assets/side-bar/bar-chart-icon-black.svg";
import messageSquareIconWhite from "../../assets/side-bar/message-square-white.svg";
import messageSquareIconBlack from "../../assets/side-bar/message-square-black.svg"; 
import warningIconWhite from "../../assets/side-bar/warning-icon-white.svg";
import warningIconBlack from "../../assets/side-bar/warning-icon-black.svg";
import logoutIconRed from "../../assets/general/log-out-icon-red.svg";
import arrowGuideBlack from "../../assets/general/arrow-guide-black.svg";
import arrowGuideWhite from "../../assets/general/arrow-guide-white.svg";
import { useSelector } from "react-redux";
import { setGeneralLogout } from "../../redux/reducers/user.ts";
import { closeMenu } from "../../redux/reducers/app.ts";
import { useSubscription } from "@apollo/client";
import {
  logoutAction
} from "./side-menu-actions";
import { LISTEN_OPEN_INCIDENTS_SUBSCRIPTION, LISTEN_UNREAD_MESSAGES_SUBSCRIPTION } from "../user-home/user-home-queries";
import { getOpenIncidentsAction } from "../user-home/user-home-actions";

const SideMenu = () => {
  const navigate = useNavigate()
  const user = useSelector((state) => state).userSlice
  const location = useLocation();
  const activeRoute = location.pathname
  const dispatch = useAppDispatch()
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState(localStorage.getItem("numberOfUnreadMessages") || 0)
  const [openIncidents, setOpenIncidents] = useState("")

  useEffect(() => {
    getStats()
  }, [])

  useSubscription(LISTEN_UNREAD_MESSAGES_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      if (data?.unreadMessages?.total) {
        setNumberOfUnreadMessages(data?.unreadMessages?.total)
      }
    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  useSubscription(LISTEN_OPEN_INCIDENTS_SUBSCRIPTION, {
    variables: null,
    onSubscriptionData: ({ subscriptionData: { data, error } }) => {
      if (data?.openIncidents?.total) {
        setOpenIncidents(data?.openIncidents?.total)
      }

    },
    shouldResubscribe: true,
    fetchPolicy: "network-only"
  })

  const getStats = async () => {
    try {
      const openIncidents = await getOpenIncidentsAction()
      if (openIncidents?.data?.openIncidents?.total) {
        setOpenIncidents(openIncidents?.data?.openIncidents?.total)
      }
    } catch (e) {
    }
  }

  const logout = () => {
    logoutAction()
    dispatch(closeMenu())
    dispatch(setGeneralLogout())
  }

  const isChat = activeRoute.split("/").filter(item => item === "chat").length > 0 ? true : false
  return (
    <SideMenuContainer>
      {user.rol === "superadmin"
        ? 
          <>
          <SideMenuButtonContainer
            onClick={() => navigate(ROOT_ROUTE)}
            style={{ background: activeRoute === ROOT_ROUTE ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
            <SideMenuButtonContentContainer>
              <SideMenuButtonIcon src={activeRoute === ROOT_ROUTE ? homeIconWhite : homeIconBlack} />
              <SideMenuButtonTitle style={{ color: activeRoute === ROOT_ROUTE ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                {i18n.t('SIDE_MENU.buttons.home')}
              </SideMenuButtonTitle>
            </SideMenuButtonContentContainer>
          </SideMenuButtonContainer>
          <SideMenuButtonContainer
            onClick={() => navigate(ADMIN_DETAILS_ROUTE)}
            style={{ background: activeRoute === ADMIN_DETAILS_ROUTE ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
            <SideMenuButtonContentContainer>
              <SideMenuButtonIcon src={activeRoute === ADMIN_DETAILS_ROUTE ? userIconWhite : userIconBlack} />
              <SideMenuButtonTitle style={{ color: activeRoute === ADMIN_DETAILS_ROUTE ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                {i18n.t('SIDE_MENU.buttons.administration')}
              </SideMenuButtonTitle>
            </SideMenuButtonContentContainer>
          </SideMenuButtonContainer>
          <SideMenuButtonContainer
            onClick={() => navigate(CLIENTS_ROUTE)}
            style={{ background: activeRoute === CLIENTS_ROUTE || activeRoute === CLIENT_DETAILS_ROUTE ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
            <SideMenuButtonContentContainer>
              <SideMenuButtonIcon src={activeRoute === CLIENTS_ROUTE || activeRoute === CLIENT_DETAILS_ROUTE ? usersIconWhite : usersIconBlack} />
              <SideMenuButtonTitle style={{ color: activeRoute === CLIENTS_ROUTE || activeRoute === CLIENT_DETAILS_ROUTE ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                {i18n.t('SIDE_MENU.buttons.clients')}
              </SideMenuButtonTitle>
            </SideMenuButtonContentContainer>
          </SideMenuButtonContainer>
          <SideMenuButtonContainer
            onClick={() => navigate(BILLING_ROUTE)}
            style={{ background: activeRoute === BILLING_ROUTE ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
            <SideMenuButtonContentContainer>
              <SideMenuButtonIcon src={activeRoute === BILLING_ROUTE ? barChartIconWhite : barChartIconBlack} />
              <SideMenuButtonTitle style={{ color: activeRoute === BILLING_ROUTE ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                {i18n.t('SIDE_MENU.buttons.billing')}
              </SideMenuButtonTitle>
            </SideMenuButtonContentContainer>
          </SideMenuButtonContainer>
          </>
        :
          <>
            {
              user.rol == "client"
              ?
                <>
                  <SideMenuButtonContainer
                    onClick={() => navigate(ROOT_ROUTE)}
                    style={{ background: activeRoute === ROOT_ROUTE ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === ROOT_ROUTE ? homeIconWhite : homeIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === ROOT_ROUTE ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.home')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => navigate(`${PROFILE_ROUTE}`)}
                    style={{ background: activeRoute === "/profile" ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === "/profile" ? userIconWhite : userIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === "/profile" ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.profile')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => navigate(TASKS_ROUTE)}
                    style={{ background: activeRoute === TASKS_ROUTE || activeRoute === TASK_DETAILS_ROUTE  ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === TASKS_ROUTE || activeRoute === TASK_DETAILS_ROUTE ? squareIconWhite : squareIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === TASKS_ROUTE || activeRoute === TASK_DETAILS_ROUTE ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.tasks')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => {
                      navigate(CHAT_ROUTE)
                      setNumberOfUnreadMessages("")
                    }}
                    style={{ background: isChat ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      {numberOfUnreadMessages > 0 && <UnreadMessages
                        style={{
                          color: isChat ? COLORS_TRUCK.ORANGE_TRUCK : "white",
                          background: isChat ? "white" : COLORS_TRUCK.ORANGE_TRUCK
                        }}>{numberOfUnreadMessages}</UnreadMessages>}
                      <SideMenuButtonIcon src={isChat ? messageSquareIconWhite : messageSquareIconBlack} />
                      <SideMenuButtonTitle style={{ color: isChat ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.chats')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                </>
              :
                user.rol == 'driver' ?
                <>
                  <SideMenuButtonContainer
                    onClick={() => navigate(ROOT_ROUTE)}
                    style={{ background: activeRoute === ROOT_ROUTE ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === ROOT_ROUTE ? homeIconWhite : homeIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === ROOT_ROUTE ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.home')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => navigate(PROFILE_ROUTE)}
                    style={{ background: activeRoute === "/profile" ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === "/profile" ? userIconWhite : userIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === "/profile" ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.profile')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => {
                      navigate(CHAT_ROUTE)
                      setNumberOfUnreadMessages("")
                    }}
                    style={{ background: isChat ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      {numberOfUnreadMessages > 0 && <UnreadMessages
                        style={{
                          color: isChat ? COLORS_TRUCK.ORANGE_TRUCK : "white",
                          background: isChat ? "white" : COLORS_TRUCK.ORANGE_TRUCK
                        }}>{numberOfUnreadMessages}</UnreadMessages>}
                      <SideMenuButtonIcon src={isChat ? messageSquareIconWhite : messageSquareIconBlack} />
                      <SideMenuButtonTitle style={{ color: isChat ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.chats')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                </>
                :
                <>
                  <SideMenuButtonContainer
                    onClick={() => navigate(ROOT_ROUTE)}
                    style={{ background: activeRoute === ROOT_ROUTE ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === ROOT_ROUTE ? homeIconWhite : homeIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === ROOT_ROUTE ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.home')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => navigate(PROFILE_ROUTE)}
                    style={{ background: activeRoute === "/profile" ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === "/profile" ? userIconWhite : userIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === "/profile" ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.profile')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => navigate(DRIVERS_ROUTE)}
                    style={{ background: activeRoute === DRIVERS_ROUTE || activeRoute === DRIVER_DETAILS_ROUTE ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === DRIVERS_ROUTE || activeRoute === DRIVER_DETAILS_ROUTE ? usersIconWhite : usersIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === DRIVERS_ROUTE || activeRoute === DRIVER_DETAILS_ROUTE ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.drivers')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => navigate(VEHICLES_ROUTE)}
                    style={{ background: activeRoute === "/vehicles" ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === "/vehicles" ? truckIconWhite : truckIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === "/vehicles" ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.vehicles')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => navigate(TASKS_ROUTE)}
                    style={{ background: activeRoute === "/tasks" ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === "/tasks" ? squareIconWhite : squareIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === "/tasks" ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.tasks')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => {
                      navigate(CHAT_ROUTE)
                      setNumberOfUnreadMessages("")
                    }}
                    style={{ background: isChat ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      {numberOfUnreadMessages > 0 && <UnreadMessages
                        style={{
                          color: isChat ? COLORS_TRUCK.ORANGE_TRUCK : "white",
                          background: isChat ? "white" : COLORS_TRUCK.ORANGE_TRUCK
                        }}>{numberOfUnreadMessages}</UnreadMessages>}
                      <SideMenuButtonIcon src={isChat ? messageSquareIconWhite : messageSquareIconBlack} />
                      <SideMenuButtonTitle style={{ color: isChat ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.chats')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => navigate(CLIENTS_ROUTE)}
                    style={{ background: activeRoute === CLIENTS_ROUTE || activeRoute.includes(CLIENT_DETAILS_ROUTE) ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === CLIENTS_ROUTE || activeRoute.includes(CLIENT_DETAILS_ROUTE) ? usersIconWhite : usersIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === CLIENTS_ROUTE || activeRoute.includes(CLIENT_DETAILS_ROUTE) ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.clients')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => navigate(ROUTES_CLIENT_ROUTE)}
                    style={{ background: activeRoute === ROUTES_CLIENT_ROUTE || activeRoute === ROUTES_DETAILS_CLIENT ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === ROUTES_CLIENT_ROUTE || activeRoute === ROUTES_DETAILS_CLIENT ? arrowGuideWhite : arrowGuideBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === ROUTES_CLIENT_ROUTE || activeRoute === ROUTES_DETAILS_CLIENT ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('ROUTES.title')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => navigate(JOURNEYS_ROUTE)}
                    style={{ background: activeRoute === "/journeys" ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === "/journeys" ? journeysIconWhite : journeysIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === "/journeys" ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('JOURNEYS.title')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => navigate(ACCOUTING_ROUTE)}
                    style={{ background: activeRoute === "/accounting" ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      <SideMenuButtonIcon src={activeRoute === "/accounting" ? barChartIconWhite : barChartIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === "/accounting" ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.accounts')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                  <SideMenuButtonContainer
                    onClick={() => navigate(ISSUES_ROUTE)}
                    style={{ background: activeRoute === "/issues" ? COLORS_TRUCK.ORANGE_TRUCK : 'transparent' }}>
                    <SideMenuButtonContentContainer>
                      {openIncidents > 0 && <UnreadMessages
                        style={{
                          color: activeRoute === "/issues" ? COLORS_TRUCK.ORANGE_TRUCK : "white",
                          background: activeRoute === "/issues" ? "white" : COLORS_TRUCK.ORANGE_TRUCK
                        }}>{openIncidents}</UnreadMessages>}
                      <SideMenuButtonIcon src={activeRoute === "/issues" ? warningIconWhite : warningIconBlack} />
                      <SideMenuButtonTitle style={{ color: activeRoute === "/issues" ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.BLACK_TRUCK }} >
                        {i18n.t('SIDE_MENU.buttons.issues')}
                      </SideMenuButtonTitle>
                    </SideMenuButtonContentContainer>
                  </SideMenuButtonContainer>
                </>
            }
          </>
        }

      <SideMenuButtonContainerLogout onClick={() => logout()} style={{ marginTop: 100 }}>
        <SideMenuButtonContentContainer>
          <SideMenuButtonIcon src={logoutIconRed} />
          <SideMenuButtonTitle style={{ color: COLORS_TRUCK.ORANGE_TRUCK }} >
            {i18n.t('SIDE_MENU.logout')}
          </SideMenuButtonTitle>
        </SideMenuButtonContentContainer>
      </SideMenuButtonContainerLogout>
    </SideMenuContainer >
  )
}

export default SideMenu