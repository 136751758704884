// import * as navigation from '../../routes/navigation';
import client from '../../utils/api';
import { CREATE_TASK_MUTATION, REMOVE_TASK_MUTATION, UPDATE_TASK_MUTATION, GET_TASKS_MUTATION, EXPORT_TASKS_CSV_MUTATION, EXPORT_TASKS_EXCEL_MUTATION, REMOVE_TASK_LOAD_MUTATION, CREATE_TASK_LOAD_MUTATION, REMOVE_TASK_DOWNLOAD_MUTATION, CREATE_TASK_DOWNLOAD_MUTATION, UPDATE_TASK_LOAD_MUTATION, UPDATE_TASK_DOWNLOAD_MUTATION, GET_TASKS_STATUSES_MUTATION, GET_TASK_IMAGES_MUTATION, IMPORT_TASK_EXCEL_MUTATION, ADD_IMAGES_TASK, GET_TASK_BY_ID_MUTATION, CHANGE_TASK_STATUS, DELETE_TASK_IMAGE, UPDATE_TASK_COORDS_MUTATION, GET_TASKS_CLIENT_MUTATION } from './tasks-queries';

/**
 * createTaskAction - action for create a driver
 *
 * @param {object} formData - createTaskAction data
 * @returns {any}
 */
export const createTaskAction = ( idClient, formData ) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: CREATE_TASK_MUTATION,
        variables: {
          company_or_client_id_input: idClient,
          input: {
            ...formData
          },
        },
      })

      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
};

/**
 * addLoadAction - action for create a driver
 *
 * @param {string} taskId - task id
 * @param {object} formData - addLoadAction data
 * @returns {any}
 */
export const addLoadAction = (
  taskId,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("addLoadAction / formData: ", formData)
      const response = await client.mutate({
        mutation: CREATE_TASK_LOAD_MUTATION,
        variables: {
          input: [{
            ...formData
          }],
          task_id: taskId,
        },
      });
      // console.log("addLoadAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("addLoadAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * addDownloadAction - action for create a driver
 *
 * @param {string} taskId - task id
 * @param {object} formData - addDownloadAction data
 * @returns {any}
 */
export const addDownloadAction = (
  taskId,
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("addDownloadAction / formData: ", formData)
      const response = await client.mutate({
        mutation: CREATE_TASK_DOWNLOAD_MUTATION,
        variables: {
          input: [{
            ...formData
          }],
          task_id: taskId,
        },
      });
      // console.log("addDownloadAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
        // console.log("addDownloadAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * removeTaskAction - action for create a driver
 *
 * @param {string} vehicleId - vehicle id
 * @returns {any}
 */
export const removeTaskAction = (
  taskId,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: REMOVE_TASK_MUTATION,
        variables: {
          task_id: taskId,
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * removeLoadAction - action for create a driver
 *
 * @param {string} taskId - task id
 * @param {string} loadId - load id
 * @returns {any}
 */
export const removeLoadAction = (
  taskId,
  loadId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: REMOVE_TASK_LOAD_MUTATION,
        variables: {
          input: {
            object_id: loadId,
            parent_id: taskId,
          }
        },
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * removeDownloadAction - action for create a driver
 *
 * @param {string} taskId - task id
 * @param {string} downloadId - download id
 * @returns {any}
 */
export const removeDownloadAction = (
  taskId,
  downloadId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // console.log("removeDownloadAction / taskId: ", taskId)
      // console.log("removeDownloadAction / downloadId: ", downloadId)
      // setLoading(true);
      const response = await client.mutate({
        mutation: REMOVE_TASK_DOWNLOAD_MUTATION,
        variables: {
          input: {
            object_id: downloadId,
            parent_id: taskId,
          }
        },
      });
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("removeDownloadAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateTaskAction - action for update a driver
 *
 * @param {String} task_id - vehicle id
 * @param {object} input - updateTaskAction data
 * @returns {any}
 */
export const updateTaskAction = (
  task_id,
  input
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: UPDATE_TASK_MUTATION,
        variables: {
          input,
          task_id,
        },
      })
      resolve(response)
      } catch (error) {
      // console.log("updateTaskLoadAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * updateTaskLoadAction - action for update a driver
 *
 * @param {object} formData - updateTaskLoadAction data
 * @returns {any}
 */
export const updateTaskLoadAction = ( task_id, input ) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: UPDATE_TASK_LOAD_MUTATION,
        variables: {
          task_id,
          input
        },
      });
      resolve(response)
      } catch (error) {
      reject(error)
    }
  })
};

/**
 * updateTaskdownloadAction - action for update a driver
 *
 * @param {object} formData - updateTaskdownloadAction data
 * @returns {any}
 */
export const updateTaskdownloadAction = ( task_id, input ) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: UPDATE_TASK_DOWNLOAD_MUTATION,
        variables: {
          task_id,
          input
        },
      });
      resolve(response)
      } catch (error) {
      reject(error)
    }
  })
};

/**
 * getTasksAction - action for get drivers
 *
 * @param {object} formData - getTasksAction data
 * @returns {any}
 */
export const getTasksAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.query({
        query: GET_TASKS_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      
      resolve(response)
      } catch (error) {
      reject(error)
    }
  })
};

/**
 * getTasksAction - action for get clients
 *
 * @param {object} formData - getTasksClientAction data
 * @returns {any}
 */
export const getTasksClientAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.query({
        query: GET_TASKS_CLIENT_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      
      resolve(response)
      } catch (error) {
      reject(error)
    }
  })
};

export const getTaskByIdAction = (task_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.query({
        query: GET_TASK_BY_ID_MUTATION,
        variables: {
          task_id
        },
        fetchPolicy: 'network-only',
      });
      
      resolve(response)
      } catch (error) {
      reject(error)
    }
  })
}

/**
 * getTaskImagesAction - action for get drivers
 *
 * @param {object} formData - getTaskImagesAction data
 * @returns {any}
 */
export const getTaskImagesAction = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("getTaskImagesAction / formData: ", formData)
      const response = await client.query({
        query: GET_TASK_IMAGES_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      // console.log("getTaskImagesAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("getTaskImagesAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getTasksStatusesAction - action for get tasks statuses
 *
 * @returns {any}
 */
export const getTasksStatusesAction = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_TASKS_STATUSES_MUTATION,
        variables: {
          name: ""
        },
        fetchPolicy: 'network-only',
      });
      // console.log("getTasksStatusesAction / response: ", response)
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * exportTasks - action for get drivers
 *
 * @param {object} formData - exportTasks data
 * @returns {any}
 */
export const exportTasks = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("exportTasks / formData: ", formData)
      const response = await client.query({
        query: EXPORT_TASKS_CSV_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      // console.log("exportTasks / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

export const exportTasksExcel = (
  formData,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.query({ 
        query: EXPORT_TASKS_EXCEL_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
};

/**
 * importTasks - Import task for platform
 *
 * @param {File} file - importTasks data
 * @returns {any}
 */
export const importTasks = (
  file,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.mutate({
        mutation: IMPORT_TASK_EXCEL_MUTATION,
        variables: {
          file: file,
        },
      });
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
};

export const addImagesTaskAction = ( task_id, images ) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: ADD_IMAGES_TASK,
        variables: {
          task_id,
          images
        }
      })

      resolve(response)
    } catch(err) {
      reject(err)
    }
  })
}

export const removeImageTaskAction = (input) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: DELETE_TASK_IMAGE,
        variables: {
          input
        }
      })

      resolve(response)
    } catch(err) {
      reject(err)
    }
  })
}

export const changeTaskStatusAction = (task_id, status) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: CHANGE_TASK_STATUS,
        variables: {
          task_id,
          status
        }
      })

      resolve(response)
    } catch(err) {
      reject(err)
    }
  })
}

export const updateCoordsAction = (input, task_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: UPDATE_TASK_COORDS_MUTATION,
        variables: {
          task_id,
          input
        }
      })

      resolve(response)
    } catch(err) {
      reject(err)
    }
  })
}