import React, { useState } from 'react'
import { TextInputContainer, CustomTextInput, FormTextInputMainContainer, TextInfo, CustomTextInputColor, ModalContainer, SendData, ModalContainerColor } from "./form-color-input-styles";
import { COLORS_TRUCK } from '../../../../utils/colors';
import { ColorSquare } from '../../../buttons/truck-map/truck-map-styles';
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

export const FormColorInput = ({value, showError, containerStyle, returnInfo}) => {
  const [palette, setPalette] = useState(false)
  const [ color, setColor ] = useColor("#123123")

  const handleChange = (e) => returnInfo(e)

  const PaletteColor = <ModalContainerColor>
    <ColorPicker color={color} onChange={(e) => {
      setColor(e)
      handleChange(e.hex)
    }} />
    <SendData style={{background:  COLORS_TRUCK.ORANGE_TRUCK }} onClick={() => setPalette(false) } >Guardar</SendData>
  </ModalContainerColor>

  return (
    <FormTextInputMainContainer>
      <TextInputContainer onClick={() => setPalette(true)} style={Object.assign({ border: showError ? `1px solid ${COLORS_TRUCK.ORANGE_TRUCK}` : `1px solid rgba(0, 0, 0, 0.23)`}, containerStyle)}>
        <CustomTextInput
            value={value}
            type="text"
            onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
            onChange={(e) => handleChange(e.target.value)}
            placeholder='#FFFFFF'
        />
        <ColorSquare style={{background: value?.includes('#') ? value : `#${value}`, width: 25, height: 20 }} />
      </TextInputContainer>
      {
        palette && <ModalContainer>{ PaletteColor }</ModalContainer>
      }
    </FormTextInputMainContainer>
  )
}