import React, { useEffect, useState } from 'react'
import { 
    ModalContainer, 
    ModalHeader, 
    InputContainer,
    ModalSubContainer, 
    TextInput, 
    SendData, 
    TimesButton 
} from './form-modal-styles'
import FormSelect from '../../inputs/forms/form-select/FormSelect'
import Times from '../../../assets/general/times.svg'
import FormTextInput from '../../inputs/forms/form-text-input/FormTextInput'
import { FormColorInput } from '../../inputs/forms/form-color-input/FormColorInput'
import { COLORS_TRUCK } from '../../../utils/colors'
import Loader from '../../loader/Loader'

export const FormModal = ({
    closeModal, 
    title, 
    info, 
    fields, 
    returnInfo, 
    titleButton,
    edit,
    loading
}) => {
    const [close, setClose] = useState(false)
    const [showInputsErrors, setShowInputsErrors] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [data, setData] = useState([])
    const [dataInput, setDataInput] = useState("null")

    useEffect(() => {
      setData(fields)
      !edit && setButtonDisabled(true)
    }, [])
    
    const handleCloseModal = () => {
        setClose(!closeModal)

        closeModal(close)
    }

    const getData = (newValue, id) => {
        for(let index in fields) {
            if(fields[index].id == id) {
                data[index].value = newValue
                setDataInput(newValue)
                setData(data)
            }
        }
    }

    const sendData = () => {
        for(let index in data) {
            let validate = !data[index].value ? true : false
            setShowInputsErrors(validate)
            setButtonDisabled(validate)
        }

        if(!showInputsErrors) returnInfo(data, edit)  
    }

    const validateData = () => {
        setButtonDisabled(false)
        for(let index in data) {
            if(data[index].required) setButtonDisabled(!data[index].value ? true : false)
        }
    }

  return (
    <ModalContainer>
        <ModalSubContainer>
            <ModalHeader>
                <div style={{ color: COLORS_TRUCK.WHITE_TRUCK}}>
                    {title}
                </div>
                <TimesButton onClick={handleCloseModal} src={Times} />
            </ModalHeader>
            <TextInput>
                {info}
            </TextInput>
            {
                fields?.map( (item, index) => 
                    <>
                        <TextInput style={{color: COLORS_TRUCK.ORANGE_TRUCK}}>
                            { item.title }
                        </TextInput>
                        <InputContainer>
                            {
                                item.type == "select" ?
                                    <FormSelect 
                                        value={ item.value || dataInput }
                                        onChange={({ target: { value } }) => {
                                            getData(value, item.id)
                                            validateData()
                                        }}
                                        items={item.option}
                                        showError={(!item.value && item.required) && showInputsErrors ? true : false}
                                    />
                                    :
                                    (
                                        item.type == "color" ? 
                                            <FormColorInput value={item.value} showError={!item.value && showInputsErrors ? true : false} 
                                                returnInfo={(data) => {
                                                    getData(data, item.id)
                                                    validateData()
                                                }}/>
                                            :
                                            <FormTextInput 
                                                value={item.value} 
                                                showError={(!item.value && item.required) && showInputsErrors ? true : false} 
                                                type={item.type} 
                                                onChange={({ target: { value } }) => {
                                                    getData(value, item.id)
                                                    validateData()
                                                }}
                                            />
                                    )
                            }
                        </InputContainer>
                    </>
                )
            }
            <SendData onClick={() => sendData()}  disabled={ buttonDisabled } style={{background:  COLORS_TRUCK.ORANGE_TRUCK }}>
                {
                    !loading ? 
                    <>{titleButton}</>
                    :
                    <Loader color={buttonDisabled ? COLORS_TRUCK.WHITE_TRUCK : COLORS_TRUCK.ORANGE_TRUCK } />
                }
            </SendData>
        </ModalSubContainer>
    </ModalContainer>
  )
}