import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import allLocales from "@fullcalendar/core/locales-all";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { useState } from "react";
import moment from "moment";
import { CalendarContainer } from "./truck-calendar-styles";
import { openAlert } from "../../redux/reducers/app.ts";
import i18n from "../../i18n/i18n";
import { useAppDispatch } from "../../redux/store.ts";
import { getTaskByIdAction, updateTaskAction } from "../../screens/task-details/tasks-actions";
import { FormCalendar } from "../modal/form-calendar/FormCalendar.jsx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TASK_DETAILS_ROUTE } from "../../routes/routes.jsx";
import { useEffect } from "react";

export const TruckCalendar = ({style, task, setTask, routes, reloadList}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const lang = localStorage.getItem('language') || 'es'
    const [showTask, setShowTask] = useState(false)
    const [routesSelect, setRoutesSelect] = useState([])
    const [loadingTask, setLoadingTask] = useState(false)
    const [taskSelected, setTasksSelected] = useState({})
    const user = useSelector((state) => state).userSlice

    useEffect(() => {
      getRoutesForSelect()
    }, [routes])
    
    const handleChangeUpdate = (e) => {
        for(let index in task) {
            if(e.oldEvent.id == task[index].id) getNewDate(task[index], e.event._def, e.event._instance, index)
        }
    }

    const getRoutesForSelect = () => {
        let route = routes?.map((item) => ({ label: item.name, value: item._id }))

        setRoutesSelect(route)
    }

    const getNewDate = (oldData, newData, newDate, index) => {
        let data = {}
        let fields = ['title', 'start', 'id', 'backgroundColor', 'borderColor', 'task']
        for(let index in fields) {
            if(fields[index] == 'id') {
                data[fields[index]] = newData.publicId
                continue
            }
            if(fields[index] == 'title') {
                data[fields[index]] = newData[fields[index]] 
                continue
            }
            if(fields[index] == 'backgroundColor' || fields[index] == 'borderColor') {
                data[fields[index]] = newData.ui[fields[index]]
                continue
            }
            if(fields[index] == 'start' ) {
                data[fields[index]] = moment(newDate.range[fields[index]]).format('YYYY-MM-DD')
                continue
            }

            data[fields[index]] = oldData.task
        }

        asignNewDate(task[index], data, index)
    }

    const asignNewDate = (oldData, newData) => {
        dispatch(openAlert({
            alertType: "question",
            alertMessage: 'Estas seguro de que quieres cambiar la fecha de esta tarea',
            isAlertOpen: true,
            alertOnYesButtonTitle: i18n.t("GENERAL.accept"),
            alertOnYes: async () => {
                let dateNew = new Date(newData.start)
                dateNew.setDate(dateNew.getDate() + 1)
                const data = {
                    start_date: dateNew.setHours(0,0,0,0),
                    end_date: dateNew.setHours(0,0,0,0),
                    name: oldData.task?.name,
                    route: oldData.task?.route?._id || null
                }

                try {
                    await updateTaskAction(oldData.id, data)
                    reloadList()
                    
                    for(let index in task) {
                        if(task[index].id == oldData.id) {
                            task[index] = newData
                            setTask(task)
                        }
                    }

                    dispatch(openAlert({
                        alertType: "success",
                        alertMessage: i18n.t("TASKS.updatedSuccessMessage"),
                        isAlertOpen: true
                    }))
                } catch (e) {
                    dispatch(openAlert({
                    alertType: "error",
                    isAlertOpen: true
                    }))
                }

            }
        }))
    }

    const showFormTask = async (item) => {
        user.rol !== 'client' ? setShowTask(true) : navigate(`${TASK_DETAILS_ROUTE}/${item.event._def.publicId}`)
        setLoadingTask(true)
        try {
            const { data } = await getTaskByIdAction(item.event._def.publicId)
            setLoadingTask(false)
            setTasksSelected(data.findTaskById)
        } catch (error) {
            setLoadingTask(false)
        }
    }



    return (
        <div style={{ width: '93%' }}>
            <div>
                { (showTask) && 
                    <FormCalendar 
                        task={taskSelected} 
                        loadingTask={ loadingTask } 
                        routes={routesSelect} 
                        reloadList={reloadList} 
                        setShowCalendar={setShowTask} 
                    /> 
                }
            </div>
            <CalendarContainer style={{style}}>
                <FullCalendar 
                    plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin ]}
                    selectMirror={true}
                    locales={allLocales}
                    eventChange={ handleChangeUpdate }
                    locale={ lang }
                    droppable={true}
                    editable={true}
                    events={task}
                    eventOrder={(a, b) => a.order - b.order}
                    eventOrderStrict
                    eventClick={showFormTask}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        left: 'prev,next today', 
                        center: 'title', 
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                />
            </CalendarContainer>
        </div>
    )
}