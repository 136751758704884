import React, {useEffect, useState} from 'react'
import { 
    ButtonContainer, 
    ButtonImg, 
    PlansRoutesContainer, 
    PlansRoutesHeader,
    TextHeader,
    TaskDetailsContainer,
    TaskDetailsItemsContainer,
    TaskDetailsTitle,
    TaskDetailsContentLink,
    TaskDetailsItemContainer,
    ContentContainer,
    TaskDetailsContent,
    DownloadItenary,
    DateContainer,
    OptionContainer,
    ColorSquare,
    InfoContainer,
    InfoContentParagraph,
    InfoItemsContainer,
    OptionsContainer,
    MainContainer,
    TableLoaderContainer
} from './truck-map-styles'
import FormDateInput from "../../inputs/forms/form-date-input/FormDateInput";

import {
    MapContainer,
    TileLayer,
    Popup,
    Marker,
    Polyline
  } from 'react-leaflet';
import MapIcon from "../../../assets/general/map-icon.svg";
import MenuIcon from "../../../assets/home-header/menu-icon-white.svg"
import Download from "../../../assets/general/download.svg";
import { useSelector } from "react-redux";
import { TASK_DETAILS_ROUTE } from "../../../routes/routes";
import i18n from '../../../i18n/i18n';
import { useNavigate } from "react-router-dom";
import { COLORS_TRUCK } from '../../../utils/colors';
import Times from '../../../assets/general/times.svg'
import { exportTasks, getTasksAction } from '../../../screens/task-details/tasks-actions';
import redPinIcon from "../../../assets/home/red-pin.svg";
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import Loader from '../../loader/Loader';
import { useAppDispatch } from '../../../redux/store.ts';
import { openAlert } from '../../../redux/reducers/app.ts';
import config from '../../../config.js';
import SortableRoutes from './SortableRoutes.jsx';
import { FormCalendar } from '../../modal/form-calendar/FormCalendar.jsx';

export const    TruckMap = ({dailyPlanning, routes}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [map, setMap] = useState(false)
    const [showTask, setShowTask] = useState(false)
    const [routesTasks, setRoutesTasks] = useState([])
    const [tasksSelected, setTasksSelected] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingExport, setLoadingExport] = useState(false)
    const [date, setDate] = useState(new Date().setHours(0,0,0,0))
    const user = useSelector((state) => state).userSlice
    const center = [42.25, -2.5]
    const [mapCoords, setMapCoords] = useState([])
    const [lineCoords, setLineCoords] = useState([])
    const statusOption = {
        'PENDING': COLORS_TRUCK.BLACK_TRUCK,
        'PROCESS': COLORS_TRUCK.BLUE_TRUCK,
        'FINISHED': COLORS_TRUCK.GREEN_TRUCK,
    }


    const getTaskFunc = async (dateFunc, route) => {
        // setLineCoords([])
        setLoading(true)
        try {
            let line = []
            let mapCoordsArray = []
            const filter = {
                route: !route ? dailyPlanning && routes[0]._id : null,
                end_date: dateFunc || date
            }

            const { data } = await getTasksAction(filter)
            if(!route) {
                setRoutesTasks([])
                assignRoutesTasks(data.myTasks, dateFunc, line, mapCoordsArray)
                return setLoading(false)
            }
            
            setLoading(false)
            return data.myTasks
        } catch (error) {
            setLoading(false)
        }
    }

    const lightenColor = (color, percent) => {
        let num = parseInt(color.replace("#",""), 16),  amt = Math.round(2.55 * percent), R = (num >> 16) + amt, G = (num >> 8 & 0x00FF) + amt, B = (num & 0x0000FF) + amt;

        return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (G<255?G<1?0:G:255)*0x100 + (B<255?B<1?0:B:255)).toString(16).slice(1);
    }

    const asignCoords = (tasks, route, mapCoordsArray) => {
        for(let index in tasks) {
            const items = tasks[index].unload
            let coordItem = items?.coords.split(',')
            let routeColorLight = lightenColor(route.color, 50)
            let coords = [coordItem[0], coordItem[1]]

            const data = {
                position: coordItem[0] && coordItem[1] ? coords : null,
                color: route?.color,
                details: {
                    task: items.name ? items.name : "",
                    driver: route.driver?.name || "N/A",
                    vehicle: route.vehicle?.plate || "N/A",
                    trailer: route.trailer?.plate || "N/A"
                  },
                  icon: divIcon({
                    html: renderToStaticMarkup(
                        <div style={{
                            background: routeColorLight, 
                            padding: 3, paddingLeft: 10, 
                            paddingRight: 10, 
                            fontWeight: 'bold', 
                            border: `1px solid ${route.color}`, 
                            borderRadius: '100%', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center'
                        }}>
                            {items.order}
                        </div>
                    ),
                  }),
                  _id: tasks[index]._id
            }
            mapCoordsArray.push(data)
        }

        setMapCoords(mapCoordsArray)
    }

    const exportTaskFunc = async () => {
        setLoadingExport(true)
        try {
            const data = {
                start_date: date
            }

            const res = await exportTasks(data)
            let win = window.open(`${config.URL_BASE_FILE}${res.data.exportMyTasks.path}`, '_blank');
            win.focus();
            setLoadingExport(false)
          } catch (e) {
            setLoadingExport(false)
            dispatch(openAlert({
              alertType: "error",
              isAlertOpen: true
            }))
          }
    }

    const calculateDate = (item) => {
        let currentDate = new Date()
        let dates = [item.load, item.unload]

        let nearDate = dates.sort( (a,b) => {
            return Math.abs(currentDate - a.date) < Math.abs(currentDate - b.date) ? a : b;
        })

        return nearDate[0]
    }

    const assignRoutesUnAssignedTasks = async (tasks, dateFunc) => {
        let tasksUnassigned = await getTaskFunc(dateFunc, true)

        let data= [
            {
                _id: 0,
                name: 'unassigned',
                driver: '',
                color: '#848583',
                tasks: tasksUnassigned
            }
        ]

        setRoutesTasks(tasks.concat(data))
    }

    const getShowTaskFunc = async (task) => {
        user.rol !== 'client' ? setShowTask(true) : navigate(`${TASK_DETAILS_ROUTE}/${task._id}`)
        setMap(false)
        setTasksSelected(task)
    }

    const assignRoutesTasks = (tasks, dateFunc, line, mapCoordsArray) => {
        let routesArray = []
        for(let index in routes) {
            let data= {
                _id: '',
                name: '',
                driver: '',
                tasks: []
            }

            let taskRoute = tasks.filter(item => item.route?._id == routes[index]._id)
            let sortTasks = taskRoute.sort((a, b) => a.unload.order - b.unload.order)
            assignCoordsPolyline(sortTasks, routes[index].color, line)
            asignCoords(sortTasks, routes[index], mapCoordsArray)
            data.name = routes[index]?.name
            data._id = routes[index]?._id
            data.driver = routes[index]?.driver?.name
            data.tasks = sortTasks
            data.color = routes[index]?.color

            routesArray.push(data)
        }
        
        assignRoutesUnAssignedTasks(routesArray, dateFunc)
    }

    const assignCoordsPolyline = (tasks, color, line) => {
        let coordsArray = []
        let data = {}
        for(let index in tasks) {
            let coordItem = tasks[index].unload.coords
            coordsArray.push(coordItem.split(','))
            data.position = coordsArray
            data.color = color
        }

        if(tasks.length) {
            line.push(data)
            setLineCoords(line)
        }
    }

    useEffect(() => {
        setLoading(true)
        getTaskFunc()
    }, [routes])

    return (
        <>
            <ButtonContainer style={{fontWeight: 'bold', padding: dailyPlanning && 10, marginLeft: 20, marginBottom: dailyPlanning && 10,  width: dailyPlanning ? 200 : 50, height: dailyPlanning ? 80 : 50}} onClick={() => setMap(!map)}>
                {dailyPlanning ? <div style={{color: COLORS_TRUCK.ORANGE_TRUCK}}>{ i18n.t('TASKS.table.dailyPlanning') }</div> : <ButtonImg src={MapIcon} />}
            </ButtonContainer>
            {
                showTask && 
                <FormCalendar 
                    task={tasksSelected} 
                    routes={routes?.map((item) => ({ label: item.name, value: item._id }))} 
                    reloadList={getTaskFunc} 
                    setShowCalendar={setShowTask} 
                    setMap={setMap}
                 />
            }
            {
                map ?
                <div>
                    <PlansRoutesContainer style={{width: 1250, right: 60}}>
                        <PlansRoutesHeader>
                            <TextHeader>
                                {i18n.t('ROUTES.forms.map.title')}
                            </TextHeader>
                            <ButtonImg 
                                src={Times} 
                                style={{width: 25, cursor: "pointer"}} 
                                onClick={() => {
                                    setMap(!map)
                                    setDate(new Date().setHours(0,0,0,0))
                                }} 
                            />
                        </PlansRoutesHeader>
                        <ContentContainer>
                            <OptionsContainer>
                                <OptionContainer>
                                    <DateContainer>
                                        <FormDateInput
                                            containerStyle={{ width: 200, height: 40 }}
                                            value={date}
                                            // minDate={new Date()}
                                            onChange={(newValue) => {
                                                setDate(new Date(newValue).setHours(0,0,0,0))
                                                getTaskFunc(new Date(newValue).setHours(0,0,0,0))
                                                getTaskFunc(new Date(newValue).setHours(0,0,0,0), true)
                                            }}
                                            required
                                        />
                                    </DateContainer>
                                    <DownloadItenary style={{ width: 200 }} onClick={exportTaskFunc} >
                                        {
                                            !loadingExport ? <>
                                                {i18n.t('ROUTES.forms.map.download')}
                                                <ButtonImg style={{paddingTop: 2, paddingBottom: 0,width: 25, height: 20,}} src={Download} />
                                            </> : <Loader color={ COLORS_TRUCK.ORANGE_TRUCK } />
                                        }
                                    </DownloadItenary>
                                </OptionContainer>
                                <InfoItemsContainer>
                                    <InfoContainer>
                                        <ColorSquare style={{ backgroundColor: COLORS_TRUCK.BLACK_TRUCK }} />
                                        <InfoContentParagraph>
                                            {i18n.t('ROUTES.forms.map.pending')}
                                        </InfoContentParagraph>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <ColorSquare style={{ backgroundColor: COLORS_TRUCK.BLUE_TRUCK }} />
                                        <InfoContentParagraph>
                                            {i18n.t('ROUTES.forms.map.transit')}
                                        </InfoContentParagraph>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <ColorSquare style={{ backgroundColor: COLORS_TRUCK.GREEN_TRUCK }} />
                                        <InfoContentParagraph>
                                            {i18n.t('ROUTES.forms.map.delivered')}
                                        </InfoContentParagraph>
                                    </InfoContainer>
                                </InfoItemsContainer>
                            </OptionsContainer>
                            <MainContainer>
                                <MapContainer
                                    style={{ width: '60%', height: '500px', marginLeft: 40, marginBottom: 20, borderRadius: 15 }}
                                    center={center}
                                    zoom={8}
                                    scrollWheelZoom={false}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                    />
                                        {mapCoords?.map((item, i) =>
                                            <>
                                                <Marker key={i} icon={item.icon} color='color' position={item.position}>
                                                    <Popup>
                                                        <TaskDetailsContainer>
                                                            <TaskDetailsItemsContainer>
                                                            {item.details.task && <TaskDetailsItemContainer>
                                                                <TaskDetailsTitle>
                                                                {i18n.t('HOME.taskdetails.task')}:
                                                                </TaskDetailsTitle>
                                                                <TaskDetailsContentLink onClick={() => navigate(`${TASK_DETAILS_ROUTE}/${item._id}`)}>
                                                                {item.details.task}
                                                                </TaskDetailsContentLink>
                                                            </TaskDetailsItemContainer>}
                                                            <TaskDetailsItemContainer>
                                                                <TaskDetailsTitle>
                                                                {i18n.t('HOME.taskdetails.driver')}:
                                                                </TaskDetailsTitle>
                                                                <TaskDetailsContent>
                                                                {item.details.driver}
                                                                </TaskDetailsContent>
                                                            </TaskDetailsItemContainer>
                                                            <TaskDetailsItemContainer>
                                                                <TaskDetailsTitle>
                                                                {i18n.t('HOME.taskdetails.vehicle')}:
                                                                </TaskDetailsTitle>
                                                                <TaskDetailsContent>
                                                                {item.details.vehicle}
                                                                </TaskDetailsContent>
                                                            </TaskDetailsItemContainer>
                                                            <TaskDetailsItemContainer>
                                                                <TaskDetailsTitle>
                                                                {i18n.t('HOME.taskdetails.trailer')}:
                                                                </TaskDetailsTitle>
                                                                <TaskDetailsContent>
                                                                {item.details.trailer || "N/A"}
                                                                </TaskDetailsContent>
                                                            </TaskDetailsItemContainer>
                                                            </TaskDetailsItemsContainer>
                                                        </TaskDetailsContainer>
                                                        </Popup>
                                                    </Marker>
                                                {
                                                    lineCoords.map(line => <Polyline key={line} stroke positions={line.position} color={line.color} />)
                                                }
                                            </>
                                        )}
                                </MapContainer>
                                <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto', alignItems: 'center', width: 430, marginLeft: 25, height: 500 }}>
                                    <div>
                                        <div>
                                            <SortableRoutes date={date} showTask={getShowTaskFunc} reload={ getTaskFunc } tasks={routesTasks} calculateDate={calculateDate} MenuIcon={MenuIcon} statusOption={statusOption} />
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            loading ?
                                                <TableLoaderContainer>
                                                    <Loader color={COLORS_TRUCK.ORANGE_TRUCK} width={25} height={25} />
                                                </TableLoaderContainer>
                                                : routesTasks.length <= 0 ? <p style={{ textAlign: 'center', margin: 30 }}>{i18n.t("GENERAL.noResults")}</p> : null
                                        }
                                    </div>
                                </div>
                            </MainContainer>
                        </ContentContainer>
                    </PlansRoutesContainer>
                </div>
                :
                <></>
            }
        </>
    )
}
   