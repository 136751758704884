import React, { useEffect, useState } from "react";
import CategoryButton from "../../components/buttons/category-button/CategoryButton";
import FormDateInput from "../../components/inputs/forms/form-date-input/FormDateInput";
import FormNumberInput from "../../components/inputs/forms/form-number-input/FormNumberInput";
import FormSelect from "../../components/inputs/forms/form-select/FormSelect";
import FormTextInput from "../../components/inputs/forms/form-text-input/FormTextInput";
import i18n from "../../i18n/i18n";
import { getCoinAction } from "../profile/profile-actions";
import { paymentConceptTasksArray } from "./paymentConceptTasksArray";
import {
  VehicleDetailsContainer,
  VehicleDetailsContentContainer,
  VehicleDetailsFormsContainer,
  VehicleDetailsFormInputsContainer,
  TableInfoContainer,
  TableTitle,
  TableInfo,
  VehicleDetailsDateInputsContainer,
  ProfileLastButtonsContainer,
  ProfileBillingContainer,
  GeneralErrorContainer,
  GeneralErrorText,
  FixedAmountText
} from "./payment-concept-styles";
import TruckTable from "../../components/truck-table/TruckTable";
import { ACCOUTING_ROUTE } from "../../routes/routes";
import { useNavigate, useParams } from "react-router-dom";


import { openAlert } from "../../redux/reducers/app.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { getDrivers } from "../driver-details/driver-details-actions";
import moment from "moment";
import {
  calculatePaymentConceptAction,
  createPaymentConceptAction,
  exportDriverJourneys,
  getPaymentsConceptsAction,
  removePaymentConceptAction,
  updatePaymentConceptAction
} from "./payment-concept-actions";
import { exportTasks } from "../task-details/tasks-actions";
import { useSelector } from "react-redux";

const PaymentConceptScreen = () => {
  const isMenuOpen = useSelector((state) => state).appSlice.isMenuOpen
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useSelector((state) => state).userSlice
  const [driver, setDriver] = useState("")
  const [activeTable, setActiveTable] = useState("tasks")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [fixed, setFixed] = useState("")
  const [paymentConceptName, setPaymentConceptName] = useState("")
  const [variable, setVariable] = useState("")
  const [variablePerKm, setVariablePerKm] = useState("")
  const [drivers, setDrivers] = useState([])
  const [extra, setExtra] = useState("")
  const [total, setTotal] = useState("")
  const [loading, setLoading] = useState(false)
  const [tasks, setTasks] = useState([])
  const [coin, setCoin] = useState({})
  const [journeys, setJourneys] = useState([])
  const [showInputsErrors, setShowInputsErrors] = useState(false)
  const [generalError, setGeneralError] = useState("")
  const { paymentConceptId } = useParams()

  useEffect(() => {
    getDriversFunc()
    getCoin()

    if (paymentConceptId) {
      getPaymentConceptData()
    }
  }, [])

  const createPaymentComcept = async () => {
    if (!paymentConceptName || !startDate || !endDate || !driver) {
      setShowInputsErrors(true)
      setGeneralError(i18n.t("SIGNUP.completeAllFields"))
      return
    } else {
      setGeneralError("")
      setShowInputsErrors(false)
    }

    setLoading(true)

    try {
      let data = {
        concept: paymentConceptName || null,
        driver_id: driver || null,
        end_date: endDate ? moment(endDate).valueOf() : null,
        extra_remuneration: extra ? Number(extra) : 0,
        fixed_remuneration: fixed || 0,
        start_date: startDate ? moment(startDate).valueOf() : null,
        total: total || 0,
        variable_remuneration: variable ? Number(variable) : 0,
        payment_per_kms: variablePerKm ? Number(variablePerKm) : 0
      }


      if (paymentConceptId) {
        await updatePaymentConceptAction(paymentConceptId, data)
      } else {
        await createPaymentConceptAction(data)
      }

      setLoading(false)
      dispatch(openAlert({
        alertType: "success",
        alertMessage: paymentConceptId ? i18n.t("ACCOUNTING.paymentConceptCreated") : i18n.t("ACCOUNTING.updateSuccess"),
        isAlertOpen: true
      }))
      navigate(ACCOUTING_ROUTE)
    } catch (e) {
      setLoading(false)
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getDriversFunc = async () => {
    try {
      setLoading(true)
      const data = {
        pagination: {
          limit: 30,
          offset: 0
        },
        email: null,
        status: null,
        type: null,
        payment_type: null,
        driver_id: null
      }
      const res = await getDrivers(data)

      setDrivers(res?.data?.myDrivers)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      // if (!search && !status && !type && !paymentType) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }

  const getPaymentConceptData = async () => {
    try {
      setLoading(true)
      const data = {
        _id: paymentConceptId
      }
      const res = await getPaymentsConceptsAction(data)
      const item = res.data.myAccounting[0]
      setPaymentConceptName(item.concept)
      setDriver(item.driver_id)
      setEndDate(item.end_date)
      setExtra(item.extra_remuneration)
      setFixed(item.fixed_remuneration)
      setVariablePerKm(item.payment_per_kms)
      setStartDate(item.start_date)
      setTotal(item.total)
      setVariable(item.variable_remuneration)
      // setDrivers(res?.data?.myDrivers)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      // if (!search && !status && !type && !paymentType) {
      //   dispatch(openAlert({
      //     alertType: "error",
      //     isAlertOpen: true
      //   }))
      // }
    }
  }

  const clean = () => {
    setExtra("")
    setFixed("")
    setTasks([])
    setJourneys([])
    setVariable("")
    setVariablePerKm("")
    setTotal("")
  }
  const calculatePaymentConcept = async () => {
    try {
      clean()
      setLoading(true)
      const data = {
        driver_id: driver,
        extra_remuneration: Number(extra),
        // start_date: startDate,
        // end_date: endDate
        start_date: moment(startDate).set("hour", 0).set("minute", 0).valueOf(),
        end_date: moment(endDate).set("hour", 23).set("minute", 59).valueOf(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      const res = await calculatePaymentConceptAction(data)

      console.log("calculatePaymentConceptAction / response: ", res)
      if (res.data.calculateAccounting) {
        setExtra(res.data.calculateAccounting.extra_remuneration)
        setFixed(res.data.calculateAccounting.fixed_remuneration)
        setTasks(res.data.calculateAccounting.tasks)
        setJourneys(res.data.calculateAccounting.journeys)
        setVariable(res.data.calculateAccounting.variable_remuneration)
        setVariablePerKm(res.data.calculateAccounting.payment_per_kms)
        setTotal(res.data.calculateAccounting.total)
      }
      // setDrivers(res?.data?.myDrivers)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      // if (!search && !status && !type && !paymentType) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
      // }
    }
  }

  const exportTasksFunc = async () => {
    try {
      const data = {
        pagination: {
          limit: 30,
          offset: 0
        },
        driver: driver,
        start_date: moment(startDate).set("hour", 0).set("minute", 0).valueOf(),
        end_date: moment(endDate).set("hour", 23).set("minute", 59).valueOf(),
        status: "FINISHED",
        incidence: null,
        name: null,
        trailer: null,
        vehicle: null
      }
      const res = await exportTasks(data)
      var win = window.open(res.data.exportMyTasks.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const exportDriverJourneysFunc = async () => {
    try {
      const data = {
        pagination: {
          limit: 30,
          offset: 0
        },
        creation_datetime: moment(startDate).set("hour", 0).set("minute", 0).valueOf(),
        finish_datetime: moment(endDate).set("hour", 23).set("minute", 59).valueOf(),
        status: "FINISHED",
      }
      const res = await exportDriverJourneys(driver, data)
      var win = window.open(res.data.exportMyJourneys.fullpath, '_blank');
      win.focus();
    } catch (e) {
      dispatch(openAlert({
        alertType: "error",
        isAlertOpen: true
      }))
    }
  }

  const getCoin = async () => {
    try {
      const response = await getCoinAction()
      const cointSelected = response.data.findAllCoins.filter( coin => coin._id == user.coin)

      if(cointSelected.length > 0) setCoin(cointSelected[0])
    } catch (e) {
      dispatch(openAlert({
        isAlertOpen: true
      }))
    }
  }

  const removePaymentConcept = async () => {
    dispatch(openAlert({
      alertType: "question",
      alertMessage: i18n.t("ACCOUNTING.areYouSureToRemove"),
      isAlertOpen: true,
      alertOnYesButtonTitle: i18n.t("GENERAL.remove"),
      alertOnYes: async () => {
        try {
          setLoading(true)
          await removePaymentConceptAction(paymentConceptId)
          dispatch(openAlert({
            alertType: "success",
            alertMessage: i18n.t("ACCOUNTING.removedSuccessMessage"),
            isAlertOpen: true
          }))
          setLoading(false)
          navigate(ACCOUTING_ROUTE)
        } catch (e) {
          setLoading(false)
          dispatch(openAlert({
            alertType: "error",
            isAlertOpen: true
          }))
        }
      }
    }))
  }

  const header = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('ACCOUNTING.forms.accounting.title')}
      </TableTitle>
      <TableInfo>
        {i18n.t('ACCOUNTING.from')} {startDate ? moment(startDate).format("DD-MM-YYYY") : "N/A"} {i18n.t('ACCOUNTING.to')} {endDate ? moment(endDate).format("DD-MM-YYYY") : "N/A"}
      </TableInfo>
    </TableInfoContainer>
    <div style={{ display: "flex" }}>
      <CategoryButton
        onClick={() => setActiveTable("jorneys")}
        active={true}
        icon=""
        title={i18n.t('ACCOUNTING.forms.accounting.journeys')}
      />
      <CategoryButton
        onClick={() => exportTasksFunc()}
        active={false}
        icon=""
        title={i18n.t('ACCOUNTING.forms.accounting.buttons.export')}
      />
    </div>
  </ProfileBillingContainer>

  const journeysHeader = <ProfileBillingContainer>
    <TableInfoContainer>
      <TableTitle>
        {i18n.t('ACCOUNTING.forms.accounting.journeysTitle')}
      </TableTitle>
      <TableInfo>
        {i18n.t('ACCOUNTING.from')} {startDate ? moment(startDate).format("DD-MM-YYYY") : "N/A"} {i18n.t('ACCOUNTING.to')} {endDate ? moment(endDate).format("DD-MM-YYYY") : "N/A"}
      </TableInfo>
    </TableInfoContainer>
    <div style={{ display: "flex" }}>
      <CategoryButton
        onClick={() => setActiveTable("tasks")}
        active={true}
        icon=""
        title={i18n.t('ACCOUNTING.forms.accounting.tasks')}
      />
      <CategoryButton
        onClick={() => exportDriverJourneysFunc()}
        active={false}
        icon=""
        title={i18n.t('ACCOUNTING.forms.accounting.buttons.export')}
      />
    </div>
  </ProfileBillingContainer>

  // console.log("tasks: ", tasks)
  // console.log("journeys: ", journeys)
  return (
    <VehicleDetailsContainer style={{ position: isMenuOpen && 'relative' }}>
      <VehicleDetailsContentContainer>
        <div style={{display: "flex",justifyContent: "center"}}>
          <TableInfoContainer>
            <TableTitle>
              {i18n.t('ACCOUNTING.forms.concept.title')}
            </TableTitle>
          </TableInfoContainer>
        </div>
        {generalError && <GeneralErrorContainer>
          <GeneralErrorText>{generalError}</GeneralErrorText>
        </GeneralErrorContainer>}
        <VehicleDetailsFormsContainer>
          <VehicleDetailsFormInputsContainer>
            <FormSelect
              title={i18n.t('ACCOUNTING.forms.concept.driver')}
              value={driver}
              onChange={({ target: { value } }) => !loading ? setDriver(value) : null}
              items={drivers.map(driver => ({ value: driver._id, label: `${driver.name} ${driver.surnames}` }))}
            />
            <VehicleDetailsDateInputsContainer>
              <FormDateInput
                title={i18n.t('ACCOUNTING.forms.concept.startDate')}
                value={startDate}
                containerStyle={{ width: 150 }}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                disabled={loading}
                showError={!startDate && showInputsErrors}
                required
              />
              <FormDateInput
                title={i18n.t('ACCOUNTING.forms.concept.endDate')}
                value={endDate}
                containerStyle={{ width: 150 }}
                minDate={startDate}
                // maxDate={new Date()}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                disabled={loading}
                showError={!endDate && showInputsErrors}
                required
              />
            </VehicleDetailsDateInputsContainer>
            <FormTextInput
              title={i18n.t('ACCOUNTING.forms.concept.paymentConceptName')}
              type={"text"}
              value={paymentConceptName}
              onChange={({ target: { value } }) => setPaymentConceptName(value)}
              disabled={loading}
              showError={!paymentConceptName && showInputsErrors}
              required
            />
            <ProfileLastButtonsContainer>
              <CategoryButton
                onClick={() => calculatePaymentConcept()}
                active
                disabled={!startDate || !endDate || !driver}
                icon=""
                title={i18n.t('ACCOUNTING.forms.concept.buttons.calculate')}
                width={120}
              />
            </ProfileLastButtonsContainer>
          </VehicleDetailsFormInputsContainer>
          <VehicleDetailsFormInputsContainer>
            <FormNumberInput
              title={i18n.t('ACCOUNTING.forms.concept.fixed')}
              value={fixed}
              placeholder="0.00"
              rightComponent={<FixedAmountText>{coin?.symbol}</FixedAmountText>}
              onValueChange={(value) => setFixed(value)}
              disabled={loading}
            />
            <FormNumberInput
              title={i18n.t('ACCOUNTING.forms.concept.variable')}
              value={variable}
              placeholder="0.00"
              rightComponent={<FixedAmountText>{coin?.symbol}</FixedAmountText>}
              onValueChange={(value) => setVariable(value)}
              disabled={loading}
            />
            <FormNumberInput
              title={i18n.t('ACCOUNTING.forms.concept.variablePerKm')}
              value={variablePerKm}
              placeholder="0.00"
              rightComponent={<FixedAmountText>{coin?.symbol}</FixedAmountText>}
              onValueChange={(value) => setVariablePerKm(value)}
              disabled={loading}
            />
            <FormNumberInput
              title={i18n.t('ACCOUNTING.forms.concept.extra')}
              value={extra}
              placeholder="0.00"
              rightComponent={<FixedAmountText>{coin?.symbol}</FixedAmountText>}
              onValueChange={(value) => setExtra(value)}
              disabled={loading}
            />
            <FormNumberInput
              title={i18n.t('ACCOUNTING.forms.concept.total')}
              value={total}
              placeholder="0.00" 
              rightComponent={<FixedAmountText>{coin?.symbol}</FixedAmountText>}
              onValueChange={(value) => setTotal(value)}
              disabled={loading}
            />
            <ProfileLastButtonsContainer>
              {paymentConceptId && <CategoryButton
                onClick={() => removePaymentConcept()}
                active={false}
                icon=""
                title={i18n.t('ACCOUNTING.forms.concept.buttons.remove')}
                width={120}
                disabled={loading}
              />}
              <CategoryButton
                onClick={() => createPaymentComcept()}
                active
                icon=""
                title={i18n.t('ACCOUNTING.forms.concept.buttons.save')}
                width={120}
                loading={loading}
              />
            </ProfileLastButtonsContainer>
          </VehicleDetailsFormInputsContainer>
        </VehicleDetailsFormsContainer>
        {activeTable === "tasks" && <TruckTable
          type="paymentConceptTasks"
          style={{
            maxHeight: 700,
            minHeight: 300,
            overflow: "auto"
          }}
          titles={[
            { label: i18n.t('ACCOUNTING.forms.concept.tasks.name') },
            { label: i18n.t('ACCOUNTING.forms.concept.tasks.startDate') },
            { label: i18n.t('ACCOUNTING.forms.concept.tasks.endDate') },
            { label: i18n.t('ACCOUNTING.forms.concept.tasks.vehicle') },
            { label: i18n.t('ACCOUNTING.forms.concept.tasks.trailer') },
            { label: i18n.t('ACCOUNTING.forms.concept.tasks.issue') },
            { label: i18n.t('ACCOUNTING.forms.concept.tasks.state') },
          ]}
          data={tasks}
          header={header}
        />}
        {activeTable === "jorneys" && <TruckTable
          type="paymentConceptJourneys"
          style={{
            maxHeight: 700,
            minHeight: 300,
            overflow: "auto"
          }}
          titles={[
            { label: i18n.t('ACCOUNTING.forms.concept.journeys.journeyStartDate') },
            { label: i18n.t('ACCOUNTING.forms.concept.journeys.journeyEndDate') },
            { label: i18n.t('ACCOUNTING.forms.concept.journeys.distance') },
            { label: i18n.t('ACCOUNTING.forms.concept.journeys.lunch') },
            { label: i18n.t('ACCOUNTING.forms.concept.journeys.dinner') },
            { label: i18n.t('ACCOUNTING.forms.concept.journeys.nocturnal') },
            { label: i18n.t('ACCOUNTING.forms.concept.journeys.nocturnalInternational') },
          ]}
          data={journeys}
          header={journeysHeader}
        />}
      </VehicleDetailsContentContainer>
    </VehicleDetailsContainer>
  )
}

export default PaymentConceptScreen