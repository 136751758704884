import styled from 'styled-components';
import { COLORS_TRUCK } from '../../../../utils/colors';

export const FormTextInputMainContainer = styled.div`
position: relative;
`;

export const TextInputContainer = styled.div`
width: 300px;
max-width: 300px;
height: 60px;
box-sizing: border-box;
border-radius: 5px;
display: flex;
padding: 10px;
justify-content: center;
align-items: center;

@media (max-width: 320px) {
width: 250px;
  }
`;

export const ModalContainer = styled.div`
width: 100%;
position: absolute;
display: flex;
align-items: center;
justify-content: center;
z-index: 4;
`

export const SendData = styled.button`
border-radius: 5px;
width: 86%;
margin: 10px 20px;
color: ${COLORS_TRUCK.WHITE_TRUCK};
font-size: 18px;
padding: 8px 0;
`

export const ModalContainerColor = styled.div`
background: ${COLORS_TRUCK.WHITE_TRUCK};
border: 1px solid ${COLORS_TRUCK.ORANGE_TRUCK};
border-radius: 5px;
display: flex;
flex-direction: column;
padding: 10px;
borderRadius: 10px;
`

export const CustomTextInput = styled.input`
background-color: transparent;
width: 100%;
height: 100%;
border: none;
font-size: 15px;
color: ${COLORS_TRUCK.BLACK_TRUCK};
margin: 0;
`;

export const ErrorMessage = styled.p`
color: ${COLORS_TRUCK.ORANGE_TRUCK};
font-size: 13px;
`;

export const TextInfo = styled.p`
font-size: 18px;
`