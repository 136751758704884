// import * as navigation from '../../routes/navigation';
import client from '../../utils/api';
import { GET_CHAT_MUTATION, SEND_MESSAGE_MUTATION, GET_CHATS_MUTATION, READ_MESSAGES_MUTATION, GET_CHAT_CLIENT_MUTATION, READ_MESSAGES_CLIENT_MUTATION, SEND_MESSAGE_CLIENT_MUTATION, GET_CHATS_CLIENTS_MUTATION } from './chats-queries';
// import {handleAlert} from '../../general/functions';



/**
 * getChat - action for get a chat
 *
 * @param {object} formData - getChat data
 * @param {string} dest_id - Dest id
 * @returns {any}
 */
 export const getChat = (
  dest_id,
  formData
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("getChat / dest_id: ", dest_id)
      // console.log("getChat / formData: ", formData)
      const response = await client.query({
        query: GET_CHAT_MUTATION,
        variables: {
          dest_id: dest_id,
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      // console.log("getChat / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("getChat / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

export const getChatClient = (
  dest_id,
  formData
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("getChat / dest_id: ", dest_id)
      // console.log("getChat / formData: ", formData)
      const response = await client.query({
        query: GET_CHAT_CLIENT_MUTATION,
        variables: {
          dest_id: dest_id,
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      // console.log("getChat / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("getChat / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getChats - action for get a chat
 *
 * @param {object} formData - getChats data
 * @param {string} name - Chat name
 * @returns {any}
 */
 export const getChats = (
  name,
  formData
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_CHATS_MUTATION,
        variables: {
          name: name,
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * getChats - action for get a chat
 *
 * @param {object} formData - getChats data
 * @param {string} name - Chat name
 * @returns {any}
 */
export const getChatsClients = (
  name,
  formData
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_CHATS_CLIENTS_MUTATION,
        variables: {
          name: name,
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

export const getChatsClient = (
  name,
  formData
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      const response = await client.query({
        query: GET_CHATS_MUTATION,
        variables: {
          name: name,
          input: {
            ...formData
          },
        },
        fetchPolicy: 'network-only',
      });
      resolve(response)
      // throw Object.assign(
      //   new Error("Ha ocurrido un error"),
      //   { code: 500 }
      // );
    } catch (error) {
      reject(error)
      // setLoading(false);
    }
  })
};

/**
 * sendMessageAction - action for update a driver
 *
 * @param {object} formData - sendMessageAction data
 * @returns {any}
 */
 export const sendMessageAction = (formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: SEND_MESSAGE_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
      });
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
};

export const sendMessageClientAction = (formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: SEND_MESSAGE_CLIENT_MUTATION,
        variables: {
          input: {
            ...formData
          },
        },
      });
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
};

/**
 * readMessagesAction - action for update a driver
 *
 * @param {sting} destId - dest id
 * @returns {any}
 */
 export const readMessagesAction = (destId) => {
  return new Promise(async (resolve, reject) => {
    try {
      // setLoading(true);
      // console.log("readMessagesAction / destId: ", destId)
      const response = await client.mutate({
        mutation: READ_MESSAGES_MUTATION,
        variables: {
          dest_id: destId,
        },
      });
      // console.log("readMessagesAction / response: ", response)
      resolve(response)
      // throw Object.assign(
        //   new Error("Ha ocurrido un error"),
        //   { code: 500 }
        // );
      } catch (error) {
      // console.log("readMessagesAction / error: ", error)
      reject(error)
      // setLoading(false);
    }
  })
};

export const readMessagesClientAction = (destId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.mutate({
        mutation: READ_MESSAGES_CLIENT_MUTATION,
        variables: {
          dest_id: destId,
        },
      });
      resolve(response)
    } catch (error) {
      reject(error)
    }
  })
};