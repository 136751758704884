export const isInfoFormCompleted = ( info, setShowInputsErrors, rol ) => {
    if ( info.start_date && (info.client || rol == 'client') && info.name ) {
      setShowInputsErrors(false)
      return true
    }

    setShowInputsErrors(true)
    return false
}

export const isLoadsFormCompleted = (load, setShowInputsErrors) => {
  if ( load?.direction ) {
    setShowInputsErrors(false)
    return true
  }

  setShowInputsErrors(true)
  return false
}

export const isNumber = (value) => {
  return isNaN(value)
}

export const isDownloadsFormCompleted = (download, setShowInputsErrors) => {
    if ( download?.direction && download.delivery_object && download.recipient_name && download.recipient_phone) {
      setShowInputsErrors(false)
      return true
    }

    console.log('first')
    setShowInputsErrors(true)
    return false
  }