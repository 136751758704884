import styled from 'styled-components';
import { COLORS_TRUCK } from '../../utils/colors';

export const TruckTableContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`;

export const ContentCheckbox = styled.div`
display: flex;
justify-content: center
`

export const LoaderContainer = styled.div`
width: 20px;
height: 20px;
background: ${COLORS_TRUCK.ORANGE_TRUCK};
padding: 5px;
border-radius: 5px;
cursor: pointer;
`;

export const ActionButton = styled.img`
width: 20px;
height: 20px;
background: ${COLORS_TRUCK.ORANGE_TRUCK};
padding: 5px;
border-radius: 5px;
cursor: pointer;
`;

export const UserPicture = styled.img`
width: 40px;
height: 40px;
border-radius: 20px;
`;

export const Flag = styled.img`
width: 30px;
height: 20px;
`;

export const ProfileTableContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
@media (max-width: 1200px) {
    width: 100vw !important;
  }
`;

export const TableLoaderContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;

export const UnreadMessages = styled.div`
width: 20px;
height: 20px;
display: flex;
justify-content: center;
color: ${COLORS_TRUCK.ORANGE_TRUCK};
background: ${COLORS_TRUCK.WHITE_TRUCK};
border-radius: 100%;
font-weight: bold;
`;

export const TextLink = styled.p`
color: ${COLORS_TRUCK.BLUE_TRUCK};
cursor: pointer;
border-bottom: 1px solid ${COLORS_TRUCK.BLUE_TRUCK};
`;

export const Container = styled.div`
width: 220vh;

@media (max-width: 1150px) {
  width: 130%;
}

@media (max-width: 1000px) {
  width: 100%;
  margin-top: 40px;
}

`